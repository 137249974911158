import _ from "lodash";
import { GET_AWPD_FILE_CONTENT, CLEAR_FILE_CONTENT } from "../actions/types";
import { ConfigFile } from "./../components/ClientManagement/ConfigFiles/fileTypes";

const awpdFileContentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AWPD_FILE_CONTENT:
      return { ..._.mapKeys(action.payload, "id") };
    case CLEAR_FILE_CONTENT:
      if (_.includes(action.payload, ConfigFile.AWPD)) {
        return {};
      }
      return state;
    default:
      return state;
  }
};

export default awpdFileContentReducer;
