import history from "../../history";

class Timer {
  static instance = null;

  static getInstance = () => {
    if (!Timer.instance) {
      Timer.instance = new Timer();
    }
    return Timer.instance;
  };

  startTimer = (expirationDate) => {
    this.clearTimer();

    // First make sure we aren't getting a string or an undefined expiration date
    if (typeof expirationDate === "string") {
      expirationDate = parseInt(expirationDate);
    } else if (expirationDate === undefined) {
      expirationDate = parseInt(sessionStorage.getItem("expirationDate"));
    }

    // If after the above the expiration is still undefined, or it isn't a number, push the user to log back in
    if (expirationDate === undefined || isNaN(expirationDate)) {
      this.initiateRelogin();
    }

    this.interval = setInterval(() => {
      const curDate = new Date();
      const expDate = new Date(expirationDate);

      if (curDate > expDate) {
        this.clearTimer();
        this.initiateRelogin();
      }
    }, 1000);
  };

  initiateRelogin = () => {
    // Clear out local storage (currently holds filter choices for users)
    localStorage.clear();

    history.push("/", { timeout: true });
  };

  clearTimer = () => {
    clearInterval(this.interval);
  };
}

export default Timer;
