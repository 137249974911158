import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import _ from "lodash";

import {
  clearClients,
  getClient,
  getProducts,
  tryAddContract,
  toggleSidebarVisibility,
} from "../../../actions";
import { populateBreadCrumbs } from "../../../actions";
import "./Contract.css";
import ClientContractForm from "./ClientContractForm";
import { BILLING_TYPES, PRODUCT_CATEGORIES } from "../../../utils";

class AddClientContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLoaded: false,
      clientId: this.props.match.params.clientId,
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
      ],
      finalCrumb: "Add Client Product",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getProducts();
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  }

  onSubmit = (formValues) => {
    this.props.tryAddContract(formValues, this.props.selectedClient.id);
  };

  render() {
    if (!this.props.selectedClient || _.isEmpty(this.props.products)) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h1>Add Client Product</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <ClientContractForm
              billingTypes={BILLING_TYPES}
              contractTypes={PRODUCT_CATEGORIES}
              products={this.props.products}
              onSubmit={this.onSubmit}
              selectedClientId={this.props.selectedClient.id}
              currentContracts={this.props.selectedClient.contracts}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    products: _.filter(
      _.sortBy(Object.values(state.products), ["sortOrder"]),
      (product) => {
        return product.description !== "No Products Assigned";
      }
    ),
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getProducts,
  tryAddContract,
  toggleSidebarVisibility,
  populateBreadCrumbs,
})(AddClientContract);
