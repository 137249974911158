import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import {
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getClientContractProductAuditLog,
  clearClientContractProductsAuditLogs,
  getClient
} from "../../../actions";
import { localDateTimeFormatter } from "../../../utils";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import ClientContractProductAuditLogSearchForm from "./ClientContractProductAuditLogSearchForm";

class ClientContractProductAuditLog extends Component {
  constructor(props) {
    super(props);

    const clientId = this.props.match.params.clientId
      ? this.props.match.params.clientId
      : 0;

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      navLoaded: false,
      totalSizePerPage: 15,
      breadcrumbsLoaded: false,
      searchTerms: {
        clientId: clientId,
        searchDate: "",
        userNames: [],
        products: [],
        changeTypes: [],
        auditDescriptions: []
      },
      clientId: clientId
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: `/clients/view/${this.props.selectedClient.id}`,
          name: this.props.selectedClient.name,
          active: false
        }
      ],
      finalCrumb: "Client Contract Audit Log"
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.getPage();
    this.props.getClient(this.props.match.params.clientId);
    this.props.toggleSidebarVisibility();
  }

  componentDidUpdate() {
    if (
      !this.state.breadcrumbsLoaded &&
      !_.isEmpty(this.props.auditLogs) &&
      !_.isEmpty(this.props.selectedClient)
    ) {
      this.setupBreadCrumbs();
      this.setState({ breadcrumbsLoaded: true });
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearClientContractProductsAuditLogs();
  }

  getPage = () => {
    this.props.getClientContractProductAuditLog(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage, this.props.sorts);
  };

  onSearchSubmit = searchTerms => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  COLUMNS = [
    {
      dataField: "updatedDate",
      text: "Change Date/Time",
      sort: true,
      formatter: localDateTimeFormatter
    },
    {
      dataField: "userName",
      text: "User",
      sort: true
    },
    {
      dataField: "productDescription",
      text: "Product",
      sort: true
    },
    {
      dataField: "changeDescription",
      text: "Change Type",
      sort: true
    },
    {
      dataField: "auditDescription",
      text: "Audit Description",
      sort: true
    }
  ];

  render() {
    if (
      _.isEmpty(this.props.auditLogs) ||
      _.isEmpty(this.props.selectedClient)
    ) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Client / Products Audit Log</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <ClientContractProductAuditLogSearchForm
              onSubmit={this.onSearchSubmit}
              searchTerms={this.state.searchTerms}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="id"
              paginated
              remote
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.auditLogs.totalElements}
              data={this.props.auditLogs.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auditLogs: state.clientContractProductAuditLogs.auditLogs,
    selectedClient: state.clients[ownProps.match.params.clientId]
  };
};

export default connect(
  mapStateToProps,
  {
    populateBreadCrumbs,
    toggleSidebarVisibility,
    getClientContractProductAuditLog,
    clearClientContractProductsAuditLogs,
    getClient
  }
)(ClientContractProductAuditLog);
