import { GET_REBATE_REF_NDCS } from "../actions/types";

const rebateReferenceNdcReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REBATE_REF_NDCS:
      return action.payload;
    default:
      return state;
  }
};

export default rebateReferenceNdcReducer;
