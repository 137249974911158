import React from "react";
import ReactDOM from "react-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";

const AlertModalv2 = (props) => {
  const closeModal = () => {
    if (props.closeCallback) {
      props.closeCallback();
    }
    props.toggle();
  };

  return ReactDOM.createPortal(
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={closeModal}
        className={props.className}
        size={props.size}
        scrollable
      >
        <ModalHeader toggle={closeModal}>{props.header}</ModalHeader>

        <ModalBody className={props.custBodyClass}>{props.children}</ModalBody>
        <ModalFooter>
          <Button
            color={props.isError ? "danger" : "primary"}
            onClick={closeModal}
          >
            {props.alertBtnText}
          </Button>
        </ModalFooter>
      </Modal>
    </>,
    document.querySelector("#modal"),
  );
};

AlertModalv2.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  isError: PropTypes.bool,
  alertBtnText: PropTypes.string,
  className: PropTypes.string,
  custBodyClass: PropTypes.string,
  closeCallback: PropTypes.func,
  size: PropTypes.string,
};

AlertModalv2.defaultProps = {
  alertBtnText: "Ok",
  isError: false,
  size: null,
};

export default AlertModalv2;
