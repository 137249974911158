import React, { Component } from "react";
import { Col, Container, Row, ListGroup, ListGroupItem } from "reactstrap";

class RollinExpandRow extends Component {
  render = () => {
    const drugCats = this.props.drugCats;
    const mpds = this.props.mpds;
    const outputStats = (msc, ndcCount, mfgCount) => {
      return (
        <div>
          MSC '{msc}'&nbsp;
          <span className="halfBold text-primary">{ndcCount}</span>
          &nbsp;NDCs,&nbsp;
          <span className="halfBold text-primary">{mfgCount}</span>
          &nbsp;mfrs&nbsp;
        </div>
      );
    };
    const rollinStats = this.props.rollinStats;
    return (
      <Container fluid>
        <Row>
          <Col md={2}>
            <h4>GPI: NDC &amp; Mfrs Counts</h4>
          </Col>
          <Col>
            <h4>Drug Category</h4>
          </Col>
          <Col>
            <h4>LC Therapeutic Alternative Files</h4>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <ListGroup className="list-unstyled">
              <ListGroupItem>
                {!rollinStats && <span> No available NDC remain</span>}
                {rollinStats && (
                  <span>
                    {outputStats(
                      "M",
                      rollinStats.ndcMCount,
                      rollinStats.manufacturingMCount,
                    )}
                    {outputStats(
                      "O",
                      rollinStats.ndcOCount,
                      rollinStats.manufacturingOCount,
                    )}
                    {outputStats(
                      "N",
                      rollinStats.ndcNCount,
                      rollinStats.manufacturingNCount,
                    )}
                    {outputStats(
                      "Y",
                      rollinStats.ndcYCount,
                      rollinStats.manufacturingYCount,
                    )}
                  </span>
                )}
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col>
            <ListGroup>
              <ListGroupItem>
                <ul>
                  {drugCats.map((drugCat) => {
                    return (
                      <li key={drugCat.key}>
                        {drugCat.name}
                        {!drugCat.ahfsMatch && drugCat.gpiLevel && (
                          <span>
                            &nbsp;
                            {`GPI(${drugCat.gpiLevel})`}
                            {drugCat.foundWithMultiSource && "/MSC"}
                          </span>
                        )}
                        {drugCat.ahfsMatch && (
                          <span>
                            &nbsp;-
                            <span className="text-danger">
                              &nbsp;AHFS match
                            </span>
                          </span>
                        )}
                        {drugCat.clinicalOperationsReview && (
                          <span className="text-danger">
                            &nbsp;(Clinical Operations needs to define.)
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col>
            <ListGroup>
              <ListGroupItem>
                <ul>
                  {mpds.map((mpd) => {
                    return (
                      <li key={mpd.id}>
                        {mpd.gpiLevel && (
                          <span className="halfBold">
                            {`GPI(${mpd.gpiLevel})`}
                            {mpd.foundWithMultiSource && "/MSC"}
                            &nbsp;
                          </span>
                        )}
                        {mpd.name}
                      </li>
                    );
                  })}
                </ul>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  };
}

export default RollinExpandRow;
