import _ from "lodash";

export const isConfigFileLocked = (benefits, configFileId) => {
  let benefit = _.find(benefits, (benefit) => {
    return _.find(benefit.configFiles, (file) => {
      return file.id === configFileId;
    });
  });

  // Check to see if the GP file is connected to a benefit via tie in
  if (!benefit) {
    benefit = _.find(benefits, (benefit) => {
      if (benefit.clientTieIn && benefit.clientTieIn.clientGroups.length > 0) {
        return _.find(benefit.clientTieIn.clientGroups, (clientGroup) => {
          return clientGroup.correlation.id === configFileId;
        });
      }
    });
  }
  return benefit ? true : false;
};
