import { LOADING } from "../actions/types";

const initialState = {
  loading: false,
  message: "Loading...",
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        loading: action.payload.loading,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export default loadingReducer;
