import _ from "lodash";
import { GET_PRODUCTS } from "../actions/types";

const productsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    default:
      return state;
  }
};

export default productsReducer;
