import { GET_EQGROUPS } from "../actions/types";

const eqGroupsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EQGROUPS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default eqGroupsReducer;
