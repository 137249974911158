import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  renderCheckbox,
  renderInput,
} from "../../../../utils/forms/inputRenderers";
class InternalMPDReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    const { handleSubmit, invalid, submitting, pristine } = this.props;

    return (
      <Form onSubmit={handleSubmit} autoComplete="off">
        <Row>
          <Col>
            <Field
              id="messages"
              name="messages"
              type="hidden"
              showError
              component={renderInput}
            />
          </Col>
        </Row>
        <FormGroup check>
          <Row>
            <Col>
              <Field
                id="includeErroredDrugPairs"
                name="includeErroredDrugPairs"
                placeholder="Include Errored Drug Pairs"
                defaultChecked={false}
                component={renderCheckbox}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                id="includeCategoryDrugPairs"
                name="includeCategoryDrugPairs"
                placeholder="Include Drug Category Excluded Drug Pairs"
                defaultChecked={false}
                component={renderCheckbox}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                id="includeExcludedDrugPairs"
                name="includeExcludedDrugPairs"
                placeholder="Include GPI Excluded Drug Pairs"
                defaultChecked={false}
                component={renderCheckbox}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                id="includeMessages"
                name="includeMessages"
                placeholder="Include Messages"
                defaultChecked={false}
                component={renderCheckbox}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                id="includeAlternateDrugs"
                name="includeAlternateDrugs"
                placeholder="Include Alternates"
                defaultChecked={false}
                component={renderCheckbox}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                id="includeNDCs"
                name="includeNDCs"
                placeholder="Include NDCs"
                defaultChecked={false}
                component={renderCheckbox}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row>
          <Col>
            <Button
              className="mt-3"
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Export VBR Format (Internal Use File)
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const selector = formValueSelector("internalMPDReportForm");

const mapStateToProps = (state) => {
  return {
    includeErroredDrugPairs: selector(state, "includeErroredDrugPairs"),
    includeCategoryDrugPairs: selector(state, "includeCategoryDrugPairs"),
    includeExcludedDrugPairs: selector(state, "includeExcludedDrugPairs"),
    includeMessages: selector(state, "includeMessages"),
    includeAlternateDrugs: selector(state, "includeAlternateDrugs"),
  };
};

const hasTrueValues = (values) => {
  return Object.keys(values)
    .filter((k) => k.startsWith("include"))
    .map((k) => values[k])
    .filter((v) => v).length;
};

const validate = (values) => {
  if (!hasTrueValues(values)) {
    return { messages: "At least one checkbox is required" };
  } else {
    return {};
  }
};
export default connect(mapStateToProps)(
  reduxForm({ form: "internalMPDReportForm", validate })(InternalMPDReportForm)
);
