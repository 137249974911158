import {
  TOGGLE_SIDEBAR_VIS,
  POPULATE_SIDEBAR,
  TOGGLE_SIDEBAR_COLLAPSE,
} from "../actions/types";

const INITIAL_STATE = { visible: true, open: true, actions: [] };

const sidebarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_VIS:
      return { ...state, visible: !state.visible };
    case POPULATE_SIDEBAR:
      return { ...state, actions: action.payload };
    case TOGGLE_SIDEBAR_COLLAPSE:
      return { ...state, open: !state.open };
    default:
      return state;
  }
};

export default sidebarReducer;
