import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getClinicalSidebarItems } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  getDrugCategories,
  getDecisionGridStatuses,
  tryCatRefSearch,
  tryUpdateCatRef,
  tryDeleteCatRef,
} from "../../../actions";
import _ from "lodash";
import { Container, Row, Col, Button, Input } from "reactstrap";
import { FaTrashAlt } from "react-icons/fa";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import cellEditFactory from "react-bootstrap-table2-editor";
import {
  Role,
  HEADER_STYLES,
  COLUMN_LEGEND,
  PRODUCT_STATUS_LIST,
  PRODUCT_STATUS,
  getSessionFiltersOrDefault,
  SESSION_KEYS,
} from "../../../utils";
import CategoryRefGPISearchForm from "./CategoryRefGPISearchForm";
import ConfirmationModal from "../../Modal/ConfirmationModal";

class CategoryRefView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      isAuthorized: _.some(this.props.roles, (role) => {
        return (
          role.name === Role.CLINICAL_APPROVER.name ||
          role.name === Role.CLINICIAN.name
        );
      }),
      isDeleteOpen: false,
    };
  }

  componentDidMount = () => {
    this.setupBreadCrumbs();
    this.setupSidebar();
    this.props.getDrugCategories();
    this.getPage();
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [{ to: "/dashboard", name: "Home", active: false }],
      finalCrumb: "Category Reference GPIs",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  setupSidebar = () => {
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  toggleDeleteModal = (catRef) => {
    if (catRef && catRef.hasOwnProperty("ahfsDescription")) {
      const ahfsName = catRef.ahfsDescription.description;
      const drugCategoryName = catRef.drugCategory
        ? catRef.drugCategory.description
        : "NOT ASSIGNED";
      const content = (
        <span>
          Are you sure you want to delete <strong>{catRef.gpi}</strong> with
          AHFS Class: <strong>{ahfsName}</strong> associated with the
          ActiveRADAR Drug Category:
          <strong>
            &nbsp;
            {drugCategoryName}
          </strong>
          ?
        </span>
      );

      this.setState({
        isDeleteOpen: !this.state.isDeleteOpen,
        dModalHeader: `Delete ${catRef.gpi} with ${ahfsName} to ${drugCategoryName} Reference`,
        dModalContent: content,
        dModalAction: this.props.tryDeleteCatRef,
        selectedCatRefId: catRef.id,
      });
    } else {
      this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
    }
  };

  getPage = () => {
    const localStorageFilters = getSessionFiltersOrDefault(
      SESSION_KEYS.CAT_REF_LIST
    );
    this.props.tryCatRefSearch(
      localStorageFilters,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  getCellEditFactory = () => {
    return cellEditFactory({
      mode: "click",
      blurToSave: true,
      nonEditableRows: this.getDisabledEditRows,
    });
  };

  getDisabledEditRows = () => {
    if (this.state.isAuthorized) {
      return [];
    }

    return this.props.catRefs.content.map((catRef) => {
      return catRef.id;
    });
  };

  getSelectRow = () => {
    return {
      mode: "checkbox",
      clickToSelect: false,
      headerColumnStyle: { width: "3%" },
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
  };

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedCatRefs: [...this.state.selectedCatRefs, row.id],
      }));
    } else {
      this.setState(() => ({
        selectedCatRefs: this.state.selectedCatRefs.filter((x) => x !== row.id),
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      this.setState(() => ({
        selectedCatRefs: ids,
      }));
    } else {
      this.setState(() => ({
        selectedCatRefs: [],
      }));
    }
  };

  onSearchSubmit = () => {
    this.setState({ currPage: 1 }, () => {
      this.getPage();
    });
  };

  onInputChange = ({ currentTarget }) => {
    const selectedId = parseInt(currentTarget.getAttribute("data-catrefid"));
    const selectedCatRef = _.find(this.props.catRefs.content, [
      "id",
      selectedId,
    ]);

    switch (currentTarget.name) {
      case "status":
        selectedCatRef.status = currentTarget.value;
        break;
      case "drugCatId":
        selectedCatRef.drugCategory = { id: currentTarget.value };
        break;
      case "anticipatedCategory":
        selectedCatRef.anticipatedCategory = currentTarget.value;
        break;
      default:
        break;
    }
    this.props.tryUpdateCatRef(selectedCatRef);
  };

  anticipatedCatRenderer = (editorProps, value, row) => {
    return (
      <Input
        type="text"
        id="anticipatedCategory"
        name="anticipatedCategory"
        defaultValue={value}
        onBlur={this.onInputChange}
        onKeyUp={({ key, target }) => {
          if (key === "Enter") {
            this.onInputChange({ currentTarget: target });
          }
        }}
        data-catrefid={row.id}
      />
    );
  };

  dropdownRenderer = (editorProps, value, row, column) => {
    const isProductStatus = column.dataField === "status";
    const inputNameId = isProductStatus ? "status" : "drugCatId";
    let selectedValue = "";

    if (isProductStatus && row.status) {
      selectedValue = row.status;
    } else if (!isProductStatus && row.drugCategory) {
      selectedValue = row.drugCategory.id;
    }

    const options = isProductStatus
      ? PRODUCT_STATUS_LIST
      : this.props.drugCategories;

    return (
      <Input
        type="select"
        id={inputNameId}
        name={inputNameId}
        value={selectedValue}
        onChange={this.onInputChange}
        data-catrefid={row.id}
      >
        <option>--Select--</option>
        {options.map((option) => {
          const keyVal = isProductStatus ? option.value : option.id;
          const label = isProductStatus ? option.label : option.description;
          return (
            <option key={keyVal} value={keyVal}>
              {label}&nbsp;&nbsp;&nbsp;
            </option>
          );
        })}
      </Input>
    );
  };

  belongsToNonRetiredGrid = (row) => {
    return _.some(row.dpos, (dpo) => {
      return (
        dpo.decisionGrid.status.name !== "Retired" &&
        dpo.decisionGrid.status.name !== "Archived"
      );
    });
  };

  actionFormatter = (_, row) => {
    const disabled =
      !this.state.isAuthorized ||
      this.belongsToNonRetiredGrid(row) ||
      row.status === "FINAL_REVIEW_COMPLETED";

    return (
      <Button
        className="linkBtn tableBtn actionIconBtnDanger"
        color="link"
        onClick={() => this.toggleDeleteModal(row)}
        disabled={disabled}
      >
        <FaTrashAlt />
      </Button>
    );
  };

  productStatusFormatter = (content) => {
    const label = PRODUCT_STATUS[content];
    return label ? label : "Status Unknown";
  };

  ddnFormatter = (ddnProp) => (content, row) => {
    const ddns = row.drugDescriptorNames;
    if (ddns && ddns.length === 1) {
      return ddns[0][ddnProp];
    } else if (ddns && ddns.length > 1) {
      return _.keys(_.groupBy(ddns, ddnProp)).join(", ");
    }

    return "Not Available";
  };

  drugNameFormatter = (content, row) => {
    const commonNames = _.map(
      row.fullGPI.gpiCommonNames,
      (name) => name.commonName
    ).join(", ");
    return <span title={commonNames}>{content}</span>;
  };

  COLUMNS = [
    {
      dataField: "actions",
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "4%",
      },
      editable: false,
    },
    {
      dataField: "status",
      sort: true,
      text: "Product Status",
      headerStyle: {
        ...HEADER_STYLES.requiredEdit,
        width: "10%",
      },
      editable: true,
      editorRenderer: this.dropdownRenderer,
      headerAttrs: {
        title: COLUMN_LEGEND.REQUIRED,
      },
      formatter: this.productStatusFormatter,
    },
    {
      dataField: "gpi",
      sort: true,
      text: "GPI",
      headerStyle: {
        width: "5%",
      },
      editable: false,
    },
    {
      dataField: "drugName.gpiName",
      sort: true,
      text: "Medispan GPI Drug Name",
      headerStyle: {
        width: "20%",
      },
      editable: false,
      formatter: this.drugNameFormatter,
    },
    {
      dataField: "dosageForm",
      sort: false,
      text: "Dosage Form",
      headerStyle: {
        width: "5%",
      },
      editable: false,
      formatter: this.ddnFormatter("dosageForm"),
    },
    {
      dataField: "roa",
      sort: false,
      text: "Route of Administration",
      headerStyle: {
        width: "5%",
      },
      editable: false,
      formatter: this.ddnFormatter("routeOfAdministration"),
    },
    {
      dataField: "drugCategory.description",
      sort: true,
      text: "Drug Category",
      headerStyle: {
        ...HEADER_STYLES.requiredEdit,
        width: "15%",
      },
      editable: true,
      editorRenderer: this.dropdownRenderer,
      headerAttrs: {
        title: COLUMN_LEGEND.REQUIRED,
      },
    },
    {
      dataField: "ahfsDescription.description",
      sort: true,
      text: "AHFS Classification",
      headerStyle: {
        width: "15%",
      },
      editable: false,
    },
    {
      dataField: "anticipatedCategory",
      sort: true,
      text: "Anticipated Category",
      headerStyle: {
        ...HEADER_STYLES.optionalEdit,
        width: "15%",
      },
      editable: true,
      editorRenderer: this.anticipatedCatRenderer,
      headerAttrs: {
        title: COLUMN_LEGEND.OPTIONAL,
      },
    },
  ];

  render() {
    if (_.isEmpty(this.props.catRefs) || _.isEmpty(this.props.drugCategories)) {
      return <h1>LOADING...</h1>;
    }

    const catRefs = this.props.catRefs;

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Category Reference GPIs</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CategoryRefGPISearchForm
              onSubmit={this.onSearchSubmit}
              dgStatuses={this.props.dgStatuses}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className={`d-flex justify-content-between`}>
            <Button
              color="primary"
              tag={Link}
              to="/category-refs/add"
              disabled={!this.state.isAuthorized}
            >
              Add Reference GPI
            </Button>
            <Button color="primary" tag={Link} to={"/category-refs/audit"}>
              View Audit Log
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={catRefs.totalElements}
              data={catRefs.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              cellEditFactory={this.getCellEditFactory()}
            />
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedCatRefId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    currentUser: state.auth.currentUser,
    drugCategories: state.drugCategories.categories,
    catRefs: state.categoryRefs,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  getDrugCategories,
  getDecisionGridStatuses,
  tryCatRefSearch,
  tryUpdateCatRef,
  tryDeleteCatRef,
})(CategoryRefView);
