export const COLUMN_LEGEND = {
  REQUIRED: "Required Field",
  OPTIONAL: "Entry allowed, but not required",
  CALCULATED: "Calculated Field",
  MEDISPAN: "Medi-Span Field",
};

export const HEADER_STYLES = {
  optionalEdit: {
    backgroundColor: "#f5d742",
  },
  requiredEdit: {
    backgroundColor: "#d13b3b",
    color: "#FFFFFF",
  },
  calculated: {
    backgroundColor: "#1852f2",
    color: "#FFFFFF",
  },
};

export const VALID_EDIT_STATUSSES = {
  Active: "Active",
  Draft: "Draft",
};

export const OBFUSCATION_VALS = {
  STAR: "***...",
  WHOLE_NUMBER: "9,999",
  FLOAT: "9,999.99",
};

export const OBFUSCATION_COLUMNS = {
  INCLUDE: {
    prop: "included",
    header: "Data Element 1",
    value: OBFUSCATION_VALS.STAR,
  },
  STRENGTH: {
    prop: "strength",
    header: "Data Element 3",
    value: OBFUSCATION_VALS.STAR,
  },
  BENCHMARK: {
    prop: "benchmark_dosage",
    header: "Data Element 4",
    value: OBFUSCATION_VALS.FLOAT,
  },
  PDC: {
    prop: "pairing_code",
    header: "Data Element 5",
    value: OBFUSCATION_VALS.STAR,
  },
  TAB: { prop: "type", header: "Data Element 6", value: OBFUSCATION_VALS.STAR },
  DIVISIBLE: {
    prop: "divisible",
    header: "Data Element 7",
    value: OBFUSCATION_VALS.STAR,
  },
  PACKAGE_SIZE_PER_COURSE: {
    prop: "course_package_size",
    header: "Data Element 8",
    value: OBFUSCATION_VALS.FLOAT,
  },
  UNIT_PER_1_DOSE: {
    prop: "dosage_unit_count",
    header: "Data Element 9",
    value: OBFUSCATION_VALS.FLOAT,
  },
  DOSES_PER_DAY: {
    prop: "da",
    header: "Data Element 10",
    value: OBFUSCATION_VALS.WHOLE_NUMBER,
  },
  ADMIN_DAYS_PER_COURSE: {
    prop: "da",
    header: "Data Element 11",
    value: OBFUSCATION_VALS.WHOLE_NUMBER,
  },
  DAYS_PER_COURSE: {
    prop: "da",
    header: "Data Element 12",
    value: OBFUSCATION_VALS.WHOLE_NUMBER,
  },
  STRENGTH_PER_DOSE: {
    prop: "da",
    header: "Data Element 13",
    value: OBFUSCATION_VALS.FLOAT,
  },
  DOSE_EQ: {
    prop: "da",
    header: "Data Element 15",
    value: OBFUSCATION_VALS.FLOAT,
  },
  DACON_BRACKET: {
    prop: "da",
    header: "Data Element 16",
    value: OBFUSCATION_VALS.STAR,
  },
  UOM: {
    prop: "da",
    header: "Data Element 17",
    value: OBFUSCATION_VALS.STAR,
  },
  DOSAGE_FORM: {
    prop: "da",
    header: "Data Element 18",
    value: OBFUSCATION_VALS.STAR,
  },
  ROUTE_OF_ADMIN: {
    prop: "da",
    header: "Data Element 19",
    value: OBFUSCATION_VALS.STAR,
  },
  TOTAL_DAILY_DOSE: {
    prop: "da",
    header: "Data Element 20",
    value: OBFUSCATION_VALS.FLOAT,
  },
};

/**
 * @param {Object} obfColumn - OBFUSCATION_COLUMNS
 * @param {string} nonDemoText - header to use if not demo user
 * @param {boolean} isDemoUser
 * @returns {string}
 */
export const getDemoColumnHeader = (obfColumn, nonDemoText, isDemoUser) => {
  if (!isDemoUser) {
    return nonDemoText;
  }
  return obfColumn.header;
};
