import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { PAIRING_TYPES, getClientTypeByValue, getDashedNDC } from "..";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";

import {
  getDateString,
  getDashedGPI,
  getDrugName,
} from "../string/stringUtils";
import { MINIMUM_VALUE } from "../models/drugCategory";
import _ from "lodash";

export const dateFormatter = (content, _) => {
  return getDateString(content);
};

export const dateTimeFormatter = (content, _) => {
  return getDateString(content, false, true);
};

export const localDateTimeFormatter = (content, _) => {
  return getDateString(content, false, true, true);
};

export const localDateFormatter = (content, _) => {
  return getDateString(content, false, false, true);
};

export const shortDateTimeFormatter = (content, _) => {
  return getDateString(content, false, false, false, true);
};

export const userNameFormatter = (content, _) => {
  if (!content) {
    return;
  } else {
    return `${content.firstName} ${content.lastName}`;
  }
};

export const gpiNameFormatter = (_, row) => {
  return getDrugName(row);
};

export const percentageFormatter = (content, _) => {
  return content ? parseFloat((content * 100).toFixed(2)) + "%" : "";
};

export const dollarFormatter = (content, _) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return content == null ? "" : formatter.format(content);
};

export const twoDecimalFormatter = (content, _) => {
  return content == null ? "" : content.toFixed(2);
};

export const dashedGpiFormatter = (_, row) => {
  const gpi = row.gpi.gpi ? row.gpi.gpi : row.gpi;
  return getDashedGPI(gpi);
};

export const versionFormatter = (content, _) => {
  return `v${content}`;
};

export const booleanYNFormatter = (content) => {
  return content ? "Yes" : "No";
};

export const booleanYesOnlyFormatter = (content) => {
  return content ? "Yes" : "";
};

export const fullYesNoFormatter = (content) => {
  if (!content) {
    return;
  } else {
    return content.toLowerCase() === "y" ? "Yes" : "No";
  }
};

export const nullAsZeroFormatter = (content) => {
  return content ? content : 0;
};

export const linkFormatter = (content, target) => {
  return (
    <Button color="link" tag={Link} to={target}>
      {content}
    </Button>
  );
};

export const optionalNAValueFormatter = (value) => {
  if (!value) {
    return "N/A";
  }
  return value;
};

/**
 * @param {string} content
 * @param {Object} row
 * @param {int} rowIndex
 * @param {Object} obCol - OBFUSCATION_COLUMNS
 * @returns
 */
export const obfuscationFormatter = (content, row, rowIndex, obCol) => {
  return obCol.value;
};

export const clientTypeFormatter = (content) => {
  return getClientTypeByValue(content).label;
};

export const dateRangeFormatter = (content) => {
  const startingDate = moment(content).subtract(1, "years");
  return dateFormatter(startingDate) + " - " + dateFormatter(content);
};

export const fillFactorFormatter = (content, row) => {
  let title = `Fill Factor created on: ${localDateFormatter(row.updatedDate)}`;

  if (row.inherited) {
    title += "\nGPI(12) Fill Factor inherited from Drug Category Fill Factor";
  }

  const className = row.inherited ? "highlight-bg" : "";

  return (
    <div className={className} title={title}>
      {content}
    </div>
  );
};

export const inheritedTooltipFormatter = (content, row) => {
  if (row.updatedDate !== undefined) {
    const tooltipId = `tt_${row.id}`;
    return (
      <>
        <div id={tooltipId} className={row.inherited ? "highlight-bg" : ""}>
          {content}
        </div>
        <UncontrolledTooltip
          placement="auto"
          target={tooltipId}
          className="artooltip"
        >
          <span>
            Fill Factor created on: {localDateFormatter(row.updatedDate)}
          </span>
        </UncontrolledTooltip>
      </>
    );
  } else {
    return <span>{content} </span>;
  }
};

export const minimumFillFactorValueFormatter = (content, _) => {
  const val = MINIMUM_VALUE[content]
    ? MINIMUM_VALUE[content]
    : "VALUE NOT FOUND";
  return <span>{val}</span>;
};

export const optionalCostFormatter = (
  content,
  nullContentMessage = "Cost could not be calculated",
) => {
  if (content) {
    return dollarFormatter(content);
  } else {
    return <span className="form-error">{nullContentMessage}</span>;
  }
};

export const pairingTypeFormatter = (content) => {
  return PAIRING_TYPES[content];
};

export const gpiNDCHeaderFormatter = (forAlt = false, showNdc = false) => {
  const headerTxt = forAlt ? "Alternative" : "Target";
  return (
    <>
      <span>{headerTxt} GPI</span>
      {showNdc && (
        <>
          &nbsp; or <span className="ndcLabel">NDC</span>
        </>
      )}
    </>
  );
};

export const gpiNDCFormatter = (content, row, ridx, key) => {
  const prop = row[key];
  if (!_.isEmpty(prop)) {
    let formatted = "";
    if (prop.length < 11) {
      formatted = "Invalid NDC format.";
    } else {
      formatted = getDashedNDC(prop);
    }
    return <span className="ndcLabel">{formatted}</span>;
  }
  return content;
};
