import { CLEAR_REPORTS, GET_REPORTS } from "../actions/types";

const reportsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REPORTS:
      return action.payload;
    case CLEAR_REPORTS:
      return {};
    default:
      return state;
  }
};

export default reportsReducer;
