import { getAuthRequest, getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import {
  GET_BENEFIT,
  GET_BENEFITS,
  EDIT_BENEFIT,
  SAVE_BENEFIT,
  DELETE_BENEFIT,
  CLEAR_BENEFITS,
  BENEFITS_LOADED
} from "../types";
import history from "../../history";
import { handleError } from "../../utils/error/errorHandlers";

export const getBenefits = clientId => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const benefits = await getAuthRequest(authToken).get(
      "/api/benefits/?clientId=" + clientId
    );

    dispatch({
      type: GET_BENEFITS,
      payload: benefits.data
    });
    dispatch({ type: BENEFITS_LOADED });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getBenefit = id => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const benefit = await getAuthRequest(authToken).get("/api/benefits/" + id);

    dispatch({
      type: GET_BENEFIT,
      payload: benefit.data
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryAddBenefit = (formValues, clientId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const data = {
      ...formValues,
      clientId: clientId,
      grant_type: process.env.REACT_APP_GRANT_TYPE
    };

    const response = await getClinicalBackend(authToken).post(
      "/api/benefits/",
      JSON.stringify(data)
    );

    dispatch({
      type: SAVE_BENEFIT,
      payload: response.data
    });
    history.push("/benefits/" + clientId);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryEditBenefit = (formValues, clientId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const data = {
      ...formValues,
      clientId: clientId,
      grant_type: process.env.REACT_APP_GRANT_TYPE
    };

    const response = await getClinicalBackend(authToken).put(
      "/api/benefits/" + formValues.id,
      JSON.stringify(data)
    );

    dispatch({
      type: EDIT_BENEFIT,
      payload: response.data
    });
    history.push("/benefits/" + clientId);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryDeleteBenefit = (benefitId, clientId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    await getClinicalBackend(authToken).delete("/api/benefits/" + benefitId);
    dispatch({ type: DELETE_BENEFIT, payload: benefitId });
    history.push("/benefits/" + clientId);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearBenefits = () => dispatch => {
  dispatch({ type: CLEAR_BENEFITS });
};
