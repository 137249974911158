import React from "react";
import { Container, FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";

export const MultiSelectCheckbox = (props) => {
  const getSelectedCount = () => {
    return _.reduce(
      props.items,
      (sum, item) => {
        if (item.checked) {
          sum++;
        }
        return sum;
      },
      0
    );
  };

  return (
    <>
      <Label className={`multi-check-header ${props.headerClass}`}>
        {props.header}&nbsp;
        {props.showSelectedCount && <span>{`(${getSelectedCount()})`}</span>}
        {props.required && <span className="required-field">*</span>}
      </Label>
      <Container className="multi-check-container" fluid>
        {props.showSelectAll && (
          <div className="sticky-top">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  defaultChecked={props.selectAllDefaultChecked}
                  onChange={props.selectAllOnChange}
                />
                (Select All)
              </Label>
            </FormGroup>
          </div>
        )}
        <ul className="collapse list-unstyled show">
          {props.items.map((item, index) => (
            <li key={index}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id={item.id}
                    name={item.name}
                    value={item.value}
                    checked={item.checked}
                    onChange={props.itemOnChange}
                  />
                  {item.displayName}
                </Label>
              </FormGroup>
            </li>
          ))}
        </ul>
      </Container>
    </>
  );
};

MultiSelectCheckbox.defaultProps = {
  required: false,
  showSelectedCount: false,
  selectAllDefaultChecked: false,
  showSelectAll: true,
  headerClass: "",
};

MultiSelectCheckbox.propTypes = {
  header: PropTypes.string.isRequired,
  selectAllOnChange: PropTypes.func,
  items: PropTypes.array.isRequired,
  itemOnChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  showSelectedCount: PropTypes.bool,
  selectAllDefaultChecked: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  headerClass: PropTypes.string,
};
