import _ from "lodash";
import { GET_BC_FILE_CONTENT, CLEAR_FILE_CONTENT } from "../actions/types";
import { ConfigFile } from "../components/ClientManagement/ConfigFiles/fileTypes";

const bcFileContentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BC_FILE_CONTENT:
      return action.payload;
    case CLEAR_FILE_CONTENT:
      if (_.includes(action.payload, ConfigFile.BC)) {
        return {};
      }
      return state;
    default:
      return state;
  }
};

export default bcFileContentReducer;
