import _ from "lodash";

const errResponse = {
  valid: false,
  message: "",
};

const validateRequired = (value) => {
  errResponse.message = "This field is required";
  if (!value || value === undefined) {
    return errResponse;
  }

  if (typeof value === "string" && value.trim().length <= 0) {
    return errResponse;
  }
  return;
};

const validateInt = (value) => {
  errResponse.message = "Value must be a valid integer.";
  if (isNaN(parseInt(value))) {
    return errResponse;
  }
  return;
};

const validateMinMax = (min, max, value) => {
  if (value < min) {
    errResponse.message = `Value must be at least ${min}`;
    return errResponse;
  } else if (value > max) {
    errResponse.message = `Value must be no more than ${max}`;
    return errResponse;
  }
  return;
};

const validateFloat = (value) => {
  errResponse.message = "Value must be a valid number.";

  if (isNaN(_.toNumber(value))) {
    return errResponse;
  }
  return;
};

export const numberRequired = (isInt) => (newValue, row, column) => {
  let errResponse = validateRequired(newValue);
  if (errResponse) {
    return errResponse;
  }

  errResponse = isInt ? validateInt(newValue) : validateFloat(newValue);

  if (errResponse) {
    return errResponse;
  }

  return true;
};

export const numberMinMaxRequired =
  (min, max, isInt) => (newValue, row, column) => {
    let errResponse = validateRequired(newValue);

    if (errResponse) {
      return errResponse;
    }

    errResponse = isInt ? validateInt(newValue) : validateFloat(newValue);
    if (errResponse) {
      return errResponse;
    }

    errResponse = validateMinMax(min, max, newValue);
    if (errResponse) {
      return errResponse;
    }
    return true;
  };

export const numberMinMaxMaxDecimalsRequired =
  (min, max, maxDecimals) => (newValue) => {
    let errResponse = validateRequired(newValue);

    if (errResponse) {
      return errResponse;
    }

    errResponse = validateFloat(newValue);
    if (errResponse) {
      return errResponse;
    }

    errResponse = validateMinMax(min, max, newValue);
    if (errResponse) {
      return errResponse;
    }

    // If we made it this far we already know we have a valid float
    const decimalCount = getDecimalCount(newValue);
    if (decimalCount > maxDecimals) {
      return {
        valid: false,
        message: `Value must be no more than ${maxDecimals} decimal places.`,
      };
    }
    return true;
  };

const getDecimalCount = (value) => {
  const floatVal = parseFloat(value);
  if (Math.floor(floatVal) === floatVal) return 0;

  var str = floatVal.toString();
  if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    return str.split("-")[1] || 0;
  } else if (str.indexOf(".") !== -1) {
    return str.split(".")[1].length || 0;
  }
  return str.split("-")[1] || 0;
};

export const intRequired = numberRequired(true);
export const floatRequired = numberRequired(false);
export const intMin1Max999Req = numberMinMaxRequired(1, 999, true);
export const intMin1Max99Req = numberMinMaxRequired(1, 99, true);
export const floatMin1Max999Req = numberMinMaxRequired(1, 999, false);
export const floatMin1Max9999Req = numberMinMaxRequired(1, 9999, false);
export const floatMinGreater0Max99MaxDec2Req = numberMinMaxMaxDecimalsRequired(
  0.01,
  99,
  2
);
export const floatMinGreater0Max9999MaxDec3Req =
  numberMinMaxMaxDecimalsRequired(0.001, 9999.999, 3);
export const floatMinGreater0Max99999MaxDec3Req =
  numberMinMaxMaxDecimalsRequired(0.001, 99999.999, 3);
export const floatMin1Max99999MaxDec3Req = numberMinMaxMaxDecimalsRequired(
  1,
  99999.999,
  3
);
