import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearClients,
  getClients,
  getMPDs,
  getReportClaimsDateRange,
  populateBreadCrumbs,
  populateSidebar,
  requestPerformanceReport,
} from "../../../actions";
import { SIDEBAR_ITEMS } from "../Constants";
import _ from "lodash";
import { Col, Container, Row } from "reactstrap";
import RequestForm from "./RequestForm";
import { DateTime } from "luxon";
import { DATE_PICKER_FORMAT } from "../../../utils";
class RequestReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedClient: null,
    };
  }

  componentDidMount = () => {
    this.setupSidebar();
    this.setupBreadCrumbs();

    this.props.getClients();
    if (this.state.selectedClient) {
      this.props.getMPDs(this.state.selectedClient.id);
      this.props.getReportClaimsDateRange(this.state.selectedClient.id);
    }
  };

  componentWillUnmount = () => {
    this.props.clearClients();
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/reporting",
          name: "Reporting",
          active: false,
        },
        {
          to: "/reporting/performance",
          name: "Performance Reporting",
          active: false,
        },
      ],
      finalCrumb: "Request Performance Report",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  handleClientChange = (selectedId) => {
    const selectedClient = _.find(this.props.clients, { id: selectedId });
    this.setState({ selectedClient });
    this.props.getReportClaimsDateRange(selectedClient.id);
    this.props.getMPDs(selectedId);
  };

  onSubmit = (formValues) => {
    this.props.requestPerformanceReport(formValues);
  };

  getClients = () => {
    const validClients = _.filter(this.props.clients, (client) => {
      const hasValidVBR = _.some(client.contracts, (contract) => {
        const validTermDate =
          contract.termDate === null ||
          DateTime.fromISO(contract.termDate) > DateTime.now();
        return contract.type === "VALUE_BASED" && validTermDate;
      });

      return client.type === "ACTIVE" && hasValidVBR;
    });
    return validClients;
  };

  render() {
    if (_.isEmpty(this.props.clients)) {
      return <h1>LOADING...</h1>;
    }
    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Request Performance Report</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <RequestForm
              onSubmit={this.onSubmit}
              clients={this.getClients()}
              handleClientChange={this.handleClientChange}
              mpds={this.props.mpds}
              claimsDateRange={this.props.dateRange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const getDecoratedImplementedMpds = (mpds) => {
  const validMpds = _.filter(mpds, (mpd) => {
    return mpd.implementationDate !== null;
  });
  const decoratedMpds = _.map(validMpds, (mpd) => {
    const implDate = DateTime.fromISO(mpd.implementationDate).toFormat(
      DATE_PICKER_FORMAT
    );
    const endImplDate =
      mpd.implementationEndDate === null
        ? "no termination date"
        : DateTime.fromISO(mpd.implementationEndDate).toFormat(
            DATE_PICKER_FORMAT
          );
    mpd.displayName = `${mpd.name} (${implDate} - ${endImplDate})`;
    return mpd;
  });
  return decoratedMpds;
};

const mapStateToProps = (state) => {
  return {
    clients: Object.values(state.clients),
    mpds: getDecoratedImplementedMpds(state.mpds),
    dateRange: state.reportClaimsDateRange,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClients,
  getMPDs,
  getReportClaimsDateRange,
  populateBreadCrumbs,
  populateSidebar,
  requestPerformanceReport,
})(RequestReport);
