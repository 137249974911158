import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderCheckbox } from "../../../utils/forms/inputRenderers";
class ArtasReportRequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit} autoComplete="off">
        <FormGroup check>
          <Row>
            <Col>
              <Field
                id="includeRefNDCs"
                name="includeRefNDCs"
                placeholder="Include Reference NDC"
                defaultChecked={false}
                component={renderCheckbox}
              />
            </Col>
          </Row>
        </FormGroup>
        <Row>
          <Col>
            <Button className="mt-3 mb-3" color="primary" type="submit" block>
              Export AR-TAS Format
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const selector = formValueSelector("artasReportRequestForm");

const mapStateToProps = (state) => {
  return {
    includeRefNDCs: selector(state, "includeRefNDCs"),
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: "artasReportRequestForm" })(ArtasReportRequestForm)
);
