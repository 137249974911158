import { getClinicalBackend } from "../../api/clinicalBackend";
import {
  GET_INTERNAL_CATALOG_REPORTS,
} from "../types";
import { tryRefreshToken } from "../auth/authAction";
import { handleError, formatPageableParameters } from "../../utils";

export const getInternalCatalogReports =
  (pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `api/internal-catalog-reports?${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null
      )}`;

      const internalCatalogReports = await getClinicalBackend(authToken).get(
        endPoint
      );
      dispatch({
        type: GET_INTERNAL_CATALOG_REPORTS,
        payload: internalCatalogReports.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
