import { HEALTH_CHECK_PASSED, HEALTH_CHECK_FAILED } from "../actions/types";

const healthCheckReducer = (state = { healthy: false }, action) => {
  switch (action.type) {
    case HEALTH_CHECK_PASSED:
      return { healthy: true };
    case HEALTH_CHECK_FAILED:
      return { healthy: false };
    default:
      return state;
  }
};

export default healthCheckReducer;
