import React, { Component } from "react";
import { Router, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getStackDetails, STACK_TYPE } from "../actions";
import "./App.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Dashboard from "./Dashboard/Dashboard";
import Login from "./Login/Login";
import history from "../history";
import UserProfile from "./UserProfile/UserProfile";
import TargetCategories from "./ClinicalTeManagement/Catalog/TargetCategories";
import Clients from "./ClientManagement/Clients/Clients";
import AddClient from "./ClientManagement/Clients/AddClient";
import AddClientContract from "./ClientManagement/ClientContracts/AddClientContract";
import ClientView from "./ClientManagement/Clients/ClientView";
import EditClient from "./ClientManagement/Clients/EditClient";
import EditClientContract from "./ClientManagement/ClientContracts/EditClientContract";
import { ProtectedRoute } from "./ProtectedRoute";
import { Role } from "../utils/auth/roles";
import ConfigDash from "./ClientManagement/ConfigFiles/ConfigDash";
import AWPDView from "./ClientManagement/ConfigFiles/AWPDView";
import MACView from "./ClientManagement/ConfigFiles/MACView";
import FormularyView from "./ClientManagement/ConfigFiles/FormularyView";
import BeneficiaryCostView from "./ClientManagement/ConfigFiles/BeneficiaryCostView";
import GPView from "./ClientManagement/ConfigFiles/GPView";
import SideBar from "./SideBar/SideBar";
import Breadcrumbs from "./BreadCrumbs/Breadcrumbs";
import Benefits from "./ClientManagement/ClientBenefits/Benefits";
import AddBenefit from "./ClientManagement/ClientBenefits/AddBenefit";
import EditBenefit from "./ClientManagement/ClientBenefits/EditBenefit";
import BenefitView from "./ClientManagement/ClientBenefits/BenefitView";
import TargetedGPIs from "./ClinicalTeManagement/Catalog/TargetedGPIs";
import MPDs from "./ClientManagement/MPDs/MPDs";
import AddMPD from "./ClientManagement/MPDs/AddMPD";
import EditMPD from "./ClientManagement/MPDs/EditMPD";
import CalcTester from "./ClientManagement/MPDs/Tester/CalcTester";
import ViewEditMPD from "./ClientManagement/MPDs/ViewEditMPD";
import MPDTieIns from "./ClientManagement/MPDTieIns/MPDTieIns";
import MPDDrugPairsManagement from "./ClientManagement/MPDs/MPDDrugPairsManagement";
import ClaimFileList from "./ClientManagement/Claims/ClaimFileList";
import RollIns from "./Reporting/Medispan/RollIns";
import CategoryFillFactor from "./Reporting/FillFactor/CategoryFillFactor";
import Gpi12FillFactor from "./Reporting/FillFactor/Gpi12FillFactor";
import EligibilityList from "./ClientManagement/Eligibility/EligibilityList";
import StackDetails from "./StackDetails/StackDetails";
import ClaimSearch from "./ClientManagement/Claims/ClaimSearch";
import Reporting from "./Reporting/Reporting";
import DecisionGrids from "./ClinicalTeManagement/DecisionGrids/DecisionGrids";
import DrugCategories from "./ClinicalTeManagement/DrugCategories/DrugCategories";
import DecisionGridView from "./ClinicalTeManagement/DecisionGrids/DecisionGridView";
import AddDrugPairingOption from "./ClinicalTeManagement/DecisionGrids/AddDrugPairingOption";
import DrugCategoriesAuditLog from "./ClinicalTeManagement/DrugCategories/DrugCategoriesAuditLog";
import DecisionGridsAuditLog from "./ClinicalTeManagement/DecisionGrids/DecisionGridsAuditLog";
import Workbooks from "./ClinicalTeManagement/Workbooks/Workbooks";
import WorkbookView from "./ClinicalTeManagement/Workbooks/WorkbookView";
import WorkbookErrors from "./ClinicalTeManagement/Workbooks/WorkbookErrors";
import CatalogDeliveryFileList from "./ClientManagement/ClientCatalogDelivery/CatalogDeliveryFileList";
import ProviderAdministeredDrugs from "./ClientManagement/ClientContracts/Products/ProviderAdministeredDrugs";
import UploadedRollinsReports from "./Reporting/UploadedRollinsReports/UploadedRollinsReports";
import AddProviderAdministeredDrugs from "./ClientManagement/ClientContracts/Products/AddProviderAdministeredDrugs";
import RollinsReportNotifications from "./Reporting/UploadedRollinsReports/RollinsReportNotifications";
import InternalClinicalCatalog from "./Reporting/InternalCatalogReport/InternalCatalogReports";
import GlossaryView from "./Reporting/Glossary/GlossaryView";
import ReportList from "./Reporting/Performance/ReportList";
import ProspectiveReportList from "./Reporting/Prospective/ProspectiveReportList";
import ProspectiveReportRequest from "./Reporting/Prospective/ProspectiveReportRequest";
import ClientContractProductAuditLog from "./ClientManagement/ClientContracts/ClientContractProductAuditLog";
import RequestReport from "./Reporting/Performance/RequestReport";
import CategoryRefView from "./ClinicalTeManagement/CategoryReference/CategoryRefView";
import AddGPI from "./ClinicalTeManagement/CategoryReference/AddGPI";
import ProviderAdministeredDrugsAuditLog from "./ClientManagement/ClientContracts/Products/ProviderAdministeredDrugsAuditLog";
import CategoryReferenceDrugAuditLog from "./ClinicalTeManagement/CategoryReference/CategoryReferenceDrugAuditLog";

class App extends Component {
  componentDidMount = () => {
    this.props.getStackDetails(STACK_TYPE.backend);
    this.props.getStackDetails(STACK_TYPE.async);
    this.props.getStackDetails(STACK_TYPE.batch);
  };

  render() {
    const currentUser = this.props.currentUser;
    return (
      <Router history={history}>
        <React.Fragment>
          <Header />
          <SideBar />
          <div
            id="content"
            className={this.props.sidebarVisible ? "" : "sidebar-hidden"}
          >
            <div
              className={
                this.props.sidebarVisible
                  ? "main-content"
                  : "main-content sidebar-hidden"
              }
            >
              <Breadcrumbs />
              <Route path="/" exact component={Login} />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/dashboard"
                component={Dashboard}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/stack-details"
                component={StackDetails}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/drug-categories"
                component={DrugCategories}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/drug-categories/audit/"
                component={DrugCategoriesAuditLog}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting"
                component={RollIns}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/roll-ins"
                component={RollIns}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/roll-ins-uploads"
                component={UploadedRollinsReports}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/roll-ins-uploads/:reportId"
                component={RollinsReportNotifications}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/internal-clinical-catalog"
                component={InternalClinicalCatalog}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/glossary-terms"
                component={GlossaryView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/category-fill-factors"
                component={CategoryFillFactor}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/gpi12-fill-factors"
                component={Gpi12FillFactor}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/performance"
                component={ReportList}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/performance/request"
                component={RequestReport}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/prospective"
                component={ProspectiveReportList}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/prospective/request"
                component={ProspectiveReportRequest}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/membership"
                component={Reporting}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/reporting/utilization"
                component={Reporting}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/clients/:clientId/mpds/:mpdId/tester"
                component={CalcTester}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/user-profile"
                component={UserProfile}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/catalog"
                component={TargetCategories}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                roles={[Role.CLINICIAN, Role.CLINICAL_APPROVER]}
                path="/category-refs"
                component={CategoryRefView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/category-refs/audit"
                component={CategoryReferenceDrugAuditLog}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                roles={[Role.CLINICIAN, Role.CLINICAL_APPROVER]}
                path="/category-refs/add"
                component={AddGPI}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/workbooks"
                component={Workbooks}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/workbooks/:wbId"
                component={WorkbookView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/workbooks/:wbId/errors"
                component={WorkbookErrors}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/grids"
                component={DecisionGrids}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/grids-audit"
                component={DecisionGridsAuditLog}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/grids-audit/:dgId"
                component={DecisionGridsAuditLog}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/grids/:dgId"
                component={DecisionGridView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/grids/:dgId/add-dpo"
                component={AddDrugPairingOption}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/products/provider-drugs/:contractId/:clientId"
                component={ProviderAdministeredDrugs}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/products/provider-drugs/client/:clientId/add"
                component={AddProviderAdministeredDrugs}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/products/provider-drugs/audit/:contractId/:clientId"
                component={ProviderAdministeredDrugsAuditLog}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/clients"
                component={Clients}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                roles={[
                  Role.CLIENT_ADMIN,
                  Role.CLINICAL_APPROVER,
                  Role.ADMIN_USER,
                ]}
                path="/clients/add"
                component={AddClient}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                roles={[
                  Role.CLIENT_ADMIN,
                  Role.CLINICAL_APPROVER,
                  Role.ADMIN_USER,
                ]}
                path="/clients/edit/:clientId"
                component={EditClient}
              />
              <ProtectedRoute
                currentUser={currentUser}
                path="/clients/view/:clientId"
                component={ClientView}
              />
              <ProtectedRoute
                currentUser={currentUser}
                roles={[
                  Role.CLIENT_ADMIN,
                  Role.CLINICAL_APPROVER,
                  Role.ADMIN_USER,
                ]}
                path="/contracts/add/:clientId"
                component={AddClientContract}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/catalog-delivery/:clientId"
                component={CatalogDeliveryFileList}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                roles={[
                  Role.CLIENT_ADMIN,
                  Role.CLINICAL_APPROVER,
                  Role.ADMIN_USER,
                ]}
                path="/contracts/edit/:contractId"
                component={EditClientContract}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/contracts/audit/client/:clientId"
                component={ClientContractProductAuditLog}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/mpd-tie-ins/:clientId"
                component={MPDTieIns}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/client-config/dashboard/:clientId"
                component={ConfigDash}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/client-config/awpd/:clientId/:correlationId"
                component={AWPDView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/client-config/mac/:clientId/:correlationId"
                component={MACView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/client-config/gp/:clientId/:correlationId"
                component={GPView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/client-config/formulary/:clientId/:correlationId"
                component={FormularyView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/client-config/beneficiary/:clientId/:correlationId"
                component={BeneficiaryCostView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/benefits/:clientId"
                component={Benefits}
              />
              <ProtectedRoute
                currentUser={currentUser}
                roles={[Role.CLIENT_ADMIN]}
                path="/benefits/add/:clientId"
                component={AddBenefit}
              />
              <ProtectedRoute
                currentUser={currentUser}
                roles={[Role.CLIENT_ADMIN]}
                path="/benefits/edit/:clientId/:benefitId"
                component={EditBenefit}
              />
              <ProtectedRoute
                currentUser={currentUser}
                path="/benefits/view/:clientId/:benefitId"
                component={BenefitView}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/clients/:clientId/mpds"
                component={MPDs}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                roles={[
                  Role.CLINICIAN,
                  Role.CLINICAL_APPROVER,
                  Role.CLIENT_ADMIN,
                ]}
                path="/clients/:clientId/add-mpd"
                component={AddMPD}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                roles={[Role.CLINICIAN]}
                path="/clients/:clientId/mpds/:mpdId/edit"
                component={EditMPD}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/clients/:clientId/mpds/:mpdId"
                component={ViewEditMPD}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/clients/:clientId/mpds/:mpdId/drugpairs"
                component={MPDDrugPairsManagement}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/clients/:clientId/claimfiles"
                component={ClaimFileList}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/clients/:clientId/eligibilitydata"
                component={EligibilityList}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/clients/:clientId/claimsearch"
                component={ClaimSearch}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/targetCategories"
                component={TargetCategories}
              />
              <ProtectedRoute
                exact
                currentUser={currentUser}
                path="/targetCategories/:categoryId/targetedGpis"
                component={TargetedGPIs}
              />
            </div>
          </div>
          <Footer
            currentUser={currentUser}
            stackDetails={this.props.stackDetails}
          />
        </React.Fragment>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    sidebarVisible: state.sidebar.visible,
    stackDetails: state.stackDetails,
  };
};

export default connect(mapStateToProps, { getStackDetails })(App);
