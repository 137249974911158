import _ from "lodash";
import {
  GET_CLIENTS,
  SAVE_CONTRACT,
  GET_CLIENT,
  SAVE_CLIENT,
  EDIT_CLIENT,
  EDIT_CONTRACT,
  DELETE_CONTRACT,
  DELETE_CLIENT,
  SELECT_CLIENT,
  CLEAR_CLIENTS,
} from "../actions/types";

// Helper method for plucking a contract out of a given client
const getContractData = (state, contractId, clientId) => {
  const curclients = { ...state };
  const sc = curclients[clientId];
  let contractIndex = -1;
  sc.contracts.forEach((contract, index) => {
    if (contract.id === contractId) {
      contractIndex = index;
    }
  });
  const contracts = sc.contracts
    .slice(0, contractIndex)
    .concat(sc.contracts.slice(contractIndex + 1, sc.contracts.length));
  return { index: contractIndex, selectedClient: sc, contracts: contracts };
};

const clientsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      // Bubble most recent contract to parent level
      const clientList = _.map(action.payload, (client) => {
        const contracts = client.contracts;
        const latestContracts = _.sortBy(contracts, ["id"]);
        client.latestContract = latestContracts[latestContracts.length - 1];
        return client;
      });
      return { ...state, ..._.mapKeys(clientList, "id") };
    case GET_CLIENT:
    case SAVE_CLIENT:
    case EDIT_CLIENT:
      return { ...state, [action.payload.id]: action.payload };
    case SELECT_CLIENT:
      return { ...state, selectedClient: action.payload.client };
    case DELETE_CLIENT:
      return _.omit(state, action.payload);
    case SAVE_CONTRACT:
      let clients = { ...state };
      const selectedClient = clients[action.payload.clientId];
      selectedClient.contracts.push(action.payload);
      return { ...state, [selectedClient.id]: selectedClient };
    case EDIT_CONTRACT:
      const editData = getContractData(
        state,
        action.payload.id,
        action.payload.clientId
      );
      // Add the edited contract onto the new array and set the selectedClient contracts to this new array
      editData.contracts.push(action.payload);
      editData.selectedClient.contracts = editData.contracts;
      return { ...state, [action.payload.clientId]: editData.selectedClient };
    case DELETE_CONTRACT:
      const deleteData = getContractData(
        state,
        action.payload.contractId,
        action.payload.clientId
      );
      deleteData.selectedClient.contracts = deleteData.contracts;
      return { ...state, [action.payload.clientId]: deleteData.selectedClient };
    case CLEAR_CLIENTS:
      return {};
    default:
      return state;
  }
};

export default clientsReducer;
