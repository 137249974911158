import {
  GET_ESTIMATED_CLAIM_CORRELATIONS,
  GET_ESTIMATED_CLAIM_CORRELATION_RANGES,
} from "../actions/types";

const claimCorrelationsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ESTIMATED_CLAIM_CORRELATIONS:
      return action.payload;
    case GET_ESTIMATED_CLAIM_CORRELATION_RANGES:
      return action.payload;
    default:
      return state;
  }
};

export default claimCorrelationsReducer;
