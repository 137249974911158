import { BENEFITS_LOADED, CLEAR_BENEFITS } from "../actions/types";

const benefitsLoadedReducer = (state = false, action) => {
  switch (action.type) {
    case BENEFITS_LOADED:
      return true;
    case CLEAR_BENEFITS:
      return false;
    default:
      return state;
  }
};

export default benefitsLoadedReducer;
