import {
  GET_MPD_CATEGORY_EXCLUSIONS,
  CLEAR_MPD_CATEGORY_EXCLUSIONS,
} from "../actions/types";

const mpdCategoryExclusionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MPD_CATEGORY_EXCLUSIONS:
      return action.payload;
    case CLEAR_MPD_CATEGORY_EXCLUSIONS:
      return {};
    default:
      return state;
  }
};

export default mpdCategoryExclusionsReducer;
