import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col, Input } from "reactstrap";
import { Link } from "react-router-dom";
import {
  clearClients,
  getClient,
  populateBreadCrumbs,
  getGPFileContent,
  getConfigFile,
  getMPDTieIns,
  clearFileContent,
  addGPClientTieIn,
  removeGPClientTieIn,
  clearMPDTieIns,
  getBenefits,
  clearBenefits,
  toggleSidebarVisibility,
} from "../../../actions";
import cellEditFactory from "react-bootstrap-table2-editor";
import { ConfigFile } from "./fileTypes";
import {
  getFileNameNoExt,
  dateFormatter,
  isConfigFileLocked,
} from "../../../utils";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";

class GPView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      correlationId: this.props.match.params.correlationId,
    };
    this.onDropDownChange = this.onDropDownChange.bind(this);
  }

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getConfigFile(this.state.correlationId);
    this.props.getMPDTieIns(this.state.clientId);
    this.props.getBenefits(this.state.clientId);
    this.getPage(0, this.state.sizePerPage);
  }

  componentDidUpdate() {
    if (this.props.selectedClient && this.props.selectedFile) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearFileContent([ConfigFile.GP]);
    this.props.clearMPDTieIns();
    this.props.clearBenefits();
    this.props.clearClients();
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
        {
          to: "/client-config/dashboard/" + this.state.clientId,
          name: "Configuration Files",
          active: false,
        },
      ],
      finalCrumb: this.props.selectedFile.fileName,
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  getPage(page, sizePerPage) {
    this.props.getGPFileContent(
      this.state.correlationId,
      page - 1,
      sizePerPage
    );
  }

  statusFormatter = (content, row) => {
    return content ? content.toLowerCase() : "";
  };

  getFileContents = () => {
    let contents = this.props.fileContents.content;
    return contents;
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState({ currPage: page, sizePerPage: sizePerPage });
    this.props.clearFileContent([ConfigFile.GP]);
    this.getPage(page, sizePerPage);
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage);
  };

  getTieInEditorData = () => {
    return this.props.mpdTieIns.map((tieIn) => {
      return { value: tieIn.id, label: tieIn.name };
    });
  };

  onDropDownChange = ({ currentTarget }) => {
    switch (currentTarget.value) {
      case "remove":
      case "select":
        const removeValues = {
          cgId: parseInt(currentTarget.getAttribute("data-cgid")),
          tieInId: 0,
          correlationId: parseInt(this.state.correlationId),
        };
        this.props.removeGPClientTieIn(
          removeValues,
          this.state.currPage - 1,
          this.state.sizePerPage
        );
        break;
      default:
        const addValues = {
          cgId: parseInt(currentTarget.getAttribute("data-cgid")),
          tieInId: parseInt(currentTarget.value),
          correlationId: parseInt(this.state.correlationId),
        };
        this.props.addGPClientTieIn(
          addValues,
          this.state.currPage - 1,
          this.state.sizePerPage
        );
    }
  };

  dropdownRenderer = (
    editorProps,
    value,
    row,
    column,
    rowIndex,
    columnIndex
  ) => {
    const options = this.props.mpdTieIns;
    const nameId = "tie-in-" + rowIndex;
    const selectedId = row.clientTieIn ? row.clientTieIn.id : "";

    return (
      <Input
        type="select"
        id={nameId}
        name={nameId}
        value={selectedId}
        onChange={this.onDropDownChange}
        data-cgid={row.id}
      >
        <option value="select">-Select-</option>
        <option value="remove">Remove Tie-In</option>
        {options.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </Input>
    );
  };

  getColumns = () => {
    return [
      {
        dataField: "clientTieIn.name",
        sort: false,
        text: "RPS LCTAF tie-in to CAG (click to select tie-in option)",
        editable: true,
        editorRenderer: this.dropdownRenderer,
      },
      {
        dataField: "carrierId",
        sort: false,
        text: "Carrier ID",
        editable: false,
      },
      {
        dataField: "accountCode",
        sort: false,
        text: "Account Code",
        editable: false,
      },
      {
        dataField: "groupId",
        sort: false,
        text: "Group ID",
        editable: false,
      },
      {
        dataField: "planCode",
        sort: false,
        text: "Plan Code",
        editable: false,
      },
      {
        dataField: "groupName",
        sort: false,
        text: "Group Name",
        editable: false,
      },
      {
        dataField: "planName",
        sort: false,
        text: "Plan Name",
        editable: false,
      },
      {
        dataField: "groupEffectiveDate",
        sort: false,
        text: "Group Effective Date",
        formatter: dateFormatter,
        editable: false,
      },
      {
        dataField: "groupTermDate",
        sort: false,
        text: "Group Term Date",
        formatter: dateFormatter,
        editable: false,
      },
    ];
  };

  getDisabledEditRows = () => {
    if (
      this.props.mpdTieIns.length <= 0 ||
      isConfigFileLocked(this.props.benefits, this.props.selectedFile.id)
    ) {
      return this.getFileContents().map((content) => {
        return content.id;
      });
    } else {
      return [];
    }
  };

  getCellEditFactory = () => {
    return cellEditFactory({
      mode: "click",
      blurToSave: true,
      nonEditableRows: this.getDisabledEditRows,
    });
  };

  render() {
    if (
      !this.props.selectedClient ||
      !this.props.fileContents.content ||
      !this.props.selectedFile ||
      !this.props.benefitsLoaded
    ) {
      return <div>Loading...</div>;
    }
    const fileName = getFileNameNoExt(this.props.selectedFile.fileName);

    return (
      <Container className="main-container" fluid>
        <Row className="mb-4">
          <Col>
            <h3>{fileName}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props.mpdTieIns.length <= 0 && (
              <div>
                <span>{this.props.selectedClient.name} requires valid </span>
                <Button
                  className="inline-button"
                  color="link"
                  tag={Link}
                  to={"/mpd-tie-ins/" + this.props.selectedClient.id}
                >
                  RPS LCTAF Tie-ins
                </Button>
                <span> to continue.</span>
              </div>
            )}
            <RPSDataGrid
              keyField="id"
              paginated
              remote
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.fileContents.totalElements}
              data={this.getFileContents()}
              columns={this.getColumns()}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              cellEditFactory={this.getCellEditFactory()}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    configFiles: state.clientConfigFiles,
    fileContents: state.gpFileContent,
    selectedFile: state.clientConfigFiles[ownProps.match.params.correlationId],
    mpdTieIns: Object.values(state.mpdTieIns),
    benefits: Object.values(state.benefits),
    benefitsLoaded: state.benefitsLoaded,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getGPFileContent,
  getConfigFile,
  getMPDTieIns,
  clearFileContent,
  populateBreadCrumbs,
  addGPClientTieIn,
  removeGPClientTieIn,
  clearMPDTieIns,
  getBenefits,
  clearBenefits,
  toggleSidebarVisibility,
})(GPView);
