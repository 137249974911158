import { GET_GLOSSARY_TERMS, CLEAR_GLOSSARY_TERMS } from "../actions/types";

const glossaryTermsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_GLOSSARY_TERMS:
      return action.payload;
    case CLEAR_GLOSSARY_TERMS:
      return {};
    default:
      return state;
  }
};

export default glossaryTermsReducer;
