import React, { Component } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import {
  maxDecimalValidator,
  unsignedInteger,
  minValue,
  maxValue,
  validMembershipCount,
  renderDatePicker,
  renderInput,
  renderInputGroup,
  renderSelectInput,
  renderCheckbox,
  required,
  validSelect,
} from "../../../utils";
import { DateTime } from "luxon";
import _ from "lodash";
import history from "../../../history";

class ProspectiveReportRequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      shouldBeDisabled: true,
      shouldDatesBeDisabled: true,
      error: { reason: null, message: null },
    };
  }

  onSubmit = (formValues) => {
    // convert values to correct format
    formValues.clientId = parseInt(formValues.clientId);
    formValues.membershipCount = parseInt(
      formValues.membershipCount.toString().replace(/,/g, ""),
    );
    formValues.includeMinors = formValues.includeMinorsField ? "Y" : "N";
    formValues.grandfatherUsers = formValues.grandfatherGreyCatsField
      ? "Y"
      : "N";
    formValues.excludeProviderAdmin = formValues.providerAdministeredField
      ? "Y"
      : "N";
    formValues.excludeBrandToBrand = formValues.brandToBrandField ? "Y" : "N";
    formValues.savingsThreshold = formValues.savingsThreshold
      ? parseFloat(formValues.savingsThreshold.toString().replace(/,/g, ""))
      : 5.0;
    formValues.switchRate = formValues.switchRate
      ? parseInt(formValues.switchRate)
      : null;
    formValues.claimEstimateCorrelationId = parseInt(formValues.claimsLoad);
    formValues.clientReferenceReportId = parseInt(formValues.mpd);

    formValues.annualize =
      formValues.annualizeSavingsField === false ? "N" : "Y";
    formValues.periodStart = formValues.periodFrom;
    formValues.periodEnd = formValues.periodTo;

    this.props.onSubmit(formValues);
  };

  onPeriodFromChange = (newVal) => {
    this.setState({
      minTo: newVal,
    });
  };

  onPeriodToChange = (newVal) => {
    this.setState({
      maxFrom: newVal,
    });
  };

  onClientSelectChange = (e, newVal) => {
    // Clear out any values that may have been input prior to client change
    this.props.reset();
    const selectedClient = _.find(this.props.clients, {
      id: parseInt(newVal),
    });
    this.props.handleClientChange(selectedClient.id);

    const error = { reason: null, message: null };
    let shouldBeDisabled = selectedClient === undefined;

    this.setState({
      shouldBeDisabled,
      error,
    });
  };

  onClaimsLoadChange = (e, newVal) => {
    const newClaimEstimateCorrelationId = parseInt(newVal);
    const claimLoad = _.find(this.props.claimLoads, {
      claimEstimateCorrelationId: newClaimEstimateCorrelationId,
    });
    if (claimLoad) {
      const minDate = DateTime.fromISO(claimLoad.minDate).toJSDate();
      const maxDate = DateTime.fromISO(claimLoad.maxDate).toJSDate();
      this.setState({
        minFrom: minDate,
        minTo: minDate,
        maxFrom: maxDate,
        maxTo: maxDate,
        shouldDatesBeDisabled: false,
      });
    }
  };

  render() {
    const { invalid, submitting, pristine } = this.props;

    return (
      <Form
        autoComplete="off"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <Row md={4}>
          <FormGroup>
            <Field
              id="client"
              label="Client"
              name="clientId"
              items={this.props.clients}
              valueProp="id"
              keyProp="id"
              displayProp="name"
              onChange={this.onClientSelectChange}
              required
              validate={[required, validSelect]}
              component={renderSelectInput}
              tooltip="Selection of any client marked as a prospect. Refresh of active clients pending in future phase."
            />
          </FormGroup>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Field
                id="membershipCount"
                name="membershipCount"
                type="text"
                placeholder="Membership Count"
                tooltip="Reflects the count of membership/person codes for a client, for PMPM calculations"
                required
                validate={[required, ...validMembershipCount]}
                component={renderInput}
                disabled={this.state.shouldBeDisabled}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Field
                id="includeMinorsField"
                name="includeMinorsField"
                component={renderCheckbox}
                disabled={this.state.shouldBeDisabled}
                placeholder="&nbsp;Include Minors"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Field
                id="grandfatherGreyCatsField"
                name="grandfatherGreyCatsField"
                component={renderCheckbox}
                disabled={this.state.shouldBeDisabled}
                placeholder="&nbsp;Grandfather Existing Users of Drugs included in 'Clinical Impact - Grey' Categories"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Field
                id="providerAdministeredField"
                name="providerAdministeredField"
                component={renderCheckbox}
                disabled={this.state.shouldBeDisabled}
                placeholder="&nbsp;Exclude drugs that are provider administered"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Field
                id="brandToBrandField"
                name="brandToBrandField"
                component={renderCheckbox}
                disabled={this.state.shouldBeDisabled}
                placeholder="&nbsp;Exclude drugs where HCA and LCA are both branded"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Field
                id="savingsThreshold"
                name="savingsThreshold"
                type="text"
                placeholder="Savings Threshold"
                tooltip="Threshold of 'Plan Savings per 30-day Supply'"
                symbolText="$"
                hint="5.00"
                validate={[maxDecimalValidator(99.99), minValue(0.01)]}
                component={renderInputGroup}
                disabled={this.state.shouldBeDisabled}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Field
                id="switchRate"
                name="switchRate"
                type="text"
                placeholder="Switch Rate"
                tooltip="Assumed Switch Rate"
                symbolText="%"
                validate={[unsignedInteger, maxValue(100)]}
                component={renderInputGroup}
                disabled={this.state.shouldBeDisabled}
                value="20"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Field
                id="claimsLoad"
                name="claimsLoad"
                label="Claims Load"
                tooltip="Loaded with estimated claims files."
                items={this.props.claimLoads}
                valueProp="claimEstimateCorrelationId"
                keyProp="claimEstimateCorrelationId"
                displayProp="fileName"
                onChange={this.onClaimsLoadChange}
                disabled={this.state.shouldBeDisabled}
                required
                validate={[required, validSelect]}
                component={renderSelectInput}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Field
                id="mpd"
                name="mpd"
                label="Low Cost Therapeutic Alternatives File"
                tooltip="Only LCTAs with an empty implementation end date are listed."
                items={this.props.mpds}
                valueProp="id"
                keyProp="id"
                displayProp="name"
                disabled={this.state.shouldBeDisabled}
                required
                validate={[required, validSelect]}
                component={renderSelectInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Field
                id="annualizeSavingsField"
                name="annualizeSavingsField"
                component={renderCheckbox}
                disabled={this.state.shouldBeDisabled}
                placeholder="&nbsp;Annualize savings on produced report"
                defaultChecked
                tooltip="If not checked, will produce savings equivalent to the span of the reporting period"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Field
                id="periodFrom"
                name="periodFrom"
                placeholder="Period of Analysis - From"
                required
                onChange={this.onPeriodFromChange}
                minDate={this.state.minFrom}
                maxDate={this.state.maxFrom}
                showYearDropdown
                component={renderDatePicker}
                validate={[required]}
                shouldDisable={this.state.shouldDatesBeDisabled}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Field
                id="periodTo"
                name="periodTo"
                placeholder="Period of Analysis - To"
                required
                onChange={this.onPeriodToChange}
                minDate={this.state.minTo}
                maxDate={this.state.maxTo}
                showYearDropdown
                component={renderDatePicker}
                validate={[required]}
                shouldDisable={this.state.shouldDatesBeDisabled}
              />
            </FormGroup>
          </Col>
        </Row>
        {this.state.error.reason && (
          <Row className="mb-3">
            <Col>
              <span className="form-error">{this.state.error.message}</span>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={3}>
            <Button
              color="danger"
              block
              onClick={(e) => {
                e.preventDefault();
                history.push("/reporting/prospective");
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col md={2}>
            <Button
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

ProspectiveReportRequestForm = reduxForm({ form: "requestForm" })(
  ProspectiveReportRequestForm,
);

// We need access to the selected client's id, claims load and MPDs
const selector = formValueSelector("requestForm");

ProspectiveReportRequestForm = connect((state) => {
  const selectedClientValue = selector(state, "client");
  return { selectedClientValue };
})(ProspectiveReportRequestForm);

export default ProspectiveReportRequestForm;
