import React, { Component } from "react";
import { Button, Collapse, Row } from "reactstrap";
import { ChipAwareTextInput } from "../../../../utils";

class ProviderAdministeredDrugsSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: {
        names: [],
        gpis: [],
        mscs: [],
        adminType: "PROVIDER",
      },
    };
  }

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      this.props.onSubmit({ ...this.state.searchTerms });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <ChipAwareTextInput
              id="gpis"
              name="gpis"
              placeholder="GPI Code"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="name"
              name="names"
              placeholder="Drug Name"
              updateCallback={this.onSearchTermUpdate}
            />
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default ProviderAdministeredDrugsSearchForm;
