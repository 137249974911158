import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Row, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Field, reduxForm, formValueSelector } from "redux-form";
import {
  renderInput,
  required,
  number,
  minDaconValue,
  maxDaconValue,
  maxDaconDecimalDigits,
  daconRequired,
  gpiRequired,
  DATE_PICKER_FORMAT,
} from "../../../../utils";

class TesterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minDate: moment().add(1, "d").year(1977).toDate(),
      maxDate: moment().toDate(),
      targetGpiToTest: "",
    };
  }

  renderDatePicker({
    input,
    id,
    placeholder,
    required,
    minDate,
    maxDate,
    meta: { touched, error },
  }) {
    return (
      <div className="field">
        <Label for={id}>
          {placeholder} {required && <span className="required-field">*</span>}
        </Label>{" "}
        <br />
        <DatePicker
          {...input}
          dateFormat={DATE_PICKER_FORMAT}
          placeholderText={DATE_PICKER_FORMAT}
          selected={Date.parse(input.value) || null}
          onChange={input.onChange}
          autoComplete="off"
          strictParsing
          minDate={minDate}
          maxDate={maxDate}
          className="form-control"
        />
        {touched && error && <span className="form-error">{error}</span>}
      </div>
    );
  }

  onSubmit = (formValues) => {
    // Transform gpi to remove dashes if present
    formValues.gpi = formValues.gpi ? formValues.gpi.replace(/-/g, "") : "";
    this.props.onSubmit(formValues);
  };

  render() {
    const { invalid, submitting, pristine } = this.props;

    return (
      <Form
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        autoComplete="off"
      >
        <Row>
          <Col md={2}>
            <FormGroup>
              <Field
                id="requestDate"
                name="requestDate"
                placeholder="Process Request Date"
                required
                minDate={this.state.minDate}
                maxDate={this.state.maxDate}
                validate={[required]}
                component={this.renderDatePicker}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Field
                id="targetInput"
                name="gpi"
                type="text"
                required
                placeholder="Target GPI"
                component={renderInput}
                validate={[gpiRequired]}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Field
                id="daconInput"
                name="dacon"
                type="number"
                required
                placeholder="Target DaCon"
                component={renderInput}
                validate={[
                  number,
                  minDaconValue,
                  maxDaconValue,
                  maxDaconDecimalDigits,
                  daconRequired,
                ]}
              />
            </FormGroup>
          </Col>
          <Col md={1} className="align-self-center text-center">
            <Button
              className="mt-3"
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Run Calculations
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const selector = formValueSelector("testerForm");

const mapStateToProps = (state) => {
  return {
    gpiValue: selector(state, "gpi"),
    daconValue: selector(state, "dacon"),
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: "testerForm" })(TesterForm)
);
