import _ from "lodash";
import {
  GET_MPDS,
  GET_MPDS_CORE_SORTED,
  SAVE_MPDS,
  DELETE_MPD,
  GET_MPD,
  EDIT_MPD,
  EXCLUDE_MPD_CATEGORIES,
  CLEAR_MPDS,
} from "../actions/types";

const mpdReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MPDS:
    case GET_MPDS_CORE_SORTED:
      return { ..._.mapKeys(action.payload, "id") };
    case GET_MPD:
      return { [action.payload.id]: action.payload };
    case EDIT_MPD:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_MPD:
      return _.omit(state, action.payload);
    case CLEAR_MPDS:
      return {};
    case SAVE_MPDS:
    case EXCLUDE_MPD_CATEGORIES:
    default:
      return state;
  }
};

export default mpdReducer;
