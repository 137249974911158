import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import {
  clearClients,
  getClient,
  getBenefits,
  getMPDs,
  tryDeleteBenefit,
  clearSidebarActions,
  clearBenefits,
  populateBreadCrumbs,
  populateSidebar,
  clearMpds,
  unloadMPDs,
} from "./../../../actions";
import { Link } from "react-router-dom";
import { dateFormatter } from "./../../../utils";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { Role } from "../../../utils/auth/roles";
import { getClientSidebarItems } from "../../SideBar/SidebarItems";

class Benefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      isAuthorized: _.some(this.props.roles, {
        name: Role.CLIENT_ADMIN.name,
      }),
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.props.getMPDs(this.state.clientId);
    this.props.getClient(this.state.clientId);
    this.props.getBenefits(this.props.match.params.clientId);
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadcrumbs();
        this.setupSidebar();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearSidebarActions();
    this.props.clearBenefits();
    this.props.clearClients();
    this.props.clearMpds();
    this.props.unloadMPDs();
  }

  setupBreadcrumbs() {
    const breadcrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
      ],
      finalCrumb: "Benefit List",
    };
    this.props.populateBreadCrumbs(breadcrumbs);
  }

  setupSidebar() {
    this.props.populateSidebar(
      getClientSidebarItems(this.props.selectedClient, this.state.isAuthorized)
    );
  }

  deleteBenefit(benefitId, benefitName) {
    this.toggleModal(benefitId, benefitName);
  }

  toggleModal = (benefitId, benefitName) => {
    const header = "Delete " + benefitName;
    const content = "Are you sure you want to delete " + benefitName + "?";

    this.setState({
      isOpen: !this.state.isOpen,
      modalHeader: header,
      modalContent: content,
      modalAction: this.props.tryDeleteBenefit,
      benefitId: benefitId,
    });
  };

  isBenefitLocked = (benefitId) => {
    const mpd = _.find(this.props.mpds, (mpd) => {
      return mpd.benefitId === benefitId;
    });
    return mpd ? true : false;
  };

  actionFormatter = (content, row) => {
    const editTarget =
      "/benefits/edit/" + this.props.selectedClient.id + "/" + row.id;
    const shouldDisable = this.isBenefitLocked(row.id);
    return (
      <div>
        {this.state.isAuthorized && (
          <div>
            <Button
              className="linkBtn tableBtn actionIconBtnDanger"
              disabled={shouldDisable}
              color="link"
              onClick={() => this.deleteBenefit(row.id, row.benefitName)}
            >
              <FaTrashAlt />
            </Button>
            <Button
              className="linkBtn tableBtn actionIconLinkPrimary"
              disabled={shouldDisable}
              color="link"
              tag={Link}
              to={editTarget}
            >
              <FaPencilAlt />
            </Button>
          </div>
        )}
      </div>
    );
  };

  linkFormatter = (content, row) => {
    const target = "/benefits/view/" + this.state.clientId + "/" + row.id;
    return (
      <Button color="link" tag={Link} to={target}>
        {content}
      </Button>
    );
  };

  COLUMNS = [
    {
      dataField: "actions",
      sort: false,
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "benefitName",
      sort: true,
      text: "Benefit Name",
      formatter: this.linkFormatter,
    },
    {
      dataField: "effectiveDate",
      sort: false,
      text: "Effective Date",
      formatter: dateFormatter,
    },
    {
      dataField: "termDate",
      sort: false,
      text: "Term Date",
      formatter: dateFormatter,
    },
  ];

  render() {
    if (
      !this.props.benefits ||
      !this.props.selectedClient ||
      !this.props.mpdsLoaded
    ) {
      return <div>Loading...</div>;
    }
    // Make sure there are no empty elements in the array so the bootstrap table doesn't explode
    const benefits = this.props.benefits.filter((benefit) => {
      return benefit != null;
    });
    return (
      <Container className="main-container" fluid>
        <Row className="mt-2">
          <Col>
            {this.state.isAuthorized && (
              <Button
                className="mb-3"
                color="primary"
                tag={Link}
                to={`/benefits/add/${this.state.clientId}`}
              >
                Add Benefit
              </Button>
            )}
            <RPSDataGrid keyField="id" data={benefits} columns={this.COLUMNS} />
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalHeader}
          content={this.state.modalContent}
          action={this.state.modalAction}
          objId={this.state.benefitId}
          parentId={this.state.clientId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.auth.currentUser.roles,
    selectedClient: state.clients[ownProps.match.params.clientId],
    benefits: Object.values(state.benefits),
    mpds: Object.values(state.mpds),
    mpdsLoaded: state.mpdsLoaded,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getBenefits,
  getMPDs,
  tryDeleteBenefit,
  clearSidebarActions,
  clearBenefits,
  clearMpds,
  populateBreadCrumbs,
  populateSidebar,
  unloadMPDs,
})(Benefits);
