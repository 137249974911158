import _ from "lodash";
import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import {
  GET_CLAIM_CORRELATIONS,
  TRY_CLAIM_SEARCH,
  CLEAR_CLAIMS,
  SHOW_CLAIM_SEARCH_EXCEPTION,
  GET_ESTIMATED_CLAIM_CORRELATIONS,
  GET_ESTIMATED_CLAIM_CORRELATION_RANGES,
  UPLOAD_ESTIMATED_CLAIMS,
  DELETE_EST_CLAIM_CORRELATION,
} from "../types";
import { handleError } from "../../utils/error/errorHandlers";
import { formatPageableParameters } from "../../utils/string/stringUtils";
import history from "../../history";

export const getClaimCorrelations =
  (pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      let endPoint = "/api/claim-correlations/?";

      endPoint += formatPageableParameters(pageNumber, size, sorts);

      const claimCorrelations = await getClinicalBackend(authToken).get(
        endPoint
      );

      dispatch({
        type: GET_CLAIM_CORRELATIONS,
        payload: claimCorrelations.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getClaimCorrelationsByClient =
  (clientId, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      let endPoint = "/api/claim-correlations/?clientId=" + clientId;

      endPoint += formatPageableParameters(pageNumber, size, sorts);

      const claimCorrelations = await getClinicalBackend(authToken).get(
        endPoint
      );

      dispatch({
        type: GET_CLAIM_CORRELATIONS,
        payload: claimCorrelations.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const trySearchClaims =
  (searchParams, pageNumber, size, sortField) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const sortParams = _.map(sortField.dataFields, (field) => {
        return `sort=${field},${sortField.order}`;
      });
      const endPoint = `/api/claims/search?page=${pageNumber}&size=${size}&${_.join(
        sortParams,
        "&"
      )}`;
      const result = await getClinicalBackend(authToken).post(
        endPoint,
        JSON.stringify(searchParams)
      );

      dispatch({ type: TRY_CLAIM_SEARCH, payload: result.data });
    } catch (err) {
      if (err.response && err.response.status === 406) {
        dispatch({
          type: SHOW_CLAIM_SEARCH_EXCEPTION,
          payload: { exceptionMessage: err.response.data.detailedMessage },
        });
      } else {
        handleError(err.response, dispatch);
      }
    }
  };

export const clearClaims = () => (dispatch) => {
  dispatch({ type: CLEAR_CLAIMS });
};

export const getEstimatedClaimCorrelationsByClient =
  (clientId, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      let endPoint = "/api/estimated-claim-correlations/?clientId=" + clientId;

      endPoint += formatPageableParameters(pageNumber, size, sorts);

      const estimatedClaimCorrelations = await getClinicalBackend(
        authToken
      ).get(endPoint);

      dispatch({
        type: GET_ESTIMATED_CLAIM_CORRELATIONS,
        payload: estimatedClaimCorrelations.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getEstimatedClaimCorrelationRangesByClient =
  (clientId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      const endPoint =
        "/api/estimated-claim-correlations/date-ranges?clientId=" + clientId;

      const estimatedClaimCorrelations = await getClinicalBackend(
        authToken
      ).get(endPoint);

      dispatch({
        type: GET_ESTIMATED_CLAIM_CORRELATION_RANGES,
        payload: estimatedClaimCorrelations.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const uploadEstimatedClaims =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `/api/estimated-claim-correlations/uploads`;

      const uploadedClaims = await getClinicalBackend(authToken).post(
        endPoint,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      dispatch({
        type: UPLOAD_ESTIMATED_CLAIMS,
        payload: uploadedClaims.data,
      });
      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const deleteEstimatedClaimCorrelation =
  (id) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `api/estimated-claim-correlations/${id}`;

      const deletedCorrelation = await getClinicalBackend(authToken).delete(
        endPoint
      );
      dispatch({
        type: DELETE_EST_CLAIM_CORRELATION,
        payload: deletedCorrelation.data,
      });
      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
