import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import _ from "lodash";
import {
  clearClients,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getClient,
  getBCFileContent,
  getConfigFile,
  clearFileContent
} from "../../../actions";

import { ConfigFile } from "./fileTypes";
import {
  getFileNameNoExt,
  dollarFormatter,
  percentageFormatter
} from "../../../utils";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";

class BeneficiaryCostView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      navLoaded: false,
      clientId: this.props.match.params.clientId
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false
        },
        {
          to: "/client-config/dashboard/" + this.state.clientId,
          name: "Configuration Files",
          active: false
        }
      ],
      finalCrumb: this.props.selectedFile.fileName
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.toggleSidebarVisibility();

    this.props.getClient(this.state.clientId);
    this.props.getConfigFile(this.props.match.params.correlationId);
    this.getPage(0, this.state.sizePerPage);
  }

  componentDidUpdate() {
    if (this.props.selectedClient && this.props.selectedFile) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearFileContent([ConfigFile.BC]);
    this.props.clearClients();
  }

  getPage(page, sizePerPage) {
    this.props.getBCFileContent(
      this.props.match.params.correlationId,
      page - 1,
      sizePerPage
    );
  }

  tierLevelFormatter = (content, row) => {
    if (content === -1) {
      return "(end of file)";
    }
    return content;
  };

  shorthandFormatter = (content, row) => {
    let expanded = "N/A";
    switch (content) {
      case "RETAIL_30":
        expanded = "Retail 30";
        break;
      case "RETAIL_90":
        expanded = "Retail 90";
        break;
      case "MAIL_90":
        expanded = "Mail 90";
        break;
      case 1:
        expanded = "Copay";
        break;
      case 2:
        expanded = "Co Insurance";
        break;
      case 0:
        expanded = "N/A";
        break;
      default:
        expanded = "";
    }
    return expanded;
  };

  costFormatter = (content, row) => {
    //Copay is a dollar value
    if (row.copayType === 1 && (content !== null || content !== undefined)) {
      return dollarFormatter(content);
    } else if (content) {
      //Co Insurance is a percentage
      return percentageFormatter(content);
    }
    return "";
  };

  getFileContents = () => {
    let contents = this.props.fileContents.content;
    //Add the end of file row
    if (!_.find(contents, { id: 0 })) {
      contents.push({
        id: 0,
        tierLevel: -1
      });
    }
    return contents;
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState({ currPage: page, sizePerPage: sizePerPage });
    this.props.clearFileContent([ConfigFile.BC]);
    this.getPage(page, sizePerPage);
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage);
  };

  COLUMNS = [
    {
      dataField: "tierLevel",
      sort: false,
      text: "Formulary Tier",
      headerStyle: { width: "6%" },
      formatter: this.tierLevelFormatter
    },
    {
      dataField: "pharmacyType",
      sort: false,
      text: "Pharmacy Type",
      headerStyle: { width: "6%" },
      formatter: this.shorthandFormatter
    },
    {
      dataField: "copayType",
      sort: false,
      text: "Preferred Pharmacy Copay Type",
      formatter: this.shorthandFormatter
    },
    {
      dataField: "memberCost30",
      sort: false,
      text: "Preferred Pharmacy Member Cost 30Retail",
      formatter: this.costFormatter
    },
    {
      dataField: "memberCost90",
      sort: false,
      text: "Preferred Pharmacy Member Cost 90Mail",
      formatter: this.costFormatter
    },
    {
      dataField: "memberCost90Retail",
      sort: false,
      text: "Preferred Pharmacy Member Cost 90Retail",
      formatter: this.costFormatter
    },
    {
      dataField: "minMemberCost30",
      sort: false,
      text: "Preferred Pharmacy MIN Member Cost 30Retail",
      formatter: dollarFormatter
    },
    {
      dataField: "minMemberCost90",
      sort: false,
      text: "Preferred Pharmacy MIN Member Cost 90Mail",
      formatter: dollarFormatter
    },
    {
      dataField: "maxMemberCost30",
      sort: false,
      text: "Preferred Pharmacy MAX Member Cost 30Retail",
      formatter: dollarFormatter
    },
    {
      dataField: "maxMemberCost90",
      sort: false,
      text: "Preferred Pharmacy MAX Member Cost 90Mail",
      formatter: dollarFormatter
    },
    {
      dataField: "minMemberCost90Retail",
      sort: false,
      text: "Preferred Pharmacy MIN Member Cost 90Retail",
      formatter: dollarFormatter
    },
    {
      dataField: "maxMemberCost90Retail",
      sort: false,
      text: "Preferred Pharmacy MAX Member Cost 90Retail",
      formatter: dollarFormatter
    }
  ];

  render() {
    if (
      !this.props.selectedClient ||
      !this.props.fileContents.content ||
      !this.props.selectedFile
    ) {
      return <div>Loading...</div>;
    }

    const fileName = getFileNameNoExt(this.props.selectedFile.fileName);

    return (
      <Container className="main-container" fluid>
        <Row className="mb-4">
          <Col>
            <h3>{fileName}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RPSDataGrid
              keyField="id"
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.fileContents.totalElements}
              data={this.getFileContents()}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              remote
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    configFiles: state.clientConfigFiles,
    fileContents: state.bcFileContent,
    selectedFile: state.clientConfigFiles[ownProps.match.params.correlationId]
  };
};

export default connect(
  mapStateToProps,
  {
    clearClients,
    getClient,
    getBCFileContent,
    getConfigFile,
    clearFileContent,
    populateBreadCrumbs,
    toggleSidebarVisibility
  }
)(BeneficiaryCostView);
