import React from "react";
import PropTypes from "prop-types";
import CostRow from "./CostRow";
import _ from "lodash";
import MessagesRow from "./MessagesRow";
import { Button } from "reactstrap";

export default function GpiCostDetails({
  vbrGpiDetails,
  includesBuyUp,
  gpiType,
  includeRebates,
  rebateModalCallback,
  rebateNdcPrices,
}) {
  const vbrGpis = vbrGpiDetails;
  const costDetailsClass = includesBuyUp
    ? "gpi-cost-details-target"
    : "gpi-cost-details";

  return (
    <div className="mt-3 d-flex flex-column gap-4">
      {vbrGpis.map((vbrGpi) => {
        const drugData = vbrGpi.drugData;
        const retailCosts = vbrGpi.retailCosts;
        const mailCosts = vbrGpi.mailCosts;
        const retail90Costs = vbrGpi.retail90Costs;
        const messages = vbrGpi.messages;
        const gpiTypeAppendex = gpiType === "LCA" ? 1 : "";

        return (
          <div key={vbrGpi.id}>
            <div className="d-flex gap-3 mb-1 px-1">
              {drugData.artasSequence !== null && (
                <span className="artas-label">
                  <strong>LCA # {drugData.artasSequence}</strong>
                </span>
              )}

              {vbrGpi.ndcData && (
                <span>
                  <strong>{gpiType} NDC:</strong> {vbrGpi.ndcData}
                </span>
              )}

              <span>
                <strong>{`${gpiType} ${gpiTypeAppendex}`} GPI:</strong>&nbsp;
                {`${drugData.gpi}, ${drugData.name}`}
              </span>
              <span>
                <strong>DACon:</strong> {drugData.dacon}
              </span>
              <span>
                <strong>MultiSource:</strong> {drugData.msc}
              </span>
              <span>
                <strong>Tier Level:</strong> {drugData.tierLevel}
              </span>
              {includeRebates && _.isEmpty(rebateNdcPrices) && (
                <span>No Rebated NDCs</span>
              )}
              {includeRebates && !_.isEmpty(rebateNdcPrices) && (
                <Button
                  className="target-rebate-btn"
                  color="link"
                  onClick={() =>
                    rebateModalCallback(rebateNdcPrices, vbrGpi.drugData)
                  }
                >
                  <strong>Rebated NDC's (%)</strong>
                </Button>
              )}
            </div>

            <div className="gpi-cost-details-container px-2 py-2">
              <div className={costDetailsClass}>
                <CostRow costData={retailCosts} daySupplyLabel="30 Day" />
                <CostRow costData={mailCosts} daySupplyLabel="90 Day" />
                {retail90Costs && (
                  <CostRow
                    costData={retail90Costs}
                    daySupplyLabel="90 Retail"
                  />
                )}
              </div>

              {!_.isEmpty(messages) && <MessagesRow messages={messages} />}
            </div>
          </div>
        );
      })}
    </div>
  );
}

GpiCostDetails.propTypes = {
  vbrGpiDetails: PropTypes.array.isRequired,
  gpiType: PropTypes.string.isRequired,
  includesBuyUp: PropTypes.bool,
  includeRebates: PropTypes.bool,
  rebateModalCallback: PropTypes.func,
  rebateNdcPrices: PropTypes.array,
};
