import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import {
  clearClients,
  clearReports,
  getClients,
  getReports,
  populateBreadCrumbs,
  clearSidebarActions,
  populateSidebar,
} from "../../actions";
import { REPORT_TYPES, SIDEBAR_ITEMS } from "./Constants";
import _ from "lodash";
import { dateFormatter } from "../../utils";
import RPSDataGrid from "../DataGrid/RPSDataGrid";
import MembershipExpandRow from "./ExpandRows/MembershipExpandRow";
import { ClientDropDown } from "./components/ClientDropDown";

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportType: this.getReportType(),
      selectedClientId: null,
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
    };
    this.setupBreadCrumbs();
  }

  componentDidMount = () => {
    this.setupSidebar();
    if (this.state.reportType) {
      this.props.getClients(this.state.reportType);
      this.getPage();
    }
  };

  componentWillUnmount = () => {
    this.props.clearSidebarActions();
    this.props.clearReports();
    this.props.clearClients();
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };

  setupBreadCrumbs = () => {
    const finalCrumb = "Billing Membership";

    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/reporting",
          name: "Reporting",
          active: false,
        },
      ],
      finalCrumb,
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  getReportType = () => {
    switch (_.split(this.props.match.path, "/")[2]) {
      case "membership":
        return REPORT_TYPES.MEMBERSHIP;
      default:
        return;
    }
  };

  getPage() {
    return this.props.getReports(
      this.state.selectedClientId,
      this.state.reportType,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  }

  getSortField = (sortField) => {
    if (this.state.reportType === REPORT_TYPES.MEMBERSHIP) {
      switch (sortField) {
        case "clientReport.client.name":
          sortField = "client.name";
          break;
        case "clientReport.generatedDate":
          sortField = "generatedDate";
          break;
        default:
          return sortField;
      }
      return sortField;
    }
    return sortField;
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: this.getSortField(sortField),
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  getReports = () => {
    return this.props.reports.content;
  };

  getColumns = () => {
    const columns = [
      {
        dataField: "clientReport.client.name",
        sort: true,
        text: "Client",
      },
      {
        dataField: "clientReport.name",
        text: "Report Name",
      },
      {
        dataField: "clientReport.period",
        text: "Report Period",
      },
      {
        dataField: "clientReport.generatedDate",
        sort: true,
        text: "Date Generated",
        formatter: dateFormatter,
      },
    ];
    return columns;
  };

  handleClientChange = (event) => {
    const currentVal = event.currentTarget.value;
    this.setState(
      {
        selectedClientId: currentVal !== "all" ? currentVal : null,
      },
      () => {
        this.getPage();
      }
    );
  };

  getExpandRow = () => {
    const expandRow = {
      renderer: (row) => <MembershipExpandRow reportId={row.clientReport.id} />,
      onlyOneExpanding: false,
      showExpandColumn: true,
      expandByColumnOnly: true,
    };

    return this.state.reportType === REPORT_TYPES.MEMBERSHIP ? expandRow : {};
  };

  render() {
    if (
      _.isEmpty(this.props.clients) ||
      _.isEmpty(this.props.reports) ||
      !this.state.reportType
    ) {
      return <h1>LOADING...</h1>;
    }
    return (
      <Container className="main-container" fluid>
        <Row>
          <ClientDropDown
            id="client"
            label="Client"
            onChange={this.handleClientChange}
            clients={this.props.clients}
          />
        </Row>
        <Row className="mt-4">
          <Col>
            <RPSDataGrid
              keyField="clientReport.id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.reports.totalElements}
              data={this.getReports()}
              columns={this.getColumns()}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              expandRow={this.getExpandRow()}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: Object.values(state.clients),
    reports: state.reports,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  clearReports,
  getClients,
  getReports,
  populateBreadCrumbs,
  clearSidebarActions,
  populateSidebar,
})(Reporting);
