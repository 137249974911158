import React, { Component } from "react";
import { connect } from "react-redux";
import {
  populateBreadCrumbs,
  populateSidebar,
  getDecisionGrid,
  searchDDNs,
  saveDPOs,
  clearDDNs
} from "../../../actions";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupText
} from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { authCheck, Role } from "../../../utils";
import _ from "lodash";
import history from "../../../history";
import { getClinicalSidebarItems } from "../Constants";

class AddDrugPairingOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorized:
        authCheck(this.props.roles, Role.CLINICAL_APPROVER.name) ||
        authCheck(this.props.roles, Role.CLINICIAN.name),
      dgId: this.props.match.params.dgId,
      breadcrumbsLoaded: false,
      selectedSearch: "drugName",
      searchValue: "",
      selectedGpis: [],
      searching: false
    };
  }

  componentDidMount = () => {
    this.props.getDecisionGrid(this.state.dgId);
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  componentDidUpdate = () => {
    if (!this.state.breadcrumbsLoaded && !_.isEmpty(this.props.selectedGrid)) {
      this.setupBreadCrumbs();
      this.setState({ breadcrumbsLoaded: true });
    }

    if (this.state.searching && !_.isEmpty(this.props.searchResults)) {
      this.setState({
        selectedGpis: this.props.searchResults,
        searching: false
      });
    }
  };

  componentWillUnmount = () => {
    this.props.clearDDNs();
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/grids", name: "P&T Decision Grids", active: false },
        {
          to: `/grids/${this.state.dgId}`,
          name: this.props.selectedGrid.drugCategory.description,
          active: false
        }
      ],
      finalCrumb: "Add GPI"
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  onSearchClicked = () => {
    this.props.clearDDNs();
    this.props.searchDDNs(this.state.selectedSearch, this.state.searchValue);
    this.setState({ searching: true, selectedGpis: [] });
  };

  onEnterKey = e => {
    if (e.key === "Enter" && !_.isEmpty(this.state.searchValue.trim())) {
      this.onSearchClicked();
    }
  };

  COLUMNS = [
    {
      dataField: "gpi",
      sort: true,
      text: "GPI"
    },
    {
      dataField: "gpiCommonName",
      sort: true,
      text: "Drug Name"
    },
    {
      dataField: "multiSourceCode",
      sort: true,
      text: "MSC"
    },
    {
      dataField: "strength",
      text: "Strength Per Unit"
    },
    {
      dataField: "dosageForm",
      text: "Dosage Form"
    },
    {
      dataField: "routeOfAdministration",

      text: "Route of Admin"
    },
    {
      dataField: "packageSizeUOM",
      text: "UOM"
    }
  ];

  getSelectRow = () => {
    return {
      mode: "checkbox",
      clickToSelect: false,
      selectionHeaderRenderer: () => "Add to Grid",
      headerColumnStyle: { width: "3%" },
      onSelect: this.onGpiSelect,
      onSelectAll: this.onSelectAll,
      selected: _.map(this.state.selectedGpis, gpi => gpi.id)
    };
  };

  onSelectAll = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ selectedGpis: rows });
    } else {
      this.setState({ selectedGpis: [] });
    }
  };

  onGpiSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({ selectedGpis: [...this.state.selectedGpis, row] });
    } else {
      this.setState({
        selectedGpis: this.state.selectedGpis.filter(gpi => gpi.id !== row.id)
      });
    }
  };

  onDPOsSave = () => {
    const dpos = _.map(this.state.selectedGpis, gpi => {
      return {
        decisionGridId: this.state.dgId,
        gpi: gpi.gpi,
        msc: gpi.multiSourceCode,
        strength: gpi.strength,
        dosageForm: gpi.dosageForm,
        routeOfAdmin: gpi.routeOfAdministration,
        uom: gpi.packageSizeUOM
      };
    });

    this.props.saveDPOs(this.state.dgId, dpos);
  };

  render = () => {
    if (!this.props.selectedGrid) {
      return <h1>LOADING...</h1>;
    }
    const selectedGrid = this.props.selectedGrid;

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>
              {selectedGrid.drugCategory.description}&nbsp;&nbsp;&nbsp;
              Status:&nbsp;
              {selectedGrid.status.name}
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={3}>
            <InputGroup>
              <InputGroupText>
                <Input
                  type="select"
                  bsSize="sm"
                  onChange={e => {
                    this.setState({
                      selectedSearch: e.currentTarget.value,
                      searchValue: ""
                    });
                  }}
                  value={this.state.selectedSearch}
                >
                  <option value="drugName">Drug Name</option>
                  <option value="ahfsClass">AHFS Classification</option>
                  <option value="gpi">GPI</option>
                </Input>
              </InputGroupText>

              <Input
                type="text"
                className="form-control"
                bsSize="lg"
                onChange={e => {
                  this.setState({ searchValue: e.currentTarget.value });
                }}
                value={this.state.searchValue}
                onKeyPress={this.onEnterKey}
              />
            </InputGroup>
          </Col>
          <Col>
            <Button
              size="lg"
              disabled={_.isEmpty(this.state.searchValue.trim())}
              onClick={this.onSearchClicked}
            >
              Search
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {this.state.searching && !this.props.zeroResultsFound && (
              <h3>SEARCHING...</h3>
            )}

            {this.props.zeroResultsFound && (
              <h3>0 Results Found. Please update your search criteria.</h3>
            )}

            {!this.state.searching && !this.props.zeroResultsFound && (
              <RPSDataGrid
                keyField="id"
                data={this.props.searchResults ? this.props.searchResults : []}
                columns={this.COLUMNS}
                selectRow={this.getSelectRow()}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <Button
              color="danger"
              disabled={_.isEmpty(this.state.selectedGpis)}
              onClick={() => {
                history.go(0);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="ms-3"
              disabled={_.isEmpty(this.state.selectedGpis)}
              onClick={this.onDPOsSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };
}

const mapStateToProps = state => {
  return {
    roles: state.auth.currentUser.roles,
    selectedGrid: state.drugPairingOptions.selectedGrid,
    searchResults: state.drugPairingOptions.searchResults,
    zeroResultsFound: state.drugPairingOptions.zeroResultsFound,
    currentDPOs: state.drugPairingOptions.dpos
  };
};

export default connect(
  mapStateToProps,
  {
    populateBreadCrumbs,
    populateSidebar,
    getDecisionGrid,
    searchDDNs,
    saveDPOs,
    clearDDNs
  }
)(AddDrugPairingOption);
