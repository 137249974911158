import React, { Component } from "react";
import {
  Field,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./Contract.css";
import history from "../../../history";
import {
  PRODUCT_TYPE,
  required,
  validSelect,
  geteffTermDateConfig,
  renderSelectInput,
  renderDatePicker,
  getUnrestrictedDateConfig,
  number,
  renderInputGroup,
  DATE_CONVERT_FORMAT,
} from "../../../utils";
import _ from "lodash";
import CatalogProductNotificationForm from "./Products/CatalogProductNotificationForm";
import {
  saveNotification,
  deleteNotification,
  getNotifications,
  clearProductNotifications,
} from "../../../actions";
import AlertModal from "../../Modal/AlertModal";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { Link } from "react-router-dom";

class ClientContractForm extends Component {
  constructor(props) {
    super(props);

    const catalogProducts = _.filter(props.products, (product) => {
      return product.type !== PRODUCT_TYPE.Pricing;
    });

    const pricingProducts = _.filter(props.products, {
      type: PRODUCT_TYPE.Pricing,
    });

    const state = {
      selectedProductType: null,
      showIncludeProduct: false,
      catNotifications: [],
      rollinNotifications: [],
      notificationsLoaded: true,
      isValueBased: this.props.pricingProduct,
    };

    _.forEach(catalogProducts, (product) => {
      const propName = `catalogProds[${product.id}]`;
      state[propName] = false;
      state[propName + "Disabled"] = false;
    });

    _.forEach(pricingProducts, (product) => {
      const radioId = `pricingProducts[${product.id}]`;
      state[radioId] = false;
      state[`${radioId}Disabled`] = false;
    });

    if (props.fromEdit) {
      state.selectedProductType = props.selectedContract.type;
      // Notification will be loaded from the server, so we need to set the initial state to false
      state.notificationsLoaded = false;
      // props.initialValues comes from redux-form on edit
      const initialValues = props.initialValues;
      _.forEach(props.selectedContract.products, (product) => {
        const propName =
          product.type !== PRODUCT_TYPE.Pricing
            ? `catalogProds[${product.id}]`
            : `pricingProducts[${product.id}]`;

        if (initialValues.hasOwnProperty(propName)) {
          state[propName] = props.initialValues[propName];
          state[`${propName}Disabled`] = !props.initialValues[propName];
        }
      });

      // Loop through passed in products to set disabled of products that aren't pre-checked
      const currentCatalogProducts = _.filter(
        props.selectedContract.products,
        (product) => {
          return product.type !== PRODUCT_TYPE.Pricing;
        }
      );

      if (currentCatalogProducts.length > 0) {
        if (
          _.some(currentCatalogProducts, (product) => {
            return product.type !== PRODUCT_TYPE.CatalogA;
          })
        ) {
          _.forEach(catalogProducts, (product) => {
            if (product.type === PRODUCT_TYPE.CatalogA) {
              state[`catalogProds[${product.id}]Disabled`] = true;
            }
          });
        } else {
          _.forEach(catalogProducts, (product) => {
            if (product.type !== PRODUCT_TYPE.CatalogA) {
              state[`catalogProds[${product.id}]Disabled`] = true;
            }
          });
        }
      }

      const currentPricingProducts = _.filter(props.selectedContract.products, {
        type: PRODUCT_TYPE.Pricing,
      });

      // There should only ever be 1 pricing product
      if (currentPricingProducts.length === 1) {
        _.forEach(
          _.filter(pricingProducts, (product) => {
            return product.id !== currentPricingProducts[0].id;
          }),
          (pricingProd) => {
            state[`pricingProducts[${pricingProd.id}]Disabled`] = true;
          }
        );
        state.isValueBased = true;
      }

      state.showIncludeProduct = _.some(currentCatalogProducts, (product) => {
        return (
          product.type === PRODUCT_TYPE.Include ||
          product.type === PRODUCT_TYPE.CatalogB
        );
      });
    }

    /**
     *  We only want to pluck the product id from the selectedContract's products.
     * map function will push undefined records into the array if the predicate does not match
     * so we use filter with isNil to remove any undefined entries
     */
    const selectedProducts = props.selectedContract
      ? _.filter(
          _.map(props.selectedContract.products, (product) => {
            if (product.description !== "No Products Assigned") {
              return product.id;
            }
          }),
          (productId) => {
            return !_.isNil(productId);
          }
        )
      : [];

    // Term dates can no longer be retroactive
    const vbrContracts = _.filter(this.props.currentContracts, {
      type: "VALUE_BASED",
    });
    const vbrDateConfig = geteffTermDateConfig(vbrContracts);
    const catContracts = _.filter(this.props.currentContracts, {
      type: "CATALOG",
    });
    const catDateConfig = geteffTermDateConfig(catContracts);
    const unrestrictedMinTermDate = getUnrestrictedDateConfig().minTermDate;
    vbrDateConfig.minTermDate = unrestrictedMinTermDate;
    catDateConfig.minTermDate = unrestrictedMinTermDate;

    // restrict minimum effective date to today's date or greater
    vbrDateConfig.minEffectiveDate = moment(
      vbrDateConfig.minEffectiveDate
    ).isBefore(moment())
      ? moment().toDate()
      : vbrDateConfig.minEffectiveDate;
    catDateConfig.minEffectiveDate = moment(
      catDateConfig.minEffectiveDate
    ).isBefore(moment())
      ? moment().toDate()
      : catDateConfig.minEffectiveDate;

    if (props.fromEdit) {
      const currentEffectiveDate = moment(props.selectedContract.effectiveDate);
      if (currentEffectiveDate.isAfter(moment())) {
        const minTermDate = currentEffectiveDate.add(1, "d").toDate();
        vbrDateConfig.minTermDate = minTermDate;
        catDateConfig.minTermDate = minTermDate;
      }
    }

    this.state = {
      ...state,
      vbrDateConfig,
      catDateConfig,
      selectedProducts: _.isEmpty(selectedProducts) ? [] : selectedProducts,
      isAlertOpen: false,
      isConfirmOpen: false,
      contractId: props.selectedContract ? props.selectedContract.id : null,
      hasTermDate:
        props.selectedContract && props.selectedContract.termDate
          ? true
          : false,
    };
  }

  componentDidMount() {
    if (this.state.contractId) {
      this.props.getNotifications(this.state.contractId);
    }
  }

  componentDidUpdate() {
    if (!this.props.notificationsLoading && !this.state.notificationsLoaded) {
      const catNotifications = _.filter(this.props.notifications, {
        type: "CATALOG",
      });
      const rollinNotifications = _.filter(this.props.notifications, {
        type: "ROLLIN",
      });

      this.setState({
        catNotifications,
        rollinNotifications,
        notificationsLoaded: true,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearProductNotifications();
  }

  renderSelectionControl({
    input,
    id,
    label,
    productId,
    productType,
    checked,
    disabled,
    type,
  }) {
    return (
      <div className="field">
        <Label check>
          <Input
            type={type}
            data-product={productId}
            data-producttype={productType}
            {...input}
            id={id}
            onChange={input.onChange}
            checked={checked}
            value={input.value}
            disabled={disabled}
          />
          {label}
        </Label>
      </div>
    );
  }

  onEffectiveDateChange = (newVal) => {
    if (newVal) {
      let proposedNewTermDate = moment(newVal, DATE_CONVERT_FORMAT).add(1, "d");
      const today = moment();

      if (proposedNewTermDate.isSameOrAfter(today)) {
        proposedNewTermDate = moment(newVal, DATE_CONVERT_FORMAT).add(1, "d");
      } else {
        proposedNewTermDate = moment(
          today.format(DATE_CONVERT_FORMAT),
          DATE_CONVERT_FORMAT
        ).add(1, "d");
      }
      if (this.state.isValueBased) {
        const vbrDateConfig = this.state.vbrDateConfig;
        vbrDateConfig.minTermDate = proposedNewTermDate.toDate();
        this.setState({
          vbrDateConfig,
        });
      } else {
        const catDateConfig = this.state.catDateConfig;
        catDateConfig.minTermDate = proposedNewTermDate.toDate();
        this.setState({
          catDateConfig,
        });
      }
    }
  };

  onTermDateChange = (newVal) => {
    if (newVal) {
      const minMax = moment(newVal, DATE_CONVERT_FORMAT)
        .subtract(1, "d")
        .toDate();
      if (this.state.isValueBased) {
        const vbrDateConfig = this.state.vbrDateConfig;
        vbrDateConfig.maxEffectiveDate = minMax;
        this.setState({
          vbrDateConfig,
        });
      } else {
        const catDateConfig = this.state.catDateConfig;
        catDateConfig.maxEffectiveDate = minMax;
        this.setState({
          catDateConfig,
        });
      }
    }
  };

  onSubmit = (formValues) => {
    // If the contract type demands products but no product was selected, throw error.
    if (
      this.props.typeValue &&
      this.shouldShowProducts(this.props.typeValue) &&
      this.state.selectedProducts.length <= 0
    ) {
      throw new SubmissionError({
        products: "No product added.",
        _error: "Clinical Catalog selection is required.",
      });
    }
    // Need to grab the product ids off component state to send along with the formValues
    formValues = { ...formValues, productIds: this.state.selectedProducts };
    if (!this.props.fromEdit) {
      const newNotifications = _.concat(
        this.state.catNotifications,
        this.state.rollinNotifications
      );
      formValues = { ...formValues, newNotifications };
    }

    // If the product was switched from VBR to catalog, null out the billing type and rate.
    if (!this.state.isValueBased) {
      formValues.billingType = null;
      formValues.billRate = null;
    }

    //Attempt convert dates
    formValues.effectiveDate = formValues.effectiveDate
      ? moment(formValues.effectiveDate).format(DATE_CONVERT_FORMAT)
      : null;
    formValues.termDate = formValues.termDate
      ? moment(formValues.termDate).format(DATE_CONVERT_FORMAT)
      : null;

    this.props.onSubmit(formValues, this.props.selectedClientId);
  };

  onProductCheckboxChange({ currentTarget }) {
    const selectedProducts = this.state.selectedProducts;
    const checkboxValue = parseInt(currentTarget.getAttribute("data-product"));
    const productType = currentTarget.getAttribute("data-producttype");

    currentTarget.checked
      ? selectedProducts.push(checkboxValue)
      : _.pull(selectedProducts, checkboxValue);

    if (productType !== PRODUCT_TYPE.Pricing) {
      const selfAdminProduct = _.find(this.props.products, (product) => {
        return product.description.search("Clinical Catalog - Self") >= 0;
      });
      const includeProduct = _.find(this.props.products, (product) => {
        return product.type === PRODUCT_TYPE.Include;
      });

      const products =
        productType === PRODUCT_TYPE.CatalogA
          ? _.filter(
              this.props.products,
              (product) =>
                product.type === PRODUCT_TYPE.CatalogB ||
                product.type === PRODUCT_TYPE.Include
            )
          : _.filter(this.props.products, { type: PRODUCT_TYPE.CatalogA });

      // If we are unchecking a CatologB product, but we still have other catalogB products selected, do nothing
      const catBProds = _.filter(
        this.props.products,
        (product) =>
          product.type === PRODUCT_TYPE.CatalogB ||
          product.type === PRODUCT_TYPE.Include
      );

      let hasCatBProd = false;
      for (let i = 0; i < selectedProducts.length; i++) {
        if (_.some(catBProds, { id: selectedProducts[i] })) {
          hasCatBProd = true;
          break;
        }
      }

      if (
        productType === PRODUCT_TYPE.CatalogA ||
        (productType !== PRODUCT_TYPE.CatalogA && !hasCatBProd) ||
        (currentTarget.checked && hasCatBProd)
      ) {
        // If CatalogA product is selected CatalogB and Include products should be disabled & vice versa
        _.forEach(products, (product) => {
          const propName = `catalogProds[${product.id}]Disabled`;
          this.setState({ [propName]: currentTarget.checked });
        });
      }

      // Only show the include if the self admin product is in the selected products array
      let showIncludeProduct = this.state.showIncludeProduct;
      if (productType === PRODUCT_TYPE.CatalogB) {
        showIncludeProduct = _.some(selectedProducts, (selected) => {
          return selected === selfAdminProduct.id;
        });
      }

      const includeProductName = `catalogProds[${includeProduct.id}]`;
      if (!showIncludeProduct) {
        _.pull(selectedProducts, includeProduct.id);
        /**
         * Redux form doesn't know how to handle checkboxes that are co-dependent
         * this.props.change is a redux form method for explicitly changing the values
         * that redux form has stored in state. So, we get the current values redux form
         * knows about, make the change that we need (in this case, if the self-administered
         * checkbox is unchecked, make sure include checkbox is also unchecked) and then reset
         * the valuse using the change function.
         */
        const prodVals = this.props.catProductValue;
        if (prodVals) {
          prodVals[includeProduct.id] = false;
          this.props.change("catalogProds", prodVals);
        }
      }

      this.setState({
        selectedProducts,
        showIncludeProduct,
        [currentTarget.name]: currentTarget.checked,
        [includeProductName]: !_.some(selectedProducts, (selected) => {
          return selected === selfAdminProduct.id;
        })
          ? false
          : _.some(selectedProducts, (selected) => {
              return selected === includeProduct.id;
            }),
      });
    } else {
      const pricingProducts = _.filter(this.props.products, {
        type: PRODUCT_TYPE.Pricing,
      });

      _.forEach(pricingProducts, (pricingProd) => {
        const disabled =
          pricingProd.id !== checkboxValue && currentTarget.checked;

        this.setState({
          [`pricingProducts[${pricingProd.id}]Disabled`]: disabled,
        });
      });

      this.setState({
        selectedProducts,
        [`pricingProducts[${checkboxValue}]`]: currentTarget.checked,
      });
    }
  }

  onProductCheckboxPreChange = (e) => {
    if (this.props.fromEdit) {
      const currentTarget = e.currentTarget;
      const selectedProducts = this.state.selectedProducts;
      const checkboxValue = parseInt(
        currentTarget.getAttribute("data-product")
      );
      const numNotifications =
        this.state.catNotifications.length +
        this.state.rollinNotifications.length;

      if (!currentTarget.checked) {
        const newSelectedProducts = _.pull(selectedProducts, checkboxValue);
        const includeProduct = _.find(this.props.products, {
          type: PRODUCT_TYPE.Include,
        });
        // If there is only 2 or fewer selected products left, make sure it isn't the include product since include can only exist when Self-administered is also checked
        if (newSelectedProducts.length <= 2) {
          _.pull(newSelectedProducts, includeProduct.id);
        }
        const hasCatProdSelected =
          this.hasCatalogProductSelected(newSelectedProducts);
        if (!hasCatProdSelected && numNotifications > 0) {
          this.toggleConfirmModal(e);
        } else {
          this.onProductCheckboxChange(e);
        }
      } else {
        this.onProductCheckboxChange(e);
      }
    } else {
      this.onProductCheckboxChange(e);
    }
  };

  shouldBeChecked = (checkboxName) => {
    if (
      this.props.fromEdit &&
      this.props.initialValues.hasOwnProperty(checkboxName)
    ) {
      return this.state[checkboxName];
    } else if (this.state.hasOwnProperty(checkboxName)) {
      /* When adding a product that wasn't previously associated with the contract,
       * use component state to determine checked
       */
      return this.state[checkboxName];
    }
    return false;
  };

  shouldShowProducts = (typeId) => {
    if (typeId) {
      const contractType = _.find(this.props.contractTypes, {
        value: typeId,
      });
      return contractType.value !== "VALUE_BASED";
    }
    return false;
  };

  onContractTypeChange = ({ currentTarget }) => {
    /**
     * Reset the checkboxes to be unchecked and enabled, as well as empty selected
     * products array when contract type changes
     */
    _.forEach(this.props.products, (product) => {
      const name =
        product.type !== PRODUCT_TYPE.Pricing
          ? `catalogProds[${product.id}]`
          : `pricingProducts[${product.id}]`;
      this.setState({
        [name]: false,
        [name + "Disabled"]: false,
        selectedProducts: [],
      });
    });
    this.setState({
      selectedProductType: currentTarget.value,
      isValueBased: currentTarget.value === "VALUE_BASED",
    });
  };

  renderPricingProducts = () => {
    const pricingProducts = _.filter(this.props.products, {
      type: PRODUCT_TYPE.Pricing,
    });
    return _.map(pricingProducts, (product) => {
      const fieldName = `pricingProducts[${product.id}]`;
      return (
        <FormGroup className="mb-2" check key={product.id}>
          <Field
            id={`pricingProducts${product.id}`}
            disabled={this.state[fieldName + "Disabled"]}
            name="pricingProduct"
            type="checkbox"
            productId={product.id}
            productType={product.type}
            label={product.description}
            value={product.id}
            onChange={(event) => this.onProductCheckboxChange(event)}
            component={this.renderSelectionControl}
            checked={this.shouldBeChecked(fieldName)}
          />
        </FormGroup>
      );
    });
  };

  renderCatalogProducts = (productType = PRODUCT_TYPE.Include) => {
    const disabledBecauseTermDate =
      this.props.fromEdit && this.state.hasTermDate;
    const catalogProducts = _.filter(this.props.products, {
      type: productType,
    });
    const cbClass =
      productType === PRODUCT_TYPE.Include ? "includeProductCb" : "";

    return _.map(catalogProducts, (product, idx) => {
      const fieldName = `catalogProds[${product.id}]`;

      return (
        <FormGroup className={`mb-2 ${cbClass}`} check key={product.id}>
          {idx === 0 && productType === PRODUCT_TYPE.CatalogB && (
            <div className="mb-2">
              <strong>---OR---</strong>
            </div>
          )}
          <Field
            id={`catalogProds[${product.id}]`}
            disabled={
              this.state[fieldName + "Disabled"] || disabledBecauseTermDate
            }
            name={fieldName}
            type="checkbox"
            productId={product.id}
            productType={product.type}
            label={product.description}
            onChange={(event) => this.onProductCheckboxPreChange(event)}
            component={this.renderSelectionControl}
            checked={this.shouldBeChecked(fieldName)}
          />
        </FormGroup>
      );
    });
  };

  onNotificationAdd = (type, newEmail, vals) => {
    if (this.props.fromEdit) {
      // We already have a contract ID, so save the new notification outright
      const newNotification = _.find(vals, { email: newEmail });
      if (newNotification) {
        newNotification.clientContractId = this.state.contractId;
        this.props.saveNotification(newNotification);
      }
    } else {
      /**
       * We cant save notifications without a valid contract ID. In this case we build our
       * collection of notifications and will save them after successful save of the new contract
       * returns a contract id to us
       */
      this.setState({
        [type === "catalog" ? "catNotifications" : "rollinNotifications"]: vals,
      });
    }
  };

  onNotificationRemove = (type, val, newVals) => {
    if (this.props.fromEdit) {
      // We know this notification is already saved to the DB, so remove it outright
      const notification = _.find(this.props.notifications, {
        email: val.email,
        type: val.type,
      });
      if (notification) {
        this.props.deleteNotification(notification.id);
      }
    }
    this.setState({
      [type === "catalog" ? "catNotifications" : "rollinNotifications"]:
        newVals,
    });
  };

  /**
   * Displays a warning to the user if editing contract
   * and trying to remove last remaining notification email
   * @returns bool
   */
  notificationPreRemoveCallback = () => {
    if (this.props.fromEdit) {
      const numNotificationEmails =
        this.state.catNotifications.length +
        this.state.rollinNotifications.length;
      if (numNotificationEmails === 1) {
        this.toggleAlertModal();
        return false;
      }
    }
    return true;
  };

  /**
   * Helper function to determine if any selected products are of Catalog type
   * @param {Array} selectedProducts
   * @returns bool
   */
  hasCatalogProductSelected = (selectedProducts) => {
    const selectedProds = !_.isEmpty(selectedProducts)
      ? selectedProducts
      : this.state.selectedProducts;
    const catProds = _.filter(this.props.products, (product) => {
      return product.type !== PRODUCT_TYPE.Pricing;
    });
    let hasCatProd = false;
    for (let i = 0; i < selectedProds.length; i++) {
      if (_.some(catProds, { id: selectedProds[i] })) {
        hasCatProd = true;
        break;
      }
    }
    return hasCatProd;
  };

  missingNotifications = () => {
    return (
      this.hasCatalogProductSelected() &&
      _.isEmpty(this.state.catNotifications) &&
      _.isEmpty(this.state.rollinNotifications)
    );
  };

  toggleAlertModal = () => {
    this.setState({
      isAlertOpen: !this.state.isAlertOpen,
    });
  };

  toggleConfirmModal = (formEvent) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmFormEvent: formEvent,
    });
  };

  onConfirmed = (formEvent) => {
    this.props.change("catalogProds", null);
    formEvent.currentTarget.checked = false;
    this.onProductCheckboxChange(formEvent);
  };

  render() {
    if (!this.props.contractTypes || !this.state.notificationsLoaded) {
      return <h1>LOADING...</h1>;
    }

    const isValueBased = this.state.isValueBased;

    const { submitting, pristine, error } = this.props;
    const shouldShowProducts = this.shouldShowProducts(this.props.typeValue);
    const catProductValue = this.props.catProductValue;
    const shouldShowNotificationForms =
      catProductValue &&
      _.some(catProductValue, (val) => {
        return val;
      });

    const includeProduct = _.find(this.props.products, {
      type: PRODUCT_TYPE.Include,
    });

    const showManageTargetBtn =
      catProductValue &&
      _.has(catProductValue, includeProduct.id) &&
      catProductValue[includeProduct.id] === true;

    const hasCatProdAndMissingNotifications = this.missingNotifications();

    const shouldBeDisabled = this.props.fromEdit && this.state.hasTermDate;
    const shouldSelectBeDisabled =
      this.props.fromEdit &&
      (this.state.hasTermDate || this.props.selectedContract.hasBackingProduct);

    return (
      <Form
        className="add-contract-form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        autoComplete="off"
      >
        <Row>
          <Col md="3">
            <FormGroup>
              <Field
                id="type"
                name="type"
                type="select"
                label="Product Type"
                required
                onChange={this.onContractTypeChange}
                items={this.props.contractTypes}
                valueProp="value"
                displayProp="label"
                keyProp="key"
                validate={[required, validSelect]}
                component={renderSelectInput}
                disabled={shouldSelectBeDisabled}
              />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Field
                id="effectiveDate"
                name="effectiveDate"
                placeholder="Effective Date"
                required
                onChange={this.onEffectiveDateChange}
                minDate={
                  isValueBased
                    ? this.state.vbrDateConfig.minEffectiveDate
                    : this.state.catDateConfig.minEffectiveDate
                }
                maxDate={
                  isValueBased
                    ? this.state.vbrDateConfig.maxEffectiveDate
                    : this.state.catDateConfig.maxEffectiveDate
                }
                validate={[required]}
                component={renderDatePicker}
                shouldDisable={
                  shouldBeDisabled || !this.state.selectedProductType
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <Field
              id="termDate"
              name="termDate"
              placeholder="Term Date"
              onChange={this.onTermDateChange}
              minDate={
                isValueBased
                  ? this.state.vbrDateConfig.minTermDate
                  : this.state.catDateConfig.minTermDate
              }
              maxDate={
                isValueBased
                  ? this.state.vbrDateConfig.maxTermDate
                  : this.state.catDateConfig.maxTermDate
              }
              component={renderDatePicker}
              shouldDisable={
                shouldBeDisabled || !this.state.selectedProductType
              }
            />
          </Col>
          {isValueBased && (
            <>
              <Col>
                <FormGroup>
                  <Field
                    id="billingType"
                    name="billingType"
                    type="select"
                    label="Billing Type"
                    required
                    items={this.props.billingTypes}
                    valueProp="value"
                    displayProp="label"
                    keyProp="key"
                    validate={[required, validSelect]}
                    component={renderSelectInput}
                    disabled={shouldBeDisabled}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Field
                    id="billRate"
                    name="billRate"
                    type="text"
                    placeholder="Bill Rate"
                    symbolText="$"
                    component={renderInputGroup}
                    validate={[number]}
                    disabled={shouldBeDisabled}
                  />
                </FormGroup>
              </Col>
            </>
          )}
        </Row>
        {this.props.typeValue && shouldShowProducts && (
          <>
            <Row className="mt-3">
              <Col>
                {this.renderCatalogProducts(PRODUCT_TYPE.CatalogA)}
                {this.renderCatalogProducts(PRODUCT_TYPE.CatalogB)}
                {this.state.showIncludeProduct &&
                  this.renderCatalogProducts(PRODUCT_TYPE.Include)}
                {showManageTargetBtn && (
                  <Button
                    className="manage-btn mb-4"
                    color="primary"
                    tag={Link}
                    to={`/products/provider-drugs/${this.state.contractId}/${this.props.selectedClientId}`}
                  >
                    Manage Additional Targeted Medications
                  </Button>
                )}
                {shouldShowNotificationForms && (
                  <Row className="mt-5">
                    <Col md={5}>
                      <h5>
                        Clinical Catalog(s) Delivered - Email Notifications
                      </h5>
                      <CatalogProductNotificationForm
                        name="catalog"
                        initialValues={this.state.catNotifications}
                        addCallback={this.onNotificationAdd}
                        removeCallback={this.onNotificationRemove}
                        preRemoveCallback={this.notificationPreRemoveCallback}
                      />
                      <p className="mt-5 text-nowrap font-weight-bold">
                        If client has opted to receive the Roll-in Report, at
                        least one Email address must be entered.
                      </p>
                      <h5 className="text-nowrap mt-3">
                        Client Roll-ins Report - Email Notifications
                      </h5>
                      <CatalogProductNotificationForm
                        name="rollin"
                        initialValues={this.state.rollinNotifications}
                        addCallback={this.onNotificationAdd}
                        removeCallback={this.onNotificationRemove}
                        preRemoveCallback={this.notificationPreRemoveCallback}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Row>{error && <span className="form-error">{error}</span>}</Row>
          </>
        )}
        <Row className="mt-4">
          <Col md={8} />
          <Col md={2}>
            <Button
              color="danger"
              block
              onClick={(e) => {
                e.preventDefault();
                history.push(`/clients/view/${this.props.selectedClientId}`);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col md={2}>
            <Button
              color="primary"
              type="submit"
              block
              disabled={
                submitting || pristine || hasCatProdAndMissingNotifications
              }
            >
              Submit
            </Button>
          </Col>
        </Row>
        <AlertModal
          isOpen={this.state.isAlertOpen}
          toggle={this.toggleAlertModal}
          header="Cannot delete last notification email"
          content="Contracts with Catalog products must contain at least 1 notification email."
        />
        <ConfirmationModal
          isOpen={this.state.isConfirmOpen}
          toggle={this.toggleConfirmModal}
          header="Removing final Catalog Product"
          content="Removing all catalog products from this contract will also remove ALL currently saved Catalog and Rollin notifications. Do you wish to continue?"
          action={this.onConfirmed}
          formEvent={this.state.confirmFormEvent}
        />
      </Form>
    );
  }
}

const selector = formValueSelector("clientContractForm");

const mapStateToProps = (state) => {
  return {
    typeValue: selector(state, "type"),
    catProductValue: selector(state, "catalogProds"),
    pricingProduct: selector(state, "pricingProducts"),
    notifications: Object.values(state.productNotifications.notifications),
    notificationsLoading: state.productNotifications.loading,
  };
};

export default connect(mapStateToProps, {
  saveNotification,
  deleteNotification,
  getNotifications,
  clearProductNotifications,
})(reduxForm({ form: "clientContractForm" })(ClientContractForm));
