import React, { Component } from "react";
import { Button, Form, FormGroup } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { renderInlineInput } from "../../../utils/forms/inputRenderers";
import {
  required,
  maxLength50,
  alphanNoSpace
} from "./../../../utils/forms/validators";

class MPDTieInForm extends Component {
  onSubmit = formValues => {
    this.props.onSubmit(formValues);
    this.props.reset();
  };

  render() {
    const { invalid, submitting, pristine } = this.props;
    return (
      <Form
        inline
        className="add-tie-in-form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        autoComplete="off"
      >
        <div className="d-flex align-items-center gap-2">
          <FormGroup>
            <Field
              id="name"
              name="name"
              type="text"
              placeholder="Name"
              required
              validate={[required, maxLength50, alphanNoSpace]}
              component={renderInlineInput}
            />
          </FormGroup>
          <FormGroup>
            <Button
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Save
            </Button>
          </FormGroup>
        </div>
      </Form>
    );
  }
}

export default reduxForm({ form: "tieInForm" })(MPDTieInForm);
