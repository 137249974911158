import React from "react";
import {
  FaTrashAlt,
  FaLock,
  FaRegClone,
  FaStickyNote,
  FaUpload,
} from "react-icons/fa";
import { AiFillFileAdd } from "react-icons/ai";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

const ActionButton = (props) => {
  const getIcon = () => {
    let icon = null;
    const tooltip = props.tooltip;
    switch (props.actionType) {
      case "copy":
        icon = <FaRegClone title={tooltip} />;
        break;
      case "delete":
        icon = <FaTrashAlt title={tooltip} />;
        break;
      case "lock":
        icon = <FaLock title={tooltip} />;
        break;
      case "note":
        icon = <FaStickyNote title={tooltip} />;
        break;
      case "upload":
        icon = <FaUpload title={tooltip} />;
        break;
      case "create":
        icon = <AiFillFileAdd title={tooltip} />;
        break;
      default:
        icon = <FaLock title={tooltip} />;
    }
    return icon;
  };

  const getSize = () => {
    return props.size === "large" ? "lg" : "sm";
  };

  const getColorClass = () => {
    if (props.disabled) {
      return "actionIconBtnDisabled";
    }

    let colorClass = "blue";
    switch (props.actionColor) {
      case "blue":
        colorClass = "actionIconLinkPrimary";
        break;
      case "red":
        colorClass = "actionIconBtnDanger";
        break;
      case "green":
        colorClass = "noteActionGreen";
        break;
      case "orange":
        colorClass = "noteActionOrange";
        break;
      default:
        break;
    }
    return colorClass;
  };

  const getCentered = () => {
    return props.centered ? "" : "linkBtn";
  };

  return (
    <Button
      className={`${getCentered()} tableBtn ${getColorClass()}`}
      color="link"
      size={`${getSize()}`}
      onClick={props.onClick}
      title={props.tooltip}
    >
      {getIcon()}
    </Button>
  );
};

ActionButton.defaultProps = {
  actionColor: "blue",
  tooltip: "",
  actionType: "locked",
  disabled: false,
  centered: false,
  size: "small",
};

ActionButton.propTypes = {
  actionColor: PropTypes.oneOf(["red", "blue", "green", "orange"]),
  actionType: PropTypes.oneOf([
    "delete",
    "copy",
    "note",
    "locked",
    "upload",
    "create",
  ]),
  size: PropTypes.oneOf(["large", "small"]),
  centered: PropTypes.bool,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ActionButton;
