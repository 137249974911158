import _ from "lodash";
import {
  GET_BENEFITS,
  SAVE_BENEFIT,
  GET_BENEFIT,
  EDIT_BENEFIT,
  DELETE_BENEFIT,
  CLEAR_BENEFITS,
} from "../actions/types";

const benefitsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_BENEFITS:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case GET_BENEFIT:
    case SAVE_BENEFIT:
    case EDIT_BENEFIT:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_BENEFIT:
      return _.omit(state, action.payload);
    case CLEAR_BENEFITS:
      return {};
    default:
      return state;
  }
};

export default benefitsReducer;
