import { SIGN_IN, SIGN_OUT, SHOW_AUTH_ERROR } from "../actions/types";
import Timer from "./../utils/auth/timer";

const INITIAL_AUTH_STATE = {
  authToken: sessionStorage.getItem("authToken"),
  refreshToken: sessionStorage.getItem("refreshToken"),
  expirationTime: sessionStorage.getItem("expirationTime"),
  currentUser: JSON.parse(sessionStorage.getItem("currentUser")),
  expirationDate: sessionStorage.getItem("expirationDate"),
  timedOut: false,
  hasAuthError: null,
};

const authReducer = (state = INITIAL_AUTH_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      sessionStorage.clear();
      const authToken = action.payload.authToken;
      const refreshToken = action.payload.refreshToken;
      const expiration = action.payload.expiration;
      const currentUser = action.payload.currentUser;
      const time = expiration * 1000;
      const expDate = new Date() * 1 + time;

      sessionStorage.setItem("authToken", authToken);
      sessionStorage.setItem("refreshToken", refreshToken);
      sessionStorage.setItem("expirationTime", expiration);
      sessionStorage.setItem("currentUser", JSON.stringify(currentUser));
      sessionStorage.setItem("expirationDate", expDate);

      const timer = Timer.getInstance();
      timer.startTimer(expDate);

      return {
        ...INITIAL_AUTH_STATE,
        authToken: authToken,
        refreshToken: refreshToken,
        expirationTime: expiration,
        currentUser: currentUser,
        expirationDate: expDate,
      };
    case SIGN_OUT:
      sessionStorage.clear();
      // Clear out local storage (currently holds filter choices for users)
      localStorage.clear();
      return {
        ...INITIAL_AUTH_STATE,
        authToken: null,
        refreshToken: null,
        expirationTime: null,
        currentUser: null,
        expirationDate: null,
        timedOut: action.payload,
      };
    case SHOW_AUTH_ERROR:
      return {
        ...state,
        hasAuthError: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
