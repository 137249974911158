import { GET_CATALOG } from "../actions/types";

const catalogReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CATALOG:
      return action.payload;
    default:
      return state;
  }
};

export default catalogReducer;
