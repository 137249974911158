import { getClinicalBackend } from "../../api/clinicalBackend";
import {
  CLEAR_DDNS,
  CLEAR_DPOS,
  CLEAR_NDC_DRUG_PAIRING_OPTIONS,
  GET_ALL_NDC_DRUG_PAIRING_OPTIONS,
  GET_DRUG_PAIRING_OPTIONS,
  SAVE_NDC_DRUG_PAIRING_OPTION,
  SEARCH_DDNS,
  UPDATE_DPO,
  UPDATE_NDC_DPO,
} from "../types";
import { tryRefreshToken } from "../auth/authAction";
import { formatPageableParameters, handleError } from "../../utils";
import history from "../../history";
import _ from "lodash";
import axios from "axios";

export const saveNDCDPO = (gDPOId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = `/api/ndc-drug-pairing-options?gDPOId=${gDPOId}`;
    await getClinicalBackend(authToken).post(endPoint);
    dispatch({ type: SAVE_NDC_DRUG_PAIRING_OPTION });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const hasValidNDCs = (gDPOId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = `/api/ndc-drug-pairing-options/hasValidNDCs?gDPOId=${gDPOId}`;
    const stuff = await getClinicalBackend(authToken).get(endPoint);
    return stuff.data;
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getNDCDPOs =
  (gDPOId, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `/api/ndc-drug-pairing-options?gDPOId=${gDPOId}${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null,
      )}`;
      const nDPOs = await getClinicalBackend(authToken).get(endPoint);
      dispatch({ type: GET_ALL_NDC_DRUG_PAIRING_OPTIONS, payload: nDPOs.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getDrugPairingOptions =
  (dgId, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/drug-pairing-options/?dgId=${dgId}${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null,
      )}`;

      const dpos = await getClinicalBackend(authToken).get(endpoint);
      dispatch({
        type: GET_DRUG_PAIRING_OPTIONS,
        payload: dpos.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const searchDrugPairingOptions =
  (dgId, searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      // Build the search strings if terms for given key exist
      let searchParams = "";
      for (const [key, value] of Object.entries(searchTerms)) {
        if (value.length > 0) {
          searchParams += `&${key}=${value.join()}`;
        }
      }

      const endpoint = `/api/drug-pairing-options/search/?dgId=${dgId}${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null,
      )}${searchParams}`;

      const dpos = await getClinicalBackend(authToken).get(endpoint);
      dispatch({
        type: GET_DRUG_PAIRING_OPTIONS,
        payload: dpos.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const searchDDNs = (searchType, term) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/drug-descriptor-names/search?${searchType}=${term}`;

    const ddns = await getClinicalBackend(authToken).get(endpoint);
    dispatch({
      type: SEARCH_DDNS,
      payload: ddns.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const saveDPOs = (gridId, dpos) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = "/api/drug-pairing-options";

    // axios.all will wait for all service call promises to be fulfilled before moving on
    await axios.all(
      _.map(dpos, (dpo) => {
        getClinicalBackend(authToken).post(endpoint, JSON.stringify(dpo));
      }),
    );

    history.push(`/grids/${gridId}`);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const updateDPO =
  (dpoId, dpo, doPageRefresh, isNDC = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/drug-pairing-options/${dpoId}`;

      const response = await getClinicalBackend(authToken).put(
        endpoint,
        JSON.stringify(dpo),
      );
      if (doPageRefresh) {
        history.go(0);
      } else {
        const type = isNDC ? UPDATE_NDC_DPO : UPDATE_DPO;
        dispatch({ type, payload: response.data });
      }
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const deleteDPO = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/drug-pairing-options/${id}`;

    await getClinicalBackend(authToken).delete(endpoint);

    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const copyDPO = (dpoId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/drug-pairing-options/copy?dpoId=${dpoId}`;

    await getClinicalBackend(authToken).post(endpoint);
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearDDNs = () => (dispatch) => {
  dispatch({ type: CLEAR_DDNS });
};

export const clearDPOS =
  (isNDC = false) =>
  (dispatch) => {
    const type = isNDC ? CLEAR_NDC_DRUG_PAIRING_OPTIONS : CLEAR_DPOS;
    dispatch({ type });
  };
