import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Container,
  FormGroup,
  Input,
  Row,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import history from "../../../history";
import "./MPD.css";
import _ from "lodash";
import {
  clearClients,
  getClient,
  getMPD,
  getTargetCategorySummaries,
  populateBreadCrumbs,
  getGPIExclusionReasons,
  getMPDExclusionReasons,
  excludeMPDGpis,
  getMPDTargetCosts,
  toggleSidebarVisibility,
  clearAllFilters,
  getDrugPairErrorCodes,
  getMPDCategoryExclusions,
  clearMPDCategoryExclusions,
  getRebateReferenceNdcs,
} from "../../../actions";
import {
  getDrugName,
  getNPEBreadcrumbs,
  twoDecimalFormatter,
  isVBRCurrentlyImplemented,
  Role,
} from "../../../utils";

import ConfirmationModal from "../../Modal/ConfirmationModal";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { isHcaNDC, isLcaNDC, PairingType } from "./PairingTypes";
import { Link } from "react-router-dom";
import CatalogBenefitHeader from "./CatalogBenefitHeader";
import DrugPairsSearchForm from "./DrugPairsSearchForm";
import VbrGpiCostTabs from "./GpiCosts/VbrGpiCostTabs";

class MPDDrugPairsManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      clientId: this.props.match.params.clientId,
      mpdId: this.props.match.params.mpdId,
      navLoaded: false,
      excludeReasonsLoaded: false,
      errorReasonsLoaded: false,
      dtos: [],
      pristine: true,
      selectedReason: "null",
      selectDisabled: true,
      currPage: 1,
      sizePerPage: 15,
      sortField: [{ dataField: "gcn.common_name", order: "asc" }],
      isAuthorized: _.some(this.props.auth.currentUser.roles, {
        name: Role.CLINICIAN.name,
      }),
      searchTerms: {
        names: [],
        categories: [],
        excludes: [],
        errors: [],
        minMax: [-999999, 999999],
      },
    };
  }

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getMPD(this.state.mpdId);
    this.props.getMPDExclusionReasons();
    this.props.getGPIExclusionReasons();
    this.props.getDrugPairErrorCodes();
    this.props.getMPDCategoryExclusions(this.state.mpdId);
    this.props.getRebateReferenceNdcs(this.state.mpdId);
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearAllFilters();
    this.props.clearClients();
    this.props.clearMPDCategoryExclusions();
  }

  componentDidUpdate() {
    if (
      !_.isEmpty(this.props.exclusionGPIReasons) &&
      !_.isEmpty(this.props.exclusionReasons) &&
      !this.state.excludeReasonsLoaded
    ) {
      this.setState({ excludeReasonsLoaded: true });
    }

    if (
      !_.isEmpty(this.props.drugPairErrorCodes) &&
      !this.state.errorReasonsLoaded
    ) {
      this.setState({ errorReasonsLoaded: true });
    }

    if (
      !_.isEmpty(this.props.selectedClient) &&
      !_.isEmpty(this.props.selectedMpd) &&
      !this.state.navLoaded
    ) {
      this.setState({
        navLoaded: true,
      });
      this.setupBreadCrumbs();
      this.getPage();
    }

    if (
      this.props.selectedMpd &&
      _.isEmpty(this.props.targetCategorySummaries)
    ) {
      this.props.getTargetCategorySummaries(
        this.props.auth.authToken,
        this.props.selectedMpd.catalogDate
      );
    }
  }

  setupBreadCrumbs = () => {
    const crumbParts = getNPEBreadcrumbs(this.props.selectedClient);
    crumbParts.push({
      to: "/clients/" + this.state.clientId + "/mpds/" + this.state.mpdId,
      name: this.props.selectedMpd.name,
      active: false,
    });

    this.props.populateBreadCrumbs({
      crumbs: crumbParts,
      finalCrumb:
        (this.state.isAuthorized ? "Edit " : "View ") +
        this.props.selectedMpd.name +
        " Drug Pairs",
    });
  };

  getPage() {
    this.props.getMPDTargetCosts(
      this.state.mpdId,
      this.state.searchTerms.categories,
      this.state.searchTerms.names,
      this.state.searchTerms.excludes,
      this.state.searchTerms.minMax,
      this.state.searchTerms.errors,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  }

  errorFormatter = (content, row) => {
    return (
      <ul className="list-unstyled">
        {_.map(content, (message) => {
          return (
            <li key={message.id} className="form-error">
              {message.description}
            </li>
          );
        })}
      </ul>
    );
  };

  getDrugPairExclusionReasons = () => {
    const emptyArray = [
      { value: null, label: "-Select One-" },
      { value: 0, label: "Remove Exclusion" },
    ];
    const optionsArray = this.props.exclusionGPIReasons.map((item) => {
      let option = {};
      option.value = item.id;
      option.label = item.id + " - " + item.description;
      return option;
    });

    return _.concat(emptyArray, optionsArray);
  };

  save = () => {
    this.props.excludeMPDGpis(this.state.mpdId, this.state.dtos);
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.clearLocationState();
    this.props.excludeMPDGpis(this.state.mpdId, this.state.dtos);
  };

  clearLocationState = () => {
    // If the user has cleared the Drug Category search term manually, reset the history state to reflect this
    if (_.isEmpty(this.state.searchTerms.categories)) {
      window.history.replaceState({}, document.title);
    }
  };

  toggleModal = (e, header, message) => {
    header = header ? header : "Unsaved Changes Warning";
    message = message
      ? message
      : "You have pending work that has not been saved. Do you wish to save these changes before exiting this screen?";
    this.setState({
      isOpen: !this.state.isOpen,
      modalHeader: header,
      modalContent: message,
      modalAction: this.save,
    });
  };

  onCancel = (e) => {
    if (_.isEmpty(this.state.dtos)) {
      history.goBack();
    } else {
      const drugs = new Set(this.state.dtos.map((val) => val.targetCostId));
      this.toggleModal(
        e,
        "Unsaved Changes Warning",
        "You have pending work that has not been saved. Modifications to the following drugs are unsaved: " +
          Array.from(drugs).join(", ") +
          ". Do you wish to save these changes before exiting this screen?"
      );
    }
  };

  isPublished = () => {
    return this.props.selectedMpd.catalog &&
      this.props.selectedMpd.catalog.publishedDate
      ? true
      : false;
  };

  isLocked = () => {
    return this.props.selectedMpd.catalog &&
      this.props.selectedMpd.catalog.lockedFlag !== "N"
      ? true
      : false;
  };

  buildDTOs = (rows, isSelect) => {
    const dtos = this.state.dtos;
    if (isSelect) {
      // When we select an item, setup the dto appropriately and save to state
      _.forEach(rows, (row) => {
        dtos.push({
          targetCostId: row.id,
          systemCodeId: this.state.selectedReason,
        });
      });
      this.setState({ dtos, selectDisabled: false });
    } else {
      // Otherwise, remove the selection from the state's dto array
      _.remove(dtos, (dto) => {
        const found = _.find(rows, { id: dto.targetCostId });
        return found !== undefined;
      });

      // If all selections have been removed, set the selected reason back to default
      // disable the exclude reason select box, and set the form to pristine
      const selectedReason =
        dtos.length <= 0 ? "null" : this.state.selectedReason;
      const dtosEmpty = dtos.length <= 0;
      this.setState({
        dtos,
        pristine: dtosEmpty,
        selectedReason,
        selectDisabled: dtosEmpty,
      });
    }
  };

  handleReasonChange = (e) => {
    const selectedReason = e.currentTarget.value;
    const dtos = this.state.dtos;
    _.forEach(dtos, (dto) => {
      dto.systemCodeId = selectedReason;
    });
    // Don't allow the save button to be active if the selection is Select One.
    let pristine = selectedReason === "-Select One-";
    this.setState({ dtos, selectedReason, pristine });
  };

  selectAllHandler = (isSelect, rows) => {
    this.buildDTOs(rows, isSelect);
  };

  selectHandler = (row, isSelect) => {
    this.buildDTOs([row], isSelect);
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  overrideIfCategoryExcluded = (drugPairArray) => {
    _.forEach(this.props.mpdCategoryExclusions, (catExclusion) => {
      if (
        _.some(drugPairArray, {
          categoryId: catExclusion.drugCategoryId,
        })
      ) {
        _.forEach(drugPairArray, (drugPair) => {
          if (drugPair.categoryId === catExclusion.drugCategoryId) {
            drugPair.excludedReason =
              "Cat. " + catExclusion.systemCode.description;
          }
        });
      }
    });

    return drugPairArray;
  };

  getAllTargetCostData = () => {
    let drugPairGPIArray = _.map(this.props.targetCostGpis.content, (item) => {
      const gpiCost = item.gpiCost;

      const lcaGpi = _.find(item.targetGpi.alternateCosts, (ac) => {
        return (
          ac.lcaFlag === "Y" &&
          ac.gpiCost.clientReferenceRptId === gpiCost.clientReferenceRptId
        );
      });

      const hcaNDC =
        lcaGpi && lcaGpi.pairingType.description !== PairingType.GPI_TO_GPI
          ? isHcaNDC(lcaGpi.pairingType.description)
          : false;

      const gpiDetail = item.targetGpi;
      const targetedGpiName = gpiDetail
        ? getDrugName(gpiDetail)
        : gpiCost.drugName.commonName;

      const drugPair = {
        id: item.id,
        gpiError: item.summaryStatus,
        errors: _.filter(item.messages, ["subType", "ERR"]),
        gpiCost: { gpi: item.gpiCost.gpi },
        targetedGpiName,
        assignedHcaName: hcaNDC ? gpiCost.ndcName : targetedGpiName,
        targetGpiId: gpiDetail.id,
        categoryId: gpiDetail.drugCategory.id,
        multiSource: gpiCost.multiSource,
        dacon: gpiCost.dacon,
        hcaTotalCost: gpiCost.totalCostRetail,
        hcaRebate: gpiCost.rebateTotal30Retail,
        planSavings: item.planSavings,
        tierLevel: gpiCost.tierLevel,
        excludedReason: item.gpiCostRemoval
          ? `${item.gpiCostRemoval.systemCode.id} - ${item.gpiCostRemoval.systemCode.description}`
          : "",
      };

      if (lcaGpi) {
        drugPair.lcaTotalCost = lcaGpi.gpiCost.totalCostRetail;
        drugPair.pairingType = lcaGpi.pairingType.description;
        drugPair.lcaRebate = lcaGpi.gpiCost.rebateTotal30Retail;

        const lcaGpiDetail = _.find(gpiDetail.alternateGpis, (gpi) => {
          return gpi.gpi.gpi === lcaGpi.gpiCost.gpi;
        });

        const lcaNDC =
          lcaGpi.pairingType.description !== PairingType.GPI_TO_GPI
            ? isLcaNDC(lcaGpi.pairingType.description)
            : false;

        if (lcaGpiDetail && lcaNDC) {
          drugPair.assignedLcaName = lcaGpi.gpiCost.ndcName;
        } else if (lcaGpiDetail && !lcaNDC) {
          drugPair.assignedLcaName = getDrugName(lcaGpiDetail);
        } else {
          drugPair.assignedLcaName = lcaGpi.gpiCost.drugName.commonName;
        }
      }

      return drugPair;
    });

    drugPairGPIArray = this.overrideIfCategoryExcluded(drugPairGPIArray);
    return drugPairGPIArray;
  };

  getTargetCostDataFromRow = (row) => {
    const targetCostGpi = _.find(this.props.targetCostGpis.content, (tcg) => {
      return tcg.id === row.id;
    });

    targetCostGpi.messages = this.getSortedMessages(targetCostGpi.messages);

    targetCostGpi.gpiCost.rebateNdcPrices = this.getRefNdcs(
      targetCostGpi.gpiCost.id
    );

    return targetCostGpi;
  };

  getAltCostData = (targetCostData) => {
    const alts = _.filter(
      [...targetCostData.targetGpi.alternateCosts],
      (alt) => {
        return alt.gpiCost.clientReferenceRptId === this.props.selectedMpd.id;
      }
    );
    let lca = _.remove(alts, { lcaFlag: "Y" });

    if (!_.isEmpty(lca)) {
      lca = lca[0];
      lca.messages = this.getSortedMessages(lca.messages);
      lca.gpiCost.rebateNdcPrices = this.getRefNdcs(lca.gpiCost.id);
    }

    const altsWithNames = _.map(alts, (alt) => {
      alt.messages = this.getSortedMessages(alt.messages);

      const drugName = alt.gpiCost.drugName;
      alt.gpiCost.determinedDrugName =
        drugName && drugName.commonName ? drugName.commonName : null;

      return alt;
    });

    return { lca: lca ? lca : null, alts: altsWithNames };
  };

  getRefNdcs = (gpiCostId) => {
    return _.filter(this.props.rebateReferenceNdcs, { gpiCostId: gpiCostId });
  };

  getSortedMessages = (messages) => {
    // Make sure error messages come first
    return _.sortBy(messages, ["subType"]);
  };

  expandRow = {
    renderer: (row) => {
      const targetCostData = this.getTargetCostDataFromRow(row);
      const altCostData = this.getAltCostData(targetCostData);

      return (
        <div className="d-flex justify-content-center bg-white py-3">
          <VbrGpiCostTabs
            tabOneName="Target and LCA GPI"
            tabTwoName="Alternative GPIs"
            targetGpiData={targetCostData}
            targetLCAGpiData={altCostData.lca}
            targetNonLCAGpiData={altCostData.alts}
            targetGpiRowData={row}
            includeRebates={this.props.selectedMpd.hasRebates}
          />
        </div>
      );
    },
    onlyOneExpanding: false,
    showExpandColumn: true,
    expandByColumnOnly: true,
  };

  transformSortField = (sortField) => {
    // Need to transform the sort field to work with the back end
    switch (sortField) {
      case "assignedHcaName":
        sortField = "gcn.common_name";
        break;
      case "hcaTotalCost":
        sortField = "gc.total_cost_retail";
        break;
      case "planSavings":
        sortField = "plan_savings";
        break;
      case "tierLevel":
        sortField = "gc.tier_level";
        break;
      case "lcaRebate":
        sortField = "altgc.rebate_total_30_retail";
        break;
      case "hcaRebate":
        sortField = "gc.rebate_total_30_retail";
        break;
      case "excludedReason":
        sortField = "gcr.cost_system_code_id";
        break;
      default:
        break;
    }
    return sortField;
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = this.transformSortField(sortField);
      const newSort = [{ dataField: sortField, order: sortOrder }];
      this.setState({ sortField: newSort, currPage: page, sizePerPage }, () => {
        this.getPage();
      });

      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  getSelectRow = (mpdIsLocked, disabledRowIds) => {
    return {
      mode: "checkbox",
      onSelect: this.selectHandler,
      onSelectAll: this.selectAllHandler,
      hideSelectColumn: mpdIsLocked,
      nonSelectable: disabledRowIds,
    };
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ searchTerms, currPage: 1 }, () => {
      this.getPage();
    });
  };

  getColumns = () => {
    const hasRebates = this.props.selectedMpd.hasRebates;

    const columns = [
      {
        dataField: "excludedReason",
        text: "Exclude Reason",
        sort: true,
        headerStyle: {
          width: "12%",
        },
      },
      {
        dataField: "errors",
        text: "Error Reason",
        formatter: this.errorFormatter,
      },
      {
        dataField: "pairingType",
        text: "Pairing Type",
      },
      {
        dataField: "assignedHcaName",
        sort: true,
        text: "High Cost Drug",
      },
      {
        dataField: "dacon",
        text: "DACon",
        headerTitle: (column) => {
          return `${column.text} shows the daily consumption for the drug`;
        },
      },
      {
        dataField: "multiSource",
        text: "MS",
      },
      {
        dataField: "tierLevel",
        sort: true,
        text: "Tier Level Copay",
      },
      {
        dataField: "hcaTotalCost",
        sort: true,
        text: "HCA Total Cost",
        align: "right",
        formatter: twoDecimalFormatter,
      },
      {
        dataField: "lcaTotalCost",
        text: "LCA Total Cost",
        align: "right",
        formatter: twoDecimalFormatter,
      },
      {
        dataField: "planSavings",
        sort: true,
        text: "Plan Saving",
        align: "right",
        formatter: twoDecimalFormatter,
      },
      {
        dataField: "assignedLcaName",
        text: "Low Cost Drug",
      },
    ];

    if (hasRebates) {
      const hcaRebate = {
        dataField: "hcaRebate",
        sort: true,
        text: "HCA Rebate",
        align: "right",
        formatter: twoDecimalFormatter,
      };

      const hcaInsertIndex = _.findIndex(columns, {
        dataField: "hcaTotalCost",
      });
      columns.splice(hcaInsertIndex + 1, 0, hcaRebate);

      const lcaRebate = { ...hcaRebate };
      lcaRebate.dataField = "lcaRebate";
      lcaRebate.text = "LCA Rebate";

      const lcaInsertIndex = _.findIndex(columns, {
        dataField: "lcaTotalCost",
      });
      columns.splice(lcaInsertIndex + 1, 0, lcaRebate);
    }

    return columns;
  };

  getDisabledRows = (targetCostData, mpdIsLocked) => {
    // Rows that contain errors or are children of an excluded category should be disabled
    const rowsToBeDisabled = _.filter(targetCostData, (decoratedTargetCost) => {
      return (
        decoratedTargetCost.gpiError !== "VALID" ||
        _.startsWith(decoratedTargetCost.excludedReason, "Cat.")
      );
    });

    // If the VBR has an implementation date AND that date is prior to the current date we need to disable setting exclusions DEV-1911
    const currentlyImplemented = isVBRCurrentlyImplemented(
      this.props.selectedMpd
    );

    return mpdIsLocked || currentlyImplemented
      ? _.map(targetCostData, "id")
      : _.map(rowsToBeDisabled, "id");
  };

  render() {
    if (
      _.isEmpty(this.props.exclusionGPIReasons) ||
      _.isEmpty(this.props.selectedMpd) ||
      _.isEmpty(this.props.targetCostGpis)
    ) {
      return <div>Loading...</div>;
    }

    const mpdIsLocked =
      this.props.selectedMpd.lockedFlag !== "N" || !this.state.isAuthorized;

    const targetCostData = this.getAllTargetCostData();

    const disabledRowIds = this.getDisabledRows(targetCostData, mpdIsLocked);

    const viewEditPath = `/clients/${this.state.clientId}/mpds/${this.state.mpdId}`;
    const viewEditPrepend = this.state.isAuthorized ? "Edit" : "View";

    return (
      <Container className="main-container horizontal-scrollable" fluid>
        <Row>
          <Col>
            <DrugPairsSearchForm
              onSubmit={this.onSearchSubmit}
              initialFilters={
                this.props.location.state !== undefined
                  ? this.props.location.state.filters
                  : null
              }
              initialMinMax={this.props.drugPairFilters.planSavings.initial}
            />
          </Col>
        </Row>
        <Form>
          <Row className="mt-3">
            <Col className="d-flex justify-content-between">
              {this.state.isAuthorized && (
                <FormGroup className="d-flex flex-row">
                  <Col md={4} className="p-0">
                    <Label for="excludeReason">Exclude Reason</Label>
                  </Col>
                  <Col md={10} className="p-0">
                    <Input
                      type="select"
                      id="excludeReason"
                      onChange={this.handleReasonChange}
                      value={this.state.selectedReason}
                      disabled={this.state.selectDisabled}
                    >
                      {this.getDrugPairExclusionReasons().map((reason) => {
                        return (
                          <option key={reason.value} value={reason.value}>
                            {reason.label}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </FormGroup>
              )}
              <CatalogBenefitHeader mpd={this.props.selectedMpd} />

              <div>
                <Button color="primary" tag={Link} to={viewEditPath}>
                  {`${viewEditPrepend} Priced Drug Categories`}
                </Button>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <RPSDataGrid
                keyField="id"
                paginated
                remote
                sizePerPage={this.state.sizePerPage}
                page={this.state.currPage}
                totalSize={this.props.targetCostGpis.totalElements}
                data={this.getAllTargetCostData()}
                columns={this.getColumns()}
                onSizePerPageChange={this.onSizePerPageChange}
                onTableChange={this.handleTableChange}
                expandRow={this.expandRow}
                selectRow={this.getSelectRow(mpdIsLocked, disabledRowIds)}
              />
            </Col>
          </Row>
          {this.state.isAuthorized && (
            <Row className="pt-3">
              <Col md={{ size: 2, offset: 4 }}>
                <Button
                  color="danger"
                  block
                  onClick={(e) => {
                    this.onCancel(e);
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={2}>
                <Button
                  color="primary"
                  type="submit"
                  block
                  onClick={(e) => {
                    this.onSubmit(e);
                  }}
                  disabled={this.state.pristine || mpdIsLocked}
                >
                  Save
                </Button>
              </Col>
            </Row>
          )}
        </Form>
        <ConfirmationModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalHeader}
          content={this.state.modalContent}
          action={this.state.modalAction}
          cancelText="No"
          cancelAction={() => history.goBack()}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    selectedClient: state.clients[ownProps.match.params.clientId],
    targetCategorySummaries: state.targetCategorySummaries,
    selectedMpd: state.mpds[ownProps.match.params.mpdId],
    exclusionGPIReasons: Object.values(state.exclusionGPIReasons),
    exclusionReasons: Object.values(state.exclusionReasons),
    drugPairErrorCodes: Object.values(state.drugPairErrorCodes),
    targetCostGpis: state.mpdTargetCosts,
    drugPairFilters: state.drugPairFilters,
    mpdCategoryExclusions: state.mpdCategoryExclusions,
    rebateReferenceNdcs: state.rebateReferenceNdcs,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getMPD,
  populateBreadCrumbs,
  getTargetCategorySummaries,
  getGPIExclusionReasons,
  getMPDExclusionReasons,
  excludeMPDGpis,
  getMPDTargetCosts,
  clearAllFilters,
  toggleSidebarVisibility,
  getDrugPairErrorCodes,
  getMPDCategoryExclusions,
  clearMPDCategoryExclusions,
  getRebateReferenceNdcs,
})(MPDDrugPairsManagement);
