import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { toggleSidebarVisibility } from "../../actions";
import DetailBlock from "./DetailBlock";
import _ from "lodash";

class StackDetails extends Component {
  componentDidMount = () => {
    this.props.toggleSidebarVisibility();
  };

  componentWillUnmount = () => {
    this.props.toggleSidebarVisibility();
  };

  render = () => {
    if (_.isEmpty(this.props.stackDetails)) {
      return <h1>Loading...</h1>;
    }

    return (
      <Container className="main-container" fluid>
        <Row className="mb-4">
          <h3>Version Details</h3>
        </Row>
        <Row>
          <Col>
            {_.map(this.props.stackDetails, stack => {
              return <DetailBlock stack={stack} key={stack.stack} />;
            })}
          </Col>
        </Row>
      </Container>
    );
  };
}

const mapStateToProps = state => {
  return {
    stackDetails: state.stackDetails
  };
};

export default connect(
  mapStateToProps,
  { toggleSidebarVisibility }
)(StackDetails);
