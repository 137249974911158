import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import {
  clearClients,
  getClient,
  getBenefits,
  getConfigFilesByClient,
  getMPDTieIns,
  clearMPDTieIns,
  tryAddBenefit,
  toggleSidebarVisibility
} from "../../../actions";
import { populateBreadCrumbs } from "../../../actions";
import "./Benefit.css";
import BenefitForm from "./BenefitForm";

class AddBenefit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLoaded: false,
      clientId: this.props.match.params.clientId
    };
  }

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getBenefits(this.state.clientId);
    this.props.getConfigFilesByClient(this.state.clientId);
    this.props.getMPDTieIns(this.state.clientId);
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearMPDTieIns();
    this.props.clearClients();
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false
        },
        {
          to: "/benefits/" + this.state.clientId,
          name: "Benefit List",
          active: false
        }
      ],
      finalCrumb: "Add Benefit"
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  onSubmit = formValues => {
    this.props.tryAddBenefit(formValues, this.props.selectedClient.id);
  };

  render() {
    if (!this.props.selectedClient) {
      return <div>Loading...</div>;
    }
    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h1>Add Benefit</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <BenefitForm
              onSubmit={this.onSubmit}
              selectedClientId={this.props.selectedClient.id}
              currentBenefits={this.props.benefits}
              configFiles={this.props.configFiles}
              clientId={this.props.selectedClient.id}
              clientTieIns={this.props.clientTieIns}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    benefits: Object.values(state.benefits),
    configFiles: state.clientConfigFiles,
    clientTieIns: Object.values(state.mpdTieIns)
  };
};

export default connect(
  mapStateToProps,
  {
    clearClients,
    getClient,
    getBenefits,
    getConfigFilesByClient,
    getMPDTieIns,
    clearMPDTieIns,
    tryAddBenefit,
    toggleSidebarVisibility,
    populateBreadCrumbs
  }
)(AddBenefit);
