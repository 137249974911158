import moment from "moment";
import _ from "lodash";
import { DATE_CONVERT_FORMAT } from "../forms";

export const getFileNameNoExt = (fileName) => {
  return fileName.substring(fileName.indexOf("."), 0);
};

export const getDateFormat = () => {
  return DATE_CONVERT_FORMAT;
};

export const getDateString = (
  date,
  returnNull,
  includeTime,
  utcTime,
  useShortFormat,
) => {
  const invalidReturn = returnNull ? null : "";

  if (date === undefined) {
    return invalidReturn;
  }

  let format = includeTime ? "MM/DD/YY h:mma" : getDateFormat();
  if (useShortFormat) {
    format = "MM/YYYY";
  }

  if (moment(date).isValid()) {
    if (utcTime) {
      return moment.utc(date).local().format(format);
    } else {
      return moment(date).format(format);
    }
  } else {
    return invalidReturn;
  }
};

export const getDashedGPI = (gpi) => {
  return gpi.replace(/(.{2})/g, "$1-").slice(0, -1);
};

//use when you want only the user portion of the complete email address
export const getUserName = (completeUserName) => {
  return _.split(completeUserName, "@", 1);
};

export const getDrugName = (drug) => {
  let commonName = null;
  let multiSrc = drug.multiSource ? drug.multiSource : drug.ahfsMultiSource;

  if (drug.gpi.gpiCommonNames) {
    commonName = _.find(drug.gpi.gpiCommonNames, (commonName) => {
      return commonName.key.multiSource === multiSrc;
    });
    commonName = commonName ? commonName.commonName : "NULL";
  } else if (!_.isEmpty(drug.gpiNamesByMultiSource)) {
    commonName = drug.ahfsCommonName;
  }

  const genericName = drug.gpi.gpiGenericName
    ? drug.gpi.gpiGenericName
    : drug.gpiGenericName;

  const finalName =
    multiSrc !== "Y" ? commonName + " [ " + genericName + " ]" : commonName;

  return finalName;
};

export const formatPageableParameters = (pageNumber, size, sorts) => {
  let endPoint = "";

  if (pageNumber && pageNumber > 0) {
    endPoint += `&page=${pageNumber}`;
  }
  if (size && size > 0) {
    endPoint += `&size=${size}`;
  }
  if (sorts && sorts.length > 0) {
    sorts.forEach((sort) => {
      endPoint += `&sort=${sort.dataField},${sort.order}`;
    });
  }

  return endPoint;
};

export const getRandomKeyFromString = (value) => {
  const val = value.replace(/ /g, "");
  return [...val].sort((_) => Math.random() - 0.5).join("");
};

export const getRawFloat = (value, prependToStrip = "$") => {
  return parseFloat(value.replace(prependToStrip, ""));
};

export const getDashedNDC = (ndc) => {
  return ndc.replace(/(.{5})(.{4})/, "$1-$2-");
};
