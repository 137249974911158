import React, { Component } from "react";
import { connect } from "react-redux";
import { getAlternateGpiCountsAction } from "../../../actions";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
} from "recharts";
import _ from "lodash";
import { Col, FormGroup, Input, Label } from "reactstrap";
import { DateTime } from "luxon";
import { DATE_PRESENTATION_FORMAT } from "../../../utils";

class SummaryChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: { targets: 1, self: 1, provider: 1, workbooks: 1 },
      showLine: false,
    };
  }

  componentDidMount() {
    this.props.getAlternateGpiCountsAction(
      DateTime.now().minus({ months: 3 }).toISODate(),
      "WEEKLY"
    );
  }

  tooltipFormatter = (value, key, props) => {
    const label = props.payload.catalogName;
    const valueLabel = `${value} Total Pairs`;
    return [valueLabel, label];
  };

  handleMouseEnter = (e) => {
    const { dataKey } = e;
    const currentOpacity = this.state.opacity;

    _.forIn(currentOpacity, (val, key) => {
      if (key !== dataKey) {
        currentOpacity[key] = 0.3;
      }
    });
    this.setState({ opacity: currentOpacity });
  };

  handleMouseLeave = () => {
    const currentOpacity = this.state.opacity;

    _.forIn(currentOpacity, (_, key) => {
      currentOpacity[key] = 1;
    });
    this.setState({ opacity: currentOpacity });
  };

  render() {
    if (
      !this.props.alternateGpiCounts ||
      this.props.alternateGpiCounts.length < 1
    ) {
      return <h1>Loading Graph Data...</h1>;
    }
    const transformedCounts = _.map(
      this.props.alternateGpiCounts,
      (altCount) => {
        return {
          effectiveDate: DateTime.fromISO(altCount.effectiveDate).toFormat(
            DATE_PRESENTATION_FORMAT
          ),
          pairCount: altCount.pairCount,
        };
      }
    );

    return (
      <>
        <Col className="d-flex align-items-center">
          {!this.state.showLine && (
            <BarChart width={1000} height={300} data={transformedCounts}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="effectiveDate" />
              <YAxis
                interval="preserveEnd"
                domain={["dataMin - 1000", "dataMax + 1000"]}
              />
              <Tooltip formatter={this.tooltipFormatter} />
              <Legend
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
              />
              <Bar dataKey="pairCount" fill="#ff8040" name="Total Pairs" />
            </BarChart>
          )}

          {this.state.showLine && (
            <LineChart width={1000} height={300} data={transformedCounts}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="effectiveDate" />
              <YAxis
                interval="preserveEnd"
                domain={["dataMin - 1000", "dataMax + 1000"]}
              />
              <Tooltip formatter={this.tooltipFormatter} />
              <Legend
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
              />
              <Line
                type="monotone"
                dataKey="pairCount"
                strokeOpacity={this.state.opacity.targets}
                stroke="#ff8040"
                name="Total Pairs"
              />
            </LineChart>
          )}
          <FormGroup switch className="ms-5">
            <Input
              type="switch"
              defaultChecked={this.state.showLine}
              onClick={() => {
                this.setState({ showLine: !this.state.showLine });
              }}
            />
            <Label check>Show Line Chart</Label>
          </FormGroup>
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    alternateGpiCounts: state.alternateGpis.counts,
  };
};

export default connect(mapStateToProps, {
  getAlternateGpiCountsAction,
})(SummaryChart);
