import React, { Component } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import _ from "lodash";
import { ChipAwareTextInput, MultiSelectCheckbox } from "../../../utils";

class WorkbookSearchForm extends Component {
  constructor(props) {
    super(props);

    const statusVals = [
      { id: 1, name: "Draft" },
      { id: 2, name: "Active" },
      { id: 3, name: "Approved" }
    ];

    const statuses = _.map(statusVals, status => {
      return {
        id: status.id,
        name: status.name,
        value: status.name,
        displayName: status.name,
        checked: true
      };
    });

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: { names: [], statuses: [] },
      statuses
    };
  }

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      const statuses = _.filter(
        _.map(this.state.statuses, val => {
          if (val.checked) {
            return val.value;
          }
        }),
        val => {
          return val !== undefined;
        }
      );
      this.props.onSubmit({ ...this.state.searchTerms, statuses });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onCheckboxClick = e => {
    const element = e.currentTarget;
    const items = this.state.statuses;
    items[parseInt(element.id) - 1].checked = element.checked;
    this.setState({ statuses: items, searchFormDirty: true });
  };

  onCheckSelectAll = e => {
    const items = this.state.statuses;
    _.forEach(items, item => {
      item.checked = e.currentTarget.checked;
    });
    this.setState({ statuses: items, searchFormDirty: true });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true
    });
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <ChipAwareTextInput
              id="name"
              name="names"
              placeholder="Clinical Pairing Workbook Name"
              updateCallback={this.onSearchTermUpdate}
            />
            <Col>
              <MultiSelectCheckbox
                header="Status"
                selectAllOnChange={this.onCheckSelectAll}
                items={this.state.statuses}
                itemOnChange={this.onCheckboxClick}
                showSelectedCount
              />
            </Col>
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default WorkbookSearchForm;
