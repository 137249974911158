import _ from "lodash";
import { Role } from "../../utils";
import { ActionType, SidebarIcon } from "../SideBar/SideBar";

const CLINICAL_SIDEBAR_ITEMS = [
  {
    type: ActionType.LINK_LIST,
    bundle: {
      header: "Clinical Mgmt",
      key: "clinical-mgmt",
      children: [
        {
          path: "/grids",
          name: "P&T Decision Grids",
          icon: SidebarIcon.DECISION_GRID,
        },
        {
          path: "/workbooks",
          name: "Clinical Pairings Workbooks",
          icon: SidebarIcon.WORKBOOK,
        },
        {
          path: "/targetCategories",
          name: "Clinical Catalog",
          icon: SidebarIcon.CLINICAL_CATALOG,
        },
        {
          path: "/drug-categories",
          name: "Drug Categories",
          icon: SidebarIcon.DRUG_CATEGORY,
        },
      ],
    },
  },
];

/**
 * Get Sidebar items with conditional children based on roles provided
 * @param {Role[]} roles
 * @returns {Object[]}
 */
export const getClinicalSidebarItems = (roles) => {
  const isAuthorized = _.some(roles, (role) => {
    return (
      role.name === Role.CLINICAL_APPROVER.name ||
      role.name === Role.CLINICIAN.name
    );
  });

  const sidebarChildren = CLINICAL_SIDEBAR_ITEMS[0].bundle.children;

  if (
    isAuthorized &&
    !_.some(sidebarChildren, { name: "Category Reference List" })
  ) {
    sidebarChildren.splice(
      _.findIndex(sidebarChildren, {
        path: "/targetCategories",
      }) + 1,
      0,
      {
        path: "/category-refs",
        name: "Category Reference List",
        icon: SidebarIcon.CATEGORY_REF,
      },
    );
  }

  return CLINICAL_SIDEBAR_ITEMS;
};
