import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  populateBreadCrumbs,
  clearRollIns,
  getRollIns,
  populateSidebar,
  clearSidebarActions,
  exportRollIns,
} from "../../../actions";
import {
  dateFormatter,
  getRenderDate,
  DATE_FORMAT,
  DATE_PICKER_FORMAT,
  gpiNDCFormatter,
} from "../../../utils";
import _ from "lodash";
import RollinExpandRow from "./RollinExpandRow";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { SIDEBAR_ITEMS } from "../Constants";

class RollIns extends Component {
  constructor(props) {
    super(props);
    const endDate = moment().add(14, "days").format(DATE_FORMAT);
    // startDate defaults to 30 days prior to current date
    const startDate = moment().subtract(30, "days").format(DATE_FORMAT);
    // End Date cannot be BEFORE Start Date
    const minEndDate = startDate;
    // Start Date cannot be AFTER End Date
    const maxStartDate = endDate;
    // Start Date can go 1 year prior to current date
    const minStartDate = moment("2020-08-12", DATE_FORMAT).format(DATE_FORMAT);

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: [{}],
      endDate,
      minEndDate,
      startDate,
      maxStartDate,
      minStartDate,
    };
  }

  componentDidMount = () => {
    this.getPage();
    this.setupBreadCrumbs();
    this.setupSidebar();
  };

  componentWillUnmount = () => {
    this.props.clearSidebarActions();
    this.props.clearRollIns();
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };

  setupBreadCrumbs = () => {
    const crumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/reporting", name: "Reporting", active: false },
      ],
      finalCrumb: "Medispan Roll-ins",
    };
    this.props.populateBreadCrumbs(crumbs);
  };

  COLUMNS = [
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "message",
      text: "Change Message",
      sort: true,
    },
    {
      dataField: "category",
      text: "AHFS Class",
      sort: true,
    },
    {
      dataField: "gpi",
      text: "GPI Code",
      sort: true,
    },
    {
      dataField: "ndc",
      text: "NDC Code",
      sort: true,
      formatter: gpiNDCFormatter,
      formatExtraData: "ndc",
    },
    {
      dataField: "drugName",
      text: "Drug Name",
      sort: true,
    },
    {
      dataField: "multiSource",
      text: "MS",
      sort: true,
    },
    {
      dataField: "prescribable",
      text: "Prescribable",
      sort: true,
    },
    {
      dataField: "changeDate",
      text: "Medispan Change Dt",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "routeOfAdministrationDescription",
      text: "Route of Admin",
    },
  ];

  getPage() {
    this.props.getRollIns(
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField,
      this.state.startDate,
      this.state.endDate,
    );
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      const newSort = [{ dataField: sortField, order: sortOrder }];
      this.setState({ sortField: newSort }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  expandRow = {
    renderer: (row) => (
      <RollinExpandRow
        mpds={row.mpds}
        rollinStats={row.rollInNdcMfgStatistic}
        drugCats={row.drugCategories}
      />
    ),
    onlyOneExpanding: false,
    showExpandColumn: true,
    expandByColumnOnly: true,
  };

  onDateChange = (date, name) => {
    let newDate = moment(date).format(DATE_FORMAT);
    if (name === "startDate") {
      // Whenever startDate changes, set minEndDate to match. This prevents date overlap.
      if (newDate === "Invalid date") {
        newDate = this.state.startDate;
      }
      this.setState(
        { startDate: newDate, minEndDate: newDate, currPage: 1 },
        () => {
          this.getPage();
        },
      );
    } else {
      // Whenever endDate changes, setMaxStartDate to match. This prevents date overlap.
      if (newDate === "Invalid date") {
        newDate = this.state.endDate;
      }
      this.setState(
        { endDate: newDate, maxStartDate: newDate, currPage: 1 },
        () => {
          this.getPage();
        },
      );
    }
  };

  render = () => {
    if (_.isEmpty(this.props.rollIns)) {
      return <div>Loading...</div>;
    }
    return (
      <Container className="main-container horizontal-scrollable" fluid>
        <Row>
          <Col>
            <h3>Medispan Roll-ins</h3>
          </Col>
        </Row>
        <Row className="mb-3 mt-2">
          <Col md={1}>
            <span className="font-weight-bold">Start Date</span>
            <DatePicker
              dateFormat={DATE_PICKER_FORMAT}
              selected={getRenderDate(this.state.startDate)}
              onChange={(date) => this.onDateChange(date, "startDate")}
              maxDate={getRenderDate(this.state.maxStartDate)}
              minDate={getRenderDate(this.state.minStartDate)}
              strictParsing
              className="form-control"
            />
          </Col>
          <Col md={1}>
            <span className="font-weight-bold">End Date</span>
            <DatePicker
              dateFormat={DATE_PICKER_FORMAT}
              selected={getRenderDate(this.state.endDate)}
              onChange={(date) => this.onDateChange(date, "endDate")}
              minDate={getRenderDate(this.state.minEndDate)}
              strictParsing
              className="form-control"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <RPSDataGrid
              keyField="id"
              paginated
              exportable
              remote
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.rollIns.totalElements}
              data={this.props.rollIns.content}
              columns={this.COLUMNS}
              expandRow={this.expandRow}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              exportDisabled={
                !this.props.rollIns.content ||
                this.props.rollIns.content.length <= 0
              }
              exportAction={() =>
                this.props.exportRollIns(
                  this.state.startDate,
                  this.state.endDate,
                )
              }
            />
          </Col>
        </Row>
      </Container>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    rollIns: state.rollIns,
  };
};

export default connect(mapStateToProps, {
  getRollIns,
  clearRollIns,
  populateBreadCrumbs,
  populateSidebar,
  clearSidebarActions,
  exportRollIns,
})(RollIns);
