import { GET_CATALOGS } from "../actions/types";

const catalogsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CATALOGS:
      return action.payload;
    default:
      return state;
  }
};

export default catalogsReducer;
