import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import _ from "lodash";
import { STACK_TYPE } from "../../actions";

class Footer extends Component {
  getStackVersion = stackType => {
    const naMessage = "Not Available";
    if (!_.isEmpty(this.props.stackDetails)) {
      const detail = _.find(this.props.stackDetails, { stack: stackType });
      return detail ? detail.version : naMessage;
    }
    return naMessage;
  };

  render() {
    return (
      <footer className="footer py-3">
        <div className="text-muted d-flex justify-content-center align-items-center vw-100">
          <div className="vw-100 d-flex flex-row-reverse">
            <span className="">
              Copyright &copy; {new Date().getFullYear()} ActiveRADAR. All
              Rights Reserved
            </span>
          </div>
          <div className="d-flex vw-100 flex-row-reverse pe-5">
            {this.props.currentUser && (
              <ol className="list-inline mb-0">
                <li className="list-inline-item">
                  Front End:&nbsp;
                  <Link to="/stack-details">
                    {this.getStackVersion(STACK_TYPE.frontend.stack)}
                  </Link>
                </li>
                <li className="list-inline-item">
                  Back End:&nbsp;
                  <Link to="/stack-details">
                    {this.getStackVersion(STACK_TYPE.backend.stack)}
                  </Link>
                </li>
                <li className="list-inline-item">
                  Batch:&nbsp;
                  <Link to="/stack-details">
                    {this.getStackVersion(STACK_TYPE.batch.stack)}
                  </Link>
                </li>
                <li className="list-inline-item">
                  Async:&nbsp;
                  <Link to="/stack-details">
                    {this.getStackVersion(STACK_TYPE.async.stack)}
                  </Link>
                </li>
              </ol>
            )}
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
