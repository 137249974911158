import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Container,
  Row,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  clearClients,
  getClient,
  getBenefit,
  toggleSidebarVisibility,
  populateBreadCrumbs,
} from "../../../actions";
import { getDateString } from "./../../../utils";
import { ConfigFile } from "../ConfigFiles/fileTypes";

class BenefitView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      benefitId: this.props.match.params.benefitId,
    };
  }

  componentDidMount = () => {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getBenefit(this.state.benefitId);
  };

  componentDidUpdate = () => {
    if (
      this.props.selectedClient &&
      this.props.selectedBenefit &&
      !this.state.navLoaded
    ) {
      this.setState({ navLoaded: true });
      this.setupBreadcrumbs();
    }
  };

  componentWillUnmount = () => {
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  };

  setupBreadcrumbs = () => {
    const breadcrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
        {
          to: "/benefits/" + this.state.clientId,
          name: "Benefit List",
          active: false,
        },
      ],
      finalCrumb: this.props.selectedBenefit.benefitName,
    };
    this.props.populateBreadCrumbs(breadcrumbs);
  };

  getViewTarget = (file) => {
    let viewTarget = "/client-config/";
    switch (file.type) {
      case ConfigFile.MAC:
        viewTarget += "mac/";
        break;
      case ConfigFile.FORMULARY:
        viewTarget += "formulary/";
        break;
      case ConfigFile.AWPD:
        viewTarget += "awpd/";
        break;
      case ConfigFile.BC:
        viewTarget += "beneficiary/";
        break;
      default:
        break;
    }
    return (viewTarget += this.state.clientId + "/" + file.id);
  };

  render = () => {
    if (!this.props.selectedBenefit || !this.props.selectedClient) {
      return <div>Loading...</div>;
    }
    const benefit = this.props.selectedBenefit;

    return (
      <Container className="main-container" fluid>
        <Row className="mt-2">
          <Col>
            <h3>{benefit.benefitName}</h3>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <ListGroup>
              <ListGroupItem>
                <ListGroupItemHeading>
                  RPS LCTAF tie-in to CAG &amp; G/P file
                </ListGroupItemHeading>
                <ListGroupItemText>
                  {benefit.clientTieIn ? benefit.clientTieIn.name : ""}
                </ListGroupItemText>
              </ListGroupItem>
              <ListGroupItem>
                <ListGroupItemHeading>Effective Date</ListGroupItemHeading>
                <ListGroupItemText>
                  {getDateString(benefit.effectiveDate)}
                </ListGroupItemText>
              </ListGroupItem>
              <ListGroupItem>
                <ListGroupItemHeading>Term Date</ListGroupItemHeading>
                <ListGroupItemText>
                  {getDateString(benefit.termDate, true)
                    ? getDateString(benefit.termDate, true)
                    : "Term Date not set"}
                </ListGroupItemText>
              </ListGroupItem>
              {benefit.configFiles.map((file) => {
                return (
                  <ListGroupItem key={file.id}>
                    <ListGroupItemHeading>
                      {file.type} File
                    </ListGroupItemHeading>
                    <ListGroupItemText>
                      <Button
                        className="listGroupBtn"
                        color="link"
                        tag={Link}
                        to={this.getViewTarget(file)}
                      >
                        {file.fileName}
                      </Button>
                    </ListGroupItemText>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    selectedBenefit: state.benefits[ownProps.match.params.benefitId],
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getBenefit,
  toggleSidebarVisibility,
  populateBreadCrumbs,
})(BenefitView);
