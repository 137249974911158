import qs from "qs";
import { loginRequest, getAuthRequest } from "../../api/clinicalBackend";
import { SIGN_IN, SIGN_OUT, SHOW_AUTH_ERROR } from "../types";
import history from "../../history";
import { handleError } from "../../utils/error/errorHandlers";
import Timer from "./../../utils/auth/timer";

export const trySignIn = (formValues) => async (dispatch) => {
  try {
    const data = {
      ...formValues,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
    };

    const response = await loginRequest.post(
      "/oauth/token",
      qs.stringify(data)
    );

    const userDetails = await getAuthRequest(response.data.access_token).get(
      "/api/users/me"
    );

    dispatch({
      type: SIGN_IN,
      payload: {
        authToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiration: response.data.expires_in,
        currentUser: userDetails.data,
      },
    });
    history.push("/dashboard");
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryRefreshToken =
  (shouldRefresh) => async (dispatch, getState) => {
    try {
      const refreshToken = getState().auth.refreshToken;

      const response = await loginRequest.get("/oauth/token", {
        params: { grant_type: "refresh_token", refresh_token: refreshToken },
      });

      const userDetails = await getAuthRequest(response.data.access_token).get(
        "/api/users/me"
      );

      dispatch({
        type: SIGN_IN,
        payload: {
          authToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          expiration: response.data.expires_in,
          currentUser: userDetails.data,
        },
      });
      if (shouldRefresh) {
        history.go(0);
      }
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const trySignOut = (fromTimeout) => (dispatch) => {
  const timer = Timer.getInstance();
  timer.clearTimer();

  dispatch({
    type: SIGN_OUT,
    payload: fromTimeout !== undefined && fromTimeout === true ? true : false,
  });
  history.push("/");
};

export const showAuthError = (show) => (dispatch, getState) => {
  dispatch({ type: SHOW_AUTH_ERROR, payload: show });
};
