import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import {
  GET_CONFIG_FILES,
  GET_CONFIG_FILES_SORTED,
  GET_AWPD_FILE_CONTENT,
  GET_CONFIG_FILE,
  DELETE_CONFIG_FILE,
  CLEAR_FILE_CONTENT,
  GET_MAC_FILE_CONTENT,
  GET_FORM_FILE_CONTENT,
  GET_BC_FILE_CONTENT,
  SET_SEARCH_TERM,
  GET_GP_FILE_CONTENT,
  GET_CLIENT_GROUPS,
  CLEAR_CLIENT_GROUPS,
  GENERATE_FORMULARY_CONTENT,
  UPLOAD_CONFIG_FILE,
  CLEAR_CONFIG_FILES,
} from "../types";
import { handleError } from "../../utils/error/errorHandlers";
import { ADD_SORT_ORDER } from "./../types";
import { formatPageableParameters } from "../../utils/string/stringUtils";

export const getConfigFilesByClient =
  (clientId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const files = await getClinicalBackend(authToken).get(
        "/api/correlations/?clientId=" + clientId
      );

      dispatch({
        type: GET_CONFIG_FILES,
        payload: files.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getConfigFiles =
  (sorts, pageNumber = 0, size = 20) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      let endPoint = "/api/correlations/?";

      endPoint += formatPageableParameters(pageNumber, size, sorts);

      const files = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_CONFIG_FILES_SORTED,
        payload: files.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getConfigFile = (correlationId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const file = await getClinicalBackend(authToken).get(
      "/api/correlations/" + correlationId
    );

    dispatch({
      type: GET_CONFIG_FILE,
      payload: file.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryDeleteConfigFile = (fileId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    await getClinicalBackend(authToken).delete("/api/correlations/" + fileId);
    dispatch({ type: DELETE_CONFIG_FILE, payload: fileId });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearConfigFiles = () => (dispatch) => {
  dispatch({ type: CLEAR_CONFIG_FILES });
};

export const getAWDPFileContent =
  (correlationId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const content = await getClinicalBackend(authToken).get(
        "/api/awp-discounts/?correlationId=" + correlationId
      );

      dispatch({
        type: GET_AWPD_FILE_CONTENT,
        payload: content.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getMACFileContent =
  (correlationId, pageNumber, size, sorts, searchTerm) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endPoint = "/api/plan-macs/?correlationId=" + correlationId;
      if (pageNumber && pageNumber > 0) {
        endPoint += "&page=" + pageNumber;
      }
      if (size && size > 0) {
        endPoint += "&size=" + size;
      }
      if (sorts && sorts.length > 0) {
        sorts.forEach((sort) => {
          endPoint += "&sort=" + sort.dataField + "," + sort.order;
        });
      }
      if (searchTerm) {
        endPoint += "&searchTerm=" + searchTerm;
      }
      const content = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_MAC_FILE_CONTENT,
        payload: content.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getGPFileContent =
  (correlationId, pageNumber, size) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endPoint = "/api/client-groups/?correlationId=" + correlationId;
      if (pageNumber && pageNumber > 0) {
        endPoint += "&page=" + pageNumber;
      }
      if (size && size > 0) {
        endPoint += "&size=" + size;
      }
      const content = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_GP_FILE_CONTENT,
        payload: content.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getClientGroups = (clientId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = "/api/client-groups/?allPages=true&clientId=" + clientId;
    const content = await getClinicalBackend(authToken).get(endPoint);

    dispatch({
      type: GET_CLIENT_GROUPS,
      payload: content.data.content,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearClientGroups = (clientId) => (dispatch) => {
  dispatch({ type: CLEAR_CLIENT_GROUPS });
};

export const addGPClientTieIn =
  (values, pageNumber, size) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endPoint = "/api/client-groups/";

      if (pageNumber && pageNumber > 0) {
        endPoint += "?page=" + pageNumber;
      }
      if (size && size > 0) {
        const prefix = !pageNumber || pageNumber <= 0 ? "?" : "&";
        endPoint += prefix + "size=" + size;
      }
      const content = await getClinicalBackend(authToken).put(
        endPoint,
        JSON.stringify(values)
      );

      dispatch({
        type: GET_GP_FILE_CONTENT,
        payload: content.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const removeGPClientTieIn =
  (values, pageNumber, size) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endPoint = "/api/client-groups/remove-tie-in";

      if (pageNumber && pageNumber > 0) {
        endPoint += "?page=" + pageNumber;
      }
      if (size && size > 0) {
        const prefix = !pageNumber || pageNumber <= 0 ? "?" : "&";
        endPoint += prefix + "size=" + size;
      }
      const content = await getClinicalBackend(authToken).put(
        endPoint,
        JSON.stringify(values)
      );

      dispatch({
        type: GET_GP_FILE_CONTENT,
        payload: content.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

  export const getFormFileContent =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/plan-formularies/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null
      )}`;
      const response = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms)
      );

      dispatch({
        type: GET_FORM_FILE_CONTENT,
        payload: response.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getBCFileContent =
  (correlationId, pageNumber, size) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endPoint = "/api/beneficiary-costs/?correlationId=" + correlationId;
      if (pageNumber && pageNumber > 0) {
        endPoint += "&page=" + pageNumber;
      }
      if (size && size > 0) {
        endPoint += "&size=" + size;
      }
      const content = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_BC_FILE_CONTENT,
        payload: content.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

// We don't want to keep tons of rows of data in memory, so call this when a file view component is going to unmount
export const clearFileContent = (fileTypes) => (dispatch) => {
  dispatch({ type: CLEAR_FILE_CONTENT, payload: fileTypes });
};

export const addSortOrder = (fileType, sorts) => (dispatch) => {
  dispatch({
    type: ADD_SORT_ORDER,
    payload: { fileType: fileType, newSorts: sorts },
  });
};

export const setSearchTerm = (term) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_TERM,
    payload: { term },
  });
};

export const generateFormularyContent =
  (clientId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endPoint = "/api/correlations/plan-formularies?clientId=" + clientId;
      const content = await getClinicalBackend(authToken).post(endPoint);

      dispatch({
        type: GENERATE_FORMULARY_CONTENT,
        payload: content.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const uploadConfigFile = (formData) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = `api/correlations/uploads`;

    const uploadedConfigFile = await getClinicalBackend(authToken).post(
      endPoint,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    dispatch({
      type: UPLOAD_CONFIG_FILE,
      payload: uploadedConfigFile.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};
