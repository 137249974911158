import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

export const Chip = (props) => {
  const remove = () => {
    props.removeCallback(props.value);
  };

  return (
    <div className="pe-3 d-inline-block">
      <div className="d-flex align-items-center">
        <Button className="chip-btn" color="link" onClick={remove}>
          X
        </Button>
        {props.value}
      </div>
    </div>
  );
};

Chip.propTypes = {
  value: PropTypes.string.isRequired,
  removeCallback: PropTypes.func.isRequired,
};
