import { getClinicalBackend } from "../../api/clinicalBackend";
import history from "../../history";
import {
  formatPageableParameters,
  handleError,
  EXCEL_DOWNLOAD_OPTIONS,
  downloadExcelFile,
} from "../../utils";
import { tryRefreshToken } from "../auth/authAction";
import {
  CLEAR_PROSPECTIVE_REPORTS,
  GET_PROSPECTIVE_REPORTS,
  PROSPECTIVE_REPORTS_LOADED,
} from "../types";

export const searchProspectiveReports =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/prospective-savings-reports/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null
      )}`;

      const reports = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms)
      );
      dispatch({ type: GET_PROSPECTIVE_REPORTS, payload: reports.data });
      dispatch({ type: PROSPECTIVE_REPORTS_LOADED });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearProspectiveReports = () => (dispatch) => {
  dispatch({ type: CLEAR_PROSPECTIVE_REPORTS });
};

export const requestProspectiveReport =
  (formValues) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = "/api/prospective-savings-reports";
      await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(formValues)
      );
      history.push("/reporting/prospective");
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const deleteProspectiveReport =
  (reportId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      await getClinicalBackend(authToken).delete(
        "/api/prospective-savings-reports/" + reportId
      );
      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryExportProspectiveSavingsReport =
  (id) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endpoint = `/api/prospective-savings-reports/export/${id}`;

      const report = await getClinicalBackend(authToken).get(
        endpoint,
        EXCEL_DOWNLOAD_OPTIONS
      );
      downloadExcelFile(report);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
