import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import {
  clearClients,
  getClient,
  addSortOrder,
  getMACFileContent,
  getConfigFile,
  clearFileContent,
  setSearchTerm,
  populateBreadCrumbs,
  toggleSidebarVisibility
} from "../../../actions";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { ConfigFile } from "./fileTypes";
import { sortHandler, getFileNameNoExt } from "../../../utils";

class MACView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      navLoaded: false,
      clientId: this.props.match.params.clientId
    };
    this.gpiStatusToName = {
      ACTIVE: "valid",
      INACTIVE: "inactive",
      NOTFOUND: "not found",
      DUPLICATE: "duplicate"
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false
        },
        {
          to: "/client-config/dashboard/" + this.state.clientId,
          name: "Configuration Files",
          active: false
        }
      ],
      finalCrumb: this.props.selectedFile.fileName
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.getClient(this.state.clientId);
    this.props.getConfigFile(this.props.match.params.correlationId);
    this.getPage(
      0,
      this.state.sizePerPage,
      this.props.sorts,
      this.props.searchTerm
    );
    this.props.toggleSidebarVisibility();
  }

  componentDidUpdate() {
    if (this.props.selectedClient && this.props.selectedFile) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.setSearchTerm("");
    this.props.clearFileContent([ConfigFile.MAC]);
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  }

  getPage(page, sizePerPage, sorts, searchText) {
    this.props.getMACFileContent(
      this.props.match.params.correlationId,
      page - 1,
      sizePerPage,
      sorts,
      searchText
    );
  }

  statusFormatter = (content, row) => {
    let msg = this.gpiStatusToName[content];
    return msg ? msg : content;
  };

  getFileContents = () => {
    let contents = this.props.fileContents.content;
    return contents;
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      const newSort = sortHandler(this.props.sorts, [
        { dataField: sortField, order: sortOrder }
      ]);
      this.props.addSortOrder(ConfigFile.MAC, newSort);
      this.props.clearFileContent([ConfigFile.MAC]);
      this.getPage(page, sizePerPage, newSort, this.props.searchTerm);
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage });
    this.props.clearFileContent([ConfigFile.MAC]);
    this.getPage(page, sizePerPage, this.props.sorts, this.props.searchTerm);
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage, this.props.sorts, this.props.searchTerm);
  };

  COLUMNS = [
    {
      dataField: "gpi",
      sort: false,
      text: "GPI",
      headerStyle: {
        width: "7%"
      }
    },
    {
      dataField: "multiSource",
      text: "MultiSource",
      headerStyle: {
        width: "1%"
      }
    },
    {
      dataField: "drugName",
      sort: false,
      text: "Drug Name"
    },
    {
      dataField: "gpiStatus",
      sort: true,
      text: "GPI Status",
      formatter: this.statusFormatter,
      headerStyle: {
        width: "7%"
      }
    },
    {
      dataField: "macUnitPrice30days",
      sort: false,
      text: "Unit Price 30days",
      headerStyle: {
        width: "7%"
      }
    },
    {
      dataField: "macUnitPrice90days",
      sort: false,
      text: "Unit Price 90days",
      headerStyle: {
        width: "7%"
      }
    },
    {
      dataField: "macUnitPrice90daysRetail",
      sort: false,
      text: "Unit Price 90days retail",
      headerStyle: {
        width: "7%"
      }
    }
  ];

  doSearch = searchTerm => {
    this.props.clearFileContent([ConfigFile.MAC]);
    this.props.setSearchTerm(searchTerm);
    this.setState({ currPage: 1 });
    this.getPage(1, this.state.sizePerPage, this.props.sorts, searchTerm);
  };

  clearSearch = () => {
    this.props.clearFileContent([ConfigFile.MAC]);
    this.props.setSearchTerm("");
    this.setState({ currPage: 1 });
    this.getPage(
      this.state.currPage,
      this.state.sizePerPage,
      this.props.sorts,
      ""
    );
  };

  render() {
    if (
      !this.props.selectedClient ||
      !this.props.fileContents.content ||
      !this.props.selectedFile
    ) {
      return <div>Loading...</div>;
    }

    const fileName = getFileNameNoExt(this.props.selectedFile.fileName);

    return (
      <Container className="main-container" fluid>
        <Row className="mb-4">
          <Col>
            <h3>{fileName}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RPSDataGrid
              keyField="id"
              paginated
              searchable
              remote
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.fileContents.totalElements}
              data={this.getFileContents()}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              searchTerm={this.props.searchTerm}
              submitFunc={this.doSearch}
              clearFunc={this.clearSearch}
              onTableChange={this.handleTableChange}
              searchInputPlaceholder="Partial Search by GPI or Brand or Generic Name"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    configFiles: state.clientConfigFiles,
    fileContents: state.macFileContent,
    selectedFile: state.clientConfigFiles[ownProps.match.params.correlationId],
    sorts: state.dataGridSorts[ConfigFile.MAC],
    searchTerm: state.dataGridSearchTerm.term
  };
};

export default connect(
  mapStateToProps,
  {
    clearClients,
    addSortOrder,
    getClient,
    getMACFileContent,
    getConfigFile,
    clearFileContent,
    setSearchTerm,
    populateBreadCrumbs,
    toggleSidebarVisibility
  }
)(MACView);
