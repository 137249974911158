import { getClinicalBackend } from "../../api/clinicalBackend";
import { REPORT_TYPES } from "../../components/Reporting/Constants";
import history from "../../history";
import {
  downloadExcelFile,
  EXCEL_DOWNLOAD_OPTIONS,
  formatPageableParameters,
  handleError,
} from "../../utils";
import { tryRefreshToken } from "../auth/authAction";
import { CLEAR_REPORTS, GET_CAGCENTRIC_COUNTS, GET_REPORTS } from "../types";

export const getReports =
  (clientId, reportType, pageNumber, size, sort) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endpointSuffix = `?page=${pageNumber}&size=${size}`;
      if (sort) {
        endpointSuffix += `&sort=${sort.dataField},${sort.order}`;
      }

      if (clientId) {
        endpointSuffix += `&clientId=${clientId}`;
      }

      let endpoint = "/api/";
      switch (reportType) {
        case REPORT_TYPES.RP_PERFORMANCE:
          endpoint += "rp-perf-reports";
          break;
        case REPORT_TYPES.MEMBERSHIP:
          endpoint += "member-summary-reports";
          break;
        default:
          break;
      }

      endpoint += endpointSuffix;

      const reports = await getClinicalBackend(authToken).get(endpoint);
      dispatch({ type: GET_REPORTS, payload: reports.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const searchReports =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/rp-perf-reports/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null
      )}`;

      const reports = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms)
      );
      dispatch({ type: GET_REPORTS, payload: reports.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getCagcentricCounts =
  (reportId, pageNumber, size) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const enpoint = `/api/member-reports?reportId=${reportId}&page=${pageNumber}&size=${size}`;
      const counts = await getClinicalBackend(authToken).get(enpoint);

      dispatch({ type: GET_CAGCENTRIC_COUNTS, payload: counts.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearReports = () => (dispatch) => {
  dispatch({ type: CLEAR_REPORTS });
};

export const tryExportPerformaceReport =
  (id, userRequested = false) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endpoint = `/api/rp-perf-reports/export/${id}`;
      if (userRequested) {
        endpoint += "?userRequested=true";
      }

      const report = await getClinicalBackend(authToken).get(
        endpoint,
        EXCEL_DOWNLOAD_OPTIONS
      );
      downloadExcelFile(report);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const requestPerformanceReport =
  (formValues) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = "/api/rp-perf-reports/request";
      await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(formValues)
      );
      history.push("/reporting/performance");
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const deletePerformanceReport =
  (reportId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      await getClinicalBackend(authToken).delete(
        "/api/rp-perf-reports/" + reportId
      );
      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
