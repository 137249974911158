import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import { Field, FieldArray, reduxForm } from "redux-form";
import _ from "lodash";
import NoteHeader from "./NoteHeader";
import { renderTextArea } from "../../utils";
import { FaTrashAlt } from "react-icons/fa";

class NoteForm extends Component {
  constructor(props) {
    super(props);

    const initialValues = this.props.initialValues
      ? _.orderBy(this.props.initialValues.notes, ["createdDate"], ["desc"])
      : [];

    this.state = {
      notes: initialValues,
      newNoteIndex: 0
    };
  }

  addNote = () => {
    const newNoteIndex = this.state.newNoteIndex + 1;
    // The ID here needs to be unique so the note can be found in onChange method
    const empty = {
      id: `note${newNoteIndex}`,
      body: ""
    };
    const notes = _.cloneDeep(this.state.notes);
    notes.unshift(empty);
    this.setState({ newNoteIndex, notes });
  };

  shouldBeDisabled = (note, currentUserId) => {
    if (!note.hasOwnProperty("author")) {
      return false;
    }
    return note.author.id !== currentUserId;
  };

  renderNotes = ({ notes, currentUser, onChange }) => {
    return (
      <Row>
        <Col>
          {_.map(notes, (note, index) => {
            const disabled = this.shouldBeDisabled(note, currentUser.id);
            return (
              <FormGroup key={index}>
                <Row className="mb-2">
                  <Col md={3}>
                    <h5>
                      <NoteHeader note={note} currentUser={currentUser} />
                    </h5>
                    {!disabled && note.hasOwnProperty("author") && (
                      <Button
                        className="linkBtn tableBtn actionIconBtnDanger noteDeleteBtn"
                        color="link"
                        onClick={() => this.onNoteDelete(note.id)}
                      >
                        <FaTrashAlt />
                      </Button>
                    )}
                  </Col>
                </Row>
                <Field
                  name={`notes[${index}]`}
                  id={`notes[${index}]`}
                  elementid={note.id}
                  type="text"
                  inputobj={note}
                  onChange={onChange}
                  label="Enter your notes here"
                  numRows={4}
                  disabled={disabled}
                  component={renderTextArea}
                />
              </FormGroup>
            );
          })}
        </Col>
      </Row>
    );
  };

  onNoteChange = e => {
    const target = e.currentTarget;
    const idAsInt = parseInt(target.getAttribute("data-elementid"));
    const noteId = !isNaN(idAsInt)
      ? idAsInt
      : target.getAttribute("data-elementid");
    const currentNote = _.find(this.state.notes, { id: noteId });
    currentNote.body = target.value;
    currentNote.new = true;
    if (currentNote.hasOwnProperty("author")) {
      currentNote.edited = true;
      currentNote.new = false;
    }
  };

  onNoteDelete = noteId => {
    this.props.onNoteDelete(noteId);
  };

  onSubmit = () => {
    // Filter out any notes that do not have a body as we cannot save bodyless notes.
    const notesToSubmit = _.map(
      _.filter(this.state.notes, note => {
        return (
          !_.isEmpty(note.body) && (note.edited === true || note.new === true)
        );
      }),
      note => {
        return {
          id: !isNaN(parseInt(note.id)) ? parseInt(note.id) : 0,
          body: note.body,
          edited: note.edited,
          new: note.new
        };
      }
    );

    this.props.onSubmit({ notes: notesToSubmit });
  };

  render = () => {
    const { handleSubmit, pristine, invalid, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.onSubmit)}>
        <Row form className="mb-4">
          <Col md={8}>
            <h3>Notes</h3>
          </Col>
          <Col md={2}>
            <Button onClick={this.addNote} block>
              Add Note
            </Button>
          </Col>
          <Col md={2}>
            <Button
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Submit
            </Button>
          </Col>
        </Row>
        <div className="data-grid-scroll-container note-container">
          <FieldArray
            name="notes"
            notes={this.state.notes}
            currentUser={this.props.currentUser}
            onChange={this.onNoteChange}
            component={this.renderNotes}
          />
        </div>
      </Form>
    );
  };
}

export default reduxForm({ form: "notesForm" })(NoteForm);
