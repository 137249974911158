import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Button, Col, Row } from "reactstrap";
import DataGridSearch from "../ClientManagement/ConfigFiles/DataGridSearch";
import PropTypes from "prop-types";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const RPSDataGrid = (props) => {
  return props.paginated ? renderPaginatedTable(props) : renderTable(props);
};

const getPaginator = (props) => {
  const options = {
    custom: true,
    sizePerPage: props.sizePerPage,
    page: props.page,
    showTotal: true,
    totalSize: props.totalSize,
    sizePerPageList: [15, 25, 35, 45, 55],
    onSizePerPageChange: (sizePerPage, page) => {
      props.onSizePerPageChange(sizePerPage, page);
    },
  };
  return paginationFactory(options);
};

const renderTable = (props, paginationTableProps) => {
  return (
    <BootstrapTable
      keyField={props.keyField}
      data={props.data}
      columns={props.columns}
      id={props.id}
      bootstrap4
      remote={props.remote}
      hover
      striped
      condensed
      cellEdit={props.cellEditFactory}
      expandRow={props.expandRow}
      selectRow={props.selectRow}
      onTableChange={props.onTableChange}
      wrapperClasses={props.wrapperClasses}
      {...paginationTableProps}
    />
  );
};

const renderPaginatedTable = (props) => {
  return (
    <PaginationProvider pagination={getPaginator(props)}>
      {({ paginationProps, paginationTableProps }) => (
        <div>
          {props.searchable && (
            <DataGridSearch
              searchTerm={props.searchTerm}
              placeholder={props.searchInputPlaceholder}
              submitFunc={props.submitFunc}
              clearFunc={props.clearFunc}
            />
          )}

          {renderTable(props, paginationTableProps)}
          <Row className="d-flex justify-space-between">
            <Col>
              <SizePerPageDropdownStandalone {...paginationProps} />
              <PaginationTotalStandalone {...paginationProps} />
            </Col>
            <Col>
              <PaginationListStandalone {...paginationProps} />
            </Col>
            {props.exportable && (
              <Col className="d-flex flex-row-reverse">
                <Button
                  className="float-right"
                  color="primary"
                  disabled={props.exportDisabled}
                  onClick={props.exportAction}
                >
                  Export
                </Button>
              </Col>
            )}
          </Row>
        </div>
      )}
    </PaginationProvider>
  );
};

RPSDataGrid.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  keyField: PropTypes.string.isRequired,
  id: PropTypes.string,
  sizePerPage: PropTypes.number,
  page: PropTypes.number,
  totalSize: PropTypes.number,
  onSizePerPageChange: PropTypes.func,
  searchTerm: PropTypes.string,
  submitFunc: PropTypes.func,
  clearFunc: PropTypes.func,
  onTableChange: PropTypes.func,
  searchInputPlaceholder: PropTypes.string,
  searchable: PropTypes.bool,
  paginated: PropTypes.bool,
  exportable: PropTypes.bool,
  exportDisabled: PropTypes.bool,
  remote: PropTypes.any,
  exportAction: PropTypes.func,
  cellEditFactory: PropTypes.object,
  selectRow: PropTypes.object,
  expandRow: PropTypes.object,
  wrapperClasses: PropTypes.string,
};

export default RPSDataGrid;
