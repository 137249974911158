import _ from "lodash";

export const SESSION_VALUE_KEYS = {
  TGT_EFFDT: "tgt-effdt",
};

export const getSessionValuesOrDefault = (sessionKey, defaultValue = {}) => {
  const sessionValues = localStorage.getItem(sessionKey);
  if (!sessionValues) {
    return defaultValue;
  }
  return JSON.parse(sessionValues);
};

export const setSessionValue = (sessionKey, key, value) => {
  let sessionValues = getSessionValuesOrDefault(sessionKey);
  if (!sessionValues) {
    // first time creating values for this session
    sessionValues = {
      [key]: value,
    };
  } else {
    // already have values for this session, just add this one
    if (!_.includes(sessionValues[key], value)) {
      sessionValues[key] = value;
    }
  }
  localStorage.setItem(sessionKey, JSON.stringify(sessionValues));
};

export const removeSessionValue = (sessionKey, key, value) => {
  const sessionValues = getSessionValuesOrDefault(sessionKey);
  if (sessionValues[key]) {
    sessionValues[key] = undefined;
    localStorage.setItem(sessionKey, JSON.stringify(sessionValues));
  }
};
