import _ from "lodash";
import { GET_DRUG_PAIR_ERROR_CODES } from "../actions/types";

const systemCodesDrugPairErrorsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DRUG_PAIR_ERROR_CODES:
      return { ..._.mapKeys(action.payload, "id") };
    default:
      return state;
  }
};

export default systemCodesDrugPairErrorsReducer;
