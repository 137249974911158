import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import {
  clearClients,
  getClient,
  tryDeleteContract,
  populateSidebar,
  clearSidebarActions,
  populateBreadCrumbs,
} from "../../../actions";
import {
  dateFormatter,
  getClientTypeByValue,
  Role,
  getProductCategoryByValue,
} from "./../../../utils";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import "./Client.css";
import _ from "lodash";
import NoteList from "../../Notes/NoteList";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { getClientSidebarItems } from "../../SideBar/SidebarItems";

class ClientView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      navLoaded: false,
      isAuthorized: _.some(this.props.roles, (role) => {
        return (
          role.name === Role.CLINICAL_APPROVER.name ||
          role.name === Role.CLIENT_ADMIN.name ||
          role.name === Role.ADMIN_USER.name
        );
      }),
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.props.getClient(this.props.match.params.clientId);
  }

  componentWillUnmount() {
    this.props.clearSidebarActions();
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
        this.setupSidebar();
      }
    }
  }

  toggleModal = (objId, objName, action, parentId) => {
    const header = "Delete " + objName;
    const content = "Are you sure you want to delete " + objName + "?";

    this.setState({
      isOpen: !this.state.isOpen,
      modalHeader: header,
      modalContent: content,
      modalAction: action,
      objId: objId,
      parentId: parentId,
    });
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
      ],
      finalCrumb: this.props.selectedClient.name,
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  setupSidebar = () => {
    this.props.populateSidebar(
      getClientSidebarItems(this.props.selectedClient, this.state.isAuthorized)
    );
  };

  deleteContract(contractId, contractName) {
    this.toggleModal(
      contractId,
      contractName,
      this.props.tryDeleteContract,
      this.props.selectedClient.id
    );
  }

  actionFormatter = (content, row) => {
    const editTarget = "/contracts/edit/" + row.id;
    return (
      <div>
        {this.state.isAuthorized && (
          <div>
            {!row.hasBackingProduct && (
              <Button
                className="linkBtn tableBtn actionIconBtnDanger"
                color="link"
                onClick={() =>
                  this.deleteContract(
                    row.id,
                    getProductCategoryByValue(row.type).label
                  )
                }
              >
                <FaTrashAlt />
              </Button>
            )}
            <Button
              className="linkBtn tableBtn actionIconLinkPrimary"
              color="link"
              tag={Link}
              to={{
                pathname: editTarget,
                state: {
                  clientId: this.props.selectedClient.id,
                },
              }}
            >
              <FaPencilAlt />
            </Button>
          </div>
        )}
      </div>
    );
  };

  productFormatter = (content) => {
    return <span>{getProductCategoryByValue(content).label}</span>;
  };

  COLUMNS = [
    {
      dataField: "actions",
      sort: false,
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "type",
      sort: true,
      text: "Product",
      formatter: this.productFormatter,
    },
    {
      dataField: "effectiveDate",
      sort: true,
      text: "Effective Date",
      formatter: dateFormatter,
    },
    {
      dataField: "termDate",
      sort: true,
      text: "Term Date",
      formatter: dateFormatter,
    },
  ];

  render() {
    if (!this.props.selectedClient) {
      return <div>Loading...</div>;
    }
    const client = this.props.selectedClient;

    const buttonContainerClass = this.state.isAuthorized
      ? "justify-content-between"
      : "flex-row-reverse";

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <Row>
              <Col>
                <h3>{client.name}</h3>
                <ul className="list-unstyled">
                  <li>
                    <strong>Client's Nickname/Acronym:</strong> {client.slug}
                  </li>
                  <li>
                    <strong>Client Type:</strong>{" "}
                    {getClientTypeByValue(client.type).label}
                  </li>
                  <li>
                    <strong>Line Of Business: </strong>
                    {client.lineOfBusiness.description}
                  </li>
                  <li>
                    <strong>Address:</strong>
                  </li>
                  <li>{client.address1}</li>
                  {client.address2 && <li>{client.address2}</li>}
                  <li>
                    {client.city}, {client.state} {client.zipCode}
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col className={`d-flex ${buttonContainerClass}`}>
                {this.state.isAuthorized && (
                  <Button
                    color="primary"
                    tag={Link}
                    to={`/contracts/add/${client.id}`}
                  >
                    Add Product
                  </Button>
                )}

                <Button
                  className="me-4"
                  color="primary"
                  tag={Link}
                  to={`/contracts/audit/client/${client.id}`}
                >
                  View Audit Log
                </Button>
              </Col>
              <Row className="mt-3">
                <Col>
                  <RPSDataGrid
                    keyField="id"
                    data={client.contracts}
                    columns={this.COLUMNS}
                  />
                </Col>
              </Row>
            </Row>
          </Col>

          <Col className="pe-4">
            <NoteList
              notes={client.notes}
              currentUser={this.props.currentUser}
            />
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalHeader}
          content={this.state.modalContent}
          action={this.state.modalAction}
          objId={this.state.objId}
          parentId={this.state.parentId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.auth.currentUser.roles,
    selectedClient: state.clients[ownProps.match.params.clientId],
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  tryDeleteContract,
  populateSidebar,
  clearSidebarActions,
  populateBreadCrumbs,
})(ClientView);
