import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import {
  required,
  minLookbackDays,
  maxLookbackDays,
} from "../../../utils/forms/validators";
import history from "../../../history";
import "./MPD.css";
import AlertModal from "../../Modal/AlertModal";
import moment from "moment";
import {
  renderInput,
  renderDatePicker,
  DATE_FORMAT,
  DATE_CONVERT_FORMAT,
} from "../../../utils";
import _ from "lodash";
import { connect } from "react-redux";

class EditMPDForm extends Component {
  constructor(props) {
    super(props);
    const minImpDate = moment();

    const minEndDate = this.props.mpd.implementationDate
      ? moment(this.props.mpd.implementationDate, DATE_FORMAT).toDate()
      : moment().toDate();

    const maxDate = moment().add(1, "y").toDate();

    const maxImpDate = this.props.mpd.implementationEndDate
      ? moment(this.props.mpd.implementationEndDate, DATE_FORMAT)
          .subtract(1, "d")
          .toDate()
      : maxDate;

    const pastDateEndDate = moment(
      this.props.mpd.implementationEndDate,
      DATE_FORMAT
    ).isBefore(moment());

    this.state = {
      mpdImplementationDate: this.props.mpd.implementationDate,
      implementationEndDate: this.props.mpd.implementationEndDate,
      lookbackDaysDisabled: this.props.mpd.implementationDate ? false : true,
      requireImpDate: this.props.mpd.implementationDate ? true : false,
      requireEndDate: this.props.mpd.implementationEndDate ? true : false,
      disableEndDate: pastDateEndDate ? true : false,
      minImpDate: minImpDate.toDate(),
      maxImpDate: maxImpDate,
      minEndDate: minEndDate,
      isAlertOpen: false,
    };
  }

  toggleAlertModal = (title, content, formatted = false) => {
    this.setState({
      isAlertOpen: !this.state.isAlertOpen,
      alertModalTitle: title,
      alertModalContent: content,
      alertModalFormatted: formatted,
    });
  };

  handleChange = (value, type) => {
    if (type === "imp") {
      this.onImpDateChange(value);
    } else {
      this.onEndDateChange(value);
    }

    this.setState({
      lookbackDaysDisabled: false,
    });
    if (this.props.mpd.implementationLookbackDays === null) {
      this.props.change("mpdLookbackDays", 30);
    }
    if (
      this.props.mpd.implementationDate !== null &&
      value === null &&
      type === "imp"
    ) {
      this.toggleAlertModal(
        "Invalid Implementation Date",
        "The Implementation Date can be changed but it cannot be removed once it has been set."
      );
    }
    if (
      this.props.mpd.implementationEndDate !== null &&
      value === null &&
      type === "end"
    ) {
      this.toggleAlertModal(
        "Invalid Implementation End Date",
        "The Implementation End Date can be changed but it cannot be removed once it has been set."
      );
    }
  };

  isDuplicateName(existingMpds, candidateMpd, editedMpd) {
    const duplicates = existingMpds
      .filter((mpd) => mpd.id !== editedMpd.id)
      .filter(
        (m) =>
          !candidateMpd.name.localeCompare(m.name, "en", {
            sensitivity: "base",
          })
      );
    return duplicates.length > 0;
  }

  isDuplicateImpDate(existingMpds, impDate) {
    const dateToCheck = moment(impDate, DATE_CONVERT_FORMAT).format(
      "YYYY-MM-DD"
    );
    const duplicate = _.find(
      existingMpds,
      (mpd) =>
        mpd.implementationDate === dateToCheck &&
        mpd.benefitId === this.props.mpd.benefitId
    );
    return duplicate !== undefined && duplicate.id !== this.props.mpd.id;
  }

  getWithinExistingDateRange(existingMpds, selectedMpd, impDate, endDate) {
    const inpDateToCheck = moment(impDate, DATE_CONVERT_FORMAT);
    const endDateToCheck = moment(endDate, DATE_CONVERT_FORMAT);

    const inpDateDuplicate = _.filter(existingMpds, function (mpd) {
      return (
        inpDateToCheck.isBetween(
          moment(mpd.implementationDate, DATE_FORMAT),
          moment(mpd.implementationEndDate, DATE_FORMAT),
          "day",
          "[]"
        ) &&
        mpd.benefitId === selectedMpd.benefitId &&
        mpd.id !== selectedMpd.id
      );
    });
    const endDateDuplicate = _.filter(existingMpds, function (mpd) {
      return (
        endDateToCheck.isBetween(
          moment(mpd.implementationDate, DATE_FORMAT),
          moment(mpd.implementationEndDate, DATE_FORMAT),
          "day",
          "[]"
        ) &&
        mpd.benefitId === selectedMpd.benefitId &&
        mpd.id !== selectedMpd.id
      );
    });

    return _.uniqBy(_.concat(inpDateDuplicate, endDateDuplicate), "id");
  }

  onSubmit = (formValues) => {
    let mpd = {
      name: formValues.mpdName.trim(),
    };

    //Attempt convert dates
    formValues.mpdImplementationDate = formValues.mpdImplementationDate
      ? moment(formValues.mpdImplementationDate).format(DATE_CONVERT_FORMAT)
      : null;
    formValues.implementationEndDate = formValues.implementationEndDate
      ? moment(formValues.implementationEndDate).format(DATE_CONVERT_FORMAT)
      : null;

    const existingDateRangeConflict = this.getWithinExistingDateRange(
      this.props.mpds,
      this.props.mpd,
      formValues.mpdImplementationDate,
      formValues.implementationEndDate
    );

    if (this.isDuplicateName(this.props.mpds, mpd, this.props.mpd)) {
      this.toggleAlertModal(
        "Duplicate Name",
        "The LCTAF Name must be unique. This name is already in use for this client."
      );
    } else if (
      this.isDuplicateImpDate(this.props.mpds, formValues.mpdImplementationDate)
    ) {
      this.toggleAlertModal(
        "Duplicate Implementation Date",
        "An LCTAF with this implementation date already exists for this client and benefit."
      );
    } else if (existingDateRangeConflict.length > 0) {
      const formattedDateConflicts = _.map(
        existingDateRangeConflict,
        (mpdData) => {
          return (
            "LCTAF: " +
            mpdData.name +
            ", Benefit: " +
            mpdData.benefitName +
            ", Implementation Date: " +
            mpdData.implementationDate +
            ", Implementation End Date: " +
            mpdData.implementationEndDate
          );
        }
      );

      this.toggleAlertModal(
        "Overlapping Date Range Detected",
        formattedDateConflicts,
        true
      );
    } else {
      const modifiedMpd = this.props.mpd;
      modifiedMpd.name = formValues.mpdName;
      modifiedMpd.implementationDate = formValues.mpdImplementationDate;
      modifiedMpd.implementationLookbackDays = formValues.mpdLookbackDays;
      modifiedMpd.implementationEndDate = formValues.implementationEndDate;
      this.props.onSubmit(modifiedMpd);
    }
  };

  onImpDateChange = (newVal) => {
    if (!newVal) {
      this.setState({
        requireImpDate: true,
      });
    } else {
      this.setState({
        minEndDate: moment(newVal, DATE_CONVERT_FORMAT).add(1, "d").toDate(),
      });
    }
  };

  onEndDateChange = (newVal) => {
    if (newVal) {
      this.setState({
        maxImpDate: moment(newVal, DATE_CONVERT_FORMAT)
          .subtract(1, "d")
          .toDate(),
      });
    }
  };

  render() {
    const { invalid, submitting, pristine } = this.props;

    if (this.props.implementationDateValue === undefined) {
      return <h1>Loading...</h1>;
    }

    return (
      <Form
        className="mpd-form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        autoComplete="off"
      >
        <Row className="mb-3">
          <Col md={{ size: 3 }}>
            <span>
              <strong>Catalog: &nbsp;</strong>
              {this.props.mpd.catalogDesc}
            </span>
            <br />
            <span>
              <strong>Benefit: &nbsp;</strong>
              {this.props.mpd.benefitName}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3 }}>
            <FormGroup>
              <Field
                id="mpdName"
                name="mpdName"
                type="text"
                placeholder="Name:"
                required
                validate={[required]}
                component={renderInput}
              />
            </FormGroup>
          </Col>
          <Col md={{ size: 3 }}>
            <FormGroup>
              <Field
                id="mpdImplementationDate"
                name="mpdImplementationDate"
                placeholder="Implementation Date:"
                minDate={this.state.minImpDate}
                maxDate={this.state.maxImpDate}
                onChange={(e) => this.handleChange(e, "imp")}
                required={this.state.requireImpDate}
                validate={this.state.requireImpDate ? [required] : null}
                component={renderDatePicker}
              />
            </FormGroup>
          </Col>
          <Col md={{ size: 3 }}>
            <FormGroup>
              <Field
                id="mpdLookbackDays"
                name="mpdLookbackDays"
                type="text"
                placeholder="Implementation Lookback Days:"
                validate={[minLookbackDays, maxLookbackDays]}
                disabled={this.state.lookbackDaysDisabled}
                component={renderInput}
                normalize={(val, prevVal) => {
                  if (val) {
                    return /^\d{1,2}$/.test(val) ? val : prevVal;
                  }
                  return val;
                }}
              />
            </FormGroup>
          </Col>
          <Col md={{ size: 3 }}>
            <FormGroup>
              <Field
                id="implementationEndDate"
                name="implementationEndDate"
                placeholder="Implementation End Date:"
                minDate={this.state.minEndDate}
                onChange={(e) => this.handleChange(e, "end")}
                component={renderDatePicker}
                shouldDisable={this.state.disableEndDate}
                required={this.state.requireEndDate}
                validate={this.state.requireEndDate ? [required] : null}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: 1, offset: 4 }}>
            <Button
              color="danger"
              block
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col md={1}>
            <Button
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Save
            </Button>
          </Col>
        </Row>
        <AlertModal
          isOpen={this.state.isAlertOpen}
          toggle={this.toggleAlertModal}
          header={this.state.alertModalTitle}
          content={this.state.alertModalContent}
          formatted={this.state.alertModalFormatted}
        />
      </Form>
    );
  }
}

const selector = formValueSelector("mpdForm");

const mapStateToProps = (state) => {
  return {
    implementationDateValue: selector(state, "mpdImplementationDate"),
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: "mpdForm" })(EditMPDForm)
);
