import axios from "axios";

export const loginRequest = axios.create({
  baseURL: process.env.REACT_APP_CLINICAL_API_BASE_URL,
  auth: {
    username: process.env.REACT_APP_CLIENT_ID,
    password: process.env.REACT_APP_CLIENT_SECRET
  },
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*"
  }
});

export const getAuthRequest = authToken => {
  if (!authToken) {
    authToken = sessionStorage.getItem("authToken");
  }
  return axios.create({
    baseURL: process.env.REACT_APP_CLINICAL_API_BASE_URL,
    headers: {
      Authorization: "Bearer " + authToken,
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*"
    }
  });
};

// Can be used for any GET request to the backend, just providing the auth token setup
export const getClinicalBackend = authToken => {
  if (!authToken) {
    authToken = sessionStorage.getItem("authToken");
  }
  return axios.create({
    baseURL: process.env.REACT_APP_CLINICAL_API_BASE_URL,
    headers: {
      Authorization: "Bearer " + authToken,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    }
  });
};
