import {
  CLEAR_ROLLINS_REPORT_NOTIFICATIONS,
  GET_ROLLINS_REPORT_NOTIFICATIONS,
} from "../actions/types";

const rollinsReportNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ROLLINS_REPORT_NOTIFICATIONS:
      return action.payload;
    case CLEAR_ROLLINS_REPORT_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

export default rollinsReportNotificationsReducer;
