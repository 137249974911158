export const required = (value) =>
  value ? undefined : "This field is required.";

export const maxLength = (max) => (value) =>
  value && value.trim().length > max
    ? `This field must be ${max} characters or less`
    : undefined;

export const minLength = (min) => (value) =>
  value && value.trim().length < min
    ? `This field must be ${min} characters or more`
    : undefined;

export const exactLength = (length) => (value) =>
  value && value.length !== length
    ? `This field must be ${length} characters long`
    : undefined;

export const number = (value) =>
  value && isNaN(Number(value)) ? "This field must be a number" : undefined;

export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;

export const maxValue = (max) => (value) =>
  value && Number(value.toString().replace(/,/g, "")) > max
    ? `Must be no more than ${max}`
    : undefined;

export const maxDecimalDigits = (max) => (value) =>
  value && value.match("[-+0-9]*.[0-9]{" + (max + 1) + ",}")
    ? `Must have no more than ${max} decimals`
    : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

/**
 * Email validation for use outside redux form
 * @param {string} value
 * @returns {boolean}
 */
export const isValidEmail = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

export const password = (value) =>
  value &&
  !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{10,20}$/.test(
    value
  )
    ? "Invalid password"
    : undefined;

export const verifyPasswordsMatch = (value, values) =>
  value && value !== values.newPassword ? "Passwords must match" : undefined;

export const verifyPasswordsDoNotMatch = (value, values) =>
  value && value === values.currentPassword
    ? "New password must not match current password"
    : undefined;

export const zipcode = (value) =>
  value && /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
    ? undefined
    : "Invalid zip code";

export const validFilename = (value) => {
  if (value != null) {
    return value.name && /[^-_.A-Za-z0-9]/.test(value.name)
      ? "Filename may only contain these valid characters A-Z a-z 0-9 . - _"
      : undefined;
  }
};

export const validSelect = (value) =>
  value && value === "-Select-"
    ? "'-Select-' is not a valid option for this field"
    : undefined;

export const alphanumeric = (withSpace) => (value) => {
  if (withSpace) {
    return value && !/^[a-z\d\s]+$/i.test(value)
      ? "This field is alphanumeric characters with spaces only. No special characters."
      : undefined;
  } else {
    return value && !/^[a-z\d]+$/i.test(value)
      ? "This field is alphanumeric characters only. No special characters or spaces."
      : undefined;
  }
};

export const numeric = (value) => {
  return value && !/^[0-9]+$/i.test(value)
    ? "This field is numeric characters only. No special characters, spaces, or letters."
    : undefined;
};

export const unsignedInteger = (value) => {
  return value && !/^[0-9,',']+$/i.test(value)
    ? "This field accepts unsigned integer values only. No decimals or minus signs allowed."
    : undefined;
};

export const daconRequired = (value, values) =>
  values.ahfs === undefined && values.gpi && !value
    ? "DaCon is required"
    : undefined;

export const gpiRequired = (value, values) =>
  values.ahfs === undefined && values.dacon && !value
    ? "GPI is required"
    : undefined;

export const maxDecimalValidator = (max) => (value) => {
  let returnMsg;
  if (value != null) {
    const valNoCommas = value.toString().replace(/,/g, "");
    if (isNaN(valNoCommas)) {
      returnMsg = "Amount must be a number";
    } else {
      const amt = Number(valNoCommas);
      const digits = valNoCommas.split(".")[1];
      const maxDigits = max.toString().split(".")[1]
        ? max.toString().split(".")[1].length
        : 0;
      if (amt > max) {
        returnMsg = `Amount exceeds max value of ${max}`;
      } else if (digits && digits.length > maxDigits) {
        returnMsg = `Max number of digits is ${maxDigits}`;
      }
    }
  }
  return returnMsg;
};

export const tableEditValidator = (newValue, row, column) => {
  const minValue = 0.0001;
  const maxValue = 99999.9999;
  const maxDecimalDigits = 4;

  const validatorReturn = {
    valid: false,
    message: "",
  };

  if (isNaN(newValue)) {
    validatorReturn.message = "DACon must be numeric";
  } else if (Number(newValue) < minValue) {
    validatorReturn.message = "Must be at least " + minValue;
  } else if (Number(newValue) > maxValue) {
    validatorReturn.message = "Must be less than " + maxValue;
  } else if (
    newValue.match("[-+0-9]*.[0-9]{" + (maxDecimalDigits + 1) + ",}")
  ) {
    validatorReturn.message =
      "Only " +
      maxDecimalDigits +
      " digits after the decimal are allowed for DACon";
  }
  if (validatorReturn.message !== "") {
    return validatorReturn;
  }
};

export const alphanWithSpace = alphanumeric(true);
export const alphanNoSpace = alphanumeric(false);

export const maxLength100 = maxLength(100);
export const maxLength50 = maxLength(50);
export const maxLength45 = maxLength(45);
export const maxLength15 = maxLength(15);
export const maxLength10 = maxLength(10);
export const maxLength9 = maxLength(9);
export const maxLength7 = maxLength(7);
export const maxLength3 = maxLength(3);
export const exactLengthGPI14 = exactLength(14);
export const minDaconValue = minValue(0.0001);
export const maxDaconValue = maxValue(99999.9999);
export const maxDaconDecimalDigits = maxDecimalDigits(4);
export const minLookbackDays = minValue(0);
export const maxLookbackDays = maxValue(99);
export const minLength3 = minLength(3);
export const validSubscriberCount = [
  unsignedInteger,
  maxValue(9999999),
  minValue(1),
];
export const validMembershipCount = [
  unsignedInteger,
  maxValue(9999999),
  minValue(1),
];
