import { POPULATE_BREADCRUMBS, CLEAR_BREADCRUMBS } from "../actions/types";

const INITIAL_STATE = {
  breadCrumbs: {
    crumbs: [{ to: "", name: "", active: false }],
    finalCrumb: "",
  },
};

const breadcrumbReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POPULATE_BREADCRUMBS:
      return { ...state, breadCrumbs: action.payload };
    case CLEAR_BREADCRUMBS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default breadcrumbReducer;
