import React from "react";
import PropTypes from "prop-types";

export default function CostRow({ costData, daySupplyLabel }) {
  return (
    <>
      <span>
        <strong>
          <u>{daySupplyLabel} </u> - Quantity:
        </strong>
        &nbsp;{costData.quantity}
      </span>
      <span>
        <strong>Plan Pay:</strong> {costData.planPay}
      </span>
      <span>
        <strong>Member Copay:</strong> {costData.copay}
      </span>
      <span>
        <strong>Total Cost:</strong> {costData.totalCost}
      </span>
      {costData.buyUp && (
        <span>
          <strong>Member Buy-up:</strong>&nbsp;
          {costData.buyUp}
        </span>
      )}
      <span>
        <strong>Plan Pay Net Rebate:</strong>&nbsp;
        {costData.planPayNetRebate}
      </span>
      <span>
        <strong>Rebate:</strong> {costData.rebate}
      </span>
    </>
  );
}

CostRow.propTypes = {
  costData: PropTypes.object.isRequired,
  daySupplyLabel: PropTypes.string.isRequired,
};
