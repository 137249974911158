import React, { Component } from "react";
import { Container } from "reactstrap";
import "./FileInput.css";

export class FileInput extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const {
      input: { onChange },
    } = this.props;
    onChange(e.target.files[0]);
  }

  render() {
    const {
      input: { value },
      meta: { touched, error },
    } = this.props;

    return (
      <Container>
        <div className="fileUploadInput">
          <label htmlFor="file">
            {this.props.label}{" "}
            {this.props.required && <span className="required-field">*</span>}
          </label>
          <input
            type="file"
            defaultValue={value}
            accept={this.props.acceptedFileType}
            onChange={this.onChange}
          />
          {touched && error && <span className="form-error">{error}</span>}
        </div>
      </Container>
    );
  }
}
