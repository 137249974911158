import {
  CLEAR_WORKBOOK_ERRORS,
  GET_WORKBOOK_ERRORS,
  TRY_WORKBOOK_ERROR_SEARCH,
} from "../actions/types";

const workbooksErrorReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WORKBOOK_ERRORS:
    case TRY_WORKBOOK_ERROR_SEARCH:
      return action.payload;
    case CLEAR_WORKBOOK_ERRORS:
      return {};
    default:
      return state;
  }
};

export default workbooksErrorReducer;
