import {
  CLEAR_APPROVE_WORKBOOK,
  PAIRING_WORKBOOK_APPROVAL_LOADING,
  TRY_APPROVE_WORKBOOK,
} from "../actions/types";
import _ from "lodash";

const transformDupes = (dupes) => {
  return _.groupBy(
    _.map(dupes, (dupe) => {
      return { ...dupe, gpimsc: dupe.gpi + dupe.msc };
    }),
    "gpimsc"
  );
};

const workbookApprovalReducer = (state = null, action) => {
  switch (action.type) {
    case CLEAR_APPROVE_WORKBOOK:
      return null;
    case PAIRING_WORKBOOK_APPROVAL_LOADING:
      return "loading";
    case TRY_APPROVE_WORKBOOK:
      const responseData = action.payload;
      if (!_.isEmpty(responseData.errorDupes)) {
        responseData.errorDupes = transformDupes(responseData.errorDupes);
      }
      if (!_.isEmpty(responseData.warningDupes)) {
        responseData.warningDupes = transformDupes(responseData.warningDupes);
      }
      return responseData;
    default:
      return state;
  }
};

export default workbookApprovalReducer;
