export const PRODUCT_TYPE = {
  Pricing: "PRICING",
  CatalogA: "CATALOGA",
  CatalogB: "CATALOGB",
  Include: "INCLUDE"
};

export const BILLING_TYPES = [
  {
    key: 1,
    value: "MEMBER",
    label: "Member"
  },
  {
    key: 2,
    value: "EMPLOYEE",
    label: "Employee"
  }
];

export const PRODUCT_CATEGORIES = [
  { key: 1, value: "VALUE_BASED", label: "Value Based Reimbursement" },
  { key: 2, value: "CATALOG", label: "Clinical Catalog" }
];

export const getProductCategoryByValue = value => {
  const category = PRODUCT_CATEGORIES.find(item => item.value === value);
  return category ? category : { label: "Product Category not found" };
};

export const CLIENT_TYPES = [
  { key: 1, value: "ACTIVE", label: "Active" },
  { key: 2, value: "PROSPECT", label: "Prospect" }
];

export const getClientTypeByValue = value => {
  const type = CLIENT_TYPES.find(item => item.value === value);
  return type ? type : { label: "Client Type not found" };
};
