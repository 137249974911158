import React, { useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import PropTypes from "prop-types";
import { Chip } from "../../../../utils";
import _ from "lodash";

export const PlanSavingsChipInput = props => {
  const [minMaxChip, setMinMaxChip] = useState(null);
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");

  const onAdd = () => {
    setMinMaxChip(`${min} - ${max}`);
    const minMax = [min, max];
    setMin("");
    setMax("");
    props.updateCallback(props.name, minMax);
  };

  const onRemove = val => {
    setMinMaxChip(null);
    props.updateCallback(props.name, [props.initialMin, props.initialMax]);
  };

  const isValid = inputName => {
    const minIsNumber = !_.isEmpty(min) && !_.isNaN(Number(min));
    const maxIsNumber = !_.isEmpty(max) && !_.isNaN(Number(max));
    let maxLessThanMin = false;
    if (maxIsNumber && minIsNumber && Number(max) < Number(min)) {
      maxLessThanMin = true;
    }
    if (inputName === "min") {
      return !minIsNumber || maxLessThanMin;
    }
    return !maxIsNumber || maxLessThanMin;
  };

  return (
    <Col md={3} className="ps-4">
      <Row>
        <Col md={5}>
          <FormGroup>
            <div className="field">
              <Label for="min">Plan Savings Min: (${props.initialMin})</Label>
              <Input
                type="text"
                id="min"
                name="min"
                maxLength="20"
                value={min}
                onChange={e => setMin(e.target.value)}
                invalid={min ? isValid("min") : false}
                valid={min ? isValid("min") : false}
              />
              <FormFeedback>
                Plan savings Min must be a number that is not greater than Plan
                Savings Max.
              </FormFeedback>
            </div>
          </FormGroup>
        </Col>
        <Col md={5} className="ps-0">
          <FormGroup>
            <div className="field">
              <Label for="max">Plan Savings Max: (${props.initialMax})</Label>
              <Input
                type="text"
                id="max"
                name="max"
                maxLength="20"
                value={max}
                onChange={e => setMax(e.target.value)}
                invalid={max ? isValid("max") : false}
                valid={max ? isValid("max") : false}
              />
              <FormFeedback>
                Plan savings Max must be a number that is not less than Plan
                Savings Min.
              </FormFeedback>
            </div>
          </FormGroup>
        </Col>
        <Col md={2} className="ps-0">
          <Button
            className="inline-text-input-button"
            onClick={onAdd}
            disabled={
              _.isEmpty(_.trim(min)) ||
              _.isEmpty(_.trim(max)) ||
              !_.isEmpty(minMaxChip) ||
              Number(min) > Number(max)
            }
          >
            Add
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          {minMaxChip && <Chip value={minMaxChip} removeCallback={onRemove} />}
        </Col>
      </Row>
    </Col>
  );
};

PlanSavingsChipInput.propTypes = {
  name: PropTypes.string.isRequired,
  updateCallback: PropTypes.func.isRequired
};
