import React, { Component } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import {
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from "reactstrap";
import {
  populateBreadCrumbs,
  tryRefreshToken,
  tryChangePassword,
  clearChangePassword
} from "../../actions";
import PasswordForm from "./PasswordForm";
import AlertModal from "../Modal/AlertModal";
import _ from "lodash";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.props.tryRefreshToken();
    this.setupBreadCrumbs();
  }

  componentDidUpdate() {
    if (!_.isEmpty(this.props.passwordChange)) {
      if (this.props.passwordChange.changed === false) {
        this.toggleModal(
          "Password Change Request Result",
          "The password was NOT changed."
        );
      } else {
        this.toggleModal(
          "Password Change Request Result",
          "The password was successfully changed."
        );
      }
      this.props.clearChangePassword();
      reset("passwordForm");
    }
  }

  onSubmit = formValues => {
    const userId = this.props.auth.currentUser.id;
    this.props.tryChangePassword(userId, formValues);
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [{ to: "/dashboard", name: "Home", active: false }],
      finalCrumb: "User Profile"
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  toggleModal = (title, content) => {
    this.setState({
      isOpen: !this.state.isOpen,
      modalTitle: title,
      modalContent: content
    });
  };

  renderRoles() {
    const roles = this.props.auth.currentUser.roles.map(role => {
      return (
        <ListGroupItem key={role.id}>
          <ListGroupItemHeading>{role.name}</ListGroupItemHeading>
          <ListGroupItemText>{role.description}</ListGroupItemText>
        </ListGroupItem>
      );
    });
    return roles;
  }

  render() {
    return (
      <Container className="main-container" fluid>
        <h3>Welcome, {this.props.auth.currentUser.email}</h3>
        <h4>Your Roles</h4>
        <ListGroup className="pb-5">{this.renderRoles()}</ListGroup>
        <PasswordForm onSubmit={this.onSubmit} />
        <AlertModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalTitle}
          content={this.state.modalContent}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.auth, passwordChange: state.passwordChange };
};

export default connect(
  mapStateToProps,
  {
    populateBreadCrumbs,
    tryRefreshToken,
    tryChangePassword,
    clearChangePassword
  }
)(UserProfile);
