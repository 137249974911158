import {
  GET_MPD_EXCLUSION_REASONS,
  GET_MPD_GPI_EXCLUSION_REASONS,
  GET_DRUG_PAIR_ERROR_CODES,
} from "../types.js";
import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { handleError } from "../../utils/error/errorHandlers.js";

export const getMPDExclusionReasons = () => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const exclusionReasons = await getClinicalBackend(authToken).get(
      "/api/codes/?type=MPD&subtype=CATX"
    );

    dispatch({
      type: GET_MPD_EXCLUSION_REASONS,
      payload: exclusionReasons.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getGPIExclusionReasons = () => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const exclusionGPIReasons = await getClinicalBackend(authToken).get(
      "/api/codes/?type=MPD&subtype=GPIX"
    );

    dispatch({
      type: GET_MPD_GPI_EXCLUSION_REASONS,
      payload: exclusionGPIReasons.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getDrugPairErrorCodes = () => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const types = await getClinicalBackend(authToken).get(
      "/api/codes/?type=MPD&subtype=ERR"
    );

    dispatch({
      type: GET_DRUG_PAIR_ERROR_CODES,
      payload: types.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};
