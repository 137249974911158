import { GET_REPORT_TYPES } from "../actions/types";
import _ from "lodash";

const reportTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REPORT_TYPES:
      return { ..._.mapKeys(action.payload, "id") };
    default:
      return state;
  }
};

export default reportTypesReducer;
