import { getClinicalBackend } from "../../api/clinicalBackend";
import {
  CLEAR_WORKBOOK_ENTRIES,
  GET_WORKBOOK_ENTRIES,
  TRY_WORKBOOK_ENTRY_SEARCH,
} from "../types";
import { tryRefreshToken } from "../auth/authAction";
import { formatPageableParameters, handleError } from "../../utils";

const apiBase = "/api/workbook-entries";

export const getDistinctPairingTypes = (wbId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    const endPoint = `${apiBase}/pairing-types?wbId=${wbId}`;

    const results = await getClinicalBackend(authToken).get(endPoint);
    return results.data;
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getWorkbookEntries =
  (wbId, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      const endPoint = `${apiBase}?wbId=${wbId}${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null,
      )}`;

      const entries = await getClinicalBackend(authToken).get(endPoint);
      dispatch({
        type: GET_WORKBOOK_ENTRIES,
        payload: entries.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryWorkbookEntrySearch =
  (wbId, searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `${apiBase}/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null,
      )}`;

      const entries = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify({ ...searchTerms, wbId }),
      );

      dispatch({ type: TRY_WORKBOOK_ENTRY_SEARCH, payload: entries.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearWorkbookEntries = () => (dispatch) => {
  dispatch({ type: CLEAR_WORKBOOK_ENTRIES });
};
