import { getClinicalBackend } from "../../api/clinicalBackend";
import { CLEAR_ROLL_INS, GET_ROLL_INS } from "../types";
import { tryRefreshToken } from "../auth/authAction";
import {
  downloadExcelFile,
  EXCEL_DOWNLOAD_OPTIONS,
  handleError
} from "../../utils";

export const getRollIns = (
  pageNumber,
  size,
  sorts,
  startDate,
  endDate,
  forDash
) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    let endPoint = `/api/roll-ins?page=${pageNumber}&size=${size}`;
    if (sorts && sorts.length > 0) {
      sorts.forEach(sort => {
        endPoint += `&sort=${sort.dataField},${sort.order}`;
      });
    }

    endPoint += `&startDate=${startDate}&endDate=${endDate}`;

    if (forDash) {
      endPoint += "&forDashboard=true";
    }

    const rollIns = await getClinicalBackend(authToken).get(endPoint);

    dispatch({ type: GET_ROLL_INS, payload: rollIns.data });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const exportRollIns = (startDate, endDate) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = `/api/roll-ins/export?startDate=${startDate}&endDate=${endDate}`;

    const riExport = await getClinicalBackend(authToken).get(
      endPoint,
      EXCEL_DOWNLOAD_OPTIONS
    );

    downloadExcelFile(riExport);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearRollIns = () => dispatch => {
  dispatch({ type: CLEAR_ROLL_INS });
};
