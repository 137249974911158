import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { GET_REBATE_REF_NDCS } from "../types";
import { handleError } from "../../utils/error/errorHandlers";

export const getRebateReferenceNdcs = (mpdId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const rebateRefNdcs = await getClinicalBackend(authToken).get(
      "/api/rebate-ref-ndcs?mpdId=" + mpdId
    );

    dispatch({
      type: GET_REBATE_REF_NDCS,
      payload: rebateRefNdcs.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};
