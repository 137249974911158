import {
  GET_MPD_SUMMARIES,
  CLEAR_MPD_SUMMARIES,
  UPDATE_MPD_SUMMARIES,
} from "../actions/types";

const mpdSummariesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MPD_SUMMARIES:
      return action.payload;
    case UPDATE_MPD_SUMMARIES:
      return action.payload;
    case CLEAR_MPD_SUMMARIES:
      return {};
    default:
      return state;
  }
};

export default mpdSummariesReducer;
