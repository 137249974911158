import {
  GET_DECISION_GRIDS,
  GET_DG_STATUSES,
  TRY_DG_SEARCH,
  GET_DECISION_GRID_AUDIT_LOG,
} from "../actions/types";

const initialState = {
  grids: {},
  statuses: [],
  auditLogs: [],
};

const decisionGridsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DECISION_GRIDS:
    case TRY_DG_SEARCH:
      return { ...state, grids: action.payload };
    case GET_DG_STATUSES:
      return { ...state, statuses: action.payload };
    case GET_DECISION_GRID_AUDIT_LOG:
      return { ...state, auditLogs: action.payload };
    default:
      return state;
  }
};

export default decisionGridsReducer;
