import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import moment from "moment";

import {
  clearClients,
  getClient,
  getProducts,
  tryEditContract,
  getContract,
  toggleSidebarVisibility,
} from "../../../actions";
import { populateBreadCrumbs } from "../../../actions";
import "./Contract.css";
import ClientContractForm from "./ClientContractForm";
import _ from "lodash";
import {
  BILLING_TYPES,
  DATE_CONVERT_FORMAT,
  PRODUCT_CATEGORIES,
} from "../../../utils";

class EditClientContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLoaded: false,
      contractId: this.props.match.params.contractId,
      clientId: this.props.location.state.clientId,
    };
  }

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getContract(this.state.contractId);
    this.props.getClient(this.state.clientId);
    this.props.getProducts();
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.props.selectedClient.id,
          name: this.props.selectedClient.name,
          active: false,
        },
      ],
      finalCrumb: "Edit Client Product",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  onSubmit = (formValues) => {
    this.props.tryEditContract(formValues, this.props.selectedClient.id);
  };

  getInitialValues = () => {
    const initialValues = {
      id: this.props.selectedContract.id,
      billingType: this.props.selectedContract.billingType,
      billRate: this.props.selectedContract.billRate,
      type: this.props.selectedContract.type,
      effectiveDate: moment(
        this.props.selectedContract.effectiveDate.substring(
          0,
          this.props.selectedContract.effectiveDate.indexOf("T")
        )
      ).format(DATE_CONVERT_FORMAT),
      termDate: this.props.selectedContract.termDate
        ? moment(
            this.props.selectedContract.termDate.substring(
              0,
              this.props.selectedContract.termDate.indexOf("T")
            )
          ).format(DATE_CONVERT_FORMAT)
        : null,
    };

    const currentContract = this.props.selectedContract;
    _.forEach(currentContract.products, (product) => {
      if (product.description !== "No Products Assigned") {
        if (product.type !== "PRICING") {
          initialValues[`catalogProds[${product.id}]`] = true;
          initialValues["catalogProds"] = {
            ...initialValues.catalogProds,
            [product.id]: true,
          };
        } else {
          initialValues[`pricingProducts[${product.id}]`] = true;
          initialValues["pricingProducts"] = {
            ...initialValues.pricingProducts,
            [product.id]: true,
          };
        }
      }
    });

    return initialValues;
  };

  render() {
    if (
      _.isEmpty(this.props.products) ||
      !this.props.selectedClient ||
      !this.props.selectedContract
    ) {
      return <div>Loading...</div>;
    }
    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h1>Edit Client Product</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <ClientContractForm
              initialValues={this.getInitialValues()}
              billingTypes={BILLING_TYPES}
              contractTypes={PRODUCT_CATEGORIES}
              onSubmit={this.onSubmit}
              selectedClientId={this.state.clientId}
              currentContracts={this.props.selectedClient.contracts}
              products={this.props.products}
              fromEdit
              selectedContract={this.props.selectedContract}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.location.state.clientId],
    selectedContract: state.selectedContract[ownProps.match.params.contractId],
    products: _.filter(
      _.sortBy(Object.values(state.products), ["sortOrder"]),
      (product) => {
        return product.description !== "No Products Assigned";
      }
    ),
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getProducts,
  tryEditContract,
  getContract,
  toggleSidebarVisibility,
  populateBreadCrumbs,
})(EditClientContract);
