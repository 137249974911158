import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import moment from "moment";
import {
  clearClients,
  getClient,
  getBenefits,
  getConfigFilesByClient,
  getMPDTieIns,
  clearMPDTieIns,
  tryEditBenefit,
  toggleSidebarVisibility,
} from "../../../actions";
import { populateBreadCrumbs } from "../../../actions";
import "./Benefit.css";
import BenefitForm from "./BenefitForm";
import { ConfigFile } from "../ConfigFiles/fileTypes";
import _ from "lodash";
import { DATE_CONVERT_FORMAT } from "../../../utils";

class EditBenefit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLoaded: false,
      clientId: this.props.match.params.clientId,
    };
  }

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getBenefits(this.state.clientId);
    this.props.getConfigFilesByClient(this.state.clientId);
    this.props.getMPDTieIns(this.state.clientId);
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearMPDTieIns();
    this.props.clearClients();
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
        {
          to: "/benefits/" + this.state.clientId,
          name: "Benefit List",
          active: false,
        },
      ],
      finalCrumb: "Edit Benefit",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  onSubmit = (formValues) => {
    this.props.tryEditBenefit(formValues, this.props.selectedClient.id);
  };

  render() {
    if (!this.props.selectedClient || !this.props.selectedBenefit) {
      return <div>Loading...</div>;
    }

    const selectedBenefit = this.props.selectedBenefit;

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h1>Edit Benefit</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <BenefitForm
              initialValues={{
                id: selectedBenefit.id,
                benefitName: selectedBenefit.benefitName,
                clientTieIn: selectedBenefit.clientTieIn.id,
                macFile: _.find(selectedBenefit.configFiles, {
                  type: ConfigFile.MAC,
                }).id.toString(),
                formFile: _.find(selectedBenefit.configFiles, {
                  type: ConfigFile.FORMULARY,
                }).id.toString(),
                awpdFile: _.find(selectedBenefit.configFiles, {
                  type: ConfigFile.AWPD,
                }).id.toString(),
                bcFile: _.find(selectedBenefit.configFiles, {
                  type: ConfigFile.BC,
                }).id.toString(),
                effectiveDate: moment(
                  selectedBenefit.effectiveDate.substring(
                    0,
                    selectedBenefit.effectiveDate.indexOf("T")
                  )
                ).format(DATE_CONVERT_FORMAT),
                termDate: selectedBenefit.termDate
                  ? moment(
                      selectedBenefit.termDate.substring(
                        0,
                        selectedBenefit.termDate.indexOf("T")
                      )
                    ).format(DATE_CONVERT_FORMAT)
                  : null,
              }}
              onSubmit={this.onSubmit}
              selectedClientId={this.props.selectedClient.id}
              currentBenefits={this.props.benefits}
              configFiles={this.props.configFiles}
              clientId={this.props.selectedClient.id}
              clientTieIns={this.props.clientTieIns}
              fromEdit
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    benefits: Object.values(state.benefits),
    selectedBenefit: state.benefits[ownProps.match.params.benefitId],
    configFiles: state.clientConfigFiles,
    clientTieIns: Object.values(state.mpdTieIns),
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getBenefits,
  getConfigFilesByClient,
  getMPDTieIns,
  clearMPDTieIns,
  tryEditBenefit,
  toggleSidebarVisibility,
  populateBreadCrumbs,
})(EditBenefit);
