import { getClinicalBackend } from "../../api/clinicalBackend";
import {
  GET_DECISION_GRID,
  GET_DECISION_GRIDS,
  GET_DG_STATUSES,
  TRY_DG_SEARCH,
  GET_DECISION_GRID_AUDIT_LOG,
} from "../types";
import { tryRefreshToken } from "../auth/authAction";
import {
  downloadExcelFile,
  EXCEL_DOWNLOAD_OPTIONS,
  formatPageableParameters,
  handleError,
} from "../../utils";

import history from "../../history";

export const getDecisionGrids =
  (pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endpointSuffix = `?page=${pageNumber}&size=${size}`;
      if (sort) {
        endpointSuffix += `&sort=${sort.dataField},${sort.order}`;
      }
      const endpoint = `/api/grids${endpointSuffix}`;
      const grids = await getClinicalBackend(authToken).get(endpoint);
      dispatch({
        type: GET_DECISION_GRIDS,
        payload: grids.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getDecisionGrid = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/grids/${id}`;
    const grid = await getClinicalBackend(authToken).get(endpoint);
    dispatch({
      type: GET_DECISION_GRID,
      payload: grid.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

// expects a comma separated list of ids
export const exportDecisionGrids = (ids) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = `/api/grid-reports/?ids=${ids}`;

    const gridExport = await getClinicalBackend(authToken).get(
      endPoint,
      EXCEL_DOWNLOAD_OPTIONS,
    );
    downloadExcelFile(gridExport);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getDecisionGridStatuses = () => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = "/api/grid-statuses";
    const statuses = await getClinicalBackend(authToken).get(endpoint);
    dispatch({
      type: GET_DG_STATUSES,
      payload: statuses.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const trySaveDecisionGrid =
  (formValues) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = "/api/grids";
      const grid = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(formValues),
      );
      const newGridId = grid.data.id;
      history.push(`/grids/${newGridId}/add-dpo`);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryDeleteGrid = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/grids/${id}`;
    await getClinicalBackend(authToken).delete(endpoint);
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryCopyGrid = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/grids/${id}`;
    await getClinicalBackend(authToken).post(endpoint);
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryGridSearch =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/grids/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null,
      )}`;
      const grids = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms),
      );

      dispatch({ type: TRY_DG_SEARCH, payload: grids.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryUpdateDecisionGrid = (grid) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/grids/${grid.id}`;

    await getClinicalBackend(authToken).put(endpoint, JSON.stringify(grid));
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const activateDecisionGrid = (gridId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/grids/activate/${gridId}`;

    await getClinicalBackend(authToken).post(endpoint);
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getDecisionGridsAuditLog =
  (searchTerms, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `/api/grids-audits?${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null,
      )}`;

      const auditLogs = await getClinicalBackend(authToken).post(
        endPoint,
        JSON.stringify(searchTerms),
      );
      dispatch({
        type: GET_DECISION_GRID_AUDIT_LOG,
        payload: auditLogs.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
