import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import {
  clearClients,
  getClient,
  addSortOrder,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getEligibilityCorrelationsByClient
} from "../../../actions";
import { dateTimeFormatter, sortHandler } from "../../../utils";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";

class EligibilityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      totalSizePerPage: 15
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false
        }
      ],
      finalCrumb: "Eligibility List"
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.getClient(this.state.clientId);
    this.props.getEligibilityCorrelationsByClient(
      this.state.clientId,
      0,
      this.state.sizePerPage
    );
    this.props.toggleSidebarVisibility();
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  }

  getEligibilityCorrelationData = () => {
    return this.props.eligibilityCorrelations.content;
  };

  getPage(page, sizePerPage, sorts) {
    return this.props.getEligibilityCorrelationsByClient(
      this.state.clientId,
      page - 1,
      sizePerPage,
      sorts
    );
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      const newSort = sortHandler(this.props.sorts, [
        { dataField: sortField, order: sortOrder }
      ]);
      this.getPage(page, sizePerPage, newSort);
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage });
    this.getPage(page, sizePerPage, this.props.sorts);
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage, this.props.sorts);
  };

  COLUMNS = [
    {
      dataField: "updatedDate",
      text: "Process Date",

      formatter: dateTimeFormatter
    },
    {
      dataField: "description",
      text: "File Name"
    },
    {
      dataField: "status",
      text: "Status"
    },
    {
      dataField: "totalCount",
      text: "Total Count",
      headerAlign: "center",
      align: "center"
    },
    {
      dataField: "addedRecordsCount",
      text: "Added",
      headerAlign: "center",
      align: "center"
    },
    {
      dataField: "updatedRecordsCount",
      text: "Updated",
      headerAlign: "center",
      align: "center"
    },
    {
      dataField: "unchangedRecordsCount",
      text: "Unchanged",
      headerAlign: "center",
      align: "center"
    },
    {
      dataField: "deletedRecordsCount",
      text: "Deleted",
      headerAlign: "center",
      align: "center"
    },
    {
      dataField: "processingErrorCount",
      text: "Errors",
      headerAlign: "center",
      align: "center"
    }
  ];

  render() {
    if (
      _.isEmpty(this.props.eligibilityCorrelations) ||
      _.isEmpty(this.props.selectedClient)
    ) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Eligibility Files</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RPSDataGrid
              keyField="id"
              paginated
              remote
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.eligibilityCorrelations.totalElements}
              data={this.getEligibilityCorrelationData()}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    eligibilityCorrelations: state.eligibilityCorrelations
  };
};

export default connect(
  mapStateToProps,
  {
    clearClients,
    addSortOrder,
    getClient,
    populateBreadCrumbs,
    toggleSidebarVisibility,
    getEligibilityCorrelationsByClient
  }
)(EligibilityList);
