import {
  GET_WORKBOOKS,
  TRY_WORKBOOK_SEARCH,
  CHECK_WORKBOOK_EXISTENCE,
  CLEAR_DPOS,
} from "../actions/types";

const workbooksReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WORKBOOKS:
    case TRY_WORKBOOK_SEARCH:
      return action.payload;
    case CHECK_WORKBOOK_EXISTENCE:
      // Result set will only ever be empty or 1
      return {
        ...state,
        existingWorkbook: action.payload.length > 0 ? action.payload[0] : null,
      };
    case CLEAR_DPOS:
      return {};
    default:
      return state;
  }
};

export default workbooksReducer;
