import React, { Component } from "react";
import { connect } from "react-redux";
import {
  populateBreadCrumbs,
  populateSidebar,
  searchDDNs,
  saveDPOs,
  clearDDNs,
  saveCatRefs,
} from "../../../actions";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { authCheck, PRODUCT_STATUS, Role } from "../../../utils";
import _ from "lodash";
import history from "../../../history";
import { getClinicalSidebarItems } from "../Constants";

class AddGPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthorized:
        authCheck(this.props.roles, Role.CLINICAL_APPROVER.name) ||
        authCheck(this.props.roles, Role.CLINICIAN.name),
      selectedSearch: "drugName",
      searchValue: "",
      selectedGpis: [],
      searching: false,
    };
  }

  componentDidMount = () => {
    this.setupBreadCrumbs();
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  componentDidUpdate = () => {
    if (this.state.searching && !_.isEmpty(this.props.searchResults)) {
      this.setState({
        selectedGpis: this.props.searchResults,
        searching: false,
      });
    }
  };

  componentWillUnmount = () => {
    this.props.clearDDNs();
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/category-refs",
          name: "Category Reference GPIs",
          active: false,
        },
      ],
      finalCrumb: "Add GPI",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  onSearchClicked = () => {
    this.props.clearDDNs();
    this.props.searchDDNs(this.state.selectedSearch, this.state.searchValue);
    this.setState({ searching: true, selectedGpis: [] });
  };

  onEnterKey = (e) => {
    if (e.key === "Enter" && !_.isEmpty(this.state.searchValue.trim())) {
      this.onSearchClicked();
    }
  };

  COLUMNS = [
    {
      dataField: "gpi",
      sort: true,
      text: "GPI",
    },
    {
      dataField: "gpiCommonName",
      sort: true,
      text: "Drug Name",
    },
    {
      dataField: "multiSourceCode",
      sort: true,
      text: "MSC",
    },
    {
      dataField: "strength",
      text: "Strength Per Unit",
    },
    {
      dataField: "dosageForm",
      text: "Dosage Form",
    },
    {
      dataField: "routeOfAdministration",

      text: "Route of Admin",
    },
    {
      dataField: "packageSizeUOM",
      text: "UOM",
    },
  ];

  getSelectRow = () => {
    return {
      mode: "checkbox",
      clickToSelect: false,
      selectionHeaderRenderer: () => "Add to Category Reference",
      headerColumnStyle: { width: "3%" },
      onSelect: this.onGpiSelect,
      onSelectAll: this.onSelectAll,
      selected: _.map(this.state.selectedGpis, (gpi) => gpi.id),
    };
  };

  onSelectAll = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ selectedGpis: rows });
    } else {
      this.setState({ selectedGpis: [] });
    }
  };

  onGpiSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({ selectedGpis: [...this.state.selectedGpis, row] });
    } else {
      this.setState({
        selectedGpis: this.state.selectedGpis.filter(
          (gpi) => gpi.id !== row.id
        ),
      });
    }
  };

  onGPIsSave = () => {
    const gpis = _.map(this.state.selectedGpis, (gpi) => {
      return {
        id: 0,
        gpi: gpi.gpi,
        status: PRODUCT_STATUS.INITIAL_REVIEW_PENDING.replace(
          /\s/g, // Global search for occurrences of whitespace
          "_"
        ).toUpperCase(),
        recordType: "5", // GPIName.FULL_GPI_RECORD_TYPE on backend
      };
    });

    this.props.saveCatRefs(gpis);
  };

  render = () => {
    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Add Category Reference GPI</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={3}>
            <InputGroup>
              <InputGroupText>
                <Input
                  type="select"
                  bsSize="sm"
                  onChange={(e) => {
                    this.setState({
                      selectedSearch: e.currentTarget.value,
                      searchValue: "",
                    });
                  }}
                  value={this.state.selectedSearch}
                >
                  <option value="drugName">Drug Name</option>
                  <option value="ahfsClass">AHFS Classification</option>
                  <option value="gpi">GPI</option>
                </Input>
              </InputGroupText>

              <Input
                type="text"
                className="form-control"
                bsSize="lg"
                onChange={(e) => {
                  this.setState({ searchValue: e.currentTarget.value });
                }}
                value={this.state.searchValue}
                onKeyPress={this.onEnterKey}
              />
            </InputGroup>
          </Col>
          <Col>
            <Button
              size="lg"
              disabled={_.isEmpty(this.state.searchValue.trim())}
              onClick={this.onSearchClicked}
            >
              Search
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {this.state.searching && !this.props.zeroResultsFound && (
              <h3>SEARCHING...</h3>
            )}

            {this.props.zeroResultsFound && (
              <h3>0 Results Found. Please update your search criteria.</h3>
            )}

            {!this.state.searching && !this.props.zeroResultsFound && (
              <RPSDataGrid
                keyField="id"
                data={this.props.searchResults ? this.props.searchResults : []}
                columns={this.COLUMNS}
                selectRow={this.getSelectRow()}
              />
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <Button
              color="danger"
              disabled={_.isEmpty(this.state.selectedGpis)}
              onClick={() => {
                history.go(0);
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="ms-3"
              disabled={_.isEmpty(this.state.selectedGpis)}
              onClick={this.onGPIsSave}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    searchResults: state.drugPairingOptions.searchResults,
    zeroResultsFound: state.drugPairingOptions.zeroResultsFound,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  searchDDNs,
  saveDPOs,
  clearDDNs,
  saveCatRefs,
})(AddGPI);
