import _ from "lodash";
import React from "react";
import { Route, Redirect } from "react-router-dom";

export const ProtectedRoute = ({
  component: Component,
  currentUser,
  roles,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (!currentUser) {
        return <Redirect to={{ pathname: "/" }} />;
      }

      if (roles) {
        const intersection = _.intersectionBy(roles, currentUser.roles, "name");
        if (intersection.length <= 0) {
          return <Redirect to={{ pathname: "/dashboard" }} />;
        }
      }

      return <Component {...props} />;
    }}
  />
);
