import {
  GET_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG,
  CLEAR_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG,
} from "../actions/types";

const defaultState = { auditLogs: [] };

const categoryReferenceDrugAuditLogReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG:
      return { auditLogs: action.payload };
    case CLEAR_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG:
      return defaultState;
    default:
      return state;
  }
};

export default categoryReferenceDrugAuditLogReducer;
