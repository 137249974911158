import {
  GET_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG,
  CLEAR_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG,
} from "../actions/types";

const defaultState = { auditLogs: [] };

const additionalTargetDrugAuditLogReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG:
      return { auditLogs: action.payload };
    case CLEAR_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG:
      return defaultState;
    default:
      return state;
  }
};

export default additionalTargetDrugAuditLogReducer;
