import { CHANGE_PASSWORD, CLEAR_CHANGE_PASSWORD } from "../actions/types";

const userProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return { ...state, ...action.payload };
    case CLEAR_CHANGE_PASSWORD:
      return {};
    default:
      return state;
  }
};

export default userProfileReducer;
