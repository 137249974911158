import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { Button, Collapse, Row } from "reactstrap";
import {
  ChipAwareOptionInput,
  ChipAwareTextInput,
  DATE_CONVERT_FORMAT,
  DATE_FORMAT,
} from "../../../utils";

class CategoryFillFactorSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: {
        baselineDates: [],
        drugCategories: [],
      },
    };
  }

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      this.props.onSubmit(this.state.searchTerms);
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  handleRunDateChange = (name, terms) => {
    const newTerms = _.map(terms, (term) => {
      return moment(term, DATE_CONVERT_FORMAT).format(DATE_FORMAT);
    });
    this.onSearchTermUpdate(name, newTerms);
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <ChipAwareOptionInput
              id="baselineDates"
              name="baselineDates"
              minLength={1}
              options={this.props.baselineDates}
              placeholder="Claims Range End Date"
              updateCallback={this.handleRunDateChange}
            />
            <ChipAwareTextInput
              id="drugCategories"
              name="drugCategories"
              placeholder="Drug Category"
              updateCallback={this.onSearchTermUpdate}
            />
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default CategoryFillFactorSearchForm;
