import { GET_CATALOG, GET_CATALOGS, GET_LATEST_CATALOG } from "../types.js";
import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { handleError } from "../../utils/error/errorHandlers.js";
import { formatPageableParameters } from "../../utils/index.js";

export const getCatalogAction = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/catalogs/${id}`;
    const response = await getClinicalBackend(authToken).get(endpoint);

    dispatch({
      type: GET_CATALOG,
      payload: response.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getCatalogsAction =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/catalogs?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null
      )}`;
      const response = await getClinicalBackend(authToken).get(endpoint);

      dispatch({
        type: GET_CATALOGS,
        payload: response.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getLatestCatalog = () => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    const latestCatalog = await getClinicalBackend(authToken).get(
      "/api/catalogs/latest"
    );

    dispatch({ type: GET_LATEST_CATALOG, payload: latestCatalog.data });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};
