import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import {
  clearClients,
  getClient,
  getFormFileContent,
  getConfigFile,
  clearFileContent,
  populateBreadCrumbs,
  toggleSidebarVisibility,
} from "../../../actions";

import { ConfigFile } from "./fileTypes";
import { getFileNameNoExt, percentageFormatter } from "../../../utils";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import FormularySearchForm from "./FormularySearchForm";

class FormularyView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      totalSizePerPage: 15,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      searchTerms: {
        drugProductLabelNames: [],
        ndcs: [],
        clientId: this.props.match.params.clientId,
        correlationId: this.props.match.params.correlationId,
      },
    };
    this.ndcStatusToName = {
      ACTIVE: "valid",
      INACTIVE: "inactive",
      ACTIVE_REASSIGNED: "valid with a new NDC",
      INACTIVE_REASSIGNED: "inactive with a new NDC",
      NOTFOUND: "not found",
      DUPLICATE: "duplicate",
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
        {
          to: "/client-config/dashboard/" + this.state.clientId,
          name: "Configuration Files",
          active: false,
        },
      ],
      finalCrumb: this.props.selectedFile.fileName,
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.props.match.params.clientId);
    this.props.getConfigFile(this.props.match.params.correlationId);
    this.getPage();
  }

  componentDidUpdate() {
    if (this.props.selectedClient && this.props.selectedFile) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearFileContent([ConfigFile.FORMULARY]);
    this.props.clearClients();
  }

  getPage = () => {
    this.props.getFormFileContent(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField,
    );
  };

  statusFormatter = (content, row) => {
    let msg = this.ndcStatusToName[content];
    return msg ? msg : content;
  };

  rebateTypeFormatter = (content, row) => {
    if (content === "PERCENT") {
      return "%";
    } else {
      return "N";
    }
  };

  getFileContents = () => {
    let contents = this.props.fileContents.content;
    return contents;
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  COLUMNS = [
    {
      dataField: "ndc",
      sort: true,
      text: "NDC",
    },
    {
      dataField: "ndcStatus",
      sort: true,
      text: "NDC Status",
      formatter: this.statusFormatter,
    },
    {
      dataField: "tierLevel",
      sort: true,
      text: "Tier Level",
      align: "center",
    },
    {
      dataField: "drugProductLabel",
      sort: true,
      text: "Drug Product Label",
    },
    {
      dataField: "rebateType",
      sort: true,
      text: "Rebate Type",
      formatter: this.rebateTypeFormatter,
      align: "center",
    },
    {
      dataField: "rebateValue",
      sort: true,
      text: "Rebate Amount",
      formatter: percentageFormatter,
      align: "right",
    },
  ];

  render() {
    if (
      !this.props.selectedClient ||
      !this.props.fileContents.content ||
      !this.props.selectedFile
    ) {
      return <div>Loading...</div>;
    }

    let fileName = getFileNameNoExt(this.props.selectedFile.fileName);
    fileName = fileName ? fileName : this.props.selectedFile.fileName;

    return (
      <Container className="main-container" fluid>
        <Row className="mb-4">
          <Col>
            <h3>{fileName}</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <FormularySearchForm
              onSubmit={this.onSearchSubmit}
              searchTerms={this.state.searchTerms}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              className="react-bootstrap-table"
              keyField="id"
              paginated
              remote
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.fileContents.totalElements}
              data={this.props.fileContents.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    configFiles: state.clientConfigFiles,
    fileContents: state.formFileContent,
    selectedFile: state.clientConfigFiles[ownProps.match.params.correlationId],
    sorts: state.dataGridSorts[ConfigFile.FORMULARY],
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getFormFileContent,
  getConfigFile,
  clearFileContent,
  populateBreadCrumbs,
  toggleSidebarVisibility,
})(FormularyView);
