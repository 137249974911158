import { ADD_SORT_ORDER, RESET_SORT_ORDER } from "../actions/types";
import { ConfigFile } from "../components/ClientManagement/ConfigFiles/fileTypes";

const initialState = {
  [ConfigFile.FORMULARY]: [
    { dataField: "ndcStatus", order: "desc" },
    { dataField: "ndc", order: "asc" },
  ],
  [ConfigFile.MAC]: [
    { dataField: "gpi_status", order: "desc" },
    { dataField: "gpi", order: "asc" },
  ],
};

const dataGridSortReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SORT_ORDER:
      return { ...state, [action.payload.fileType]: action.payload.newSorts };
    case RESET_SORT_ORDER:
      return initialState;
    default:
      return state;
  }
};

export default dataGridSortReducer;
