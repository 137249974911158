import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { getDashedGPI } from "../../../../utils";

const ModalTargetsList = ({ items }) => {
  return (
    <ul>
      {_.map(items, item => {
        return (
          <li key={item.key}>{`${getDashedGPI(item.gpi)} with MSC: ${
            item.multiSource
          }`}</li>
        );
      })}
    </ul>
  );
};

export default ModalTargetsList;

ModalTargetsList.defaultProps = {
  items: []
};

ModalTargetsList.propTypes = {
  items: PropTypes.array.isRequired
};
