import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import {
  clearClients,
  getClient,
  getMPDsPageable,
  tryEditMPD,
  clearSidebarActions,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getMPDActivity,
} from "../../../actions";
import _ from "lodash";
import EditMPDForm from "./EditMPDForm";
import moment from "moment";
import {
  localDateTimeFormatter,
  userNameFormatter,
  getDateString,
  npeBrandingName,
  getNPEBreadcrumbs,
} from "../../../utils";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";

class EditMPD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      mpdId: this.props.match.params.mpdId,
      lookbackDaysDefault: 30,
      currPage: 1,
      sizePerPage: 15,
      sortField: [{ dataField: "updatedDate", order: "desc" }],
    };
  }

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getMPDsPageable(this.state.clientId, "coreplus");
    this.props.getClient(this.state.clientId);
    this.props.getMPDActivity(
      this.state.mpdId,
      undefined,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  }

  componentWillUnmount() {
    this.props.clearSidebarActions();
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  }

  componentDidUpdate() {
    if (this.props.selectedClient && this.props.selectedMpd) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  setupBreadCrumbs = () => {
    this.props.populateBreadCrumbs({
      crumbs: getNPEBreadcrumbs(this.props.selectedClient),
      finalCrumb: this.props.selectedMpd.name,
    });
  };

  getPage(page, sizePerPage, sorts) {
    this.setState({ currPage: page, sizePerPage: sizePerPage });
    this.props.getMPDActivity(
      this.state.mpdId,
      undefined,
      page - 1,
      sizePerPage,
      sorts
    );
  }

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage, this.state.sortField);
  };

  getMPDData = (mpdActivities) => {
    return _.forEach(mpdActivities, (mpdActivity) => {
      switch (mpdActivity.activityTrackType.longValue) {
        case "Eff DT":
          mpdActivity.activityLong =
            mpdActivity.activityTrackType.longValue +
            "(" +
            getDateString(mpdActivity.dateSetTo) +
            ", LookbackDays: " +
            mpdActivity.lookbackDays +
            ")";
          break;
        case "Lookback":
          mpdActivity.activityLong =
            mpdActivity.activityTrackType.longValue +
            "(" +
            mpdActivity.lookbackDays +
            ")";
          break;
        case "Term DT":
          mpdActivity.activityLong =
            mpdActivity.activityTrackType.longValue +
            "(" +
            getDateString(mpdActivity.dateSetTo) +
            ")";
          break;
        default:
          mpdActivity.activityLong = mpdActivity.activityTrackType.longValue;
          break;
      }
    });
  };

  COLUMNS = [
    {
      dataField: "updatedDate",
      text: "Date",
      sort: true,
      formatter: localDateTimeFormatter,
    },
    {
      dataField: "activityLong",
      text: "Activity",
      sort: true,
    },
    {
      dataField: "user",
      text: "User",
      sort: true,
      formatter: userNameFormatter,
    },
  ];

  transformSortField = (sortField) => {
    // Need to transform the sort field to work with the back end
    let sortFieldArray = [];
    switch (sortField) {
      case "updatedDate":
        sortFieldArray.push("updatedDate");
        break;
      case "activityLong":
        sortFieldArray.push("activityTrackType");
        sortFieldArray.push("dateSetTo");
        sortFieldArray.push("lookbackDays");
        break;
      case "user":
        sortFieldArray.push("user.lastName");
        break;
      default:
        break;
    }
    return sortFieldArray;
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = this.transformSortField(sortField);
      let newSort = [];
      if (sortField && sortField.length > 0) {
        sortField.forEach((sort) => {
          newSort.push({ dataField: sort, order: sortOrder });
        });
      }
      this.setState({ sortField: newSort });
      this.getPage(page, sizePerPage, newSort);
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage });
    this.getPage(page, sizePerPage, this.state.sortField);
  };

  onSubmit = (mpd) => {
    this.props.tryEditMPD(mpd);
  };

  getLookbackDays = () => {
    if (
      this.props.selectedMpd.implementationLookbackDays === 0 ||
      this.props.selectedMpd.implementationLookbackDays
    ) {
      return this.props.selectedMpd.implementationLookbackDays;
    }
    return this.state.lookbackDaysDefault;
  };

  render() {
    if (
      _.isEmpty(this.props.selectedClient) ||
      _.isEmpty(this.props.selectedMpd) ||
      _.isEmpty(this.props.mpdActivity)
    ) {
      return "Loading...";
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h1>Edit {npeBrandingName}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <EditMPDForm
              initialValues={{
                id: this.props.selectedMpd.id,
                mpdName: this.props.selectedMpd.name,
                mpdImplementationDate: getDateString(
                  moment(
                    this.props.selectedMpd.implementationDate,
                    "YYYY-MM-DD"
                  ).toDate(),
                  true
                ),
                implementationEndDate: getDateString(
                  moment(
                    this.props.selectedMpd.implementationEndDate,
                    "YYYY-MM-DD"
                  ).toDate(),
                  true
                ),
                mpdLookbackDays: this.getLookbackDays(),
              }}
              onSubmit={this.onSubmit}
              mpd={this.props.selectedMpd}
              mpds={this.props.mpds}
              mpdActivity={this.props.mpdActivity}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="mt-5">
              <Col>
                <span>
                  <strong>{npeBrandingName} Activity Log</strong>
                </span>
                <RPSDataGrid
                  keyField="id"
                  paginated
                  remote
                  sizePerPage={this.state.sizePerPage}
                  page={this.state.currPage}
                  totalSize={this.props.mpdActivity.totalElements}
                  data={this.getMPDData(this.props.mpdActivity.content)}
                  columns={this.COLUMNS}
                  onSizePerPageChange={this.onSizePerPageChange}
                  onTableChange={this.handleTableChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    selectedMpd: state.mpds[ownProps.match.params.mpdId],
    mpds: Object.values(state.mpds),
    mpdActivity: state.mpdActivity,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getMPDsPageable,
  tryEditMPD,
  clearSidebarActions,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getMPDActivity,
})(EditMPD);
