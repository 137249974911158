import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import {
  clearClients,
  getClient,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getCatalogDeliveries,
} from "../../../actions";
import {
  localDateTimeFormatter,
  dateFormatter,
  booleanYesOnlyFormatter,
} from "../../../utils";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";

class CatalogDeliveryFileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      totalSizePerPage: 15,
      breadcrumbsLoaded: false,
      sortField: { dataField: "updatedDate", order: "desc" },
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
      ],
      finalCrumb: "Clients Clinical Catalog Delivery List",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.getClient(this.state.clientId);
    this.getPage();
    this.props.toggleSidebarVisibility();
  }

  componentDidUpdate() {
    if (
      this.props.selectedClient &&
      !this.state.breadcrumbsLoaded &&
      !_.isEmpty(this.props.catalogDeliveries)
    ) {
      this.setupBreadCrumbs();
      this.setState({ breadcrumbsLoaded: true });
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  }

  getPage = () => {
    this.props.getCatalogDeliveries(
      this.state.clientId,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage, this.props.sorts);
  };

  actionFormatter = (content, row) => {
    const target = row.downloadUrl;
    if (target) {
      return (
        <Button
          className="linkBtn tableBtn actionIconLinkPrimary"
          color="link"
          tag={Link}
          to={{ pathname: target }}
          target="_blank"
        >
          <FaDownload />
        </Button>
      );
    } else return "";
  };

  COLUMNS = [
    {
      dataField: "actions",
      text: "Action",
      formatter: this.actionFormatter,
    },
    {
      dataField: "fileName",
      text: "File Name",
      sort: true,
    },
    {
      dataField: "catalogDate",
      text: "Effective Date",
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "extended",
      text: "Additional Provider Targets Included in file",
      headerAlign: "center",
      align: "center",
      formatter: booleanYesOnlyFormatter,
    },
    {
      dataField: "deliveryDate",
      text: "FTP Delivery DateTime",
      headerAlign: "center",
      align: "center",
      sort: true,
      formatter: localDateTimeFormatter,
    },
    {
      dataField: "status",
      text: "FTP Delivery Status",
      headerAlign: "center",
      align: "center",
      sort: true,
    },
  ];

  render() {
    if (
      _.isEmpty(this.props.catalogDeliveries) ||
      _.isEmpty(this.props.selectedClient)
    ) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>
              {this.props.selectedClient.name} Clinical Catalog File Delivery
              History
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RPSDataGrid
              keyField="id"
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.catalogDeliveries.totalElements}
              data={this.props.catalogDeliveries.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              remote
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    catalogDeliveries: state.catalogDeliveries,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getCatalogDeliveries,
})(CatalogDeliveryFileList);
