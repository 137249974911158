import React, { Component } from "react";
import { Button, Collapse, Row, Col } from "reactstrap";
import {
  ChipAwareTextInput,
  MultiSelectCheckbox,
  DATE_PICKER_FORMAT,
  getRenderDate,
  SESSION_VALUE_KEYS,
  getSessionValuesOrDefault,
  setSessionValue,
} from "../../../utils";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class TargetedGPIsSearchForm extends Component {
  EFF_DATE_FILTER = "effectiveDate";

  constructor(props) {
    super(props);

    const mscs = [
      { id: 1, name: "M", value: "M", checked: false, displayName: "M" },
      { id: 2, name: "O", value: "O", checked: false, displayName: "O" },
      { id: 3, name: "N", value: "N", checked: false, displayName: "N" },
      { id: 4, name: "Y", value: "Y", checked: false, displayName: "Y" },
    ];

    const searchTerms = this.props.searchTerms
      ? this.props.searchTerms
      : {
          effectiveDate: DateTime.now().toISODate(),
          categoryId: 0,
          names: [],
          gpis: [],
          mscs: [],
        };

    const localStorageFilters = getSessionValuesOrDefault(
      SESSION_VALUE_KEYS.TGT_EFFDT,
      { [this.EFF_DATE_FILTER]: DateTime.now().toISODate() }
    );

    let effDate = localStorageFilters[this.EFF_DATE_FILTER]
    if (effDate) {
      searchTerms.effectiveDate = effDate;
    }

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: searchTerms,
      catalogEffDate: DateTime.fromISO(this.props.searchTerms.effectiveDate).toJSDate(),
      mscs,
    };
  }

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      const mscs = _.filter(
        _.map(this.state.mscs, (val) => {
          if (val.checked) {
            return val.value;
          }
        }),
        (val) => {
          return val !== undefined;
        }
      );

      this.props.onSubmit({ ...this.state.searchTerms, mscs });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  handleChange = (value) => {
    if (value) {
      this.setState({
        searchTerms: {
          ...this.state.searchTerms,
        },
        searchFormDirty: true,
      });
    } else {
      this.setState({
        searchTerms: {
          ...this.state.searchTerms,
        },
        searchFormDirty: true,
      });
    }
  };

  onCheckSelectAll = (e) => {
    const items = this.state.mscs;
    _.forEach(items, (item) => {
      item.checked = e.currentTarget.checked;
    });
    this.setState({ mscs: items, searchFormDirty: true });
  };

  onCheckboxClick = (e) => {
    const element = e.currentTarget;
    const items = this.state.mscs;
    items[parseInt(element.id) - 1].checked = element.checked;
    this.setState({
      searchTerms: { ...this.state.searchTerms },
      searchFormDirty: true,
    });
  };

  onDateChange = (date) => {
    const effDate = DateTime.fromJSDate(date).toISODate();
    this.setState({
      searchTerms: { ...this.state.searchTerms, effectiveDate: effDate },
      catalogEffDate: date,
      searchFormDirty: true,
    });
    setSessionValue(SESSION_VALUE_KEYS.TGT_EFFDT, this.EFF_DATE_FILTER, effDate);
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <Col md={2}>
              <span className="font-weight-bold gap-2">
                Catalog Effective Date
              </span>
              <DatePicker
                dateFormat={DATE_PICKER_FORMAT}
                selected={getRenderDate(this.state.catalogEffDate)}
                onChange={this.onDateChange}
                strictParsing
                className="form-control"
                maxDate={DateTime.now().toJSDate()}
              />
            </Col>
            <ChipAwareTextInput
              id="names"
              name="names"
              placeholder="Target or Alt. Drug Name"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="gpi"
              name="gpis"
              placeholder="GPI"
              updateCallback={this.onSearchTermUpdate}
            />
            <Col md={1}>
              <MultiSelectCheckbox
                header="MSC"
                items={this.state.mscs}
                itemOnChange={this.onCheckboxClick}
                showSelectedCount
                selectAllOnChange={this.onCheckSelectAll}
              />
            </Col>
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default TargetedGPIsSearchForm;
