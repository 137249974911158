import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Col, Container, Row } from "reactstrap";
import {
  clearClients,
  getClient,
  getBenefits,
  getCatalogsAction,
  getMPDs,
  tryAddMPD,
  populateBreadCrumbs,
  toggleSidebarVisibility,
} from "../../../actions";
import MPDForm from "./MPDForm";
import AlertModal from "../../Modal/AlertModal";
import history from "../../../history";
import { getNPEBreadcrumbs, npeBrandingName } from "../../../utils";

class AddMPD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
    };
  }

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.props.match.params.clientId);
    this.props.getCatalogsAction();
    this.props.getMPDs(this.state.clientId);
    this.props.getBenefits(this.state.clientId);
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  setupBreadCrumbs = () => {
    this.props.populateBreadCrumbs({
      crumbs: getNPEBreadcrumbs(this.props.selectedClient),
      finalCrumb: `Request ${npeBrandingName}`,
    });
  };

  toggleModal = (title, content) => {
    this.setState({
      isOpen: !this.state.isAlertOpen,
      modalTitle: title,
      modalContent: content,
    });
  };

  onSubmit = (_, mpd) => {
    mpd["clientId"] = this.state.clientId;
    this.props.tryAddMPD(mpd);
    this.toggleModal(
      `${npeBrandingName} Creation Submitted`,
      `Calculations will take a long time to complete. ${npeBrandingName} will be locked until it is completed.`
    );
  };

  render() {
    if (_.isEmpty(this.props.benefits) || _.isEmpty(this.props.catalogs)) {
      return "Loading...";
    }
    return (
      <Container className="main-container" fluid>
        <Row>
          <h1>Request {npeBrandingName}</h1>
        </Row>
        <Row>
          <Col>
            <MPDForm
              onSubmit={this.onSubmit}
              benefits={this.props.benefits}
              catalogs={this.props.catalogs}
              mpds={this.props.mpds}
            />
          </Col>
        </Row>
        <AlertModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalTitle}
          content={this.state.modalContent}
          action={() => history.goBack()}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    selectedClient: state.clients[ownProps.match.params.clientId],
    catalogs: state.catalogs.content,
    mpds: Object.values(state.mpds),
    benefits: Object.values(state.benefits),
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getBenefits,
  getCatalogsAction,
  getMPDs,
  tryAddMPD,
  populateBreadCrumbs,
  toggleSidebarVisibility,
})(AddMPD);
