import React, { Component } from "react";
import {
  Button,
  Col,
  Form,
  Container,
  FormGroup,
  Input,
  Row,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import history from "../../../history";
import "./MPD.css";
import _ from "lodash";
import {
  clearClients,
  getClient,
  getMPD,
  tryEditMPD,
  toggleSidebarVisibility,
  populateBreadCrumbs,
  getMPDExclusionReasons,
  excludeMPDCategories,
  getMpdSummaries,
} from "../../../actions";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import {
  getNPEBreadcrumbs,
  isVBRCurrentlyImplemented,
  linkFormatter,
  Role,
} from "../../../utils";
import { Link } from "react-router-dom";
import CatalogBenefitHeader from "./CatalogBenefitHeader";

class ViewEditMPD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: this.props.match.params.clientId,
      mpdId: this.props.match.params.mpdId,
      navLoaded: false,
      pristine: true,
      dtos: [],
      selectedReason: "null",
      selectDisabled: true,
      isAuthorized: _.some(this.props.roles, {
        name: Role.CLINICIAN.name,
      }),
    };
  }

  getExclusionReasons = () => {
    const optionsArray = [
      { value: null, label: "-Select One-" },
      { value: 0, label: "Remove Exclusion" },
    ];

    _.forEach(this.props.exclusionReasons, (reason) => {
      optionsArray.push({
        value: reason.id,
        label: `${reason.id} - ${reason.description}`,
      });
    });

    return optionsArray;
  };

  buildDTOs = (rows, isSelect) => {
    const dtos = this.state.dtos;
    if (isSelect) {
      // When we select an item, setup the dto appropriately and save to state
      _.forEach(rows, (row) => {
        dtos.push({
          categoryId: row.id,
          clientReferenceReportId: this.state.mpdId,
        });
      });
      this.setState({ dtos, selectDisabled: false });
    } else {
      // Otherwise, remove the selection from the state's dto array
      _.remove(dtos, (dto) => {
        const found = _.find(rows, { id: dto.categoryId });
        return found !== undefined;
      });

      // If all selections have been removed, set the selected reason back to default
      // disable the exclude reason select box, and set the form to pristine
      const selectedReason =
        dtos.length <= 0 ? "null" : this.state.selectedReason;
      const dtosEmpty = dtos.length <= 0;
      this.setState({
        dtos,
        pristine: dtosEmpty,
        selectedReason,
        selectDisabled: dtosEmpty,
      });
    }
  };

  handleReasonChange = (e) => {
    const selectedReason = e.currentTarget.value;
    const dtos = this.state.dtos;
    _.forEach(dtos, (dto) => {
      dto.systemCodeId = selectedReason;
    });
    // Don't allow the save button to be active if the selection is Select One.
    const pristine = selectedReason === "-Select One-";
    this.setState({ dtos, selectedReason, pristine });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.excludeMPDCategories(this.state.mpdId, this.state.dtos);
  };

  setupBreadCrumbs = () => {
    this.props.populateBreadCrumbs({
      crumbs: getNPEBreadcrumbs(this.props.selectedClient),
      finalCrumb: this.props.selectedMpd.name,
    });
  };

  getCategoryData = () => {
    this.props.mpdSummaries.forEach((c) => {
      if (c.categoryExcludedCode) {
        c.excludedReason =
          c.categoryExcludedCode + " - " + c.categoryExcludedDescription;
      } else if (!c.excludedReason) {
        c.excludedReason = "";
      }
    });

    return _.sortBy(this.props.mpdSummaries, function (mpdSummary) {
      return mpdSummary.categoryDescription.toLowerCase();
    });
  };

  componentDidMount() {
    this.props.getClient(this.state.clientId);
    this.props.getMPD(this.state.mpdId);
    this.props.getMPDExclusionReasons();
    this.props.getMpdSummaries(this.state.mpdId);
    this.props.toggleSidebarVisibility();
  }

  componentWillUnmount() {
    this.props.clearClients();
    this.props.toggleSidebarVisibility();
  }

  componentDidUpdate() {
    if (this.props.selectedClient && this.props.selectedMpd) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  selectAllHandler = (isSelect, rows) => {
    this.buildDTOs(rows, isSelect);
  };

  selectHandler = (row, isSelect) => {
    this.buildDTOs([row], isSelect);
  };

  getColumns = () => {
    const columns = [
      {
        dataField: "excludedReason",
        sort: true,
        text: "Exclude Reason",
        headerStyle: {
          width: "10%",
        },
        formatter: (content, row) => {
          if (row.gpiExcludedCount && row.gpiExcludedCount > 0) {
            return "Contains Excluded Drug Pairs";
          }
          return content;
        },
      },
      {
        dataField: "categoryDescription",
        sort: true,
        text: "Drug Category",
        formatter: (content) => {
          const target = {
            pathname: `/clients/${this.state.clientId}/mpds/${this.state.mpdId}/drugpairs`,
            state: { filters: { drugCategory: [content] } },
          };
          return linkFormatter(content, target);
        },
      },
      {
        dataField: "gpiExcludedCount",
        sort: true,
        text: "Excluded Drug Pairs",
      },
      {
        dataField: "tgtErrSummaryCount",
        sort: true,
        text: "Errored Drug Pairs",
      },
    ];

    return columns;
  };

  getDisabledRows = (categories) => {
    const currentlyImplemented = isVBRCurrentlyImplemented(
      this.props.selectedMpd
    );

    const rowsToDisable = _.filter(categories, (category) => {
      return category.gpiExcludedCount && category.gpiExcludedCount > 0;
    });

    return currentlyImplemented
      ? _.map(categories, "id")
      : _.map(rowsToDisable, "id");
  };

  render() {
    if (
      _.isEmpty(this.props.exclusionReasons) ||
      _.isEmpty(this.props.selectedMpd) ||
      _.isEmpty(this.props.mpdSummaries) ||
      !this.props.mpdsLoaded
    ) {
      return <h1>Loading...</h1>;
    }

    const categories = this.getCategoryData();

    const disabledRowIds = this.getDisabledRows(categories);

    const mpdIsLocked =
      this.props.selectedMpd.lockedFlag !== "N" || !this.state.isAuthorized;

    const viewEditPrepend = this.state.isAuthorized ? "Edit" : "View";

    const viewEditPath = `/clients/${this.state.clientId}/mpds/${this.state.mpdId}/drugpairs`;

    return (
      <Container className="main-container" fluid>
        <Form className="mpd-form">
          <Row>
            <Col>
              <h1>{`${viewEditPrepend} Priced Drug Categories`}</h1>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col className="d-flex justify-content-between">
              {this.state.isAuthorized && (
                <FormGroup className="d-flex flex-row">
                  <Col md={4} className="p-0">
                    <Label for="excludeReason">Exclude Reason</Label>
                  </Col>

                  <Col md={10} className="p-0">
                    <Input
                      type="select"
                      id="excludeReason"
                      onChange={this.handleReasonChange}
                      value={this.state.selectedReason}
                      disabled={this.state.selectDisabled}
                    >
                      {this.getExclusionReasons().map((reason) => {
                        return (
                          <option key={reason.value} value={reason.value}>
                            {reason.label}
                          </option>
                        );
                      })}
                    </Input>
                  </Col>
                </FormGroup>
              )}
              <CatalogBenefitHeader mpd={this.props.selectedMpd} />

              <div>
                <Button color="primary" tag={Link} to={viewEditPath}>
                  {`${viewEditPrepend} Drug Pairs`}
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <div className="mpdExcludeGrid">
                <RPSDataGrid
                  remote={{ cellEdit: true }}
                  keyField="id"
                  data={categories}
                  columns={this.getColumns()}
                  selectRow={{
                    mode: "checkbox",
                    onSelect: this.selectHandler,
                    onSelectAll: this.selectAllHandler,
                    hideSelectColumn: mpdIsLocked,
                    nonSelectable: disabledRowIds,
                  }}
                />
              </div>
            </Col>
          </Row>
          {this.state.isAuthorized && (
            <Row className="pt-3">
              <Col md={{ size: 2, offset: 4 }}>
                <Button
                  color="danger"
                  block
                  onClick={(e) => {
                    e.preventDefault();
                    history.goBack();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col md={2}>
                <Button
                  color="primary"
                  type="submit"
                  onClick={(e) => {
                    this.onSubmit(e);
                  }}
                  block
                  disabled={this.state.pristine || mpdIsLocked}
                >
                  Save
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.auth.currentUser.roles,
    selectedClient: state.clients[ownProps.match.params.clientId],
    selectedMpd: state.mpds[ownProps.match.params.mpdId],
    exclusionReasons: Object.values(state.exclusionReasons),
    mpdSummaries: state.mpdSummaries,
    headerType: state.mpdSummaries[0],
    mpdsLoaded: state.mpdsLoaded,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getMPD,
  tryEditMPD,
  toggleSidebarVisibility,
  populateBreadCrumbs,
  getMPDExclusionReasons,
  excludeMPDCategories,
  getMpdSummaries,
})(ViewEditMPD);
