import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col } from "reactstrap";
import {
  getFillFactorBaselineDates,
  tryGpi12FillFactorsSearch,
  tryUpdateGpi12FillFactor,
  populateBreadCrumbs,
  clearSidebarActions,
  populateSidebar,
  gpi12FillFactorsExport,
  clearFillFactors,
} from "../../../actions";
import { SIDEBAR_ITEMS } from "../Constants";
import _ from "lodash";
import {
  getDateString,
  HEADER_STYLES,
  dateRangeFormatter,
  dateFormatter,
  fillFactorFormatter,
} from "../../../utils";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import Gpi12FillFactorSearchForm from "./Gpi12FillFactorSearchForm";
import "./FillFactor.css";

class Gpi12FillFactor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortArray: [
        {
          dataField: "baselineDate",
          order: "desc",
        },
      ],
      searchTerms: {},
    };
    this.setupBreadCrumbs();
  }

  componentDidMount = () => {
    this.setupSidebar();
    this.props.getFillFactorBaselineDates();
    this.getPage();
  };

  componentWillUnmount = () => {
    this.props.clearFillFactors();
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/reporting",
          name: "Reporting",
          active: false,
        },
      ],
      finalCrumb: "GPI Fill Factors",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  getPage = () => {
    return this.props.tryGpi12FillFactorsSearch(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortArray
    );
  };

  getExport = () => {
    return this.props.gpi12FillFactorsExport(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortArray
    );
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  drugCatFormatter = (content, row) => {
    const title =
      "GPI(12) Fill Factor inherited from Drug Category Fill Factor";
    if (row.inherited) {
      return (
        <div className="highlight-bg" title={title}>
          {content}
        </div>
      );
    } else {
      return content;
    }
  };

  COLUMNS = [
    {
      dataField: "baselineDate",
      sort: true,
      text: "Claims Date Range",
      formatter: dateRangeFormatter,
    },
    {
      dataField: "catalogDate",
      sort: true,
      text: "Catalog Effective Date",
      editable: false,
      formatter: dateFormatter,
    },
    {
      dataField: "categoryFillFactor.category.description",
      sort: true,
      text: "Drug Category",
      headerStyle: {
        width: "25%",
      },
      formatter: this.drugCatFormatter,
      formatExtraData: false,
    },
    {
      dataField: "gpiName.gpiName",
      sort: true,
      text: "GPI(12) Drug Name",
      headerStyle: {
        width: "25%",
      },
    },
    {
      dataField: "gpi12",
      sort: true,
      text: "GPI(12)",
      headerAlign: "right",
      align: "right",
      editable: false,
    },
    {
      dataField: "memberCount",
      sort: true,
      text: "Unique Members",
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "annualFillFactor",
      sort: true,
      text: "Fill Factor",
      headerAlign: "right",
      align: "right",
      headerStyle: HEADER_STYLES.optionalEdit,
      formatter: fillFactorFormatter,
    },
  ];

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      var sortArray =
        this.state.sortArray.length > 1
          ? [_.last(this.state.sortArray)]
          : this.state.sortArray;
      sortArray.splice(0, 0, sortField);
      this.setState({ sortArray }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  getDisabledEditRows = () => {
    return _.map(this.props.fillFactors.content, (fillFactor) => {
      if (fillFactor.memberCount >= 5 || !this.state.isAuthorized) {
        return fillFactor.id;
      }
    });
  };

  render() {
    if (
      _.isEmpty(this.props.baselineDates) ||
      _.isEmpty(this.props.fillFactors)
    ) {
      return <h2>LOADING...</h2>;
    }

    const baselineDates = _.map(this.props.baselineDates, (ffdate) => {
      return {
        value: getDateString(ffdate.baselineDate),
        displayValue: getDateString(ffdate.baselineDate),
      };
    });

    return (
      <Container className="main-container" fluid>
        <Row className="mt-3">
          <Col>
            <Gpi12FillFactorSearchForm
              onSubmit={this.onSearchSubmit}
              baselineDates={baselineDates}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className={"d-flex justify-content-between"}>
            <Button color="primary" onClick={() => this.getExport()}>
              Export Fill Factors
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.fillFactors.totalElements}
              data={this.props.fillFactors.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    baselineDates: Object.values(state.fillFactorsBaselineDates),
    fillFactors: state.fillFactors,
  };
};

export default connect(mapStateToProps, {
  getFillFactorBaselineDates,
  tryGpi12FillFactorsSearch,
  tryUpdateGpi12FillFactor,
  gpi12FillFactorsExport,
  populateBreadCrumbs,
  clearSidebarActions,
  populateSidebar,
  clearFillFactors,
})(Gpi12FillFactor);
