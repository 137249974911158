import React from "react";
import { List } from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";

const AssignedAltsList = props => {
  if (_.isEmpty(props.alts)) {
    return (
      <span className="form-error">NOT FOUND IN CURRENT CLINICAL CATALOG</span>
    );
  }

  const randomKeys = [];

  const getRandomKey = () => {
    const newKey = Math.random();
    if (!_.find(randomKeys, newKey)) {
      randomKeys.push(newKey);
      return newKey;
    } else {
      return getRandomKey();
    }
  };

  return (
    <List type="unstyled">
      {_.map(props.alts, alt => (
        <li key={getRandomKey()}>
          {alt.name} <strong>GPI=</strong>
          {alt.gpi} &nbsp;
          <strong>MSC=</strong>
          {alt.multiSource} <strong>Admin=</strong>
          {alt.adminType} <strong>DpC=</strong>
          {alt.courseDayCount}
        </li>
      ))}
    </List>
  );
};

export default AssignedAltsList;

AssignedAltsList.defaultProps = {
  alts: []
};

AssignedAltsList.propTypes = {
  alts: PropTypes.array
};
