import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";

import {
  clearClients,
  getClient,
  getLinesOfBusiness,
  tryEditClient,
  tryAddNotes,
  tryDeleteNote,
  toggleSidebarVisibility
} from "../../../actions";
import { populateBreadCrumbs } from "../../../actions";
import "./Client.css";
import ClientForm from "./ClientForm";
import NoteForm from "../../Notes/NoteForm";

class EditClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLoaded: false,
      clientId: this.props.match.params.clientId
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false }
      ],
      finalCrumb: this.props.selectedClient.name
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getLinesOfBusiness();
    this.props.getClient(this.state.clientId);
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  }

  onSubmit = formValues => {
    this.props.tryEditClient(formValues);
  };

  onNoteSubmit = formValues => {
    formValues.clientId = this.props.selectedClient.id;
    this.props.tryAddNotes(formValues);
  };

  onNoteDelete = noteId => {
    this.props.tryDeleteNote(noteId);
  };

  render() {
    if (!this.props.linesOfBusiness || !this.props.selectedClient) {
      return <div>Loading...</div>;
    }
    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h1>Edit Client Demographics</h1>
            <ClientForm
              linesOfBusiness={this.props.linesOfBusiness}
              initialValues={{
                ...this.props.selectedClient,
                lineOfBusiness: this.props.selectedClient.lineOfBusiness.id
              }}
              onSubmit={this.onSubmit}
            />
          </Col>
          <Col>
            <NoteForm
              initialValues={{ notes: this.props.selectedClient.notes }}
              currentUser={this.props.currentUser}
              onSubmit={this.onNoteSubmit}
              onNoteDelete={this.onNoteDelete}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    linesOfBusiness: Object.values(state.linesOfBusiness),
    currentUser: state.auth.currentUser
  };
};

export default connect(
  mapStateToProps,
  {
    clearClients,
    getClient,
    getLinesOfBusiness,
    tryEditClient,
    tryAddNotes,
    tryDeleteNote,
    toggleSidebarVisibility,
    populateBreadCrumbs
  }
)(EditClient);
