import { getClinicalBackend } from "../../api/clinicalBackend";
import { GET_WEEK_OF_YEAR_LIST, CLEAR_WEEK_OF_YEAR_LIST } from "../types";
import { tryRefreshToken } from "../auth/authAction";
import { handleError } from "../../utils";

export const getWeekOfYearList = (
  startDate,
  weeksToProvide,
  goForwardInTime
) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    let endPoint = "/api/weeks/?";
    if (startDate) {
      endPoint += `startDate=${startDate}`;
    }
    if (weeksToProvide && weeksToProvide > 0) {
      endPoint += `&weeksToProvide=${weeksToProvide}`;
    }
    if (goForwardInTime) {
      endPoint += `&goForwardInTime=${goForwardInTime}`;
    }

    const weekOfYearList = await getClinicalBackend(authToken).get(endPoint);

    dispatch({
      type: GET_WEEK_OF_YEAR_LIST,
      payload: weekOfYearList.data
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearWeekOfYearList = () => dispatch => {
  dispatch({ type: CLEAR_WEEK_OF_YEAR_LIST });
};
