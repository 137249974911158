import React from "react";
import ReactDOM from "react-dom";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import PropTypes from "prop-types";

const LoadingModal = props => {
  return ReactDOM.createPortal(
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className={props.className}
        backdrop="static"
      >
        <ModalHeader>
          <span className="p-3">{props.header}</span>
        </ModalHeader>
        <ModalBody>
          <Row className="p-3">
            <Col className="align-content-center">
              <div>{props.content}</div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>,
    document.querySelector("#modal")
  );
};

LoadingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired
};

export default LoadingModal;
