import React from "react";
import PropTypes from "prop-types";
import { getDateString } from "../../utils";
import moment from "moment";

const NoteHeader = props => {
  const author = props.note.author ? props.note.author : props.currentUser;
  const createdDate = props.note.createdDate
    ? props.note.createdDate
    : moment.now();
  return (
    <React.Fragment>
      {`${author.firstName} ${author.lastName} - ${getDateString(
        createdDate,
        false,
        true
      )}`}
    </React.Fragment>
  );
};

NoteHeader.propTypes = {
  note: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired
};

export default NoteHeader;
