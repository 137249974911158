import {
  CLEAR_FILL_FACTORS,
  TRY_CATEGORY_FILL_FACTORS_SEARCH,
  TRY_GPI12_FILL_FACTORS_SEARCH,
} from "../actions/types";

const fillFactorsReducer = (state = {}, action) => {
  switch (action.type) {
    case TRY_CATEGORY_FILL_FACTORS_SEARCH:
    case TRY_GPI12_FILL_FACTORS_SEARCH:
      return action.payload;
    case CLEAR_FILL_FACTORS:
      return {};
    default:
      return state;
  }
};

export default fillFactorsReducer;
