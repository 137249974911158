import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { Button, Col, Form, FormGroup, Input, Row } from "reactstrap";
import { required } from "../../../utils/forms/validators";
import history from "../../../history";
import "./MPD.css";
import AlertModal from "../../Modal/AlertModal";
import moment from "moment";
import {
  npeBrandingName,
  renderInput,
  renderSelectInput,
  renderDatePicker,
  DATE_PICKER_FORMAT,
} from "../../../utils";
import _ from "lodash";
import { DateTime } from "luxon";
import "react-datepicker/dist/react-datepicker.css";

class MPDForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertOpen: false,
      benefitHasRebates: false,
      useRebates: false,
    };
  }

  catalogNameFormatter = (item) => {
    return `${item.catalogDesc} (published: ${item.publishedDate})`;
  };

  getCatalogs = () => {
    return this.props.catalogs.map((catalog) => {
      catalog.displayName = this.catalogNameFormatter(catalog);
      return catalog;
    });
  };

  toggleAlertModal = (title, content) => {
    this.setState({
      isAlertOpen: !this.state.isAlertOpen,
      alertModalTitle: title,
      alertModalContent: content,
    });
  };

  isDuplicateName = (existingMpds, candidateMpd) => {
    const duplicates = existingMpds.filter(
      (m) =>
        !candidateMpd.name.localeCompare(m.name, "en", {
          sensitivity: "base",
        }),
    );
    return duplicates.length > 0;
  };

  getDuplicateBenefitAndCatalog = (existingMpds, candidateMpd) => {
    // duplicate when benefit, catalog and request date are the same.
    const duplicates = existingMpds.filter(
      (m) =>
        m.benefitId === candidateMpd.benefitId &&
        moment().startOf("day").isSame(moment(m.requestDate).startOf("day")),
    );
    if (duplicates.length > 0) {
      return duplicates[0];
    } else {
      return undefined;
    }
  };

  onSubmit = (formValues) => {
    const mpd = {
      name: formValues.mpdName.trim(),
      benefitId: parseInt(formValues.benefit),
      catalogDate: DateTime.fromFormat(
        formValues.effectiveDate,
        DATE_PICKER_FORMAT,
      ).toISODate(),
      // if the selected benefit has rebate information, use the user selection for HCA/LCA matching
      useRebates: this.state.benefitHasRebates ? this.state.useRebates : false,
      hasRebates: this.state.benefitHasRebates,
    };

    const duplicateSelection = this.getDuplicateBenefitAndCatalog(
      this.props.mpds,
      mpd,
    );
    if (this.isDuplicateName(this.props.mpds, mpd)) {
      this.toggleAlertModal(
        "Duplicate Name",
        `The ${npeBrandingName} Name must be unique. This name is already in use for this client.`,
      );
    } else if (duplicateSelection) {
      this.toggleAlertModal(
        "Duplicate Plan Benefit for Catalog",
        `The selected combination of plan benefit and catalog already exists for ${duplicateSelection.name}`,
      );
    } else {
      this.props.onSubmit(formValues, mpd);
    }
  };

  onBenefitSelect = (e) => {
    const selectedBenefitId = e.currentTarget.value;
    const selectedBenefit = _.find(this.props.benefits, {
      id: parseInt(selectedBenefitId),
    });
    const hasRebates = _.find(selectedBenefit.configFiles, {
      type: "FF",
    }).countsByStatus.rebatePERCENT;
    this.setState({ benefitHasRebates: hasRebates ? true : false });
  };

  renderRadios = ({ input, checked, label }) => {
    return (
      <>
        <FormGroup check>
          <Input
            {...input}
            type="radio"
            checked={checked}
            onChange={() => {
              this.setState({ useRebates: !this.state.useRebates });
            }}
          />
          <label>{label}</label>
        </FormGroup>
      </>
    );
  };

  render() {
    const { invalid, submitting, pristine } = this.props;
    return (
      <Form
        className="mpd-form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        autoComplete="off"
      >
        <Row>
          <Col>
            <FormGroup>
              <Field
                id="mpdName"
                name="mpdName"
                type="text"
                placeholder={`${npeBrandingName} Name`}
                required
                validate={[required]}
                component={renderInput}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Field
                id="benefit"
                name="benefit"
                label="Select client's Benefit"
                items={this.props.benefits}
                valueProp="id"
                displayProp="benefitName"
                keyProp="id"
                validate={[required]}
                required
                component={renderSelectInput}
                onChange={this.onBenefitSelect}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Field
                id="effectiveDate"
                name="effectiveDate"
                placeholder="Clinical Catalog Effective Published Date"
                validate={[required]}
                required
                component={renderDatePicker}
                maxDate={DateTime.now().toJSDate()}
              />
            </FormGroup>
          </Col>
        </Row>
        {this.state.benefitHasRebates && (
          <Row>
            <Col>
              <FormGroup tag="fieldset">
                <legend>HCA/LCA Matching:</legend>
                <Field
                  id="useRebates"
                  name="useRebates"
                  label="Total Cost"
                  component={this.renderRadios}
                  value={false}
                  type="radio"
                  checked={!this.state.useRebates}
                />
                <Field
                  id="useRebates"
                  name="useRebates"
                  label="Total Cost Net of Rebates"
                  component={this.renderRadios}
                  value={true}
                  type="radio"
                  checked={this.state.useRebates}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={4} />
          <Col md={2}>
            <Button
              color="danger"
              block
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col md={2}>
            <Button
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Submit Request
            </Button>
          </Col>
          <Col md={4} />
        </Row>
        <AlertModal
          isOpen={this.state.isAlertOpen}
          toggle={this.toggleAlertModal}
          header={this.state.alertModalTitle}
          content={this.state.alertModalContent}
        />
      </Form>
    );
  }
}

export default reduxForm({ form: "mpdForm" })(MPDForm);
