import React, { Component } from "react";
import { connect } from "react-redux";
import { getClinicalSidebarItems } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  getWorkbook,
  tryWorkbookErrorSearch,
  clearWorkbookErrors,
  getNDCPricesAction,
  clearNdcPrices,
  getDistinctPairingTypesForErrors,
} from "../../../actions";
import _ from "lodash";
import { Container, Row, Col, Button } from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import WorkbookEntrySearchForm from "./WorkbookEntrySearchForm";
import {
  getDemoColumnHeader,
  isDemoUser,
  obfuscationFormatter,
  OBFUSCATION_COLUMNS,
  pairingTypeFormatter,
  gpiNDCHeaderFormatter,
  gpiNDCFormatter,
  fullYesNoFormatter,
  getDrugNameCSSClass,
} from "../../../utils";
import NDCPriceModal from "../../Modal/NDCPriceModal";

class WorkbookErrors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      wbId: this.props.match.params.wbId,
      isDemoUser: isDemoUser(this.props.roles),
      breadcrumbsLoaded: false,
      searchTerms: { names: [], pdcs: [], gpis: [], pairingTypes: [] },
      ndcPriceModalOpen: false,
      drugData: {
        gpi: "",
        msc: "",
        commonDrugName: "",
      },
      pairingTypes: [],
    };
  }

  componentDidMount = () => {
    this.setupSidebar();
    this.props
      .getDistinctPairingTypesForErrors(this.state.wbId)
      .then((result) => this.setState({ pairingTypes: result }));
    this.props.getWorkbook(this.state.wbId);
    this.getPage();
  };

  componentDidUpdate = () => {
    if (
      !this.state.breadcrumbsLoaded &&
      !_.isEmpty(this.props.selectedWorkbook)
    ) {
      this.setupBreadCrumbs();
      this.setState({ breadcrumbsLoaded: true });
    }
  };

  componentWillUnmount = () => {
    this.props.clearWorkbookErrors();
  };

  setupSidebar = () => {
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  setupBreadCrumbs = () => {
    const selectedWorkbook = this.props.selectedWorkbook;
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/workbooks",
          name: "Clinical Pairings Workbooks",
          active: false,
        },
        {
          to: `/workbooks/${selectedWorkbook.id}`,
          name: selectedWorkbook.name,
          active: false,
        },
      ],
      finalCrumb: "Errored and Dropped Pairs",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  getPage = () => {
    this.props.tryWorkbookErrorSearch(
      this.state.wbId,
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField,
    );
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  targetDrugNameFormatter = (_, row) => {
    let drugName = null;

    if (
      row.pairingType === "NDC_TO_GPI_MSC" ||
      row.pairingType === "NDC_TO_NDC"
    ) {
      drugName = row.tgtNdcName;
    } else if (row.targetMedicationName !== null) {
      drugName = row.targetMedicationName.commonName;
    }

    if (!drugName) {
      return null;
    }

    return (
      <Button
        className={getDrugNameCSSClass(row.tgtNewStartsOnly)}
        color="link"
        onClick={() => this.handleTargetDrugNameOnClick(row)}
      >
        {drugName}
      </Button>
    );
  };

  altDrugNameFormatter = (_, row) => {
    let drugName = null;

    if (
      row.pairingType === "NDC_TO_NDC" ||
      row.pairingType === "GPI_MSC_TO_NDC"
    ) {
      drugName = row.altNdcName;
    } else if (row.altMedicationName !== null) {
      drugName = row.altMedicationName.commonName;
    }

    if (!drugName) {
      return null;
    }

    return (
      <Button
        className={getDrugNameCSSClass(row.altNewStartsOnly)}
        color="link"
        onClick={() => this.handleAltDrugNameOnClick(row)}
      >
        {drugName}
      </Button>
    );
  };

  getNdcPrices = (targetGpi, targetMsc) => {
    this.props.getNDCPricesAction(targetGpi, targetMsc);
  };

  handleTargetDrugNameOnClick = (row) => {
    const drugData = {
      gpi: row.tgtGpi,
      msc: row.tgtMultiSource,
      commonDrugName: row.targetMedicationName.commonName,
    };

    if (row.pairingType !== "GPI_TO_GPI") {
      drugData.ndc = row.tgtNdc;
    }

    this.setState({
      drugData: drugData,
    });

    this.getNdcPrices(row.tgtGpi, row.tgtMultiSource);
    this.toggleNdcPriceModal();
  };

  handleAltDrugNameOnClick = (row) => {
    const drugData = {
      gpi: row.altGpi,
      msc: row.altMultiSource,
      commonDrugName: row.altMedicationName.commonName,
    };

    if (row.pairingType !== "GPI_TO_GPI") {
      drugData.ndc = row.altNdc;
    }

    this.setState({
      drugData: drugData,
    });

    this.getNdcPrices(row.altGpi, row.altMultiSource);
    this.toggleNdcPriceModal();
  };

  toggleNdcPriceModal = () => {
    if (this.state.ndcPriceModalOpen) {
      this.props.clearNdcPrices();
    }
    this.setState({
      ndcPriceModalOpen: !this.state.ndcPriceModalOpen,
    });
  };

  getColumns = () => {
    const selectedWorkbook = this.props.selectedWorkbook;
    return [
      {
        dataField: "errorMsg.action",
        sort: true,
        text: "Dropped Reason",
      },
      {
        dataField: "errorMsg.description",
        sort: true,
        text: "Message",
      },
      {
        dataField: "pairingType",
        text: "Pairing Type",
        formatter: pairingTypeFormatter,
      },
      {
        dataField: "pairNewStartsOnly",
        text: "Pair NSO",
        sort: true,
        formatter: fullYesNoFormatter,
      },
      {
        dataField: "pairingCode",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.PDC,
          "PDC",
          this.state.isDemoUser,
        ),
        sort: true,
        formatter: this.state.isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.PDC,
      },
      {
        dataField: "tgtGpi",
        sort: true,
        text: "",
        headerFormatter: () =>
          gpiNDCHeaderFormatter(
            false,
            selectedWorkbook.decisionGrid.hasNDCDPOs,
          ),
        formatter: gpiNDCFormatter,
        formatExtraData: "tgtNdc",
      },
      {
        dataField: "tgtMultiSource",
        sort: true,
        text: "Target MSC",
      },
      {
        dataField: "targetMedicationName.commonName",
        sort: true,
        text: "Target Drug Name",
        formatter: this.targetDrugNameFormatter,
      },
      {
        dataField: "tgtType",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.TAB,
          "T/A/B",
          this.state.isDemoUser,
        ),
        formatter: this.state.isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.TAB,
        sort: true,
      },
      {
        dataField: "tgtDacon",
        text: "Target DACon",
        sort: true,
      },
      {
        dataField: "altGpi",
        sort: true,
        text: "",
        headerFormatter: () =>
          gpiNDCHeaderFormatter(true, selectedWorkbook.decisionGrid.hasNDCDPOs),
        formatter: gpiNDCFormatter,
        formatExtraData: "altNdc",
      },
      {
        dataField: "altMultiSource",
        sort: true,
        text: "Alt. MSC",
      },
      {
        dataField: "altMedicationName.commonName",
        sort: true,
        text: "Alternative Drug Name",
        formatter: this.altDrugNameFormatter,
      },
      {
        dataField: "altType",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.TAB,
          "T/A/B",
          this.state.isDemoUser,
        ),
        formatter: this.state.isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.TAB,
        sort: true,
      },
      {
        dataField: "altDacon",
        text: "Alt DACon",
        sort: true,
      },
    ];
  };

  render = () => {
    const selectedWorkbook = this.props.selectedWorkbook;
    if (!selectedWorkbook) {
      return <h1>LOADING...</h1>;
    }

    if (_.isEmpty(this.props.errors) || _.isEmpty(this.state.pairingTypes)) {
      return <h1 className="ps-4">No Results Found.</h1>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>{selectedWorkbook.name} - Errored and Dropped Pairs</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <WorkbookEntrySearchForm
              showMissingValidNDCCheckbox={false}
              onSubmit={this.onSearchSubmit}
              pairingTypes={this.state.pairingTypes}
              hasNDCDPOs={selectedWorkbook.decisionGrid.hasNDCDPOs}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.errors.totalElements}
              data={this.props.errors.content}
              columns={this.getColumns()}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <NDCPriceModal
          isOpen={this.state.ndcPriceModalOpen}
          toggle={this.toggleNdcPriceModal}
          ndcPrices={this.props.ndcPrices}
          clearAction={this.props.clearNdcPrices}
          drugData={this.state.drugData}
          showSequence={false}
        />
      </Container>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    selectedWorkbook: state.workbookEntries.selectedWorkbook,
    errors: state.workbookErrors,
    ndcPrices: Object.values(state.ndcPrices),
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  getWorkbook,
  clearWorkbookErrors,
  tryWorkbookErrorSearch,
  getNDCPricesAction,
  clearNdcPrices,
  getDistinctPairingTypesForErrors,
})(WorkbookErrors);
