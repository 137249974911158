import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { getCagcentricCounts } from "../../../actions";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";

class MembershipExpandRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15
    };
  }

  componentDidMount = () => {
    this.getPage();
  };

  COLUMNS = [
    {
      dataField: "carrierId",
      text: "Carrier Account Group",
      formatter: (content, row) => {
        return `${row.carrierId} | ${row.accountCode} | ${row.groupId}`;
      }
    },
    {
      dataField: "activeMemberCount",
      text: "Active Members"
    },
    {
      dataField: "termedSubscriberCount",
      text: "Termed Subscribers"
    },
    {
      dataField: "termedMemberCount",
      text: "Termed Members"
    },
    {
      dataField: "membersWithNoEligibilityCount",
      text: "Members with no Eligibility"
    }
  ];

  getPage() {
    return this.props.getCagcentricCounts(
      this.props.reportId,
      this.state.currPage - 1,
      this.state.sizePerPage
    );
  }

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage }) => {
    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  render = () => {
    if (_.isEmpty(this.props.cagcentricCounts)) {
      return <h1>LOADING...</h1>;
    }

    return (
      <>
        <Row className="m-5">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.cagcentricCounts.totalElements}
              data={this.props.cagcentricCounts.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return { cagcentricCounts: state.cagcentricCounts[ownProps.reportId] };
};

export default connect(
  mapStateToProps,
  { getCagcentricCounts }
)(MembershipExpandRow);
