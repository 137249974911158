import React from "react";
import ReactDOM from "react-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
/**
 * Need to figure out a better way of handling the actions here. Right now it needs an action that takes at least one param
 * This makes this component a little more rigid than I would like.
 */
const ConfirmationModal = props => {
  return ReactDOM.createPortal(
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className={props.className}
        size={props.size}
      >
        <ModalHeader>{props.header}</ModalHeader>
        <ModalBody>{props.content ? props.content : props.children}</ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              if (props.cancelAction) {
                props.cancelAction(props.objId);
              }
              props.toggle();
            }}
          >
            {props.cancelText ? props.cancelText : "Cancel"}
          </Button>
          <Button
            color="danger"
            onClick={() => {
              if (props.parentId) {
                props.action(props.objId, props.parentId);
              } else if (props.modalFormVals) {
                props.action(props.modalFormVals, props.objId);
              } else if (props.formEvent) {
                props.action(props.formEvent);
              } else if (props.objId) {
                props.action(props.objId);
              } else {
                props.action();
              }
              props.toggle();
            }}
          >
            {props.confirmText ? props.confirmText : "Yes"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>,
    document.querySelector("#modal")
  );
};

export default ConfirmationModal;
