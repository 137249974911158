import _ from "lodash";

export const PRODUCT_STATUS = {
  NOT_TO_BE_REVIEWED: "Not to be Reviewed",
  INITIAL_REVIEW_PENDING: "Initial Review Pending",
  FINAL_REVIEW_PENDING: "Final Review Pending",
  FINAL_REVIEW_COMPLETED: "Final Review Completed",
};

export const PRODUCT_STATUS_LIST = _.map(PRODUCT_STATUS, (value, key) => {
  return {
    value: key,
    label: value,
  };
});
