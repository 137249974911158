import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import {
  getDashedGPI,
  getRandomKeyFromString,
  getRawFloat,
} from "../../../../utils";
import _ from "lodash";
import { PairingType } from "../PairingTypes";
import NDCRebateModal from "../../../Modal/NDCRebateModal";
import GpiCostDetails from "./GpiCostDetails";

const VbrGpiCostTabs = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [rebateModalOpen, setRebateModalOpen] = useState(false);
  const [rebateNDCs, setRebateNDCs] = useState([]);
  const [modalTitleData, setmodalTitleData] = useState([]);

  const PHARMACY_TYPE = {
    RETAIL: { days: 30, key: "Retail", label: "Day" },
    MAIL: { days: 90, key: "Mail", label: "Mail" },
    RETAIL90: { days: 90, key: "90Retail", label: "Retail" },
  };

  const VBR_GPI_TYPE = {
    TARGET: "Target",
    LCA: "LCA",
    ALT: "Alternative",
  };

  const toggleRebateModal = (ndcs, drugData) => {
    setRebateNDCs(ndcs);
    setmodalTitleData(drugData);
    setRebateModalOpen(!rebateModalOpen);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const formatQuantity = (dacon, days) => {
    const quantity = dacon * days;
    let formatted = quantity.toFixed(2);
    if (formatted % 1 === 0) {
      formatted = Math.floor(formatted);
    }
    return formatted;
  };

  const getCostData = (vbrGpi, pharmType, includeBuyUp = false) => {
    const gpiCost = vbrGpi.gpiCost;
    const costData = {
      quantity: formatQuantity(gpiCost.dacon, pharmType.days),
      planPay: `$${gpiCost[`planPay${pharmType.key}`]}`,
      copay: `$${gpiCost[`copay${pharmType.key}`]}`,
      totalCost: `$${gpiCost[`totalCost${pharmType.key}`]}`,
    };

    if (includeBuyUp) {
      costData["buyUp"] = `$${vbrGpi[`membBuyUp${pharmType.key}`]}`;
    }

    if (props.includeRebates) {
      costData["planPayNetRebate"] = `$${
        gpiCost[`planPayNetRebate${pharmType.days.toString()}${pharmType.key}`]
      }`;
      costData["rebate"] = `$${
        gpiCost[`rebateTotal${pharmType.days.toString()}${pharmType.key}`]
      }`;
    }
    return costData;
  };

  const getVbrGpiDisplayData = (vbrGpis, vbrGpiType) => {
    let formattedData = _.map(vbrGpis, (vbrGpi) => {
      const gpiCost = vbrGpi.gpiCost;
      const includeBuyUp = vbrGpiType === VBR_GPI_TYPE.TARGET;

      let drugName = "";
      switch (vbrGpiType) {
        case VBR_GPI_TYPE.TARGET:
          drugName = props.targetGpiRowData.targetedGpiName;
          break;
        case VBR_GPI_TYPE.LCA:
          drugName = props.targetGpiRowData.assignedLcaName;
          break;
        case VBR_GPI_TYPE.ALT:
          drugName = gpiCost.determinedDrugName;
          break;
        default:
          drugName = "";
      }

      const drugData = {
        artasSequence: null,
        gpi: getDashedGPI(gpiCost.gpi),
        name: drugName,
        dacon: gpiCost.dacon,
        msc: gpiCost.multiSource,
        tierLevel: gpiCost.tierLevel ? gpiCost.tierLevel : "null",
        nonDashedGpi: gpiCost.gpi,
      };

      const retailCosts = getCostData(
        vbrGpi,
        PHARMACY_TYPE.RETAIL,
        includeBuyUp
      );

      const mailCosts = getCostData(vbrGpi, PHARMACY_TYPE.MAIL, includeBuyUp);

      const retail90Present =
        gpiCost.copay90Retail &&
        gpiCost.planPay90Retail &&
        gpiCost.totalCost90Retail;

      const retail90Costs = retail90Present
        ? getCostData(vbrGpi, PHARMACY_TYPE.RETAIL90, includeBuyUp)
        : null;

      const pairingType = props.targetGpiRowData.pairingType;

      return {
        id: vbrGpi.id.toString(),
        drugData: drugData,
        retailCosts: retailCosts,
        mailCosts: mailCosts,
        retail90Costs: retail90Costs,
        messages: vbrGpi.messages,
        ndcData:
          pairingType !== PairingType.GPI_TO_GPI && gpiCost.ndc !== null
            ? `${gpiCost.ndc}, ${gpiCost.ndcName}`
            : null,
      };
    });
    return formattedData;
  };

  const getAltVbrGpiData = (altVbrGpis) => {
    const sortedAltCosts = _.sortBy(altVbrGpis, (altCost) => {
      return altCost.gpiCost.totalCostRetail;
    });
    let formattedData = getVbrGpiDisplayData(sortedAltCosts, VBR_GPI_TYPE.ALT);

    let artasSequenceCount = 2;

    // Loop through and assign up to 3 sequence numbers/errors
    _.forEach(formattedData, (drugDetail) => {
      const hasErrorMessage = _.some(drugDetail.messages, { subType: "ERR" });
      // Check to see if alt has a higher or equal cost than given target. If so, add artas label error
      if (
        props.targetGpiData.gpiCost.totalCostRetail <=
          getRawFloat(drugDetail.retailCosts.totalCost) &&
        !hasErrorMessage
      ) {
        drugDetail.messages.push({
          id: getRandomKeyFromString("random"),
          subType: "ERR",
          description: "ERROR: Alt. Priced Higher than or equal to Target",
        });
      }

      // Add artas sequence to alts that are not in error state
      if (artasSequenceCount <= 4 && !hasErrorMessage) {
        drugDetail.drugData.artasSequence = artasSequenceCount;
        artasSequenceCount++;
      }

      const sortFunc = (item, property) => {
        let nullReturn = "";

        let val = _.get(item, property, null);
        if (property !== "artasSequence") {
          // Total cost is a string with $, which messes up comparison logic
          val = getRawFloat(val);
        } else {
          // a null artasSequence means the gpi has errors or sequencing is finished
          nullReturn = 999999;
        }

        return val == null ? nullReturn : val;
      };

      const sequenceErroredGpiCosts = _.sortBy(
        _.filter(formattedData, (drugDetail) => {
          return _.some(drugDetail.messages, {
            description: "ERROR: Alt. Priced Higher than or equal to Target",
          });
        }),
        (drugDetail) => {
          return getRawFloat(drugDetail.retailCosts.totalCost);
        }
      );

      const erroredGpiCosts = _.sortBy(
        _.filter(formattedData, (drugDetail) => {
          return (
            _.some(drugDetail.messages, { subType: "ERR" }) &&
            !_.some(drugDetail.messages, {
              description: "ERROR: Alt. Priced Higher than or equal to Target",
            })
          );
        }),
        (drugDetail) => {
          return getRawFloat(drugDetail.retailCosts.totalCost);
        }
      );

      const nonErrored = _.orderBy(
        _.filter(formattedData, (drugDetail) => {
          return !_.some(drugDetail.messages, { subType: "ERR" });
        }),
        [
          (drugDetail) => sortFunc(drugDetail.drugData, "artasSequence"),
          (drugDetail) => sortFunc(drugDetail.retailCosts, "totalCost"),
        ]
      );

      formattedData = [
        ...nonErrored,
        ...sequenceErroredGpiCosts,
        ...erroredGpiCosts,
      ];
    });
    return formattedData;
  };

  const doAlternatesExist = !_.isEmpty(props.targetNonLCAGpiData);

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            {props.tabOneName}
          </NavLink>
        </NavItem>
        {doAlternatesExist && (
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => {
                toggle("2");
              }}
            >
              {props.tabTwoName}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <GpiCostDetails
            vbrGpiDetails={getVbrGpiDisplayData(
              [props.targetGpiData],
              VBR_GPI_TYPE.TARGET
            )}
            gpiType={VBR_GPI_TYPE.TARGET}
            includeRebates={props.includeRebates}
            rebateNdcPrices={props.targetGpiData.gpiCost.rebateNdcPrices}
            rebateModalCallback={toggleRebateModal}
            includesBuyUp
          />

          {props.targetLCAGpiData.gpiCost && (
            <>
              <GpiCostDetails
                vbrGpiDetails={getVbrGpiDisplayData(
                  [props.targetLCAGpiData],
                  VBR_GPI_TYPE.LCA
                )}
                gpiType={VBR_GPI_TYPE.LCA}
                includeRebates={props.includeRebates}
                rebateNdcPrices={props.targetLCAGpiData.gpiCost.rebateNdcPrices}
                rebateModalCallback={toggleRebateModal}
              />
            </>
          )}
        </TabPane>
        {doAlternatesExist && (
          <TabPane tabId="2">
            <GpiCostDetails
              vbrGpiDetails={getAltVbrGpiData(props.targetNonLCAGpiData)}
              gpiType={VBR_GPI_TYPE.ALT}
            />
          </TabPane>
        )}
      </TabContent>
      <NDCRebateModal
        isOpen={rebateModalOpen}
        toggle={toggleRebateModal}
        ndcPrices={rebateNDCs}
        drugData={modalTitleData}
      />
    </div>
  );
};

export default VbrGpiCostTabs;
