import { GET_REPORT_CLAIMS_DATE_RANGE } from "../actions/types";

const claimsDateRangeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REPORT_CLAIMS_DATE_RANGE:
      return action.payload;
    default:
      return state;
  }
};

export default claimsDateRangeReducer;
