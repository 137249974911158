import _ from "lodash";
import { GET_AHFS_GPIS } from "../actions/types";

const ahfsGpisReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AHFS_GPIS:
      const ahfsGpis = [];
      let count = 1;
      _.forEach(action.payload, (ahfs) => {
        _.forIn(ahfs.gpiNamesByMultiSource, (value, key) => {
          const ahfsGpi = { ...ahfs };
          ahfsGpi.ahfsMultiSource = key;
          ahfsGpi.ahfsCommonName = value;
          ahfsGpi.key = count;

          count++;

          ahfsGpis.push(ahfsGpi);
        });
      });
      return ahfsGpis;
    default:
      return state;
  }
};

export default ahfsGpisReducer;
