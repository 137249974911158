import {
  CLEAR_DDNS,
  CLEAR_DPOS,
  GET_DECISION_GRID,
  GET_DRUG_PAIRING_OPTIONS,
  SEARCH_DDNS,
  UPDATE_DPO,
} from "../actions/types";
import _ from "lodash";

const initialState = {
  dpos: {},
  selectedGrid: null,
  searchResults: [],
  zeroResultsFound: false,
};

const drugPairingOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DECISION_GRID:
      return { ...state, selectedGrid: action.payload };
    case GET_DRUG_PAIRING_OPTIONS:
      return { ...state, dpos: action.payload };
    case SEARCH_DDNS:
      return {
        ...state,
        searchResults: action.payload,
        zeroResultsFound: _.isEmpty(action.payload),
      };
    case UPDATE_DPO:
      const dpos = { ...state.dpos };
      const dpoIndex = _.findIndex(dpos.content, { id: action.payload.id });
      dpos.content[dpoIndex] = action.payload;
      return { ...state, dpos };
    case CLEAR_DDNS:
      return { ...state, searchResults: [], zeroResultsFound: false };
    case CLEAR_DPOS:
      return { initialState };
    default:
      return state;
  }
};

export default drugPairingOptionsReducer;
