import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import "./BreadCrumbs.css";

export class Breadcrumbs extends Component {
  getBreadCrumbs = () => {
    if (this.props.breadCrumbs) {
      return this.props.breadCrumbs;
    } else {
      let empty = {
        crumbs: [{ to: "", name: "", active: false }],
        finalCrumb: "",
      };
      return empty;
    }
  };

  breadcrumbs = () => {
    const breadCrumbs = this.getBreadCrumbs();
    var showFinalBreadcrumbItem = false;
    if (breadCrumbs.finalCrumb !== "") {
      showFinalBreadcrumbItem = true;
    }

    const sbClosedOrHidden =
      !this.props.sidebarOpen || !this.props.sidebarVisible;
    let sidebarOpenClass = "";
    if (sbClosedOrHidden) {
      sidebarOpenClass = "breadcrumbSidebarClosed";
    }

    return (
      <Breadcrumb className={`breadcrumb ${sidebarOpenClass}`}>
        {breadCrumbs.crumbs.map((item, index) => {
          const biClass = index === 0 && sbClosedOrHidden ? "firstCrumb" : "";
          return (
            <BreadcrumbItem
              key={index}
              tag={Link}
              to={{ pathname: item.to, state: item.state ? item.state : [] }}
              active={item.active}
              className={biClass}
            >
              {item.name}
            </BreadcrumbItem>
          );
        })}
        {showFinalBreadcrumbItem ? (
          <BreadcrumbItem active>{breadCrumbs.finalCrumb}</BreadcrumbItem>
        ) : undefined}
      </Breadcrumb>
    );
  };

  render() {
    return this.breadcrumbs();
  }
}

const mapStateToProps = (state) => {
  return {
    breadCrumbs: state.breadcrumbs.breadCrumbs,
    sidebarOpen: state.sidebar.open,
    sidebarVisible: state.sidebar.visible,
  };
};

export default connect(mapStateToProps)(Breadcrumbs);

/**
|--------------------------------------------------
| Usage instructions
|--------------------------------------------------

To implement breadcrumbs do the following within your component:

1.  import { populateBreadCrumbs } from "../../../actions";

2.  Create this function (this is an example, modify as needed):
  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "catalogs", name: "Clinical Catalog", active: false }
      ],
      finalCrumb: this.getCatalogDescription()
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

Note:  the breadCrumbs array is how you want things to look in the displayed breadcrumb.
Putting the active: to false means it will not be a clickable Link.

3.  Within the componentDidMount() function put this statement:
this.setupBreadCrumbs();

4.  Within the connect() function put this statement:
, populateBreadCrumbs

See Clients.js for full implementation example.

*/
