import _ from "lodash";

export const Role = {
  STANDARD_USER: { name: "STANDARD_USER" },
  ADMIN_USER: { name: "ADMIN_USER" },
  CLINICIAN: { name: "CLINICIAN" },
  CLINICAL_APPROVER: { name: "CLINICAL_APPROVER" },
  CLIENT_ADMIN: { name: "CLIENT_ADMIN" },
  MPD_TESTER: { name: "MPD_TESTER" },
  DEMO: { name: "DEMO_USER" },
};

export const authCheck = (roles, roleName) => {
  return _.some(roles, { name: roleName });
};

export const isDemoUser = (roles) => {
  return _.some(roles, { name: Role.DEMO.name });
};
