import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import RPSDataGrid from "../DataGrid/RPSDataGrid";
import { percentageFormatter } from "../../utils/dataGrid/formatters";
import "./NDCPriceModal.css";
import Draggable from "react-draggable";
import { FaArrowsAlt } from "react-icons/fa";

const NDCRebateModal = (props) => {
  const COLUMNS = [
    {
      dataField: "planFormulary.ndc",
      text: "NDC #",
    },
    {
      dataField: "planFormulary.ndcName",
      sort: true,
      text: "NDC Drug Name",
    },
    {
      dataField: "planFormulary.rebateValue",
      sort: true,
      text: "Rebated Percent",
      formatter: percentageFormatter,
    },
  ];

  return ReactDOM.createPortal(
    <Draggable>
      <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
        <ModalHeader
          className="ar-movable-modal-background-color"
          toggle={() => {
            props.toggle([], []);
          }}
        >
          <FaArrowsAlt />
          <span>
            {!_.isEmpty(props.drugData) &&
              `   Valid AR-NDCs used in rebate calculation for: ${props.drugData.name}
              (${props.drugData.nonDashedGpi}/${props.drugData.msc})`}
          </span>
        </ModalHeader>
        <ModalBody>
          <Container className="data-grid-scroll-container">
            <Row className="header-row">
              <Col>
                <div>
                  {_.isEmpty(props.ndcPrices) && (
                    <h3>Pricing does not contain Rebated NDCs.</h3>
                  )}
                  {!_.isEmpty(props.ndcPrices) && (
                    <RPSDataGrid
                      keyField="id"
                      data={_.isArray(props.ndcPrices) ? props.ndcPrices : []}
                      columns={COLUMNS}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Row />

          <Button
            color="primary"
            onClick={() => {
              props.toggle([], []);
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>,
    document.querySelector("#modal")
  );
};

export default NDCRebateModal;
