import {
  TRY_ADDITIONAL_TARGET_DRUG_SEARCH,
  CLEAR_ADDITIONAL_TARGET_DRUGS,
  GET_LATEST_CATALOG,
  TRY_PROVIDER_TARGET_SEARCH,
  ADD_ADDITIONAL_TARGET_SAVE_ERRORS,
} from "../actions/types";
import _ from "lodash";

const initialState = {
  targets: {},
  providerTargetSearchResults: [],
  latestCatalog: null,
  erroredTargetsOnSave: [],
};

const additionalTargetDrugReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRY_ADDITIONAL_TARGET_DRUG_SEARCH:
      return { ...state, targets: action.payload };
    case TRY_PROVIDER_TARGET_SEARCH:
      let resultCount = action.payload.content.length + 1;
      const transformed = _.map(action.payload.content, (target) => {
        resultCount--;
        return {
          ...target,
          key: target.gpi + target.multiSource + resultCount,
        };
      });
      return { ...state, providerTargetSearchResults: transformed };
    case GET_LATEST_CATALOG:
      return { ...state, latestCatalog: action.payload };
    case ADD_ADDITIONAL_TARGET_SAVE_ERRORS:
      return {
        ...state,
        erroredTargetsOnSave: action.payload,
      };
    case CLEAR_ADDITIONAL_TARGET_DRUGS:
      return initialState;
    default:
      return state;
  }
};

export default additionalTargetDrugReducer;
