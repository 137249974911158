import { SET_STACK_DETAILS } from "../actions/types";
import _ from "lodash";
import frontendData from "../actions/stack/frontendData.json";

const buildDetailState = (state, details) => {
  if (!_.isEmpty(frontendData) && !_.some(state, { stack: "Front End" })) {
    const naMessage = `Please reference ${frontendData.build.version} in Gitlab for more details.`;

    frontendData.commit.user = { name: naMessage };
    frontendData.commit.message = { short: naMessage };

    details.push(frontendData);
  }

  const newDetails = _.map(details, (detail) => {
    return {
      stack: detail.stack,
      version: detail.build.version,
      branch: detail.branch,
      lastCommitMsg: detail.commit.message.short,
      lastCommitAuth: detail.commit.user.name,
    };
  });

  return _.concat(state, newDetails);
};

const stackDetailsReducer = (state = [], action) => {
  switch (action.type) {
    case SET_STACK_DETAILS:
      return buildDetailState(state, action.payload);
    default:
      return state;
  }
};

export default stackDetailsReducer;
