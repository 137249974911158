import React, { Component } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import {
  ChipAwareTextInput,
  getSessionFiltersOrDefault,
  MultiSelectCheckbox,
  PRODUCT_STATUS_LIST,
  removeSessionFilterValue,
  SESSION_KEYS,
  setSessionFilter,
} from "../../../utils";
import _ from "lodash";

class CategoryRefGPISearchForm extends Component {
  FILTER_KEYS = {
    AHFS_CLASSES: "ahfsClasses",
    DRUG_CATS: "drugCats",
    GPIS: "gpis",
    DRUG_NAMES: "drugNames",
    ANTICIPATED_CATS: "anticipatedCats",
    RECLASS_STATUSES: "reclassStatuses",
  };

  constructor(props) {
    super(props);

    const localStorageFilters = getSessionFiltersOrDefault(
      SESSION_KEYS.CAT_REF_LIST
    );

    const localAhfsClasses = localStorageFilters[this.FILTER_KEYS.AHFS_CLASSES];
    const localDrugCats = localStorageFilters[this.FILTER_KEYS.DRUG_CATS];
    const localGpis = localStorageFilters[this.FILTER_KEYS.GPIS];
    const localDrugNames = localStorageFilters[this.FILTER_KEYS.DRUG_NAMES];
    const localAnticipatedCats =
      localStorageFilters[this.FILTER_KEYS.ANTICIPATED_CATS];
    const localReclassStatuses =
      localStorageFilters[this.FILTER_KEYS.RECLASS_STATUSES];

    this.state = {
      isOpen: true,
      searchFormDirty: true,
      searchTerms: {
        ahfsClasses: localAhfsClasses ? localAhfsClasses : [],
        drugCats: localDrugCats ? localDrugCats : [],
        gpis: localGpis ? localGpis : [],
        drugNames: localDrugNames ? localDrugNames : [],
        anticipatedCats: localAnticipatedCats ? localAnticipatedCats : [],
        reclassStatuses: localReclassStatuses ? localReclassStatuses : [],
      },
      reclassStatuses: this.getStatuses(),
    };
  }

  getStatuses = () => {
    const initialStatuses = getSessionFiltersOrDefault(
      SESSION_KEYS.CAT_REF_LIST
    );

    const reclassStatuses = _.map(PRODUCT_STATUS_LIST, (status) => {
      let checked = false;
      if (_.isEmpty(initialStatuses.reclassStatuses)) {
        checked = true;
        this.handleCheckboxSessionFilters(status, checked);
      } else {
        checked = _.includes(initialStatuses.reclassStatuses, status.value);
      }
      return {
        id: status.value,
        name: status.value,
        value: status.value,
        displayName: status.label,
        checked,
      };
    });
    return reclassStatuses;
  };

  handleCheckboxSessionFilters = (item, checked) => {
    if (checked) {
      setSessionFilter(
        SESSION_KEYS.CAT_REF_LIST,
        this.FILTER_KEYS.RECLASS_STATUSES,
        item.value
      );
    } else {
      removeSessionFilterValue(
        SESSION_KEYS.CAT_REF_LIST,
        this.FILTER_KEYS.RECLASS_STATUSES,
        item.value
      );
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      this.props.onSubmit();
      this.setState({ searchFormDirty: false });
    }
  };

  onCheckboxClick = (e) => {
    const element = e.currentTarget;
    const items = this.state.reclassStatuses;
    _.find(items, { id: element.id }).checked = element.checked;

    _.forEach(items, (item) => {
      this.handleCheckboxSessionFilters(item, item.checked);
    });

    this.setState({
      statuses: items,
      searchFormDirty: true,
    });
  };

  onCheckSelectAll = (e) => {
    const items = this.state.reclassStatuses;
    _.forEach(items, (item) => {
      const checked = e.currentTarget.checked;
      item.checked = checked;
      this.handleCheckboxSessionFilters(item, checked);
    });
    this.setState({ statuses: items, searchFormDirty: true });
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <ChipAwareTextInput
              id="ahfsClasses"
              name="ahfsClasses"
              placeholder="AHFS Classification"
              updateCallback={this.onSearchTermUpdate}
              sticky
              sessionKey={SESSION_KEYS.CAT_REF_LIST}
              filterKey={this.FILTER_KEYS.AHFS_CLASSES}
              initialValues={this.state.searchTerms.ahfsClasses}
            />
            <ChipAwareTextInput
              id="drugCats"
              name="drugCats"
              placeholder="Drug Category"
              updateCallback={this.onSearchTermUpdate}
              sticky
              sessionKey={SESSION_KEYS.CAT_REF_LIST}
              filterKey={this.FILTER_KEYS.DRUG_CATS}
              initialValues={this.state.searchTerms.drugCats}
            />
            <ChipAwareTextInput
              id="anticipatedCats"
              name="anticipatedCats"
              placeholder="AnticipatedCategory"
              updateCallback={this.onSearchTermUpdate}
              sticky
              sessionKey={SESSION_KEYS.CAT_REF_LIST}
              filterKey={this.FILTER_KEYS.ANTICIPATED_CATS}
              initialValues={this.state.searchTerms.anticipatedCats}
            />
            <Col md={2}>
              <MultiSelectCheckbox
                header="Product Status"
                selectAllOnChange={this.onCheckSelectAll}
                items={this.state.reclassStatuses}
                itemOnChange={this.onCheckboxClick}
                showSelectedCount
                selectAllDefaultChecked
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <ChipAwareTextInput
              id="gpis"
              name="gpis"
              placeholder="GPI"
              updateCallback={this.onSearchTermUpdate}
              sticky
              sessionKey={SESSION_KEYS.CAT_REF_LIST}
              filterKey={this.FILTER_KEYS.GPIS}
              initialValues={this.state.searchTerms.gpis}
            />
            <ChipAwareTextInput
              id="drugNames"
              name="drugNames"
              placeholder="GPI Drug Name"
              updateCallback={this.onSearchTermUpdate}
              sticky
              sessionKey={SESSION_KEYS.CAT_REF_LIST}
              filterKey={this.FILTER_KEYS.DRUG_NAMES}
              initialValues={this.state.searchTerms.drugNames}
            />
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default CategoryRefGPISearchForm;
