import _ from "lodash";
import { GET_MAC_FILE_CONTENT, CLEAR_FILE_CONTENT } from "../actions/types";
import { ConfigFile } from "../components/ClientManagement/ConfigFiles/fileTypes";

const macFileContentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MAC_FILE_CONTENT:
      // Drug name is based on whether or not a gpiCommonName exists
      const newContent = action.payload.content.map((item) => {
        const obj = { ...item };
        obj.drugName = item.gpiCommonName ? item.gpiCommonName : item.gpiName;
        return obj;
      });
      action.payload.content = newContent;
      return action.payload;
    case CLEAR_FILE_CONTENT:
      if (_.includes(action.payload, ConfigFile.MAC)) {
        return {};
      }
      return state;
    default:
      return state;
  }
};

export default macFileContentReducer;
