import { getClinicalBackend } from "../../api/clinicalBackend";
import { handleError, formatPageableParameters } from "../../utils";
import { tryRefreshToken } from "../auth/authAction";
import { GET_GLOSSARY_TERMS, CLEAR_GLOSSARY_TERMS } from "../types";
import history from "../../history";

export const getGlossaryTermsList = (pageNumber, size, sorts) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = `/api/glossary-terms?${formatPageableParameters(
      pageNumber,
      size,
      sorts ? [sorts] : null
    )}`;

    const terms = await getClinicalBackend(authToken).get(endPoint);

    dispatch({
      type: GET_GLOSSARY_TERMS,
      payload: terms.data
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const trySaveGlossaryTerm = formValues => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = "/api/glossary-terms";

    await getClinicalBackend(authToken).post(
      endpoint,
      JSON.stringify(formValues)
    );

    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryUpdateGlossaryTerm = formValues => async (
  dispatch,
  getState
) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/glossary-terms/${formValues.id}`;

    await getClinicalBackend(authToken).put(
      endpoint,
      JSON.stringify(formValues)
    );

    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const changeGlossaryTermOrder = (id, direction) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/glossary-terms/${id}/${direction}`;

    await getClinicalBackend(authToken).patch(endpoint);

    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const deleteGlossaryTerm = id => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/glossary-terms/${id}`;

    await getClinicalBackend(authToken).delete(endpoint);
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearGlossaryTerms = () => dispatch => {
  dispatch({ type: CLEAR_GLOSSARY_TERMS });
};
