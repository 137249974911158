import { npeBrandingName } from "../../../utils";
import { ActionType, SidebarIcon } from "../SideBar";

/**
 * Get Sidebar items with conditional children based on roles provided
 * @param {Object} client
 * @param {boolean} isAuthorized
 * @returns {Object[]}
 */
export const getClientSidebarItems = (client, isAuthorized) => {
  const clientId = client.id;
  const catalogDeliveryRoute = `/catalog-delivery/${clientId}`;
  const configDashRoute = `/client-config/dashboard/${clientId}`;
  const benefitsRoute = `/benefits/${clientId}`;
  const mpdListRoute = `/clients/${clientId}/mpds`;
  const tieInsRoute = `/mpd-tie-ins/${clientId}`;
  const claimsRoute = `/clients/${clientId}/claimfiles`;
  const eligibilityRoute = `/clients/${clientId}/eligibilitydata`;
  const claimSearchRoute = `/clients/${clientId}/claimsearch`;

  const children = [
    {
      path: mpdListRoute,
      name: `${npeBrandingName}s`,
      icon: SidebarIcon.ARTAS,
    },
    {
      path: catalogDeliveryRoute,
      name: "Catalog Delivery List",
      icon: SidebarIcon.CAT_DELIVERY_LIST,
    },
    {
      path: tieInsRoute,
      name: isAuthorized ? "Add RPS Client Tie-in" : "View RPS Client Tie-ins",
      icon: SidebarIcon.TIE_IN,
    },
    {
      path: benefitsRoute,
      name: `${isAuthorized ? "Configure " : "View "} Benefits`,
      state: { clientName: client.name },
      icon: SidebarIcon.BENEFIT,
    },
    {
      path: claimsRoute,
      name: "Claims Load Status",
      icon: SidebarIcon.CLAIMS_LOAD,
    },
    {
      path: claimSearchRoute,
      name: "Claims Search",
      icon: SidebarIcon.CLAIMS_SEARCH,
    },
    {
      path: configDashRoute,
      name: "Configuration Files",
      icon: SidebarIcon.CONFIG_FILES,
    },
    {
      path: eligibilityRoute,
      name: "Eligibility Load Status",
      icon: SidebarIcon.ELIGIBILITY_LOAD,
    },
  ];

  const sidebarItems = [
    {
      type: ActionType.LINK_LIST,
      bundle: {
        header: "Action Items",
        key: "dropdown1",
        children,
      },
    },
  ];

  return sidebarItems;
};
