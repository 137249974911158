import React, { Component } from "react";
import { Button, Col, Collapse, Form, FormGroup, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import {
  required,
  validSelect,
  numeric,
  renderSelectInput,
  renderDatePicker,
  renderInput,
  alphanNoSpace,
  exactLengthGPI14,
  MultiSelectCheckbox,
  DATE_FORMAT,
  maxLength3,
  DATE_CONVERT_FORMAT,
} from "../../../utils";
import _ from "lodash";
import moment from "moment";
import history from "../../../history";

class ClaimSearchForm extends Component {
  constructor(props) {
    super(props);

    const minClaimStartDate = moment().year(1990).toDate();
    const maxClaimStartDate = moment().toDate();
    const minClaimEndDate = moment().year(1990).toDate();
    const maxClaimEndDate = moment().toDate();
    const maxDOB = moment().toDate();
    const minDOB = moment().year(1900).toDate();

    const claimStatusVals = [
      { id: 1, name: "paid", value: "P", checked: true, displayName: "Paid" },
      {
        id: 2,
        name: "reversed",
        value: "R",
        checked: false,
        displayName: "Reversed (With Originals)",
      },
      {
        id: 3,
        name: "duplicate",
        value: "D",
        checked: false,
        displayName: "Duplicate",
      },
    ];

    this.state = {
      isOpen: true,
      checkboxesInvalid: false,
      minClaimStartDate,
      maxClaimStartDate,
      minClaimEndDate,
      maxClaimEndDate,
      claimStatusVals,
      maxDOB,
      minDOB,
    };
  }

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  getMONYCodes = () => {
    return [{ code: "M" }, { code: "O" }, { code: "N" }, { code: "Y" }];
  };

  getClientGroups = () => {
    const all = _.map(this.props.clientGroups, (group) => {
      return {
        id: group.id,
        name: `${group.carrierId} | ${group.accountCode} | ${group.groupId}`,
      };
    });
    return _.uniqBy(all, (group) => group.name);
  };

  onStartDateChange = (newVal) => {
    this.setState({
      minClaimEndDate: moment(newVal, DATE_CONVERT_FORMAT).toDate(),
      maxClaimStartDate: moment(newVal, DATE_CONVERT_FORMAT)
        .subtract(1, "d")
        .toDate(),
    });
  };

  onEndDateChange = (newVal) => {
    this.setState({
      maxClaimStartDate: moment(newVal, DATE_CONVERT_FORMAT)
        .subtract(1, "d")
        .toDate(),
    });
  };

  onCheckboxClick = (e) => {
    const element = e.currentTarget;
    const items = this.state.claimStatusVals;
    items[parseInt(element.id) - 1].checked = element.checked;
    this.setState({ claimStatusVals: items });
    this.setCheckboxesInvalid();
  };

  onCheckSelectAll = (e) => {
    const items = this.state.claimStatusVals;
    _.forEach(items, (item) => {
      item.checked = e.currentTarget.checked;
    });
    this.setState({ claimStatusVals: items });
    this.setCheckboxesInvalid();
  };

  setCheckboxesInvalid = () => {
    this.setState({
      checkboxesInvalid: !_.some(this.state.claimStatusVals, ["checked", true]),
    });
  };

  resetForm = (e) => {
    e.preventDefault();
    history.go(0);
  };

  /**
   * Format Dates Correctly to match what is in the database
   */
  formatClaimsDates = (dateString) => {
    return moment(dateString, DATE_CONVERT_FORMAT).format(DATE_FORMAT);
  };

  onSubmit = (formValues) => {
    // Add checked claim status to formValues
    formValues.status = _.map(
      _.filter(this.state.claimStatusVals, { checked: true }),
      (status) => {
        if (status.checked) {
          return status.value;
        }
      }
    );

    formValues.formattedStartDate = this.formatClaimsDates(
      formValues.startDate
    );

    if (formValues.endDate) {
      formValues.formattedEndDate = this.formatClaimsDates(formValues.endDate);
    } else {
      formValues.formattedEndDate = null;
    }

    if (formValues.dob) {
      formValues.formattedDOB = this.formatClaimsDates(formValues.dob);
    } else {
      formValues.formattedDOB = null;
    }

    this.props.onSubmit(formValues);
  };

  render = () => {
    const { submitting, pristine, invalid } = this.props;
    const { checkboxesInvalid } = this.state;

    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Form
            onSubmit={this.props.handleSubmit(this.onSubmit)}
            autoComplete="off"
          >
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Field
                    id="clientGroupId"
                    name="clientGroupId"
                    label="CAG"
                    required
                    items={this.getClientGroups()}
                    keyProp="id"
                    valueProp="id"
                    displayProp="name"
                    validate={[required, validSelect]}
                    component={renderSelectInput}
                  />
                </FormGroup>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="startDate"
                        name="startDate"
                        placeholder="Claim Date Start"
                        required
                        validate={[required]}
                        onChange={this.onStartDateChange}
                        maxDate={moment().toDate()}
                        component={renderDatePicker}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="endDate"
                        name="endDate"
                        placeholder="Claim Date End"
                        onChange={this.onEndDateChange}
                        minDate={this.state.minClaimEndDate}
                        maxDate={this.state.maxClaimEndDate}
                        component={renderDatePicker}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <MultiSelectCheckbox
                      header="Claim Status"
                      selectAllOnChange={this.onCheckSelectAll}
                      items={this.state.claimStatusVals}
                      itemOnChange={this.onCheckboxClick}
                      required
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="dob"
                        name="dob"
                        placeholder="Member DOB"
                        minDate={this.state.minDOB}
                        maxDate={this.state.maxDOB}
                        showYearDropdown
                        component={renderDatePicker}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="memberId"
                        name="memberId"
                        type="text"
                        placeholder="Member ID"
                        component={renderInput}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="personCode"
                        name="personCode"
                        type="text"
                        placeholder="Person Code"
                        validate={[numeric, maxLength3]}
                        component={renderInput}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="drugName"
                        name="drugName"
                        type="text"
                        placeholder="Claim Drug Name"
                        component={renderInput}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="lastName"
                        name="lastName"
                        type="text"
                        placeholder="Member Last Name"
                        component={renderInput}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="firstName"
                        name="firstName"
                        type="text"
                        placeholder="Member First Name"
                        component={renderInput}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="gpi"
                        name="gpi"
                        type="text"
                        placeholder="Claim GPI 14"
                        validate={[alphanNoSpace, exactLengthGPI14]}
                        component={renderInput}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Field
                        id="multiSource"
                        name="multiSource"
                        label="Claim MONY"
                        items={this.getMONYCodes()}
                        keyProp="code"
                        valueProp="code"
                        displayProp="code"
                        component={renderSelectInput}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4} className="align-self-center">
                    <Row className="pt-3">
                      <Col md={6}>
                        <Button
                          color="danger"
                          block
                          onClick={(e) => {
                            this.resetForm(e);
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col md={6}>
                        <Button
                          color="primary"
                          type="submit"
                          block
                          disabled={
                            submitting ||
                            pristine ||
                            invalid ||
                            checkboxesInvalid
                          }
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Form" : "Show Form"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default reduxForm({ form: "claimSearchForm" })(ClaimSearchForm);
