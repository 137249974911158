export const PAIRING_TYPES = {
  GPI_MSC_TO_GPI_MSC: "GPI/MSC to GPI/MSC",
  GPI_MSC_TO_NDC: "GPI/MSC to NDC",
  NDC_TO_GPI_MSC: "NDC to GPI/MSC",
  NDC_TO_NDC: "NDC to NDC",
  NOT_PAIRED: "Not Paired",
};

export const getDrugNameCSSClass = (newStartsOnly) => {
  let drugNameClass = "altButton";
  if (newStartsOnly && newStartsOnly === "Y") {
    drugNameClass += " nso-drug-name";
  }
  return drugNameClass;
};
