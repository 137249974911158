import {
  CLEAR_WORKBOOK_ENTRIES,
  GET_PREVIOUSLY_APPROVED_WORKBOOK,
  GET_WORKBOOK,
  GET_WORKBOOK_ENTRIES,
  TRY_WORKBOOK_ENTRY_SEARCH,
} from "../actions/types";

const initialState = {
  entries: {},
  selectedWorkbook: null,
  previouslyApproved: false,
};

const workbookEntryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKBOOK:
      return { ...state, selectedWorkbook: action.payload };
    case GET_WORKBOOK_ENTRIES:
    case TRY_WORKBOOK_ENTRY_SEARCH:
      return { ...state, entries: action.payload };
    case CLEAR_WORKBOOK_ENTRIES:
      return { initialState };
    case GET_PREVIOUSLY_APPROVED_WORKBOOK:
      return { ...state, previouslyApproved: action.payload ? true : false };
    default:
      return state;
  }
};

export default workbookEntryReducer;
