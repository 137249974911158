import { getClinicalBackend } from "../../api/clinicalBackend";
import {
  CLEAR_WORKBOOK_ERRORS,
  GET_WORKBOOK_ERRORS,
  TRY_WORKBOOK_ERROR_SEARCH,
} from "../types";
import { tryRefreshToken } from "../auth/authAction";
import { formatPageableParameters, handleError } from "../../utils";

const apiBase = "/api/workbook-errors";

export const getDistinctPairingTypesForErrors =
  (wbId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      const endPoint = `${apiBase}/pairing-types?wbId=${wbId}`;

      const results = await getClinicalBackend(authToken).get(endPoint);
      return results.data;
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getWorkbookErrors =
  (wbId, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      const endPoint = `${apiBase}?wbId=${wbId}${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null,
      )}`;

      const errors = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_WORKBOOK_ERRORS,
        payload: errors.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryWorkbookErrorSearch =
  (wbId, searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `${apiBase}/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null,
      )}`;

      const errors = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify({ ...searchTerms, wbId }),
      );

      dispatch({ type: TRY_WORKBOOK_ERROR_SEARCH, payload: errors.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearWorkbookErrors = () => (dispatch) => {
  dispatch({ type: CLEAR_WORKBOOK_ERRORS });
};
