import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { GET_PRODUCTS } from "../types";
import { handleError } from "../../utils/error/errorHandlers";

export const getProducts = () => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const products = await getClinicalBackend(authToken).get("/api/products");

    dispatch({ type: GET_PRODUCTS, payload: products.data });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};
