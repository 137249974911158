import { getClinicalBackend } from "../../api/clinicalBackend";
import history from "../../history";
import { formatPageableParameters, handleError } from "../../utils";
import { tryRefreshToken } from "../auth/authAction";
import {
  GET_CATEGORY_REFERENCE,
  GET_CATEGORY_REFERENCES,
  GET_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG,
  CLEAR_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG,
} from "../types";
import _ from "lodash";

const ENDPOINT_ROOT = "/api/category-ref-drugs";

export const getCatRef = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `${ENDPOINT_ROOT}/${id}`;
    const catRef = await getClinicalBackend(authToken).get(endpoint);

    dispatch({ type: GET_CATEGORY_REFERENCE, payload: catRef.data });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryCatRefSearch =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `${ENDPOINT_ROOT}/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null
      )}`;
      const catRefs = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms)
      );

      dispatch({ type: GET_CATEGORY_REFERENCES, payload: catRefs.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const saveCatRefs = (gpis) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const errors = [];
    const calls = _.map(gpis, async (gpi) => {
      await getClinicalBackend(authToken)
        .post(ENDPOINT_ROOT, JSON.stringify(gpi))
        .catch((e) => {
          errors.push(e);
        });
    });

    await Promise.allSettled(calls);

    if (errors.length > 0 && errors[0].code !== "ERR_NETWORK") {
      console.log(errors);
      throw errors[0];
    }

    history.push("/category-refs");
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryUpdateCatRef = (catRef) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `${ENDPOINT_ROOT}/${catRef.id}`;

    await getClinicalBackend(authToken).put(endpoint, JSON.stringify(catRef));
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryDeleteCatRef = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `${ENDPOINT_ROOT}/${id}`;
    await getClinicalBackend(authToken).delete(endpoint);
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getCategoryReferenceDrugsAuditLog =
  (searchTerms, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `/api/category-ref-drugs-audits?${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null
      )}`;

      const auditLogs = await getClinicalBackend(authToken).post(
        endPoint,
        JSON.stringify(searchTerms)
      );

      dispatch({
        type: GET_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG,
        payload: auditLogs.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearCategoryReferenceDrugsAuditLog = () => (dispatch) => {
  dispatch({ type: CLEAR_CATEGORY_REFERENCE_DRUGS_AUDIT_LOG });
};
