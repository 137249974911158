import React, { Component } from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { Button, Col, Container, Row } from "reactstrap";
import { FaTrashAlt } from "react-icons/fa";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import {
  clearClients,
  getClient,
  getMPDTieIns,
  tryAddMPDTieIn,
  tryDeleteMPDTieIn,
  tryEditMPDTieIn,
  clearMPDTieIns,
  populateBreadCrumbs,
  toggleSidebarVisibility,
} from "../../../actions";
import MPDTieInForm from "./MPDTieInForm";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { Role } from "../../../utils/auth/roles";

class MPDTieIns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      isAuthorized: _.some(this.props.roles, {
        name: Role.CLIENT_ADMIN.name,
      }),
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount = () => {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getMPDTieIns(this.state.clientId);
  };

  componentDidUpdate = () => {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadcrumbs();
      }
    }
  };

  componentWillUnmount = () => {
    this.props.toggleSidebarVisibility();
    this.props.clearMPDTieIns();
    this.props.clearClients();
  };

  setupBreadcrumbs = () => {
    const breadcrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
      ],
      finalCrumb: "RPS Client Tie-ins",
    };
    this.props.populateBreadCrumbs(breadcrumbs);
  };

  toggleModal = (tieInId, tieInName) => {
    const header = "Delete " + tieInName;
    const content = "Are you sure you want to delete " + tieInName + "?";

    this.setState({
      isOpen: !this.state.isOpen,
      modalHeader: header,
      modalContent: content,
      modalAction: this.props.tryDeleteMPDTieIn,
      tieInId: tieInId,
    });
  };

  deleteMPDTieIn = (tieInId, tieInName) => {
    const selectedTieIn = _.find(this.props.mpdTieIns, { id: tieInId });
    if (selectedTieIn.lockFlag === "N") {
      this.toggleModal(tieInId, tieInName);
    }
  };

  onSubmit = (formValues) => {
    if (
      _.find(
        this.props.mpdTieIns,
        _.matchesProperty("name", formValues.name)
      ) !== undefined
    ) {
      throw new SubmissionError({
        name: "RPS LCTAF Tie-in name already exists for this client.",
      });
    } else {
      formValues.client = { id: this.state.clientId };
      this.props.tryAddMPDTieIn(formValues);
    }
  };

  actionFormatter = (content, row) => {
    const selectedTieIn = _.find(this.props.mpdTieIns, { id: row.id });
    return (
      <div>
        {this.state.isAuthorized && (
          <Button
            className="linkBtn tableBtn actionIconBtnDanger"
            color="link"
            onClick={() => this.deleteMPDTieIn(row.id, row.name)}
            disabled={selectedTieIn.lockFlag === "Y"}
          >
            <FaTrashAlt />
          </Button>
        )}
      </div>
    );
  };

  onTableChange = (type, newState) => {
    if (type === "cellEdit") {
      const editData = newState.cellEdit;
      const data = {
        id: editData.rowId,
        name: editData.newValue,
      };
      this.props.tryEditMPDTieIn(data);
    }
  };

  tableEditValidator = (newValue, row, column) => {
    if (newValue.length > 50) {
      return {
        valid: false,
        message: "This field must be 50 characters or less.",
      };
    }
    if (!/^[a-z\d]+$/i.test(newValue)) {
      return {
        valid: false,
        message:
          "This field is alphanumeric characters only. No special characters or spaces.",
      };
    }
    // We do not want to display the error message if we are matching on self
    let matchingName = _.find(
      this.props.mpdTieIns,
      _.matchesProperty("name", newValue)
    );
    if (matchingName !== undefined && matchingName.id !== row.id) {
      return {
        valid: false,
        message: "RPS LCTAF Tie-in name already exists for this client.",
      };
    }
  };

  COLUMNS = [
    {
      dataField: "actions",
      sort: false,
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "8%",
      },
      editable: false,
    },
    {
      dataField: "name",
      sort: true,
      text: "Name",
      editor: {
        type: Type.TEXT,
      },
      validator: this.tableEditValidator,
    },
  ];

  getDisabledEditRows = () => {
    return this.props.mpdTieIns.map((tieIn) => {
      let id = null;
      if (tieIn.lockFlag === "Y" || !this.state.isAuthorized) {
        id = tieIn.id;
      }
      return id;
    });
  };

  render() {
    if (!this.props.mpdTieIns || !this.props.selectedClient) {
      return <div>Loading...</div>;
    }
    const mpdTieIns = this.props.mpdTieIns;

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>
              {(this.state.isAuthorized ? "Add " : "") + "RPS Client Tie-in"}
            </h3>
          </Col>
        </Row>
        {this.state.isAuthorized && (
          <Row>
            <Col>
              <MPDTieInForm onSubmit={this.onSubmit} mpdTieIns={mpdTieIns} />
            </Col>
          </Row>
        )}
        <Row className="mt-4">
          <Col>
            <RPSDataGrid
              remote={{ cellEdit: true }}
              keyField="id"
              data={mpdTieIns}
              columns={this.COLUMNS}
              onTableChange={this.onTableChange}
              cellEditFactory={cellEditFactory({
                mode: "click",
                blurToSave: true,
                nonEditableRows: this.getDisabledEditRows,
              })}
            />
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalHeader}
          content={this.state.modalContent}
          action={this.state.modalAction}
          objId={this.state.tieInId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.auth.currentUser.roles,
    mpdTieIns: Object.values(state.mpdTieIns),
    selectedClient: state.clients[ownProps.match.params.clientId],
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getMPDTieIns,
  tryAddMPDTieIn,
  tryDeleteMPDTieIn,
  tryEditMPDTieIn,
  clearMPDTieIns,
  populateBreadCrumbs,
  toggleSidebarVisibility,
})(MPDTieIns);
