import { GET_CONTRACT, CLEAR_SELECTED_CONTRACT } from "./../actions/types";

const contractsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CONTRACT:
      return { ...state, [action.payload.id]: action.payload };
    case CLEAR_SELECTED_CONTRACT:
      return {};
    default:
      return state;
  }
};

export default contractsReducer;
