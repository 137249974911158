import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import {
  clearClients,
  getClient,
  getClientGroups,
  clearClientGroups,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  trySearchClaims,
  clearClaims,
} from "../../../actions";
import ClaimSearchForm from "./ClaimSearchForm";
import "./Claims.css";
import _ from "lodash";
import { dateFormatter } from "../../../utils";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import ClaimSearchExpandRow from "./ClaimSearchExpandRow";
import { Link } from "react-router-dom";

class ClaimSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: this.props.match.params.clientId,
      navLoaded: false,
      currPage: 1,
      sizePerPage: 15,
      searching: false,
      sortField: { dataFields: ["filledDate"], order: "desc" },
    };
  }

  componentDidMount = () => {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getClientGroups(this.state.clientId);
  };

  componentDidUpdate = () => {
    if (this.props.selectedClient && !this.state.navLoaded) {
      this.setState({ navLoaded: true });
      this.setupBreadCrumbs();
    }

    if (
      this.state.searching &&
      !_.isEmpty(this.props.claims) &&
      !this.props.claims.empty
    ) {
      this.setState({ searching: false });
    } else if (this.state.searching && this.props.claims.empty) {
      this.setState({ searching: false });
    }
  };

  componentWillUnmount = () => {
    this.props.clearClientGroups();
    this.props.toggleSidebarVisibility();
    this.props.clearClaims();
    this.props.clearClients();
  };

  setupBreadCrumbs = () => {
    this.props.populateBreadCrumbs({
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: `/clients/view/${this.state.clientId}`,
          name: this.props.selectedClient.name,
          active: false,
        },
      ],
      finalCrumb: "Claims Search",
    });
  };

  onSearchSubmit = (formValues) => {
    const selectedClientGroup = _.find(this.props.clientGroups, (group) => {
      return group.id === parseInt(formValues.clientGroupId);
    });
    // Backend requires values matching ClaimSearchDTO
    let searchValues = {
      clientId: this.state.clientId,
      carrierId: selectedClientGroup.carrierId,
      accountCode: selectedClientGroup.accountCode,
      groupId: selectedClientGroup.groupId,
      clientTieInId: selectedClientGroup.clientTieIn.id,
      startDate: "",
      status: [],
      endDate: "",
      firstName: "",
      lastName: "",
      dob: "",
      memberId: "",
      personCode: "",
      drugName: "",
      gpi: "",
      multiSource: "",
    };

    // Apply the values that user has provided to searchValues
    searchValues = { ...searchValues, ..._.cloneDeep(formValues) };
    searchValues.startDate = formValues.formattedStartDate
      ? formValues.formattedStartDate
      : "";
    searchValues.endDate = formValues.formattedEndDate
      ? formValues.formattedEndDate
      : "";
    searchValues.dob = formValues.formattedDOB ? formValues.formattedDOB : "";

    this.setState(
      {
        searchValues: _.omit(searchValues, [
          "formattedDOB",
          "formattedStartDate",
          "formattedEndDate",
        ]),
        searching: true,
        currPage: 1,
      },
      () => {
        this.getPage();
      }
    );
  };

  memberIdPersonCodeFormatter = (content, row) => {
    return `${row.claimMember.memberId} ${row.claimMember.personCode}`;
  };

  claimMemberNameFormatter = (content) => {
    return `${content.lastName} ${content.firstName}`;
  };

  getClaimsData = () => {
    const claims = this.props.claims.content ? this.props.claims.content : [];
    // A claim could have multiple evaluations. If the data is valid, there should be at
    // most one lookback evaluation and one whose filledDate was in an MPD. We prefer
    // displaying the one in the MPD, if both are present
    claims.forEach((claim) => {
      const results = claim.evaluationResults;
      if (results.length > 1) {
        const inMpdResults = results.filter(
          (result) => !result.claimMpdProfile.toUpperCase().includes("LOOKBACK")
        );
        if (inMpdResults.length > 0) {
          claim.evaluationResult = inMpdResults[0];
        } else {
          claim.evaluationResult = results[0];
        }
      } else if (results.length === 1) {
        claim.evaluationResult = results[0];
      }
    });
    return claims;
  };

  getPage() {
    return this.props.trySearchClaims(
      this.state.searchValues,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  }

  transformSortField = (sortField) => {
    switch (sortField) {
      case "claimMember":
        return ["claimMember.lastName", "claimMember.firstName"];
      default:
        return [sortField];
    }
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataFields: this.transformSortField(sortField),
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  COLUMNS = [
    {
      dataField: "internalClaimStatus",
      text: "Paid",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "filledDate",
      text: "Claim Date",
      headerAlign: "center",
      align: "center",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "claimMemberId",
      text: "MemberID/PersonCode",
      formatter: this.memberIdPersonCodeFormatter,
    },
    {
      dataField: "claimMember",
      text: "Last/First Name",
      formatter: this.claimMemberNameFormatter,
      sort: true,
    },
    {
      dataField: "drugDescription",
      text: "Claim Drug Name",
      sort: true,
    },
    {
      dataField: "metricQty",
      text: "Qty",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "daysSupply",
      text: "Days Supply",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "amtPaid",
      text: "Amount Paid",
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "ingredCost",
      text: "Ingr Cost",
      headerAlign: "right",
      align: "right",
    },
    {
      dataField: "memberCopay",
      text: "Copay",
      headerAlign: "right",
      align: "right",
      sort: true,
    },
  ];

  expandRow = {
    renderer: (row) => <ClaimSearchExpandRow claim={row} />,
    onlyOneExpanding: false,
    showExpandColumn: true,
    expandByColumnOnly: true,
  };

  getValidClientGroups = () => {
    return _.filter(this.props.clientGroups, (clientGroup) => {
      return clientGroup.clientTieIn != null;
    });
  };

  renderClientGroupsErrorMessage = (client, noTieIns) => {
    var messageEnd = noTieIns
      ? ` containing RPS LCTAF Tie-ins associated with ${client.name}`
      : ` associated with ${client.name}`;

    return (
      <>
        <Row>
          <Col>
            <h2>{client.name} Claims Search Unavailable</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>
              <strong>Reason:</strong> There are no&nbsp;
              <Link to={`/client-config/dashboard/${client.id}`}>
                Group Plan Files
              </Link>
              {messageEnd}
            </h4>
          </Col>
        </Row>
      </>
    );
  };

  render = () => {
    const client = this.props.selectedClient;
    if (!client) {
      return <h1>Loading...</h1>;
    }

    const validClientGroups = this.getValidClientGroups();

    return (
      <Container className="main-container" fluid>
        {_.isEmpty(this.props.clientGroups) &&
          this.renderClientGroupsErrorMessage(client)}

        {!_.isEmpty(this.props.clientGroups) &&
          _.isEmpty(validClientGroups) &&
          this.renderClientGroupsErrorMessage(client, true)}

        {!_.isEmpty(validClientGroups) && (
          <>
            <Row>
              <Col>
                <h3>{client.name} Claims Search</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  You must fill out the search form and click the Search button
                  before data will be presented in the grid. Your search will be
                  logged for auditing purposes.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <ClaimSearchForm
                  clientGroups={validClientGroups}
                  onSubmit={this.onSearchSubmit}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                {this.state.searching && <h3>SEARCHING...</h3>}

                {!_.isEmpty(this.props.claims) && this.props.claims.empty && (
                  <h3>0 Results Found. Please update your search criteria.</h3>
                )}

                {this.props.claims.hasOwnProperty("exceptionMessage") && (
                  <h3>{this.props.claims.exceptionMessage}</h3>
                )}

                {!_.isEmpty(this.props.claims) &&
                  !this.props.claims.empty &&
                  !this.props.claims.hasOwnProperty("exceptionMessage") && (
                    <RPSDataGrid
                      keyField="id"
                      paginated
                      sizePerPage={this.state.sizePerPage}
                      page={this.state.currPage}
                      totalSize={this.props.claims.totalElements}
                      data={this.getClaimsData()}
                      columns={this.COLUMNS}
                      onSizePerPageChange={this.onSizePerPageChange}
                      onTableChange={this.handleTableChange}
                      expandRow={this.expandRow}
                      remote
                    />
                  )}
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    clientGroups: state.clientGroups,
    claims: state.claims,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getClientGroups,
  clearClientGroups,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  trySearchClaims,
  clearClaims,
})(ClaimSearch);
