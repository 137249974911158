import React, { Component } from "react";
import { connect } from "react-redux";
import { SIDEBAR_ITEMS } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  getInternalCatalogReports,
} from "../../../actions";
import _ from "lodash";
import { Container, Row, Col } from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import {
  localDateTimeFormatter,
} from "../../../utils";
import { FaDownload } from "react-icons/fa";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

class InternalCatalogReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
    };
  }

  componentDidMount = () => {
    this.setupBreadCrumbs();
    this.setupSidebar();
    this.getPage();
  };

  componentWillUnmount() {
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/reporting",
          name: "Reporting",
          active: false,
        },
      ],
      finalCrumb: "Internal Clinical Catalog CSV",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };


  actionFormatter = (content, row) => {
    return (
      <div>
      <Button
          className="linkBtn tableBtn actionIconLinkPrimary"
          color="link"
          tag={Link}
          to={{ pathname: row.downloadUrl }}
          target="_blank"
          size="sm"
        >
          <FaDownload />
        </Button>
      </div>
    );
  };

  COLUMNS = [
    {
      dataField: "actions",
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "reportName",
      sort: false,
      text: "File Name",
      formatter: this.linkFormatter,
    },
        {
      dataField: "uploadedDate",
      sort: false,
      text: "Effective Date",
      formatter: localDateTimeFormatter,
    },

  ];

  getPage = () => {
    this.props.getInternalCatalogReports(
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  render() {
    if (
      _.isEmpty(this.props.internalCatalogReports)
    ) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>
              FOR INTERNAL USE ONLY - Internal Clinical Catalog CSV - includes GPIs and MultiSource Codes
            </h3>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="reportName"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.internalCatalogReports.totalElements}
              data={this.props.internalCatalogReports.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>

      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    internalCatalogReports: state.internalCatalogReports,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  getInternalCatalogReports,
})(InternalCatalogReports);
