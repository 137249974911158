import React from "react";
import { Col, Row } from "reactstrap";
import _ from "lodash";
import PropTypes from "prop-types";
import ExpandRowDetail from "../../../utils/dataGrid/ExpandRowDetail";

const ClaimSearchExpandRow = (props) => {
  const { claim } = props;
  const firstRow = [
    { label: "GPI", prop: "gpi" },
    { label: "MSC", prop: "multiSource" },
    { label: "NDC", prop: "ndc" },
    { label: "UnitOfMeasure", prop: "unitOfMeasure" },
    { label: "PriorAuth", prop: "priorAuthNbr" },
    { label: "DAW", prop: "daw" },
  ];
  const secondRow = [
    { label: "ClaimRefNbr", prop: "claimRefNbr" },
    { label: "Pharmacy Number", prop: "pharmacyNpi" },
    { label: "Prescriber NPI", prop: "prescriberNpiId" },
  ];
  const secondRowClaimMember = [
    { label: "Carrier", prop: "carrierId" },
    { label: "Account", prop: "accountCode" },
    { label: "Group", prop: "groupId" },
  ];
  const thirdRowClaimEvaluation = [
    { label: "CLAIM EVALUATION - Claim to LCTAF", prop: "claimMpdProfile" },
    { label: "Member Profile", prop: "memberProfile" },
  ];

  return (
    <>
      <Row className="px-4">
        {_.map(firstRow, (col) => (
          <Col key={col.prop}>
            <ExpandRowDetail
              label={col.label}
              detail={claim[col.prop] ? claim[col.prop].toString() : ""}
            />
          </Col>
        ))}
      </Row>
      <Row className="px-4 mt-3">
        {_.map(secondRow, (col) => (
          <Col key={col.prop}>
            <ExpandRowDetail
              label={col.label}
              detail={claim[col.prop] ? claim[col.prop].toString() : ""}
            />
          </Col>
        ))}
        {_.map(secondRowClaimMember, (col) => (
          <Col key={col.prop}>
            <ExpandRowDetail
              label={col.label}
              detail={
                claim.claimMember[col.prop]
                  ? claim.claimMember[col.prop].toString()
                  : ""
              }
            />
          </Col>
        ))}
      </Row>
      {claim.evaluationResult && (
        <>
          <hr />
          <Row className="px-4 mt-3 mb-3">
            {_.map(thirdRowClaimEvaluation, (col) => (
              <Col key={col.prop}>
                <ExpandRowDetail
                  label={col.label}
                  detail={
                    claim.evaluationResult && claim.evaluationResult[col.prop]
                      ? claim.evaluationResult[col.prop].toString()
                      : ""
                  }
                />
              </Col>
            ))}
          </Row>
        </>
      )}
    </>
  );
};

ClaimSearchExpandRow.propTypes = {
  claim: PropTypes.object.isRequired,
};

export default ClaimSearchExpandRow;
