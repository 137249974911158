import React, { useState, useEffect } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Chip } from "./Chip";
import _ from "lodash";

export const ChipAwareOptionInput = (props) => {
  const { name, updateCallback } = props;
  const [terms, setTerms] = useState([]);
  const [inputVal, setInputVal] = useState("");
  const [isDuplicateVal, setIsDuplicateVal] = useState(false);

  const onAdd = () => {
    const newTerms = terms;
    newTerms.push(inputVal);
    setTerms(newTerms);

    setInputVal("");
    updateCallback(name, terms);
  };

  const addValue = (val) => {
    const isDupe = _.includes(terms, val);
    setIsDuplicateVal(isDupe);
    if (!isDupe) {
      setInputVal(val);
    }
  };

  const onRemove = (val) => {
    const newTerms = _.filter(terms, (term) => {
      return term !== val;
    });

    setTerms(newTerms);
  };

  useEffect(() => {
    updateCallback(name, terms);
  }, [terms, name, updateCallback]);

  return (
    <Col md={2}>
      <Row>
        <Col md={10}>
          <FormGroup className="d-flex gap-2">
            <div className="field">
              <Label
                for={props.id}
              >{`${props.placeholder} (${terms.length})`}</Label>
              <Input
                type="select"
                id={props.id}
                name={name}
                onChange={(e) => addValue(e.target.value)}
              >
                <option value="">-Select-</option>
                {props.options.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.displayValue}
                    </option>
                  );
                })}
              </Input>
            </div>
            <Button
              className="inline-text-input-button"
              onClick={onAdd}
              disabled={
                _.isEmpty(_.trim(inputVal)) ||
                !inputVal ||
                _.trim(inputVal).length < props.minLength ||
                isDuplicateVal
              }
            >
              Add
            </Button>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="gap-3">
          {_.map(terms, (term) => (
            <Chip
              key={term + _.random(1, 999)}
              value={term}
              removeCallback={onRemove}
            />
          ))}
        </Col>
      </Row>
    </Col>
  );
};

ChipAwareOptionInput.defaultProps = {
  minLength: 3,
};

ChipAwareOptionInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  updateCallback: PropTypes.func.isRequired,
  minLength: PropTypes.number,
};
