import { getClinicalBackend } from "../../api/clinicalBackend";
import {
  TRY_CATEGORY_FILL_FACTOR_UPDATE,
  TRY_CATEGORY_FILL_FACTORS_SEARCH,
  TRY_GPI12_FILL_FACTORS_SEARCH,
  TRY_GPI12_FILL_FACTOR_UPDATE,
  GET_FILL_FACTORS_BASELINE_DATES,
  CLEAR_FILL_FACTORS,
} from "../types";
import { tryRefreshToken } from "../auth/authAction";
import {
  downloadExcelFile,
  EXCEL_DOWNLOAD_OPTIONS,
  handleError,
  formatPageableParameters,
} from "../../utils";

export const tryUpdateCategoryFillFactor =
  (formValues, callback) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/fill-factors/category/${formValues.id}`;
      const fillFactor = await getClinicalBackend(authToken).patch(
        endpoint,
        JSON.stringify(formValues)
      );
      dispatch({
        type: TRY_CATEGORY_FILL_FACTOR_UPDATE,
        payload: fillFactor.data,
      });
      callback();
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryUpdateGpi12FillFactor =
  (formValues) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/fill-factors/gpi12/${formValues.id}`;
      const fillFactor = await getClinicalBackend(authToken).patch(
        endpoint,
        JSON.stringify(formValues)
      );
      dispatch({
        type: TRY_GPI12_FILL_FACTOR_UPDATE,
        payload: fillFactor.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryCategoryFillFactorsSearch =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endpoint = `/api/fill-factors/category-search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? sort : null
      )}`;
      const fillFactors = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms)
      );
      dispatch({
        type: TRY_CATEGORY_FILL_FACTORS_SEARCH,
        payload: fillFactors.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryGpi12FillFactorsSearch =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/fill-factors/gpi12-search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? sort : null
      )}`;
      const fillFactors = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms)
      );
      dispatch({
        type: TRY_GPI12_FILL_FACTORS_SEARCH,
        payload: fillFactors.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const gpi12FillFactorsExport =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/fill-factors/gpi12-search/reports?${formatPageableParameters(
        0,
        0,
        sort ? sort : null
      )}`;
      const fillFactors = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms),
        EXCEL_DOWNLOAD_OPTIONS
      );
      downloadExcelFile(fillFactors);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getFillFactorBaselineDates = () => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const baselineDates = await getClinicalBackend(authToken).get(
      "/api/fill-factors/baseline-dates"
    );
    dispatch({
      type: GET_FILL_FACTORS_BASELINE_DATES,
      payload: baselineDates.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearFillFactors = () => (dispatch) => {
  dispatch({ type: CLEAR_FILL_FACTORS });
};
