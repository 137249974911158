import React, { Component } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import _ from "lodash";
import {
  ChipAwareTextInput,
  DATE_CONVERT_FORMAT,
  DATE_PICKER_FORMAT,
  MultiSelectCheckbox,
} from "../../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class DecisionGridAuditLogsSearchForm extends Component {
  constructor(props) {
    super(props);

    const mscs = [
      { id: 1, name: "M", value: "M", checked: false, displayName: "M" },
      { id: 2, name: "O", value: "O", checked: false, displayName: "O" },
      { id: 3, name: "N", value: "N", checked: false, displayName: "N" },
      { id: 4, name: "Y", value: "Y", checked: false, displayName: "Y" },
    ];

    const searchTerms = this.props.searchTerms
      ? this.props.searchTerms
      : {
          decisionGridId: 0,
          categoryNames: [],
          gpis: [],
          multiSources: [],
          drugNames: [],
          userNames: [],
          changeTypes: [],
          auditDescriptions: [],
          searchDate: "",
        };

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: searchTerms,
      mscs,
    };
  }

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      const multiSources = _.filter(
        _.map(this.state.mscs, (val) => {
          if (val.checked) {
            return val.value;
          }
        }),
        (val) => {
          return val !== undefined;
        }
      );
      this.props.onSubmit({ ...this.state.searchTerms, multiSources });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onCheckboxClick = (e) => {
    const element = e.currentTarget;
    const items = this.state.mscs;
    items[parseInt(element.id) - 1].checked = element.checked;
    this.setState({ mscs: items, searchFormDirty: true });
  };

  onCheckSelectAll = (e) => {
    const items = this.state.mscs;
    _.forEach(items, (item) => {
      item.checked = e.currentTarget.checked;
    });
    this.setState({ mscs: items, searchFormDirty: true });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  handleChange = (value) => {
    if (value) {
      this.setState({
        searchTerms: {
          ...this.state.searchTerms,
          searchDate: moment(value).format(DATE_CONVERT_FORMAT),
        },
        searchFormDirty: true,
      });
    } else {
      this.setState({
        searchTerms: {
          ...this.state.searchTerms,
          searchDate: "",
        },
        searchFormDirty: true,
      });
    }
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <Col md={1}>
              <label>Change Date</label>
              <DatePicker
                dateFormat={DATE_PICKER_FORMAT}
                placeholderText={DATE_PICKER_FORMAT}
                selected={Date.parse(this.state.searchTerms.searchDate) || null}
                onChange={(e) => this.handleChange(e)}
                isClearable={true}
                className="form-control"
              />
            </Col>

            <ChipAwareTextInput
              id="userNames"
              name="userNames"
              placeholder="User Name"
              updateCallback={this.onSearchTermUpdate}
            />
            {!this.state.searchTerms.decisionGridId && (
              <ChipAwareTextInput
                id="categoryNames"
                name="categoryNames"
                placeholder="Drug Category"
                updateCallback={this.onSearchTermUpdate}
              />
            )}
            <ChipAwareTextInput
              id="drugNames"
              name="drugNames"
              placeholder="Drug Name"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="gpis"
              name="gpis"
              placeholder="GPI"
              updateCallback={this.onSearchTermUpdate}
            />
            <Col md={1}>
              <MultiSelectCheckbox
                header="MSC"
                selectAllOnChange={this.onCheckSelectAll}
                items={this.state.mscs}
                itemOnChange={this.onCheckboxClick}
                showSelectedCount
              />
            </Col>
            <ChipAwareTextInput
              id="changeTypes"
              name="changeTypes"
              placeholder="Change Type"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="auditDescriptions"
              name="auditDescriptions"
              placeholder="Audit Description"
              updateCallback={this.onSearchTermUpdate}
            />
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default DecisionGridAuditLogsSearchForm;
