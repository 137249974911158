import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearClients,
  getClientsBy,
  getMPDs,
  getEstimatedClaimCorrelationRangesByClient,
  populateBreadCrumbs,
  populateSidebar,
  requestProspectiveReport,
} from "../../../actions";
import { SIDEBAR_ITEMS } from "../Constants";
import _ from "lodash";
import { Col, Container, Row } from "reactstrap";
import ProspectiveReportRequestForm from "./ProspectiveReportRequestForm";
import AlertModal from "../../Modal/AlertModal";
import history from "../../../history";

class ProspectiveReportRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedClient: null,
      claimLoads: [],
    };
  }

  componentDidMount = () => {
    this.setupSidebar();
    this.setupBreadCrumbs();

    this.props.getClientsBy({ type: "PROSPECT" });
    if (this.state.selectedClient) {
      this.props.getEstimatedClaimCorrelationsByClient(
        this.state.selectedClient.id
      );
      this.props.getMPDs(this.state.selectedClient.id);
    }
  };

  componentWillUnmount = () => {
    this.props.clearClients();
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/reporting",
          name: "Reporting",
          active: false,
        },
        {
          to: "/reporting/prospective",
          name: "Prospective Savings / Impact Report",
          active: false,
        },
      ],
      finalCrumb: "Request Prospective Savings/Impact Report",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  handleClientChange = (clientId) => {
    const selectedClient = _.find(this.props.clients, { id: clientId });
    this.setState({ selectedClient });
    this.props.getEstimatedClaimCorrelationRangesByClient(selectedClient.id);
    this.props.getMPDs(selectedClient.id);
  };

  toggleModal = (title, content) => {
    this.setState({
      isOpen: !this.state.isAlertOpen,
      modalTitle: title,
      modalContent: content,
    });
  };

  onSubmit = (formValues) => {
    this.props.requestProspectiveReport(formValues);
    this.toggleModal(
      `Prospective Report Creation Submitted`,
      `Analysis will take several minutes to complete. The report will be locked until it is completed.`
    );
  };

  render() {
    if (_.isEmpty(this.props.clients)) {
      return <h1>LOADING...</h1>;
    }
    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Request Prospective Savings and Impact Report</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <ProspectiveReportRequestForm
              onSubmit={this.onSubmit}
              clients={this.props.clients}
              handleClientChange={this.handleClientChange}
              getClaimLoads={this.getClaimLoads}
              claimLoads={this.props.claimLoads}
              mpds={this.props.mpds}
            />
          </Col>
        </Row>
        <AlertModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalTitle}
          content={this.state.modalContent}
          action={() => history.goBack()}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: Object.values(state.clients),
    claimLoads: Object.values(state.estimatedClaimCorrelations),
    mpds: _.filter(Object.values(state.mpds), (mpd) => {
      return mpd.implementationEndDate === null;
    }),
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClientsBy,
  getMPDs,
  getEstimatedClaimCorrelationRangesByClient,
  populateBreadCrumbs,
  populateSidebar,
  requestProspectiveReport,
})(ProspectiveReportRequest);
