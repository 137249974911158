import React, { Component } from "react";
import { Button, Collapse, Row, Col } from "reactstrap";
import {
  ChipAwareTextInput,
  getRenderDate,
  DATE_PICKER_FORMAT,
  SESSION_VALUE_KEYS,
  getSessionValuesOrDefault,
  setSessionValue,
} from "../../../utils";
import "react-datepicker/dist/react-datepicker.css";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class TargetCategoriesSearchForm extends Component {
  FILTER_KEYS = {
    EFF_DATE: "effectiveDate",
  };

  constructor(props) {
    super(props);

    const searchTerms = this.props.searchTerms
      ? this.props.searchTerms
      : {
          effectiveDate: DateTime.now().toISODate(),
          drugCategoryNames: [],
          drugNames: [],
        };

    const localStorageFilters = getSessionValuesOrDefault(
      SESSION_VALUE_KEYS.TGT_EFFDT,
      { [this.FILTER_KEYS.EFF_DATE]: DateTime.now().toISODate() }
    );
    const effDate = localStorageFilters[this.FILTER_KEYS.EFF_DATE]
    if (effDate) {
      searchTerms.effectiveDate = effDate;
    }

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: searchTerms,
      catalogEffDate: DateTime.fromISO(this.props.searchTerms.effectiveDate).toJSDate()
    };
  }

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      this.props.onSubmit({ ...this.state.searchTerms });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  handleChange = (value) => {
    if (value) {
      this.setState({
        searchTerms: {
          ...this.state.searchTerms,
        },
        searchFormDirty: true,
      });
    } else {
      this.setState({
        searchTerms: {
          ...this.state.searchTerms,
        },
        searchFormDirty: true,
      });
    }
  };

  onDateChange = (date) => {
    const effDate = DateTime.fromJSDate(date).toISODate()
    this.setState({
      searchTerms: { ...this.state.searchTerms, effectiveDate: effDate },
      catalogEffDate: date,
      searchFormDirty: true,
    });
    setSessionValue(
      SESSION_VALUE_KEYS.TGT_EFFDT,
      this.FILTER_KEYS.EFF_DATE,
      effDate
    );
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <Col md={2}>
              <span className="font-weight-bold gap-2">
                Catalog Effective Date
              </span>
              <DatePicker
                dateFormat={DATE_PICKER_FORMAT}
                selected={getRenderDate(this.state.catalogEffDate)}
                onChange={this.onDateChange}
                strictParsing
                className="form-control"
                maxDate={DateTime.now().toJSDate()}
              />
            </Col>
            <ChipAwareTextInput
              id="drugCategoryNames"
              name="drugCategoryNames"
              placeholder="Drug Category"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="drugNames"
              name="drugNames"
              placeholder="Target Drug Name"
              updateCallback={this.onSearchTermUpdate}
            />
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default TargetCategoriesSearchForm;
