import { GET_CLIENT_GROUPS, CLEAR_CLIENT_GROUPS } from "../actions/types";

const clientGroupReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENT_GROUPS:
      return action.payload;
    case CLEAR_CLIENT_GROUPS:
      return {};
    default:
      return state;
  }
};

export default clientGroupReducer;
