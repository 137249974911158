import React, { Component } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import {
  ChipAwareTextInput,
  DATE_CONVERT_FORMAT,
  DATE_PICKER_FORMAT,
} from "../../../utils";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ClientContractProductAuditLog extends Component {
  constructor(props) {
    super(props);

    const searchTerms = this.props.searchTerms
      ? this.props.searchTerms
      : {
          clientId: 0,
          searchDate: "",
          userNames: [],
          products: [],
          changeTypes: [],
          auditDescriptions: [],
        };

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: searchTerms,
    };
  }

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      this.props.onSubmit({ ...this.state.searchTerms });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  handleChange = (value) => {
    if (value) {
      this.setState({
        searchTerms: {
          ...this.state.searchTerms,
          searchDate: moment(value).format(DATE_CONVERT_FORMAT),
        },
        searchFormDirty: true,
      });
    } else {
      this.setState({
        searchTerms: {
          ...this.state.searchTerms,
          searchDate: "",
        },
        searchFormDirty: true,
      });
    }
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <Col md={1}>
              <label>Change Date</label>
              <DatePicker
                dateFormat={DATE_PICKER_FORMAT}
                placeholderText={DATE_PICKER_FORMAT}
                selected={Date.parse(this.state.searchTerms.searchDate) || null}
                onChange={(e) => this.handleChange(e)}
                isClearable={true}
                className="form-control"
              />
            </Col>
            <ChipAwareTextInput
              id="userNames"
              name="userNames"
              placeholder="User Name"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="products"
              name="products"
              placeholder="Product"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="changeTypes"
              name="changeTypes"
              placeholder="Change Type"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="auditDescriptions"
              name="auditDescriptions"
              placeholder="Audit Description"
              updateCallback={this.onSearchTermUpdate}
            />
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default ClientContractProductAuditLog;
