import axios from "axios";
import { loginRequest } from "../../api/clinicalBackend";
import { handleError } from "../../utils";
import { SET_STACK_DETAILS } from "../types";

export const STACK_TYPE = {
  backend: { url: "/actuator/info", stack: "Back End" },
  async: { url: process.env.REACT_APP_ASYNC_INFO_URL, stack: "Async" },
  batch: { url: process.env.REACT_APP_BATCH_INFO_URL, stack: "Batch" },
  frontend: { url: null, stack: "Front End" }
};

export const getStackDetails = stackType => async dispatch => {
  try {
    let requestor = loginRequest;
    if (stackType.stack !== STACK_TYPE.backend.stack) {
      requestor = axios.create({
        headers: { "Access-Control-Allow-Origin": "*" }
      });
    }

    const detailsData = await requestor.get(stackType.url);
    dispatch({
      type: SET_STACK_DETAILS,
      payload: [{ ...detailsData.data.git, stack: stackType.stack }]
    });
  } catch (err) {
    if (err.hasOwnProperty("data")) {
      handleError(err, dispatch);
    } else {
      console.warn(`Unable to retrieve info for ${stackType.stack}. ${err}`);
    }
  }
};
