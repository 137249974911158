import React, { Component } from "react";
import { connect } from "react-redux";
import { getClinicalSidebarItems } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  getDrugCategories,
  getDecisionGridStatuses,
  exportDecisionGrids,
  trySaveDecisionGrid,
  tryDeleteGrid,
  tryCopyGrid,
  tryGridSearch,
  tryUpdateDecisionGrid,
  activateDecisionGrid,
} from "../../../actions";
import _ from "lodash";
import { Container, Row, Col, Button, FormGroup, Input } from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Field } from "redux-form";
import { Link } from "react-router-dom";
import {
  DATE_FORMAT,
  getRenderDate,
  renderDatePicker,
  renderSelectInput,
  required,
  validSelect,
  Role,
  shortDateTimeFormatter,
  linkFormatter,
  VALID_EDIT_STATUSSES,
  HEADER_STYLES,
  COLUMN_LEGEND,
  DATE_PICKER_FORMAT,
  DATE_CONVERT_FORMAT,
} from "../../../utils";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import DecisionGridSearchForm from "./DecisionGridSearchForm";
import DatePicker from "react-datepicker";
import moment from "moment";
import FormModal from "../../Modal/FormModal";
import ActionButton from "../../DataGrid/ActionButton";
import {
  DC_COL_TOOLTIPS,
} from "../../../utils/models/drugCategory";
import { FaCircle } from "react-icons/fa";


class DecisionGrids extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      selectedGrids: [],
      isAddModalOpen: false,
      isAuthorized: _.some(this.props.roles, {
        name: Role.CLINICAL_APPROVER.name,
      }),
      isDeleteOpen: false,
      searchTerms: { names: [], statuses: [] },
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount = () => {
    this.setupBreadCrumbs();
    this.setupSidebar();
    this.getPage();
    this.props.getDrugCategories();
    this.props.getDecisionGridStatuses();
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [{ to: "/dashboard", name: "Home", active: false }],
      finalCrumb: "P&T Decision Grids",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  setupSidebar = () => {
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  toggleModal = () => {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen });
  };

  toggleDeleteModal = (grid) => {
    if (grid && grid.hasOwnProperty("drugCategory")) {
      const gridName = grid.drugCategory.description;
      const content = (
        <span>
          Are you sure you want to delete {gridName}? Deleting this grid will
          also <strong>REMOVE</strong> any associated clinical pairing workbook.
        </span>
      );

      this.setState({
        isDeleteOpen: !this.state.isDeleteOpen,
        dModalHeader: `Delete ${gridName}`,
        dModalContent: content,
        dModalAction: this.props.tryDeleteGrid,
        selectedGridId: grid.id,
      });
    } else {
      this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
    }
  };

  onAddSubmit = (formValues) => {
    formValues.approvalDate = formValues.approvalDate
      ? moment(formValues.approvalDate).format(DATE_CONVERT_FORMAT)
      : null;
    this.props.trySaveDecisionGrid(formValues);
  };

  actionFormatter = (_, row) => {
    return (
      <>
        {row.status.name === VALID_EDIT_STATUSSES.Draft &&
          this.state.isAuthorized && (
            <ActionButton
              onClick={() => this.toggleDeleteModal(row)}
              actionType="delete"
              actionColor="red"
              tooltip="Delete Clinical Decision Grid"
            />
          )}
        {row.status.name === VALID_EDIT_STATUSSES.Active &&
          this.state.isAuthorized && (
            <ActionButton
              onClick={() => this.props.tryCopyGrid(row.id)}
              actionType="copy"
              actionColor="blue"
              tooltip="Copy Clinical Decision Grid"
            />
          )}
        {row.status.name !== VALID_EDIT_STATUSSES.Draft &&
          this.state.isAuthorized && (
            <ActionButton
              actionType="locked"
              tooltip={`Clinical Decision Grid is not editable - Grid is ${row.status.name}`}
              disabled
            />
          )}
      </>
    );
  };

  descriptionFormatter = (content, row) => {
    return linkFormatter(content, `/grids/${row.id}`);
  };

  onDropDownChange = ({ currentTarget }) => {
    this.props.activateDecisionGrid(currentTarget.getAttribute("data-dgid"));
  };

  dropdownRenderer = (
    editorProps,
    value,
    row,
    column,
    rowIndex,
    columnIndex
  ) => {
    const options = _.filter(this.props.statuses, (status) => {
      return status.name === VALID_EDIT_STATUSSES.Active;
    });
    const selectedId = row.status.id;

    return (
      <Input
        type="select"
        id="status"
        name="status"
        value={selectedId}
        onChange={this.onDropDownChange}
        data-dgid={row.id}
        disabled={row.status.id === options[0].id}
      >
        <option>--Select--</option>
        {options.map((option) => {
          return (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          );
        })}
      </Input>
    );
  };

  datePickerRenderer = (
    editorProps,
    value,
    row,
    column,
    rowIndex,
    columnIndex
  ) => {
    return (
      <DatePicker
        id="approvalDate"
        name="approvalDate"
        dateFormat={DATE_PICKER_FORMAT}
        placeholderText="mm/dd/yyyy"
        selected={getRenderDate(value) || null}
        onChange={(newVal) => {
          const formattedDate = moment(newVal).format(DATE_FORMAT);
          row.approvalDate = formattedDate;
          this.props.tryUpdateDecisionGrid(row);
        }}
        autoComplete="off"
        strictParsing
        className="form-control"
        showYearDropdown
      />
    );
  };

  COLUMNS = [
    {
      dataField: "actions",
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "4%",
      },
      editable: false,
    },
    {
      dataField: "drugCategory.description",
      sort: true,
      text: "P&T Decision Grid Description",
      headerStyle: {
        width: "50%",
      },
      editable: false,
      formatter: this.descriptionFormatter,
    },
    {
      dataField: "clinicallySensitiveCode",
      sort: true,
      text: "Clinically Sensitive",
      formatter: (content, _) => {
        const className =
          content.code === "RED"
            ? "clinicallySensitive-red"
            : "clinicallySensitive-green";
        return <FaCircle className={className} title={content.description} />;
      },
      headerStyle: {
        width: "8%",
      },
      headerAttrs: {
        title: DC_COL_TOOLTIPS.CLINICALLY_SENSITIVE,
      },
      headerAlign: "center",
      align: "center",
      editable: false,
    },
    {
      dataField: "approvalDate",
      sort: true,
      formatter: shortDateTimeFormatter,
      text: "P&T Committee Date",
      headerStyle: {
        ...HEADER_STYLES.optionalEdit,
        width: "8%",
      },
      headerAlign: "center",
      align: "center",
      editorRenderer: this.datePickerRenderer,
      headerAttrs: {
        title: COLUMN_LEGEND.OPTIONAL,
      },
    },
    {
      dataField: "status.name",
      text: "Status",
      headerStyle: { ...HEADER_STYLES.optionalEdit, width: "8%" },
      headerAlign: "center",
      align: "center",
      editorRenderer: this.dropdownRenderer,
      headerAttrs: {
        title: COLUMN_LEGEND.OPTIONAL,
      },
    },
  ];

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selectedGrids: [...this.state.selectedGrids, row.id],
      }));
    } else {
      this.setState(() => ({
        selectedGrids: this.state.selectedGrids.filter((x) => x !== row.id),
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map((r) => r.id);
    if (isSelect) {
      this.setState(() => ({
        selectedGrids: ids,
      }));
    } else {
      this.setState(() => ({
        selectedGrids: [],
      }));
    }
  };

  getExportSelectRow = () => {
    if (this.state.isAuthorized) {
      return {
        mode: "checkbox",
        clickToSelect: false,
        headerColumnStyle: { width: "3%" },
        onSelect: this.handleOnSelect,
        onSelectAll: this.handleOnSelectAll,
      };
    }
    return;
  };

  exportSelectedGrids = () => {
    this.props.exportDecisionGrids(this.state.selectedGrids.join());
  };

  getPage = () => {
    this.props.tryGridSearch(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  getDisabledEditRows = () => {
    if (this.state.isAuthorized) {
      const ids = [];
      // If authorized, only grids in Draft or Active are editable
      this.props.grids.content.forEach((grid) => {
        if (
          grid.status.name !== VALID_EDIT_STATUSSES.Draft &&
          grid.status.name !== VALID_EDIT_STATUSSES.Active
        ) {
          ids.push(grid.id);
        }
      });
      return ids;
    }

    return this.props.grids.content.map((grid) => {
      return grid.id;
    });
  };

  getCellEditFactory = () => {
    return cellEditFactory({
      mode: "click",
      blurToSave: true,
      nonEditableRows: this.getDisabledEditRows,
    });
  };

  render() {
    if (_.isEmpty(this.props.grids) || _.isEmpty(this.props.statuses)) {
      return <div>Loading...</div>;
    }

    const buttonContainerClass = this.state.isAuthorized
      ? "justify-content-between"
      : "flex-row-reverse";

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>P&amp;T Decision Grids</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <DecisionGridSearchForm
              onSubmit={this.onSearchSubmit}
              statuses={this.props.statuses}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className={`d-flex ${buttonContainerClass}`}>
            {this.state.isAuthorized && (
              <Button
                color="secondary"
                disabled={!this.state.selectedGrids.length}
                onClick={this.exportSelectedGrids}
              >
                Export Selected Decision Grid(s)
              </Button>
            )}
            <div className="float-right">
              {this.state.isAuthorized && (
                <Button
                  className="me-5"
                  color="primary"
                  onClick={this.toggleModal}
                >
                  New P&amp;T Decision Grid
                </Button>
              )}
              <Button color="primary" tag={Link} to={"/grids-audit"}>
                View Audit Log
              </Button>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.grids.totalElements}
              data={this.props.grids.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              selectRow={this.getExportSelectRow()}
              cellEditFactory={this.getCellEditFactory()}
            />
          </Col>
        </Row>
        <FormModal
          isOpen={this.state.isAddModalOpen}
          toggle={this.toggleModal}
          header="Add P&amp;T Decision Grid"
          onSubmit={this.onAddSubmit}
        >
          <FormGroup>
            <Field
              id="drugCategoryId"
              name="drugCategoryId"
              type="select"
              label="Drug Category"
              required
              items={this.props.categories}
              valueProp="id"
              keyProp="id"
              displayProp="description"
              validate={[required, validSelect]}
              component={renderSelectInput}
            />
          </FormGroup>
          <FormGroup>
            <Field
              id="approvalDate"
              name="approvalDate"
              placeholder="P &amp; T Committee Date"
              component={renderDatePicker}
            />
          </FormGroup>
        </FormModal>
        <ConfirmationModal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedGridId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    grids: state.decisionGrids.grids,
    statuses: state.decisionGrids.statuses,
    categories: state.drugCategories.categories,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  getDrugCategories,
  getDecisionGridStatuses,
  exportDecisionGrids,
  trySaveDecisionGrid,
  tryDeleteGrid,
  tryCopyGrid,
  tryGridSearch,
  tryUpdateDecisionGrid,
  activateDecisionGrid,
})(DecisionGrids);
