import React, { Component } from "react";
import { connect } from "react-redux";
import { SIDEBAR_ITEMS } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  getGlossaryTermsList,
  clearGlossaryTerms,
  trySaveGlossaryTerm,
  tryUpdateGlossaryTerm,
  deleteGlossaryTerm,
  changeGlossaryTermOrder,
} from "../../../actions";
import _ from "lodash";
import { Container, Row, Col, Button, FormGroup } from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import FormModal from "../../Modal/FormModal";
import { Field } from "redux-form";
import {
  dateFormatter,
  minLength3,
  renderInput,
  number,
  required,
  Role,
  minValue,
  maxValue,
} from "../../../utils";
import {
  FaTrashAlt,
  FaPencilAlt,
  FaArrowDown,
  FaArrowUp,
} from "react-icons/fa";

class GlossaryView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      isAuthorized: _.some(this.props.roles, (role) => {
        return (
          role.name === Role.CLINICAL_APPROVER.name ||
          role.name === Role.CLINICIAN.name
        );
      }),
      breadcrumbsLoaded: false,
      formModalHeader: "Add Reporting Glossary of Terms",
      isAddModalOpen: false,
      deleteGlossaryTermRow: null,
      confirmContent: "",
    };
  }

  toggleModal = () => {
    const isAddModalOpen = !this.state.isAddModalOpen;
    if (!isAddModalOpen) {
      this.setState({
        isAddModalOpen,
        formInitialValues: null,
      });
    } else {
      this.setState({ isAddModalOpen });
    }
  };

  toggleConfirmModal = () => {
    const confirmContent = (
      <span>
        <p>
          Deleting <strong>{this.state.deleteGlossaryTermRow.term}</strong> will
          prevent this term from being shown on the Glossary of Terms for a
          report.
        </p>
        <br />
        Press Yes to delete.
      </span>
    );
    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
      confirmContent,
    });
  };

  onConfirmClicked = () => {
    const glossaryTermId = this.state.deleteGlossaryTermRow.id;
    this.props.deleteGlossaryTerm(glossaryTermId);
  };

  componentDidMount = () => {
    this.setupSidebar();
    this.props.getGlossaryTermsList();
    this.getPage();
  };

  componentDidUpdate = () => {
    if (
      !this.state.breadcrumbsLoaded &&
      !_.isEmpty(this.props.glossaryTerms.content)
    ) {
      this.setupBreadCrumbs();
      this.setState({ breadcrumbsLoaded: true });
    }
  };

  componentWillUnmount = () => {
    this.props.clearGlossaryTerms();
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/reporting", name: "Reporting", active: false },
      ],
      finalCrumb: "Glossary of Terms",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  getPage = () => {
    this.props.getGlossaryTermsList(
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  onAddSubmit = (formValues) => {
    const data = {
      reportOrder: parseInt(formValues.reportOrder),
      term: _.trim(formValues.term),
      termDescription: _.trim(formValues.termDescription),
    };

    if (formValues.termId) {
      data.id = formValues.termId;
      this.props.tryUpdateGlossaryTerm(data);
    } else {
      this.props.trySaveGlossaryTerm(data);
    }
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  actionFormatter = (content, row) => {
    return (
      <>
        <Button
          className="linkBtn tableBtn actionIconLinkPrimary"
          color="link"
          title="Delete Glossary Term"
          onClick={() => {
            this.setState({ deleteGlossaryTermRow: row }, () => {
              this.toggleConfirmModal();
            });
          }}
        >
          <FaTrashAlt />
        </Button>
        <Button
          className="linkBtn tableBtn actionIconLinkPrimary"
          color="link"
          title="Edit Glossary Term"
          onClick={() => {
            this.setState(
              {
                formInitialValues: {
                  termId: row.id,
                  reportOrder: row.reportOrder,
                  term: row.term,
                  termDescription: row.termDescription,
                },
                formModalHeader: "Edit Reporting Glossary of Terms",
              },
              () => {
                this.toggleModal();
              }
            );
          }}
        >
          <FaPencilAlt />
        </Button>
        {row.reportOrder < 99 && (
          <Button
            className="linkBtn tableBtn actionIconLinkPrimary"
            color="link"
            onClick={() => {
              this.props.changeGlossaryTermOrder(row.id, "DOWN");
            }}
          >
            <FaArrowDown />
          </Button>
        )}
        {row.reportOrder > 1 && (
          <Button
            className="linkBtn tableBtn actionIconLinkPrimary"
            color="link"
            onClick={() => {
              this.props.changeGlossaryTermOrder(row.id, "UP");
            }}
          >
            <FaArrowUp />
          </Button>
        )}
      </>
    );
  };

  getColumns = () => {
    const columns = [
      {
        dataField: "reportOrder",
        sort: true,
        text: "Order",
      },
      {
        dataField: "term",
        sort: true,
        text: "Term",
      },
      {
        dataField: "termDescription",
        sort: false,
        text: "Term Description",
      },
      {
        dataField: "updatedDate",
        text: "Last Updated",
        sort: false,
        formatter: dateFormatter,
      },
    ];
    if (this.state.isAuthorized) {
      columns.unshift({
        dataField: "actions",
        sort: false,
        text: "Action",
        formatter: this.actionFormatter,
        isDummyField: true,
        headerStyle: {
          width: "8%",
        },
      });
    }

    return columns;
  };

  render = () => {
    const glossaryTerms = this.props.glossaryTerms;
    if (_.isEmpty(glossaryTerms)) {
      return <h1>LOADING...</h1>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Reporting Glossary of Terms</h3>
          </Col>
        </Row>
        {this.state.isAuthorized && (
          <Row className="mt-3">
            <Col>
              <Button
                color="primary"
                onClick={() => {
                  this.setState(
                    {
                      formModalHeader: "Add Reporting Glossary of Terms",
                    },
                    () => {
                      this.toggleModal();
                    }
                  );
                }}
              >
                Add Glossary Term
              </Button>
            </Col>
          </Row>
        )}

        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.glossaryTerms.totalElements}
              data={this.props.glossaryTerms.content}
              columns={this.getColumns()}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <FormModal
          isOpen={this.state.isAddModalOpen}
          toggle={this.toggleModal}
          header={this.state.formModalHeader}
          onSubmit={this.onAddSubmit}
          initialValues={this.state.formInitialValues}
        >
          <FormGroup>
            <Field
              id="reportOrder"
              name="reportOrder"
              type="text"
              placeholder="Order"
              required
              validate={[required, number, minValue(1), maxValue(99)]}
              component={renderInput}
            />
          </FormGroup>
          <FormGroup>
            <Field
              id="term"
              name="term"
              type="text"
              placeholder="Term"
              required
              validate={[required, minLength3]}
              component={renderInput}
            />
          </FormGroup>
          <FormGroup>
            <Field
              id="termDescription"
              name="termDescription"
              type="text"
              placeholder="Term Description"
              required
              validate={[required, minLength3]}
              component={renderInput}
            />
          </FormGroup>
          <Field
            id="termId"
            name="termId"
            type="hidden"
            component={renderInput}
          />
        </FormModal>
        <ConfirmationModal
          isOpen={this.state.isConfirmModalOpen}
          toggle={this.toggleConfirmModal}
          header="Delete Glossary Term"
          content={this.state.confirmContent}
          action={this.onConfirmClicked}
          objId={0}
        />
      </Container>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    glossaryTerms: state.glossaryTerms,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  getGlossaryTermsList,
  clearGlossaryTerms,
  trySaveGlossaryTerm,
  tryUpdateGlossaryTerm,
  deleteGlossaryTerm,
  changeGlossaryTermOrder,
})(GlossaryView);
