import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { handleError } from "../../utils/error/errorHandlers";
import {
  ADD_PRODUCT_NOTIFICATION,
  CLEAR_PRODUCT_NOTIFICATIONS,
  GET_PRODUCT_NOTIFICATIONS,
  REMOVE_PRODUCT_NOTIFICATION
} from "../types";

const ENDPOINT = "/api/products/notifications";

export const getNotifications = contractId => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    const response = await getClinicalBackend(authToken).get(
      ENDPOINT + `?contractId=${contractId}`
    );
    dispatch({ type: GET_PRODUCT_NOTIFICATIONS, payload: response.data });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const saveNotification = notification => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    const response = await getClinicalBackend(authToken).post(
      ENDPOINT,
      JSON.stringify(notification)
    );
    dispatch({ type: ADD_PRODUCT_NOTIFICATION, payload: response.data });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const deleteNotification = id => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    await getClinicalBackend(authToken).delete(`${ENDPOINT}/${id}`);
    dispatch({ type: REMOVE_PRODUCT_NOTIFICATION, payload: id });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearProductNotifications = () => dispatch => {
  dispatch({ type: CLEAR_PRODUCT_NOTIFICATIONS });
};
