import React, { Component } from "react";
import { Button, Collapse, Row } from "reactstrap";
import { ChipAwareTextInput } from "../../../utils";
import { PlanSavingsChipInput } from "./Filters/PlanSavingsChipInput";

class DrugPairsSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: {
        names: [],
        categories: props.initialFilters
          ? props.initialFilters.drugCategory
          : [],
        excludes: [],
        errors: [],
        minMax: [-999999, 999999],
      },
    };
  }

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      this.props.onSubmit({ ...this.state.searchTerms });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  render = () => {
    const categoryInitialVals = this.props.initialFilters
      ? this.props.initialFilters.drugCategory
      : [];

    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <ChipAwareTextInput
              id="categories"
              name="categories"
              placeholder="Drug Category"
              initialValues={categoryInitialVals}
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="names"
              name="names"
              placeholder="Drug Name"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="excludes"
              name="excludes"
              placeholder="Exclude Reason"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="errors"
              name="errors"
              placeholder="Error Reason"
              updateCallback={this.onSearchTermUpdate}
            />
            <PlanSavingsChipInput
              name="minMax"
              initialMin={this.props.initialMinMax.min}
              initialMax={this.props.initialMinMax.max}
              updateCallback={this.onSearchTermUpdate}
            />
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default DrugPairsSearchForm;
