import { getClinicalBackend } from "../../api/clinicalBackend";
import {
  GET_UPLOADED_ROLLINS_REPORTS,
  UPLOAD_ROLLINS_REPORT,
  DELETE_ROLLINS_REPORT,
  GET_ROLLINS_REPORT_NOTIFICATIONS,
  CLEAR_ROLLINS_REPORT_NOTIFICATIONS,
} from "../types";
import { tryRefreshToken } from "../auth/authAction";
import { handleError, formatPageableParameters } from "../../utils";
import history from "../../history";

export const getUploadedRollinsReport =
  (pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `api/roll-ins-reports?${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null
      )}`;

      const uploadedRollinsReports = await getClinicalBackend(authToken).get(
        endPoint
      );
      dispatch({
        type: GET_UPLOADED_ROLLINS_REPORTS,
        payload: uploadedRollinsReports.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const uploadRollinsReport = (formData) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = `api/roll-ins-reports`;

    const uploadedRollinsReports = await getClinicalBackend(authToken).post(
      endPoint,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    );
    dispatch({
      type: UPLOAD_ROLLINS_REPORT,
      payload: uploadedRollinsReports.data,
    });
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const deleteRollinsReport = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = `api/roll-ins-reports/${id}`;

    const deletedRollinsReport = await getClinicalBackend(authToken).delete(
      endPoint
    );
    dispatch({
      type: DELETE_ROLLINS_REPORT,
      payload: deletedRollinsReport.data,
    });
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getRollinsReportNotifications =
  (reportId, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `api/roll-ins-report-notifications/?reportId=${reportId}${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null
      )}`;

      const rollinsReportNotifications = await getClinicalBackend(
        authToken
      ).get(endPoint);
      dispatch({
        type: GET_ROLLINS_REPORT_NOTIFICATIONS,
        payload: rollinsReportNotifications.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearRollinsReportNotication = () => (dispatch) => {
  dispatch({ type: CLEAR_ROLLINS_REPORT_NOTIFICATIONS });
};
