import { getClinicalBackend } from "../../api/clinicalBackend";
import {
  CHECK_WORKBOOK_EXISTENCE,
  CLEAR_APPROVE_WORKBOOK,
  GET_PREVIOUSLY_APPROVED_WORKBOOK,
  GET_WORKBOOK,
  GET_WORKBOOKS,
  LOADING,
  PAIRING_WORKBOOK_APPROVAL_LOADING,
  TRY_APPROVE_WORKBOOK,
  TRY_WORKBOOK_SEARCH,
} from "../types";
import { tryRefreshToken } from "../auth/authAction";
import {
  downloadExcelFile,
  EXCEL_DOWNLOAD_OPTIONS,
  formatPageableParameters,
  handleError,
} from "../../utils";

import history from "../../history";

export const getWorkbooks =
  (pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      const endPoint = `/api/workbooks?${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null
      )}`;

      const workbooks = await getClinicalBackend(authToken).get(endPoint);
      dispatch({
        type: GET_WORKBOOKS,
        payload: workbooks.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryWorkbookSearch =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/workbooks/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null
      )}`;
      const workbooks = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms)
      );

      dispatch({ type: TRY_WORKBOOK_SEARCH, payload: workbooks.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getWorkbook = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/workbooks/${id}`;
    const workbook = await getClinicalBackend(authToken).get(endpoint);
    dispatch({
      type: GET_WORKBOOK,
      payload: workbook.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getPreviouslyApprovedWorkbook =
  (dCatId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/workbooks/approved?dCatId=${dCatId}`;
      const workbook = await getClinicalBackend(authToken).get(endpoint);
      dispatch({
        type: GET_PREVIOUSLY_APPROVED_WORKBOOK,
        payload: workbook.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const existenceCheck = (dgId, status) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/workbooks?dgId=${dgId}&status=${status}`;
    const workbook = await getClinicalBackend(authToken).get(endpoint);
    dispatch({
      type: CHECK_WORKBOOK_EXISTENCE,
      payload: workbook.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryDeleteWorkbook = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/workbooks/${id}`;
    await getClinicalBackend(authToken).delete(endpoint);
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryApproveWorkbook = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/workbooks/approve/${id}`;
    dispatch({ type: PAIRING_WORKBOOK_APPROVAL_LOADING });
    const approvalResponse = await getClinicalBackend(authToken).post(endpoint);
    dispatch({ type: TRY_APPROVE_WORKBOOK, payload: approvalResponse.data });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearApproveWorkbook = () => (dispatch) => {
  dispatch({ type: CLEAR_APPROVE_WORKBOOK });
};

export const tryPairWorkbook = (gridId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endpoint = `/api/workbooks/?gridId=${gridId}`;
    const workbook = await getClinicalBackend(authToken).post(endpoint);
    history.push(`/workbooks/${workbook.data.workbook.id}`);
  } catch (err) {
    handleError(err.response, dispatch);
    return { error: true };
  }
};

/**
 * @param {string} ids comma separated ids of workbooks to download
 * @returns
 */
export const exportWorkbooks = (ids) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const endPoint = `/api/workbook-reports/?ids=${ids}`;
    const numIds = ids.split(",").length;

    dispatch({
      type: LOADING,
      payload: {
        loading: true,
        message: `Exporting ${numIds} Workbook(s). Please do not leave this page or refresh your browser.`,
      },
    });

    const workbookExport = await getClinicalBackend(authToken).get(
      endPoint,
      EXCEL_DOWNLOAD_OPTIONS
    );

    downloadExcelFile(workbookExport);
    dispatch({ type: LOADING, payload: { loading: false } });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const exportAllWorkbooks =
  (searchTerms) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = "/api/workbook-reports";

      dispatch({
        type: LOADING,
        payload: {
          loading: true,
          message:
            "Exporting All Workbooks. Please do not leave this page or refresh your browser.",
        },
      });

      const workbookExport = await getClinicalBackend(authToken).post(
        endPoint,
        JSON.stringify(searchTerms),
        EXCEL_DOWNLOAD_OPTIONS
      );

      downloadExcelFile(workbookExport);
      dispatch({ type: LOADING, payload: { loading: false } });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
