import _ from "lodash";
import {
  GET_GP_FILE_CONTENT,
  CLEAR_FILE_CONTENT,
  GET_CLIENT_GROUPS,
  CLEAR_CLIENT_GROUPS,
} from "../actions/types";
import { ConfigFile } from "../components/ClientManagement/ConfigFiles/fileTypes";

const gpFileContentReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_GP_FILE_CONTENT:
      return action.payload;
    case CLEAR_FILE_CONTENT:
      if (_.includes(action.payload, ConfigFile.GP)) {
        return {};
      }
      return state;
    case GET_CLIENT_GROUPS:
      return action.payload;
    case CLEAR_CLIENT_GROUPS:
      return {};
    default:
      return state;
  }
};

export default gpFileContentReducer;
