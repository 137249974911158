import React, { Component } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import { ChipAwareTextInput, MultiSelectCheckbox } from "../../../utils";
import _ from "lodash";

class WorkbookEntrySearchForm extends Component {
  constructor(props) {
    super(props);

    const basePairingTypes = {
      GPI_MSC_TO_GPI_MSC: {
        id: 1,
        name: "GPI/MSC to GPI/MSC",
        value: "GPI_MSC_TO_GPI_MSC",
      },
      GPI_MSC_TO_NDC: {
        id: 2,
        name: "GPI/MSC to NDC",
        value: "GPI_MSC_TO_NDC",
      },
      NDC_TO_GPI_MSC: {
        id: 3,
        name: "NDC to GPI/MSC",
        value: "NDC_TO_GPI_MSC",
      },
      NDC_TO_NDC: { id: 4, name: "NDC to NDC", value: "NDC_TO_NDC" },
      NOT_PAIRED: { id: 5, name: "Not Paired", value: "NOT_PAIRED" },
    };

    const pairingTypes = _.sortBy(
      _.map(this.props.pairingTypes, (type) => {
        return basePairingTypes[type];
      }),
      "id",
    );

    _.forEach(pairingTypes, (type, key) => {
      type.id = key + 1;
      type.displayName = type.name;
      type.checked = true;
    });

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: {
        names: [],
        pdcs: [],
        gpis: [],
        noValidNDCs: null,
        pairingTypes: [],
      },
      noValidNDCsItem: {
        id: 1,
        name: "onlyNoValid",
        displayName: "Only show rows with Missing AR Valid NDC's",
        value: "Y",
        checked: false,
      },
      pairingTypes,
      showMissingValidNDCCheckbox: props.showMissingValidNDCCheckbox ?? true,
    };
  }

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      const pairingTypes = _.filter(
        _.map(this.state.pairingTypes, (val) => {
          if (val.checked) {
            return val.value;
          }
        }),
        (val) => {
          return val !== undefined;
        },
      );
      this.props.onSubmit({ ...this.state.searchTerms, pairingTypes });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  onCheckboxClick = (e) => {
    const element = e.currentTarget;
    const item = this.state.noValidNDCsItem;
    item.checked = element.checked;
    const showOnlyNoValidNDCs = element.checked ? "N" : "Y";
    this.setState({
      noValidNDCsItem: item,
      searchFormDirty: true,
      searchTerms: {
        ...this.state.searchTerms,
        noValidNDCs: showOnlyNoValidNDCs,
      },
    });
  };

  onPairingTypeCheckboxClick = (e) => {
    const element = e.currentTarget;
    const items = this.state.pairingTypes;
    items[parseInt(element.id) - 1].checked = element.checked;
    this.setState({ pairingTypes: items, searchFormDirty: true });
  };

  onCheckSelectAll = (e) => {
    const items = this.state.pairingTypes;
    _.forEach(items, (item) => {
      item.checked = e.currentTarget.checked;
    });
    this.setState({ pairingTypes: items, searchFormDirty: true });
  };

  render = () => {
    const gpiLabel = this.props.hasNDCDPOs ? "GPI or NDC" : "GPI";
    return (
      <>
        <Collapse isOpen={this.state.isOpen} className="mb-5">
          <Row>
            <ChipAwareTextInput
              id="name"
              name="names"
              placeholder="Target Drug Name"
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="pdcs"
              name="pdcs"
              placeholder="PDC"
              minLength={1}
              updateCallback={this.onSearchTermUpdate}
            />
            <ChipAwareTextInput
              id="gpis"
              name="gpis"
              placeholder={gpiLabel}
              updateCallback={this.onSearchTermUpdate}
            />
            <Col md={2}>
              <MultiSelectCheckbox
                itemOnChange={this.onPairingTypeCheckboxClick}
                selectAllOnChange={this.onCheckSelectAll}
                header="Pairing Type"
                items={this.state.pairingTypes}
              />
            </Col>
            {this.state.showMissingValidNDCCheckbox && (
              <Col>
                <MultiSelectCheckbox
                  header="Missing AR Valid NDC's"
                  items={[this.state.noValidNDCsItem]}
                  itemOnChange={this.onCheckboxClick}
                  showSelectAll={false}
                  headerClass="form-error"
                />
              </Col>
            )}
          </Row>
        </Collapse>
        <div className="toggle-container pt-3">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </>
    );
  };
}

export default WorkbookEntrySearchForm;
