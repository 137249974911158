import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import {
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getClient,
  clearClients,
  tryAdditionalTargetDrugSearch,
  clearAdditionalTargetDrugs,
  deleteAdditionalTargetDrug,
  tryUpdateAdditionalTargetDrug,
} from "../../../../actions";
import {
  dateFormatter,
  optionalNAValueFormatter,
  DATE_FORMAT,
  getRenderDate,
  DATE_PICKER_FORMAT,
} from "../../../../utils";
import RPSDataGrid from "../../../DataGrid/RPSDataGrid";
import cellEditFactory from "react-bootstrap-table2-editor";
import ProviderAdministeredDrugsSearchForm from "./ProviderAdministeredDrugsSearchForm";
import _ from "lodash";
import AssignedAltsList from "./AssignedAltsList";
import { Link } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import moment from "moment";
import DatePicker from "react-datepicker";

class ProviderAdministeredDrugs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: props.match.params.clientId,
      contractId: parseInt(props.match.params.contractId),
      navLoaded: false,
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      searchTerms: {
        names: [],
        gpis: [],
      },
      isDeleteOpen: false,
    };
  }

  componentDidMount = () => {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.getPage();
  };

  componentWillUnmount = () => {
    this.props.toggleSidebarVisibility();
    this.props.clearAdditionalTargetDrugs();
  };

  componentDidUpdate = () => {
    if (this.props.selectedClient && !this.state.navLoaded) {
      this.setState({ navLoaded: true });
      this.setupBreadCrumbs();
    }
  };

  setupBreadCrumbs = () => {
    const contractId = this.state.contractId;
    const isAdd = isNaN(contractId) ? true : false;
    const client = this.props.selectedClient;

    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        { to: `/clients/view/${client.id}`, name: client.name, active: false },
        {
          to: isAdd
            ? `/contracts/add/${client.id}`
            : `/contracts/edit/${this.state.contractId}`,
          state: { clientId: client.id },
          name: isAdd ? "Add Client Product" : "Edit Client Product",
          active: false,
        },
      ],
      finalCrumb: `${client.name}'s Provider Administered Drug List`,
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  toggleDeleteModal = (target) => {
    if (target && target.hasOwnProperty("name")) {
      const name = target.name;
      this.setState({
        isDeleteOpen: !this.state.isDeleteOpen,
        dModalHeader: `Delete ${name}`,
        dModalContent: `Are you sure you want to delete ${name}?`,
        dModalAction: this.props.deleteAdditionalTargetDrug,
        selectedTargetId: target.id,
      });
    } else {
      this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
    }
  };

  alternativesFormatter = (alts) => {
    return <AssignedAltsList alts={alts} />;
  };

  actionFormatter = (_1, row) => {
    return (
      <div>
        <Button
          className="linkBtn tableBtn actionIconBtnDanger"
          color="link"
          onClick={() => this.toggleDeleteModal(row)}
        >
          <FaTrashAlt />
        </Button>
      </div>
    );
  };

  datePickerRenderer = (
    editorProps,
    value,
    row,
    column,
    rowIndex,
    columnIndex
  ) => {
    const dataField = column.dataField;
    const targets = this.props.targets.content;

    const duplicateGpiMsc = _.find(targets, (target) => {
      return (
        target.gpi === row.gpi && target.msc === row.msc && target.id !== row.id
      );
    });

    let minDate = moment().startOf("day").toDate();
    if (duplicateGpiMsc) {
      const duplicateDate = moment(duplicateGpiMsc[dataField])
        .startOf("day")
        .toDate();

      if (moment(duplicateDate).isSame(minDate)) {
        minDate = moment().add(1, "days").toDate();
      }
    }

    // When the effective date is in the future, min term date must be a day after the set effective date
    if (
      dataField === "termDate" &&
      (moment(row.effectiveDate).isAfter(minDate) ||
        moment(row.effectiveDate).isSame(minDate))
    ) {
      minDate = moment(row.effectiveDate).add(1, "days").toDate();
    }

    return (
      <DatePicker
        id="targetDate"
        name="targetDate"
        dateFormat={DATE_PICKER_FORMAT}
        placeholderText={DATE_PICKER_FORMAT}
        selected={getRenderDate(value) || null}
        onChange={(newVal) => {
          const formattedDate = moment(newVal).format(DATE_FORMAT);
          row[dataField] = formattedDate;

          if (
            dataField === "effectiveDate" &&
            moment(newVal) > moment(row.termDate)
          ) {
            row.termDate = null;
          }

          this.props.tryUpdateAdditionalTargetDrug(row);
        }}
        autoComplete="off"
        strictParsing
        className="form-control"
        showYearDropdown
        minDate={minDate}
      />
    );
  };

  COLUMNS = [
    {
      dataField: "actions",
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "2%",
      },
      editable: false,
    },
    {
      dataField: "name",
      sort: true,
      text: "Targeted Drug Name",
      headerStyle: {
        width: "20%",
      },
      editable: false,
    },
    {
      dataField: "gpi",
      sort: true,
      text: "GPI",
      headerStyle: {
        width: "6%",
      },
      editable: false,
    },
    {
      dataField: "multiSource",
      sort: false,
      text: "MSC",
      editable: false,
      headerStyle: {
        width: "4%",
      },
    },
    {
      dataField: "adminType",
      sort: false,
      text: "Administered By",
      editable: false,
      headerStyle: {
        width: "6%",
      },
      formatter: optionalNAValueFormatter,
    },
    {
      dataField: "courseDayCount",
      sort: false,
      text: "Days per Course",
      editable: false,
      headerStyle: {
        width: "7%",
      },
      formatter: optionalNAValueFormatter,
    },
    {
      dataField: "effectiveDate",
      sort: true,
      text: "Effective Date",
      editable: true,
      formatter: dateFormatter,
      editorRenderer: this.datePickerRenderer,
      headerStyle: {
        width: "7%",
      },
    },
    {
      dataField: "termDate",
      sort: true,
      text: "Termination Date",
      editable: true,
      formatter: dateFormatter,
      editorRenderer: this.datePickerRenderer,
      headerStyle: {
        width: "7%",
      },
    },
    {
      dataField: "alternatives",
      sort: false,
      text: "Assigned Alternative(s)",
      editable: false,
      formatter: this.alternativesFormatter,
    },
  ];

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  getPage = () => {
    this.props.tryAdditionalTargetDrugSearch(
      this.state.clientId,
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      if (sortField === "name") {
        sortField = "drugName.commonName";
      }

      sortField = {
        dataField: sortField,
        order: sortOrder,
      };

      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  getCellEditFactory = () => {
    return cellEditFactory({ mode: "click", blurToSave: true });
  };

  render() {
    if (
      _.isEmpty(this.props.targets) ||
      !this.props.targets.content ||
      !this.props.selectedClient
    ) {
      return <h1>LOADING...</h1>;
    }

    const selectedClient = this.props.selectedClient;
    const contractId = this.state.contractId;

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>{selectedClient.name}'s Provider Administered Drug List</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <ProviderAdministeredDrugsSearchForm
              onSubmit={this.onSearchSubmit}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-between">
            <Button
              color="primary"
              tag={Link}
              to={{
                pathname: `/products/provider-drugs/client/${selectedClient.id}/add`,
                state: { contractId: this.state.contractId },
              }}
            >
              Add Targeted GPI/MSC
            </Button>
            <div className="float-right">
              <Button
                color="primary"
                tag={Link}
                to={`/products/provider-drugs/audit/${contractId}/${selectedClient.id}`}
              >
                View Audit Log
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex flex-row-reverse">
              <span>
                The Alternatives listed on the Provider Administered drug list
                are from the&nbsp;
                <Link to={`/targetCategories`}>current clinical catalog</Link>
              </span>
            </div>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.targets.totalElements}
              data={this.props.targets.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              cellEditFactory={this.getCellEditFactory()}
            />
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedTargetId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    targets: state.additionalTargetDrugs.targets,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getClient,
  clearClients,
  tryAdditionalTargetDrugSearch,
  clearAdditionalTargetDrugs,
  deleteAdditionalTargetDrug,
  tryUpdateAdditionalTargetDrug,
})(ProviderAdministeredDrugs);
