import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { DATE_PRESENTATION_FORMAT } from "../../../utils";

const CatalogBenefitHeader = (props) => {
  const catalogDate = DateTime.fromISO(props.mpd.catalogDate);
  return (
    <>
      <div>
        <b>Catalog Effective Date:</b>{" "}
        {catalogDate.toFormat(DATE_PRESENTATION_FORMAT)}
      </div>
      <div>
        <b>Benefit:</b> {props.mpd.benefit.benefitName}
      </div>
    </>
  );
};

export default CatalogBenefitHeader;

CatalogBenefitHeader.propTypes = {
  mpd: PropTypes.object.isRequired,
};
