import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

export default function MessagesRow({ messages }) {
  return (
    <div className="d-flex mt-3">
      <span>
        <strong>
          <u>Messages </u>
        </strong>
      </span>
      <ul>
        {_.map(messages, (message) => {
          const style = message.description.startsWith("ERR")
            ? "form-error"
            : "";

          return (
            <li key={message.id} className={`list-unstyled ${style}`}>
              {message.description}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

MessagesRow.propTypes = {
  messages: PropTypes.array.isRequired,
};
