import { GET_WEBPAGE_PROPERTIES } from "../actions/types";

const webpagePropertyReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WEBPAGE_PROPERTIES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default webpagePropertyReducer;
