import {
  GET_ALL_NDC_DRUG_PAIRING_OPTIONS,
  CLEAR_NDC_DRUG_PAIRING_OPTIONS,
  UPDATE_NDC_DPO,
} from "../actions/types";
import _ from "lodash";

const initialState = {
  dpos: {},
};

const ndcDrugPairingOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NDC_DRUG_PAIRING_OPTIONS:
      return { ...state, dpos: action.payload };
    case CLEAR_NDC_DRUG_PAIRING_OPTIONS:
      return { initialState };
    case UPDATE_NDC_DPO:
      const dpos = { ...state.dpos };
      const dpoIndex = _.findIndex(dpos.content, { id: action.payload.id });
      dpos.content[dpoIndex] = action.payload;
      return { ...state, dpos };
    default:
      return state;
  }
};

export default ndcDrugPairingOptionsReducer;
