import React, { Component } from "react";
import { Button, Collapse, Row } from "reactstrap";
import { ChipAwareTextInput } from "../../../utils";
import { ClientDropDown } from "../components/ClientDropDown";

class ReportSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      searchFormDirty: false,
      searchTerms: { users: [], clientId: null }
    };
  }

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true
    });
  };

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      this.props.onSubmit({ ...this.state.searchTerms });
      this.setState({ searchFormDirty: false });
    }
  };

  handleClientChange = ({ currentTarget }) => {
    this.onSearchTermUpdate(
      "clientId",
      currentTarget.value === "all" ? null : currentTarget.value
    );
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row>
            <ClientDropDown
              label="Client"
              id="client"
              onChange={this.handleClientChange}
              clients={this.props.clients}
            />

            <ChipAwareTextInput
              id="users"
              name="users"
              placeholder="Generated By"
              updateCallback={this.onSearchTermUpdate}
              initialValues={[this.props.currentUser]}
            />
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default ReportSearchForm;
