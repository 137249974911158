import _ from "lodash";

export const PairingType = {
  GPI_TO_GPI: "GPI.MSC to GPI.MSC",
  GPI_TO_NDC: "GPI.MSC to NDC",
  NDC_TO_GPI: "NDC to GPI.MSC",
  NDC_TO_NDC: "NDC to NDC"
};

export const isLcaNDC = pairingType => {
  return _.some(
    [PairingType.GPI_TO_NDC, PairingType.NDC_TO_NDC],
    x => x === pairingType
  );
};

export const isHcaNDC = pairingType => {
  return _.some(
    [PairingType.NDC_TO_GPI, PairingType.NDC_TO_NDC],
    x => x === pairingType
  );
};
