import {
  GET_TARGET_CATEGORY_SUMMARIES,
  GET_FILTERED_TARGET_CATEGORY_SUMMARIES,
  CLEAR_TARGET_CATEGORIES,
} from "../actions/types";

const INITIAL_STATE = {};

const targetCategorySummariesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TARGET_CATEGORY_SUMMARIES:
      return { ...state, ...action.payload };
    case GET_FILTERED_TARGET_CATEGORY_SUMMARIES:
      return action.payload;
    case CLEAR_TARGET_CATEGORIES:
      return {};
    default:
      return state;
  }
};

export default targetCategorySummariesReducer;
