import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
} from "reactstrap";
import RPSDataGrid from "../DataGrid/RPSDataGrid";
import "./NDCPriceModal.css";
import Draggable from "react-draggable";
import { FaArrowsAlt } from "react-icons/fa";
import { getDashedNDC, getDateString } from "../../utils";

const NDCPriceModal = (props) => {
  const drugData = props.drugData;
  let ndcPrices = _.isArray(props.ndcPrices) ? props.ndcPrices : [];

  if (drugData.ndc && ndcPrices.length > 1) {
    const reordered = _.filter(ndcPrices, (price) => {
      return price.ndc !== drugData.ndc;
    });
    const matching = _.find(ndcPrices, { ndc: drugData.ndc });

    ndcPrices = [...reordered];
    ndcPrices.unshift(matching);
  }

  const ndcPairingContentFormatter = (content, row) => {
    if (drugData.ndc && row.ndc) {
      if (row.ndc !== drugData.ndc) {
        return <span className="nonMatchNdcLabel">{content}</span>;
      } else {
        return <span className="halfBold">{content}</span>;
      }
    }
    return content;
  };

  const dateFormatter = (content, row) => {
    return ndcPairingContentFormatter(getDateString(content), row);
  };

  const SEQUENCE_COLUMN = [
    {
      dataField: "sequence",
      sort: true,
      text: "Catalog rNDC",
    },
  ];
  const COLUMNS = [
    {
      dataField: "ndc",
      sort: true,
      text: "NDC #",
      formatter: (content) => {
        if (content) {
          const formattedNDC = getDashedNDC(content);
          if (drugData.ndc) {
            if (content === drugData.ndc) {
              return <span className="ndcLabel">{formattedNDC}</span>;
            } else {
              return <span className="nonMatchNdcLabel">{formattedNDC}</span>;
            }
          }
        }

        return content;
      },
    },
    {
      dataField: "drugName",
      sort: true,
      text: "NDC Drug Name",
      formatter: ndcPairingContentFormatter,
    },
    {
      dataField: "packageSize",
      sort: false,
      text: "Package Size",
      formatter: ndcPairingContentFormatter,
    },
    {
      dataField: "packageSizeUOM",
      sort: false,
      text: "UOM",
      formatter: ndcPairingContentFormatter,
    },
    {
      dataField: "unitPrice",
      sort: false,
      text: "AWP Unit $",
      formatter: ndcPairingContentFormatter,
    },
    {
      dataField: "priceEffectiveDate",
      sort: true,
      text: "Price Eff. Date",
      formatter: dateFormatter,
    },
  ];

  return ReactDOM.createPortal(
    <Draggable>
      <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
        <ModalHeader
          className="ar-movable-modal-background-color"
          toggle={() => {
            props.clearAction();
            props.toggle();
          }}
        >
          <FaArrowsAlt />
          <span>
            {`  Valid-NDC’s Price List for: ${drugData.commonDrugName}
              (${drugData.gpi}/${drugData.msc})`}
          </span>
        </ModalHeader>
        <ModalBody>
          <Container className="data-grid-scroll-container">
            <Row className="header-row">
              <Col>
                <div>
                  <RPSDataGrid
                    keyField="ndc"
                    data={ndcPrices}
                    columns={
                      props.showSequence
                        ? [...SEQUENCE_COLUMN, ...COLUMNS]
                        : COLUMNS
                    }
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Row />

          <Button
            color="primary"
            onClick={() => {
              props.clearAction();
              props.toggle();
            }}
          >
            Done
          </Button>
        </ModalFooter>
      </Modal>
    </Draggable>,
    document.querySelector("#modal"),
  );
};

export default NDCPriceModal;
