import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BiCategory, BiMoney } from "react-icons/bi";
import { BsFillBookmarksFill, BsFillPersonLinesFill } from "react-icons/bs";
import { BsChevronBarLeft, BsChevronBarRight } from "react-icons/bs";
import { CgPerformance } from "react-icons/cg";
import { MdSavings } from "react-icons/md";
import {
  FaFile,
  FaFileDownload,
  FaPills,
  FaScroll,
  FaSearchPlus,
} from "react-icons/fa";
import { GiBandageRoll } from "react-icons/gi";
import {
  GrCatalog,
  GrConfigure,
  GrCube,
  GrDocumentCsv,
  GrDocumentConfig,
  GrStatusInfo,
} from "react-icons/gr";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { IoMdBowtie } from "react-icons/io";
import { MdCategory, MdGridOff, MdGridOn } from "react-icons/md";
import "./sidebar.css";
import { Button } from "reactstrap";
import { toggleSidebarCollapse } from "../../actions";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  getActions = () => {
    const markup = [];
    this.props.actions.forEach((action) => {
      switch (action.type) {
        case ActionType.SINGLE_LINK:
          markup.push(this.singles(action.bundle));
          break;
        case ActionType.LINK_LIST:
          markup.push(this.links(action.bundle));
          break;
        default:
          break;
      }
    });
    return markup;
  };

  //TODO: The onClick doesn't actually fix the problem unless it's applied to anything that can navigate you to a new page. Needs to clear state in a lifecycle event for the component
  singles = (bundle) => {
    return (
      <li key={bundle.key} className="sidebar-list">
        <Link
          to={bundle.path}
          onClick={() => this.setState({ searchValue: "" })}
        >
          {bundle.name}
        </Link>
      </li>
    );
  };

  getIcon = (icon) => {
    return SidebarIcon[icon];
  };

  links = (bundle) => {
    const hideClass = this.state.isOpen ? "" : "d-none";
    return (
      <li key={bundle.key} className="sidebar-list">
        <span className={`link-list-action-header ${hideClass}`}>
          {bundle.header}
        </span>
        <ul className="collapse list-unstyled show" id={bundle.key}>
          {bundle.children.map((child, index) => (
            <li key={index}>
              <Link
                to={child.path}
                className="d-flex align-items-center"
                title={child.name}
              >
                {child.icon}
                <span className={`ps-2 sidebarLinkText ${hideClass}`}>
                  {child.name}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </li>
    );
  };

  onCollapseClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.props.toggleSidebarCollapse();
  };

  render() {
    const actions = this.getActions();

    return (
      <nav
        id="sidebar"
        className={"gray-700 " + (this.props.visible ? "" : "sidebar-hidden")}
      >
        <div className="sidebar-header">
          <Button
            id="sidebarCollapse"
            color="link"
            onClick={this.onCollapseClick}
          >
            {this.state.isOpen ? (
              <BsChevronBarLeft color="white" className="sidebar-ctrl-icon" />
            ) : (
              <BsChevronBarRight color="white" className="sidebar-ctrl-icon" />
            )}
          </Button>
        </div>

        <ul className="list-unstyled components">
          {actions.map((action) => action)}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    visible: state.sidebar.visible,
    actions: state.sidebar.actions,
  };
};

export default connect(mapStateToProps, { toggleSidebarCollapse })(SideBar);

export const ActionType = {
  SINGLE_LINK: "singleLink",
  LINK_LIST: "linkList",
};

export const SidebarIcon = {
  CLINICAL_CATALOG: <GrCatalog className="grIcon sidebarIcon" />,
  CATEGORY_REF: <GrCube className="grIcon sidebarIcon" />,
  DRUG_CATEGORY: <MdCategory className="sidebarIcon" />,
  DECISION_GRID: <MdGridOn className="sidebarIcon" />,
  WORKBOOK: <MdGridOff className="sidebarIcon" />,
  ARTAS: <FaFile className="sidebarIcon" />,
  CAT_DELIVERY_LIST: <FaFileDownload className="sidebarIcon" />,
  TIE_IN: <IoMdBowtie className="sidebarIcon" />,
  BENEFIT: <GrConfigure className="grIcon sidebarIcon" />,
  CLAIMS_LOAD: <AiOutlineLoading3Quarters className="sidebarIcon" />,
  CLAIMS_SEARCH: <FaSearchPlus className="sidebarIcon" />,
  CONFIG_FILES: <GrDocumentConfig className="grIcon sidebarIcon" />,
  ELIGIBILITY_LOAD: <GrStatusInfo className="grIcon sidebarIcon" />,
  ROLL_INS: <GiBandageRoll className="sidebarIcon" />,
  DRUG_CAT_FILL_FACTOR: <BiCategory className="sidebarIcon" />,
  GPI_FILL_FACTOR: <FaPills className="sidebarIcon" />,
  PERFORMANCE: <CgPerformance className="sidebarIcon" />,
  SAVINGS: <MdSavings className="sidebarIcon" />,
  LEGACY_PERFORMANCE: <HiOutlineDocumentReport className="sidebarIcon" />,
  BILLING_MEMBERSHIP: <BsFillPersonLinesFill className="sidebarIcon" />,
  UTILIZATION: <BiMoney className="sidebarIcon" />,
  CLIENT_ROLL_IN: <FaScroll className="sidebarIcon" />,
  INTERNAL_CLINICAL_CATALOG: <GrDocumentCsv className="sidebarIcon" />,
  GLOSSARY: <BsFillBookmarksFill className="sidebarIcon" />,
};

/**
 * Action is an object that defines the type of action to be rendered and the bundle of items that
 * make up the actions for the current component.
 *
 * @typedef {Object} Action
 * @property {string} type - Type of action to be rendered. Use ActionType constant for known types
 * @property {Object} Bundle - Bundle of items to be rendered for the action.
 */

/**
 * Bundle contains the items to be rendered for the given action type.
 *
 * @typedef {Object} Bundle
 * @property {string} header - Text to display as the header for the action
 * @property {string} key - Needs to be unique across provided actions for a given screen
 * @property {Object[]} children - Available on DROPDOWN, LINK_LIST, and FILTER actions. Contains items to be rendered
 * @property {string} name - Available on SINGLE_LINK actions. Text to display for the action
 * @property {string} path - Available on SINGLE_LINK actions. Route the action should take the user to
 */

/* exampleActions is an example of a fleshed out array of actions to render in the Sidebar. */
/*exampleActions = [
    {
      type: this.ActionType.SINGLE_LINK,
      bundle: {
        key: "single1",
        name: "Single 1",
        path: "#single1"
      }
    },
    {
      type: this.ActionType.SINGLE_LINK,
      bundle: {
        key: "single2",
        name: "Single 2",
        path: "#single2"
      }
    },
    {
      type: this.ActionType.LINK_LIST,
      bundle: {
        header: "Link List Header",
        key: "linkList1",
        children: [
          { path: "#linklist", name: "Link List Child 1" },
          { path: "#linklist", name: "Link List Child 2" },
          { path: "#linklist", name: "Link List Child 3" },
          { path: "#linklist", name: "Link List Child 4" }
        ]
      }
    }
  ];*/
