import { GET_FILL_FACTORS_BASELINE_DATES } from "../actions/types";

const fillFactorsBaselineDatesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_FILL_FACTORS_BASELINE_DATES:
      return action.payload;
    default:
      return state;
  }
};

export default fillFactorsBaselineDatesReducer;
