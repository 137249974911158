import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import AlertModal from "../../Modal/AlertModal";
import "./Benefit.css";
import history from "../../../history";
import { required, validSelect } from "../../../utils/forms/validators";
import { ConfigFile } from "../ConfigFiles/fileTypes";
import {
  DATE_CONVERT_FORMAT,
  DATE_PICKER_FORMAT,
  getUnrestrictedDateConfig,
} from "../../../utils";

class BenefitForm extends Component {
  constructor(props) {
    super(props);
    if (!this.props.fromEdit) {
      const dateConfig = getUnrestrictedDateConfig();
      this.state = {
        isOpen: false,
        isAlertOpen: false,
        ...dateConfig,
      };
    } else {
      const selectedBenefit = this.props.initialValues;
      const minEffectiveDate = moment
        .max(moment(selectedBenefit.effectiveDate, DATE_CONVERT_FORMAT))
        .add(1, "d")
        .toDate();
      const maxEffectiveDate = moment
        .max(moment(selectedBenefit.termDate, DATE_CONVERT_FORMAT))
        .subtract(1, "d")
        .toDate();
      const minTermDate = minEffectiveDate;
      const maxTermDate = moment().year(2999).toDate();
      this.state = {
        isOpen: false,
        isAlertOpen: false,
        minEffectiveDate: minEffectiveDate,
        minTermDate: minTermDate,
        maxEffectiveDate: maxEffectiveDate,
        maxTermDate: maxTermDate,
      };
    }

    this.toggleModal = this.toggleModal.bind(this);
  }

  renderInput({
    input,
    id,
    placeholder,
    required,
    type,
    showError,
    meta: { touched, error },
  }) {
    return (
      <div className="field">
        <Label for={id}>
          {placeholder} {required && <span className="required-field">*</span>}
        </Label>
        <Input type={type} id={id} required={required} {...input} />
        {(touched || showError) && error && (
          <span className="form-error">{error}</span>
        )}
      </div>
    );
  }

  renderFileSelect({
    input,
    id,
    placeholder,
    required,
    type,
    fileType,
    files,
    clientId,
    meta: { touched, error },
  }) {
    const filteredFiles = _.filter(files, {
      type: fileType,
      clientId: clientId,
    });

    return (
      <div className="field">
        <Label for={id}>
          {placeholder} {required && <span className="required-field">*</span>}
        </Label>
        <Input type={type} id={id} required={required} {...input}>
          <option value="">-Select-</option>
          {filteredFiles.map((file) => {
            return (
              <option key={file.id} value={file.id}>
                {file.fileName}
              </option>
            );
          })}
        </Input>
        {touched && error && <span className="form-error">{error}</span>}
      </div>
    );
  }

  renderTieInSelect({
    input,
    id,
    placeholder,
    required,
    type,
    tieIns,
    meta: { touched, error },
  }) {
    return (
      <div className="field">
        <Label for={id}>
          {placeholder} {required && <span className="required-field">*</span>}
        </Label>
        <Input type={type} id={id} required={required} {...input}>
          <option value="">-Select-</option>
          {tieIns.map((tieIn) => {
            return (
              <option key={tieIn.id} value={tieIn.id}>
                {tieIn.name} &nbsp;&nbsp;&nbsp; File: &nbsp;
                {_.uniq(
                  tieIn.clientGroups.map((cg) => cg.correlation.fileName)
                )}
              </option>
            );
          })}
        </Input>
        {touched && error && <span className="form-error">{error}</span>}
      </div>
    );
  }

  renderDatePicker({
    input,
    id,
    placeholder,
    required,
    minDate,
    maxDate,
    meta: { touched, error },
  }) {
    return (
      <div className="field">
        <Label for={id}>
          {placeholder} {required && <span className="required-field">*</span>}
        </Label>{" "}
        <br />
        <DatePicker
          {...input}
          dateFormat={DATE_PICKER_FORMAT}
          placeholderText={DATE_CONVERT_FORMAT}
          selected={Date.parse(input.value) || null}
          onChange={input.onChange}
          autoComplete="off"
          strictParsing
          minDate={minDate}
          maxDate={maxDate}
          className="form-control"
        />
        {touched && error && <span className="form-error">{error}</span>}
      </div>
    );
  }

  onEffectiveDateChange = (newVal) => {
    if (newVal) {
      this.setState({
        minTermDate: moment(newVal, DATE_CONVERT_FORMAT).add(1, "d").toDate(),
      });
    }
  };

  onTermDateChange = (newVal) => {
    if (newVal) {
      const minMax = moment(newVal, DATE_CONVERT_FORMAT)
        .subtract(1, "d")
        .toDate();
      this.setState({
        maxEffectiveDate: minMax,
      });
    }
  };

  toggleModal = (benefit, clientId, formValues) => {
    if (benefit) {
      const header = "Duplicate Benefit found";
      const content =
        benefit.benefitName +
        " contains the same configuration files. Do you wish to continue?";

      this.setState({
        isOpen: !this.state.isOpen,
        modalHeader: header,
        modalContent: content,
        modalAction: this.props.onSubmit,
        clientId: clientId,
        modalFormVals: formValues,
      });
    } else {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  toggleAlertModal = (title, content) => {
    this.setState({
      isAlertOpen: !this.state.isAlertOpen,
      alertModalTitle: title,
      alertModalContent: content,
    });
  };

  onSubmit = (formValues) => {
    // Need to check against current benefits to see if we have a benefit for given client that has exact configFile settings
    const benefits = this.props.currentBenefits;
    let benefitName = formValues.benefitName.trim();
    const benefitId = formValues.id;

    //Attempt convert dates
    formValues.effectiveDate = formValues.effectiveDate
      ? moment(formValues.effectiveDate).format(DATE_CONVERT_FORMAT)
      : null;
    formValues.termDate = formValues.termDate
      ? moment(formValues.termDate).format(DATE_CONVERT_FORMAT)
      : null;

    // Get the fileIds from form values and strip null ids
    let givenFileIds = [
      formValues.awpdFile,
      formValues.macFile,
      formValues.formFile,
      formValues.bcFile,
    ].filter((fileId) => {
      return fileId != null;
    });

    let matchedBenefit = null;
    let matches = [];
    let isDuplicate = false;

    // Loop through the benefits already saved to the client
    benefits.forEach((benefit) => {
      // Get an array of the benefit's configFile ids
      const benefitIds = benefit.configFiles.map((file) => {
        return file.id.toString();
      });

      // If we have an exact match of ids, set the matchedBenefit
      if (matches.length <= 0) {
        matches = _.intersection(givenFileIds, benefitIds);
        if (matches.length === benefitIds.length) {
          matchedBenefit = benefit;
        } else {
          matches = [];
        }
      }

      // If the name is the same as an existing benefit
      if (
        !isDuplicate &&
        benefit.id !== benefitId &&
        benefit.benefitName
          .trim()
          .localeCompare(benefitName, undefined, { sensitivity: "accent" }) ===
          0
      ) {
        isDuplicate = true;
      }
    });

    // If we have a matched benefit, display the warning popup first
    if (isDuplicate) {
      this.toggleAlertModal(
        "Duplicate Benefit Name",
        "This benefit name has already been used.  Please choose another name."
      );
    } else if (matchedBenefit) {
      this.toggleModal(matchedBenefit, this.props.selectedClientId, formValues);
    } else {
      this.props.onSubmit(formValues, this.props.selectedClientId);
    }
  };

  render() {
    const { invalid, submitting, pristine } = this.props;
    return (
      <Form
        className="add-benefit-form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        autoComplete="off"
      >
        <FormGroup>
          <Field
            id="benefitName"
            name="benefitName"
            type="text"
            placeholder="Benefit Name"
            required
            validate={[required]}
            component={this.renderInput}
          />
        </FormGroup>
        <FormGroup>
          <Field
            id="clientTieIn"
            name="clientTieIn"
            placeholder="RPS Client tie-in to CAG &amp; G/P file"
            type="select"
            required
            validate={[required]}
            tieIns={this.props.clientTieIns}
            component={this.renderTieInSelect}
          />
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <Field
                id="effectiveDate"
                name="effectiveDate"
                placeholder="Effective Date"
                required
                onChange={this.onEffectiveDateChange}
                minDate={this.state.minEffectiveDate}
                maxDate={this.state.maxEffectiveDate}
                validate={[required]}
                component={this.renderDatePicker}
              />
            </FormGroup>
          </Col>
          <Col>
            <Field
              id="termDate"
              name="termDate"
              placeholder="Term Date"
              onChange={this.onTermDateChange}
              minDate={this.state.minTermDate}
              maxDate={this.state.maxTermDate}
              component={this.renderDatePicker}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Field
                id="macFile"
                name="macFile"
                placeholder="MAC Files"
                type="select"
                fileType={ConfigFile.MAC}
                files={this.props.configFiles}
                clientId={this.props.clientId}
                validate={[required, validSelect]}
                component={this.renderFileSelect}
                required
              />
            </FormGroup>
          </Col>
          <Col>
            <Field
              id="formFile"
              name="formFile"
              placeholder="Formulary Files"
              type="select"
              fileType={ConfigFile.FORMULARY}
              files={this.props.configFiles}
              clientId={this.props.clientId}
              validate={[required, validSelect]}
              component={this.renderFileSelect}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Field
                id="awpdFile"
                name="awpdFile"
                placeholder="AWP Discount Files"
                type="select"
                fileType={ConfigFile.AWPD}
                files={this.props.configFiles}
                clientId={this.props.clientId}
                validate={[required, validSelect]}
                component={this.renderFileSelect}
                required
              />
            </FormGroup>
          </Col>
          <Col>
            <Field
              id="bcFile"
              name="bcFile"
              placeholder="Beneficiary Cost Files"
              type="select"
              fileType={ConfigFile.BC}
              files={this.props.configFiles}
              clientId={this.props.clientId}
              validate={[required, validSelect]}
              component={this.renderFileSelect}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={8} />
          <Col md={2}>
            <Button
              color="danger"
              block
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col md={2}>
            <Button
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Save
            </Button>
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalHeader}
          content={this.state.modalContent}
          action={this.state.modalAction}
          objId={this.state.clientId}
          modalFormVals={this.state.modalFormVals}
        />
        <AlertModal
          isOpen={this.state.isAlertOpen}
          toggle={this.toggleAlertModal}
          header={this.state.alertModalTitle}
          content={this.state.alertModalContent}
        />
      </Form>
    );
  }
}

export default reduxForm({ form: "benefitForm" })(BenefitForm);
