import {
  GET_WEEK_OF_YEAR_LIST,
  CLEAR_WEEK_OF_YEAR_LIST,
} from "../actions/types";

const weekOfYearReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WEEK_OF_YEAR_LIST:
      return action.payload;
    case CLEAR_WEEK_OF_YEAR_LIST:
      return {};
    default:
      return state;
  }
};

export default weekOfYearReducer;
