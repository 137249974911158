import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "reactstrap";
import { FaTrashAlt } from "react-icons/fa";
import {
  clearClients,
  getClient,
  addSortOrder,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getClaimCorrelationsByClient,
  getEstimatedClaimCorrelationsByClient,
  uploadEstimatedClaims,
  deleteEstimatedClaimCorrelation,
} from "../../../actions";
import {
  dateTimeFormatter,
  sortHandler,
  localDateTimeFormatter,
  userNameFormatter,
  Role,
  required,
  validFilename,
  FileInput,
} from "../../../utils";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import "./Claims.css";
import FormModal from "../../Modal/FormModal";
import { FormGroup } from "reactstrap";
import { Field } from "redux-form";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import AlertModal from "../../Modal/AlertModal";
import ActionButton from "../../DataGrid/ActionButton";

class ClaimFileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPageEstimatedClaims: 1,
      sizePerPageEstimatedClaims: 15,
      currPageClaims: 1,
      sizePerPageClaims: 15,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      isAuthorized: _.some(this.props.roles, {
        name: Role.CLIENT_ADMIN.name,
      }),
      isUploadModalOpen: false,
      isDeleteModalOpen: false,
      isAlertModalOpen: false,
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
      ],
      finalCrumb: "Claim List",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.getClient(this.state.clientId);
    this.props.getEstimatedClaimCorrelationsByClient(
      this.state.clientId,
      0,
      this.state.sizePerPageEstimatedClaims
    );
    this.props.getClaimCorrelationsByClient(
      this.state.clientId,
      0,
      this.state.sizePerPageClaims
    );
    this.props.toggleSidebarVisibility();
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
  }

  getEstimatedClaimsCorrelationData = () => {
    return this.props.estimatedClaimCorrelations.content;
  };

  getClaimsCorrelationData = () => {
    return this.props.claimCorrelations.content;
  };

  getEstimatedClaimsPage(page, sizePerPage, sorts) {
    return this.props.getEstimatedClaimCorrelationsByClient(
      this.state.clientId,
      page - 1,
      sizePerPage,
      sorts
    );
  }

  onUploadSubmit = (formValues) => {
    const formData = new FormData();
    if (formValues.file.size > 50000000) {
      this.toggleAlertModal(
        `File too large`,
        `Maximum file size is 50 MB. Please zip file and resubmit.`
      );
    } else {
      formData.append("clientId", this.state.clientId);
      formData.append("file", formValues.file);
      formData.append("fileName", formValues.file.name);
      formData.append("fileType", formValues.file.type);
      this.props.uploadEstimatedClaims(formData);
    }
  };

  toggleUploadModal = () => {
    this.setState({ isUploadModalOpen: !this.state.isUploadModalOpen });
  };

  toggleAlertModal = (title, content) => {
    this.setState({
      isAlertModalOpen: !this.state.isAlertModalOpen,
      modalTitle: title,
      modalContent: content,
    });
  };

  toggleDeleteModal = (target) => {
    if (target && target.hasOwnProperty("id")) {
      const name = target.fileName;
      this.setState({
        isDeleteModalOpen: !this.state.isDeleteModalOpen,
        dModalHeader: `Delete ${name}`,
        dModalContent: `Are you sure you want to delete ${name}?`,
        dModalAction: this.props.deleteEstimatedClaimCorrelation,
        selectedTargetId: target.id,
      });
    } else {
      this.setState({ isDeleteModalOpen: !this.state.isDeleteModalOpen });
    }
  };

  handleTableChangeEstimatedClaims = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    if (type === "sort") {
      const newSort = sortHandler(this.props.sorts, [
        { dataField: sortField, order: sortOrder },
      ]);
      this.getEstimatedClaimsPage(page, sizePerPage, newSort);
      return -1;
    }

    this.setState({
      currPageEstimatedClaims: page,
      sizePerPageEstimatedClaims: sizePerPage,
    });
    this.getEstimatedClaimsPage(page, sizePerPage, this.props.sorts);
  };

  onSizePerPageChangeEstimatedClaims = (sizePerPage, page) => {
    this.getEstimatedClaimsPage(page, sizePerPage, this.props.sorts);
  };

  actionFormatter = (content, row) => {
    const shouldDisable = row.locked === "Y";
    return (
      <div>
        {this.state.isAuthorized && (
          <Button
            className="linkBtn tableBtn actionIconBtnDanger"
            color="link"
            disabled={shouldDisable}
            onClick={() => this.toggleDeleteModal(row)}
            size="sm"
          >
            <FaTrashAlt />
          </Button>
        )}
      </div>
    );
  };

  iconHeaderFormatter = (column, colIndex) => {
    return (
      <div>
        Action
        {this.state.isAuthorized && (
          <ActionButton
            centered
            actionType="upload"
            tooltip={`Upload ${this.props.selectedClient.name} ${column.text} file`}
            onClick={this.toggleUploadModal}
          />
        )}
      </div>
    );
  };

  COLUMNS_ESTIMATED_CLAIMS = [
    {
      dataField: "actions",
      sort: false,
      text: "Estimated Claims",
      formatter: this.actionFormatter,
      headerFormatter: this.iconHeaderFormatter,
      isDummyField: true,
      headerStyle: {
        width: "4%",
      },
    },
    {
      dataField: "fileName",
      text: "File Name",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "claimCount",
      text: "Paid Claim Cnt",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "uploadedBy",
      text: "Uploaded By User",
      headerAlign: "center",
      align: "center",
      formatter: userNameFormatter,
    },
    {
      dataField: "uploadedDate",
      text: "Uploaded Date",
      headerAlign: "center",
      align: "center",
      formatter: localDateTimeFormatter,
    },
  ];

  getPage(page, sizePerPage, sorts) {
    return this.props.getClaimCorrelationsByClient(
      this.state.clientId,
      page - 1,
      sizePerPage,
      sorts
    );
  }

  handleTableChangeClaims = (
    type,
    { page, sizePerPage, sortField, sortOrder }
  ) => {
    if (type === "sort") {
      const newSort = sortHandler(this.props.sorts, [
        { dataField: sortField, order: sortOrder },
      ]);
      this.getPage(page, sizePerPage, newSort);
      return -1;
    }

    this.setState({ currPageClaims: page, sizePerPageClaims: sizePerPage });
    this.getPage(page, sizePerPage, this.props.sorts);
  };

  onSizePerPageChangeClaims = (sizePerPage, page) => {
    this.getPage(page, sizePerPage, this.props.sorts);
  };

  COLUMNS_CLAIMS = [
    {
      dataField: "updatedDate",
      text: "Process Date",
      formatter: dateTimeFormatter,
    },
    {
      dataField: "description",
      text: "File Name",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "claimCount",
      text: "Paid Claim Cnt",
      headerAlign: "center",
      align: "center",
    },
    {
      dataField: "revClaimCount",
      text: "Reversal Claim Cnt",
      headerAlign: "center",
      align: "center",
    },
  ];

  render() {
    if (
      _.isEmpty(this.props.claimCorrelations) ||
      _.isEmpty(this.props.selectedClient) ||
      _.isEmpty(this.props.estimatedClaimCorrelations)
    ) {
      return <div>Loading...</div>;
    }
    const uploadModalHeader = `Upload a ${this.props.selectedClient.name} prospect claims file.`;
    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Estimated Savings Claim Files</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RPSDataGrid
              keyField="id"
              paginated
              sizePerPage={this.state.sizePerPageEstimatedClaims}
              page={this.state.currPageEstimatedClaims}
              totalSize={this.props.estimatedClaimCorrelations.totalElements}
              data={this.getEstimatedClaimsCorrelationData()}
              columns={this.COLUMNS_ESTIMATED_CLAIMS}
              onSizePerPageChange={this.onSizePerPageChangeEstimatedClaims}
              onTableChange={this.handleTableChangeEstimatedClaims}
              remote
            />
          </Col>
        </Row>
        <Row className="lower-heading">
          <Col>
            <h3>Production Claim Files</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RPSDataGrid
              keyField="id"
              paginated
              sizePerPage={this.state.sizePerPageClaims}
              page={this.state.currPageClaims}
              totalSize={this.props.claimCorrelations.totalElements}
              data={this.getClaimsCorrelationData()}
              columns={this.COLUMNS_CLAIMS}
              onSizePerPageChange={this.onSizePerPageChangeClaims}
              onTableChange={this.handleTableChangeClaims}
              remote
            />
          </Col>
        </Row>
        <FormModal
          isOpen={this.state.isUploadModalOpen}
          toggle={this.toggleUploadModal}
          header={uploadModalHeader}
          onSubmit={this.onUploadSubmit}
          size="lg"
          submitButtonText="Attach File"
          touchOnChange={true}
        >
          <FormGroup row>
            <Field
              id="file"
              name="file"
              label="Select CSV or zipped CSV File to Upload. Large files can take a while to fully load."
              required
              validate={[required, validFilename]}
              component={FileInput}
              className="inputfile"
            />
          </FormGroup>
        </FormModal>
        <ConfirmationModal
          isOpen={this.state.isDeleteModalOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedTargetId}
          size="lg"
        />
        <AlertModal
          isOpen={this.state.isAlertModalOpen}
          toggle={this.toggleAlertModal}
          header={this.state.modalTitle}
          content={this.state.modalContent}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.auth.currentUser.roles,
    selectedClient: state.clients[ownProps.match.params.clientId],
    claimCorrelations: state.claimCorrelations,
    estimatedClaimCorrelations: state.estimatedClaimCorrelations,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  addSortOrder,
  getClient,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getClaimCorrelationsByClient,
  getEstimatedClaimCorrelationsByClient,
  uploadEstimatedClaims,
  deleteEstimatedClaimCorrelation,
})(ClaimFileList);
