import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import InternalMPDReportForm from "../ClientManagement/MPDs/InternalReport/InternalMPDReportForm";
import ArtasReportRequestForm from "../ClientManagement/MPDs/ArtasReportRequestForm";

const MPDExportModal = (props) => {
  return ReactDOM.createPortal(
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
        <ModalHeader
          toggle={() => {
            props.toggle();
          }}
        >
          <span>{props.mpd ? props.mpd.name : ""}</span>
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <h6>Export Lower Cost Therapeutic Alternative File</h6>
                <ListGroup>
                  {_.map(props.content, (stat) => {
                    return <ListGroupItem key={stat}>{stat}</ListGroupItem>;
                  })}
                </ListGroup>
                <FormGroup>
                  {props.checkBoxEnabled ? (
                    <div className="mt-3">
                      <Input
                        type="checkbox"
                        name="check"
                        id="modalCheck"
                        defaultChecked={props.checkBoxDefault}
                        onChange={(e) => {
                          props.checkBoxAction(e.target.checked);
                        }}
                      />
                      <Label className="ps-2" for="modalCheck" check>
                        {props.checkBoxLabel}
                      </Label>
                    </div>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col>
                      <Button
                        className="mt-3"
                        color="primary"
                        type="submit"
                        block
                        onClick={() => {
                          props.action();
                          props.toggle();
                        }}
                      >
                        Export Value Based Reimbursement (VBR) Format
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <ArtasReportRequestForm onSubmit={props.artasAction} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Export Internal VBR Format Including Additional Data</h6>
                <InternalMPDReportForm onSubmit={props.onInternalSubmit} />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Row />

          <Button
            color="danger"
            onClick={() => {
              props.toggle();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>,
    document.querySelector("#modal")
  );
};

export default MPDExportModal;
