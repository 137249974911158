import FileSaver from "file-saver";

export const downloadExcelFile = report => {
  // Get the name for the files from headers
  const fileName = report.headers["x-suggested-filename"];
  FileSaver.saveAs(report.data, fileName);
};

export const EXCEL_DOWNLOAD_OPTIONS = {
  responseType: "blob",
  timeout: 300000
};
