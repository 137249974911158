import React, { Component } from "react";
import { connect } from "react-redux";
import { getClinicalSidebarItems } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  getWorkbook,
  clearApproveWorkbook,
  clearWorkbookEntries,
  tryWorkbookEntrySearch,
  getPreviouslyApprovedWorkbook,
  tryApproveWorkbook,
  getNDCPricesAction,
  clearNdcPrices,
  getDistinctPairingTypes,
} from "../../../actions";
import _ from "lodash";
import { Container, Row, Col, Button } from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import {
  authCheck,
  getDemoColumnHeader,
  isDemoUser,
  obfuscationFormatter,
  OBFUSCATION_COLUMNS,
  Role,
  VALID_EDIT_STATUSSES,
  optionalCostFormatter,
  pairingTypeFormatter,
  gpiNDCHeaderFormatter,
  gpiNDCFormatter,
  fullYesNoFormatter,
  getDrugNameCSSClass,
} from "../../../utils";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import WorkbookEntrySearchForm from "./WorkbookEntrySearchForm";
import NDCPriceModal from "../../Modal/NDCPriceModal";
import { HiCurrencyDollar } from "react-icons/hi";
import AlertModalv2 from "../../Modal/AlertModalv2";
import history from "../../../history";

class WorkbookView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      isAuthorized: authCheck(this.props.roles, Role.CLINICAL_APPROVER.name),
      isDemoUser: isDemoUser(this.props.roles),
      wbId: this.props.match.params.wbId,
      breadcrumbsLoaded: false,
      previouslyApprovedChecked: false,
      isApproveConfirmOpen: false,
      searchTerms: {
        names: [],
        pdcs: [],
        gpis: [],
        noValidNDCs: null,
        pairingTypes: [],
      },
      ndcPriceModalOpen: false,
      drugData: {
        gpi: "",
        msc: "",
        commonDrugName: "",
        ndc: null,
      },
      isApproveAlertOpen: false,
      approveAlertHeader: "Target GPI/MSCs found as Targets in other Workbooks",
      pairingTypes: [],
    };
  }

  componentDidMount = () => {
    this.setupSidebar();
    this.props.getWorkbook(this.state.wbId);
    this.props
      .getDistinctPairingTypes(this.state.wbId)
      .then((result) => this.setState({ pairingTypes: result }));
    this.getPage();
  };

  componentDidUpdate = () => {
    if (
      !this.state.breadcrumbsLoaded &&
      !_.isEmpty(this.props.selectedWorkbook)
    ) {
      this.setupBreadCrumbs();
      this.setState({ breadcrumbsLoaded: true });
    }

    if (
      !_.isEmpty(this.props.selectedWorkbook) &&
      !this.state.previouslyApprovedChecked
    ) {
      this.props.getPreviouslyApprovedWorkbook(
        this.props.selectedWorkbook.decisionGrid.drugCategoryId,
      );
      this.setState({ previouslyApprovedChecked: true });
    }

    const approvalResponse = this.props.workbookApproval;
    if (
      approvalResponse &&
      (!_.isEmpty(approvalResponse.errorDupes) ||
        !_.isEmpty(approvalResponse.warningDupes)) &&
      !this.state.isApproveAlertOpen
    ) {
      this.toggleApproveAlert();
    }
  };

  componentWillUnmount = () => {
    this.props.clearWorkbookEntries();
    this.props.clearApproveWorkbook();
  };

  setupSidebar = () => {
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/workbooks",
          name: "Clinical Pairings Workbooks",
          active: false,
        },
      ],
      finalCrumb: this.props.selectedWorkbook.name,
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  toggleConfirmModal = (workbook) => {
    if (workbook && workbook.hasOwnProperty("name")) {
      const name = workbook.name;
      this.setState({
        isApproveConfirmOpen: !this.state.isApproveConfirmOpen,
        dModalHeader: "Warning",
        dModalContent: `Approving this clinical pairing workbook will replace the currently approved ${name}. Do you wish to continue?`,
        dModalAction: this.approve,
        selectedWorkbookId: workbook.id,
      });
    } else {
      this.setState({ isApproveConfirmOpen: !this.state.isApproveConfirmOpen });
    }
  };

  toggleApproveAlert = () => {
    this.setState({ isApproveAlertOpen: !this.state.isApproveAlertOpen });
  };

  onApproveAlertClose = () => {
    this.props.clearApproveWorkbook();
    history.go(0);
  };

  getPage = () => {
    this.props.tryWorkbookEntrySearch(
      this.state.wbId,
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField,
    );
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  renderNoValidArNDC = () => {
    return (
      <HiCurrencyDollar
        className="me-3 required-field"
        title="Missing AR Valid NDC’s"
      />
    );
  };

  targetDrugNameFormatter = (_, row) => {
    let drugName = null;

    if (
      row.pairingType === "NDC_TO_GPI_MSC" ||
      row.pairingType === "NDC_TO_NDC"
    ) {
      drugName = row.tgtNdcName;
    } else if (row.targetMedicationName !== null) {
      drugName = row.targetMedicationName.commonName;
    }

    if (!drugName) {
      return null;
    }

    return (
      <>
        {row.targetMedicationName.validNdc === "N" && this.renderNoValidArNDC()}

        <Button
          className={getDrugNameCSSClass(row.tgtNewStartsOnly)}
          color="link"
          onClick={() => this.handleTargetDrugNameOnClick(row)}
        >
          {drugName}
        </Button>
      </>
    );
  };

  altDrugNameFormatter = (_, row) => {
    let drugName = null;

    if (
      row.pairingType === "NDC_TO_NDC" ||
      row.pairingType === "GPI_MSC_TO_NDC"
    ) {
      drugName = row.altNdcName;
    } else if (row.altMedicationName !== null) {
      drugName = row.altMedicationName.commonName;
    }

    if (!drugName) {
      return null;
    }

    return (
      <>
        {row.altMedicationName.validNdc === "N" && this.renderNoValidArNDC()}

        <Button
          className={getDrugNameCSSClass(row.altNewStartsOnly)}
          color="link"
          onClick={() => this.handleAltDrugNameOnClick(row)}
        >
          {drugName}
        </Button>
      </>
    );
  };

  getNdcPrices = (targetGpi, targetMsc) => {
    this.props.getNDCPricesAction(targetGpi, targetMsc);
  };

  handleTargetDrugNameOnClick = (row) => {
    const drugData = {
      gpi: row.tgtGpi,
      msc: row.tgtMultiSource,
      commonDrugName: row.targetMedicationName.commonName,
    };

    // When deailing with NDC pairing type we need to feed the ndc popup ndc related info
    if (row.pairingType !== "GPI_TO_GPI") {
      drugData.ndc = row.tgtNdc;
    }

    this.setState({
      drugData: drugData,
    });

    this.getNdcPrices(row.tgtGpi, row.tgtMultiSource);
    this.toggleNdcPriceModal();
  };

  handleAltDrugNameOnClick = (row) => {
    const drugData = {
      gpi: row.altGpi,
      msc: row.altMultiSource,
      commonDrugName: row.altMedicationName.commonName,
    };

    // When deailing with NDC pairing type we need to feed the ndc popup ndc related info
    if (row.pairingType !== "GPI_TO_GPI") {
      drugData.ndc = row.altNdc;
    }

    this.setState({
      drugData: drugData,
    });

    this.getNdcPrices(row.altGpi, row.altMultiSource);
    this.toggleNdcPriceModal();
  };

  toggleNdcPriceModal = () => {
    if (this.state.ndcPriceModalOpen) {
      this.props.clearNdcPrices();
    }
    this.setState({
      ndcPriceModalOpen: !this.state.ndcPriceModalOpen,
    });
  };

  getColumns = () => {
    const selectedWorkbook = this.props.selectedWorkbook;
    return [
      {
        dataField: "pairingType",
        text: "Pairing Type",
        formatter: pairingTypeFormatter,
      },
      {
        dataField: "pairNewStartsOnly",
        text: "Pair NSO",
        sort: true,
        formatter: fullYesNoFormatter,
      },
      {
        dataField: "pairingCode",
        sort: true,
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.PDC,
          "PDC",
          this.state.isDemoUser,
        ),
        formatter: this.state.isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.PDC,
      },
      {
        dataField: "tgtGpi",
        sort: true,
        headerFormatter: () =>
          gpiNDCHeaderFormatter(
            false,
            selectedWorkbook.decisionGrid.hasNDCDPOs,
          ),
        text: "",
        formatter: gpiNDCFormatter,
        formatExtraData: "tgtNdc",
      },
      {
        dataField: "tgtMultiSource",
        sort: true,
        text: "Target MSC",
      },
      {
        dataField: "targetMedicationName.commonName",
        sort: true,
        text: "Target Drug Name",
        formatter: this.targetDrugNameFormatter,
      },
      {
        dataField: "tgtType",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.TAB,
          "T/A/B",
          this.state.isDemoUser,
        ),
        formatter: this.state.isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.TAB,
        sort: true,
      },
      {
        dataField: "tgtDacon",
        text: "Target DACon",
        sort: true,
      },
      {
        dataField: "tgtTotalCost",
        text: "Target 30 Day (Per Claim) Ingr. Cost",
        sort: true,
        align: "right",
        headerStyle: { width: "6%" },
        formatter: (content) => {
          return optionalCostFormatter(content);
        },
      },
      {
        dataField: "altGpi",
        sort: true,
        text: "",
        headerFormatter: () =>
          gpiNDCHeaderFormatter(true, selectedWorkbook.decisionGrid.hasNDCDPOs),
        formatter: gpiNDCFormatter,
        formatExtraData: "altNdc",
      },
      {
        dataField: "altMultiSource",
        sort: true,
        text: "Alt. MSC",
      },
      {
        dataField: "altMedicationName.commonName",
        sort: true,
        text: "Alternative Drug Name",
        formatter: this.altDrugNameFormatter,
      },
      {
        dataField: "altType",
        text: getDemoColumnHeader(
          OBFUSCATION_COLUMNS.TAB,
          "T/A/B",
          this.state.isDemoUser,
        ),
        formatter: this.state.isDemoUser ? obfuscationFormatter : null,
        formatExtraData: OBFUSCATION_COLUMNS.TAB,
        sort: true,
      },
      {
        dataField: "altDacon",
        text: "Alt DACon",
        sort: true,
      },
      {
        dataField: "altTotalCost",
        text: "Alt 30 Day (Per Claim) Ingr. Cost",
        sort: true,
        align: "right",
        headerStyle: { width: "5%" },
        formatter: (content) => {
          return optionalCostFormatter(content);
        },
      },
    ];
  };

  tryApprove = () => {
    if (this.props.previouslyApproved) {
      this.toggleConfirmModal(this.props.selectedWorkbook);
    } else {
      this.approve(this.props.selectedWorkbook.id);
    }
  };

  approve = (wbId) => {
    this.props.tryApproveWorkbook(wbId);
  };

  isApprovable = () => {
    let approvable = false;
    const workbookApprovalResponse = this.props.workbookApproval;

    if (workbookApprovalResponse && workbookApprovalResponse.workbook) {
      approvable =
        workbookApprovalResponse.workbook.status.toLowerCase() ===
          VALID_EDIT_STATUSSES.Active.toLowerCase() && this.state.isAuthorized;
    } else {
      approvable =
        this.props.selectedWorkbook.status.toLowerCase() ===
          VALID_EDIT_STATUSSES.Active.toLowerCase() && this.state.isAuthorized;
    }

    return approvable;
  };

  render = () => {
    const selectedWorkbook = this.props.selectedWorkbook;
    const approvalResponse = this.props.workbookApproval;
    if (
      !selectedWorkbook ||
      _.isEmpty(this.props.entries) ||
      _.isEmpty(this.state.pairingTypes)
    ) {
      return <h1>LOADING...</h1>;
    }
    const approvable = this.isApprovable();

    const btnContainerClass = approvable
      ? "justify-content-between"
      : "flex-row-reverse";

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>
              {selectedWorkbook.name}&nbsp;&nbsp;&nbsp;
              <strong>
                Status:&nbsp;
                {approvalResponse && approvalResponse.workbook && (
                  <span>{approvalResponse.workbook.status}</span>
                )}
                {(!approvalResponse || !approvalResponse.workbook) && (
                  <span>{selectedWorkbook.status}</span>
                )}
              </strong>
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <WorkbookEntrySearchForm
              onSubmit={this.onSearchSubmit}
              hasNDCDPOs={selectedWorkbook.decisionGrid.hasNDCDPOs}
              pairingTypes={this.state.pairingTypes}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className={`d-flex ${btnContainerClass}`}>
            {approvable && (
              <Button
                color="primary"
                tag={Link}
                onClick={this.tryApprove}
                to="#"
                disabled={this.props.workbookApproval === "loading"}
              >
                Approve
              </Button>
            )}
            <Button
              color="info"
              tag={Link}
              to={`/workbooks/${selectedWorkbook.id}/errors`}
            >
              View Errored &amp; Dropped Pairs
            </Button>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.entries.totalElements}
              data={this.props.entries.content}
              columns={this.getColumns()}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isApproveConfirmOpen}
          toggle={this.toggleConfirmModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedWorkbookId}
        />
        <NDCPriceModal
          isOpen={this.state.ndcPriceModalOpen}
          toggle={this.toggleNdcPriceModal}
          ndcPrices={this.props.ndcPrices}
          clearAction={this.props.clearNdcPrices}
          drugData={this.state.drugData}
          showSequence={false}
        />
        <AlertModalv2
          isOpen={this.state.isApproveAlertOpen}
          toggle={this.toggleApproveAlert}
          header={this.state.approveAlertHeader}
          isError={
            this.props.workbookApproval &&
            !_.isEmpty(this.props.workbookApproval.errorDupes)
          }
          closeCallback={this.onApproveAlertClose}
          size="lg"
          custBodyClass={"limit-v2-modal-height"}
        >
          <Row>
            <Col>
              {approvalResponse &&
                !_.isEmpty(approvalResponse.errorDupes) &&
                this.renderDupes(approvalResponse.errorDupes)}
              {approvalResponse &&
                !_.isEmpty(approvalResponse.warningDupes) &&
                this.renderDupes(approvalResponse.warningDupes, false)}
            </Col>
          </Row>
        </AlertModalv2>
      </Container>
    );
  };

  renderDupes = (dupes, forErrors = true) => {
    const markup = [];
    const gpiKeys = Object.keys(dupes);
    const headerPrependClass = forErrors ? "form-error" : "form-warning";
    const headerPrepend = forErrors ? "Error: " : "Warning: ";
    const headerStatus = forErrors ? "Approved" : "Active/Draft";

    _.forEach(gpiKeys, (key) => {
      const keyDupes = dupes[key];
      const header = (
        <div className="mb-3">
          <span className={headerPrependClass}>{headerPrepend}</span>
          <span className="halfBold">Target GPI: {keyDupes[0].gpi}</span>{" "}
          and&nbsp;
          <span className="halfBold">
            MSC:&nbsp;
            {keyDupes[0].msc}
          </span>
          &nbsp; is also found in the following&nbsp;
          <span className="halfBold">{headerStatus}</span> workbooks:
        </div>
      );
      const workbookListItems = _.map(keyDupes, (dupe) => (
        <li key={dupe.workbookName}>
          {dupe.workbookName}
          {!forErrors && (
            <span className="halfBold">
              &nbsp;(Status: {dupe.workbookStatus})
            </span>
          )}
        </li>
      ));
      const fullMarkup = (
        <div key={key}>
          {header}
          <ul>{_.map(workbookListItems, (item) => item)}</ul>
        </div>
      );
      markup.push(fullMarkup);
    });
    return <div>{_.map(markup, (item) => item)}</div>;
  };
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    selectedWorkbook: state.workbookEntries.selectedWorkbook,
    entries: state.workbookEntries.entries,
    previouslyApproved: state.workbookEntries.previouslyApproved,
    ndcPrices: Object.values(state.ndcPrices),
    workbookApproval: state.workbookApproval,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  getWorkbook,
  clearApproveWorkbook,
  clearWorkbookEntries,
  tryWorkbookEntrySearch,
  getPreviouslyApprovedWorkbook,
  tryApproveWorkbook,
  getNDCPricesAction,
  clearNdcPrices,
  getDistinctPairingTypes,
})(WorkbookView);
