import React, { Component } from "react";
import { connect } from "react-redux";
import { getClinicalSidebarItems } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  getDecisionGridsAuditLog
} from "../../../actions";
import _ from "lodash";
import { Container, Row, Col } from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import DecisionGridAuditLogsSearchForm from "./DecisionGridAuditLogsSearchForm";
import { localDateTimeFormatter } from "../../../utils";
import "./DecisionGrids.css";

class DecisionGridsAuditLog extends Component {
  constructor(props) {
    super(props);

    const decisionGridId = this.props.match.params.dgId
      ? this.props.match.params.dgId
      : 0;

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      searchTerms: {
        decisionGridId: decisionGridId,
        categoryNames: [],
        gpis: [],
        multiSources: [],
        drugNames: [],
        userNames: [],
        changeTypes: [],
        auditDescriptions: [],
        searchDate: ""
      },
      decisionGridId: decisionGridId
    };
  }

  componentDidMount = () => {
    this.setupBreadCrumbs();
    this.setupSidebar();
    this.getPage();
  };

  setupBreadCrumbs = () => {
    const drugCategory = _.find(this.props.grids.content, drugCategory => {
      return drugCategory.id === parseInt(this.state.decisionGridId);
    });
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/grids", name: "P&T Decision Grids", active: false }
      ],
      finalCrumb: "P&T Decision Grid Audit Log"
    };
    if (drugCategory) {
      breadCrumbs.crumbs.push({
        to: `/grids/${drugCategory.id}`,
        name: `${drugCategory.drugCategory.description}`,
        active: false
      });
    }
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  setupSidebar = () => {
    this.props.populateSidebar(getClinicalSidebarItems(this.props.roles));
  };

  COLUMNS = [
    {
      dataField: "updatedDate",
      sort: true,
      text: "Change Date/TIme",
      formatter: localDateTimeFormatter
    },
    {
      dataField: "userName",
      sort: true,
      text: "User"
    },
    {
      dataField: "drugCategoryName",
      sort: true,
      text: "Drug Category"
    },
    {
      dataField: "gpi",
      sort: true,
      text: "GPI"
    },
    {
      dataField: "drugName",
      sort: true,
      text: "Drug Name"
    },
    {
      dataField: "multiSource",
      sort: true,
      text: "MSC"
    },
    {
      dataField: "changeDescription",
      sort: true,
      text: "Change Type"
    },
    {
      dataField: "auditDescription",
      sort: true,
      text: "Audit Description"
    }
  ];

  getPage = () => {
    this.props.getDecisionGridsAuditLog(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSearchSubmit = searchTerms => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  render() {
    if (_.isEmpty(this.props.auditLogs)) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>P&amp;T Decision Grid Audit Log</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <DecisionGridAuditLogsSearchForm
              onSubmit={this.onSearchSubmit}
              searchTerms={this.state.searchTerms}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.auditLogs.totalElements}
              data={this.props.auditLogs.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    auditLogs: state.decisionGrids.auditLogs,
    grids: state.decisionGrids.grids,
    roles: state.auth.currentUser.roles
  };
};

export default connect(
  mapStateToProps,
  {
    populateBreadCrumbs,
    populateSidebar,
    getDecisionGridsAuditLog
  }
)(DecisionGridsAuditLog);
