import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import {
  getFillFactorBaselineDates,
  tryUpdateCategoryFillFactor,
  tryCategoryFillFactorsSearch,
  populateBreadCrumbs,
  populateSidebar,
  clearFillFactors,
} from "../../../actions";
import { SIDEBAR_ITEMS } from "../Constants";
import _ from "lodash";
import {
  getDateString,
  Role,
  authCheck,
  HEADER_STYLES,
  dateRangeFormatter,
  inheritedTooltipFormatter,
  dateFormatter,
} from "../../../utils";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import CategoryFillFactorSearchForm from "./CategoryFillFactorSearchForm";
import cellEditFactory from "react-bootstrap-table2-editor";

class CategoryFillFactor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortArray: [
        {
          dataField: "baselineDate",
          order: "desc",
        },
      ],
      searchTerms: {},
      isAuthorized: authCheck(this.props.roles, Role.CLINICIAN.name),
    };
    this.setupBreadCrumbs();
  }

  componentDidMount = () => {
    this.setupSidebar();
    this.props.getFillFactorBaselineDates();
    this.getPage();
  };

  componentWillUnmount = () => {
    this.props.clearFillFactors();
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/reporting",
          name: "Reporting",
          active: false,
        },
      ],
      finalCrumb: "Drug Category Fill Factors",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  getPage = () => {
    return this.props.tryCategoryFillFactorsSearch(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortArray
    );
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  drugCatFormatter = (content, row) => {
    const drugCat = row.category;

    if (!drugCat.minimumValue) {
      return content;
    }

    let minValTxt = "";
    if (drugCat.minimumValue === "PER_GPI") {
      if (row.minimumValue === "MINIMUM_ONE") {
        minValTxt = (
          <>
            &nbsp;-&nbsp;
            <span className="approval-approved halfBold">
              Fill Factor with min. of 1
            </span>
          </>
        );
      } else {
        minValTxt = (
          <>
            &nbsp;-&nbsp;
            <span className="info-color halfBold">
              Fill Factor with no min.
            </span>
          </>
        );
      }
    }

    return (
      <span>
        {content}
        {!_.isEmpty(minValTxt) && minValTxt}
      </span>
    );
  };

  COLUMNS = [
    {
      dataField: "baselineDate",
      sort: true,
      text: "Claims Date Range",
      editable: false,
      formatter: dateRangeFormatter,
    },
    {
      dataField: "catalogDate",
      sort: true,
      text: "Catalog Effective Date",
      editable: false,
      formatter: dateFormatter,
    },
    {
      dataField: "category.description",
      sort: true,
      text: "Drug Category",
      headerStyle: {
        width: "40%",
      },
      editable: false,
      formatter: this.drugCatFormatter,
    },
    {
      dataField: "memberCount",
      sort: true,
      text: "Unique Members",
      headerAlign: "right",
      align: "right",
      editable: false,
    },
    {
      dataField: "annualFillFactor",
      sort: true,
      text: "Fill Factor",
      headerAlign: "right",
      align: "right",
      editable: true,
      headerStyle: HEADER_STYLES.optionalEdit,
      formatter: inheritedTooltipFormatter,
    },
    {
      dataField: "source",
      sort: true,
      text: "Fill Factor Source",
      headerAlign: "right",
      align: "right",
      editable: false,
    },
  ];

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, cellEdit }
  ) => {
    if (type === "cellEdit" && cellEdit.dataField === "annualFillFactor") {
      const ff = _.find(this.props.fillFactors.content, { id: cellEdit.rowId });
      const newValue = cellEdit.newValue;
      if (!isNaN(newValue) && ff.annualFillFactor !== Number(newValue)) {
        const newFF = Number(newValue).toFixed(2) / 1;
        if (newFF > 12) {
          ff.annualFillFactor = 12;
        } else if (newFF < 1) {
          ff.annualFillFactor = 1;
        } else {
          ff.annualFillFactor = newFF;
        }
        this.props.tryUpdateCategoryFillFactor(ff, this.getPage);
      }
    } else {
      if (type === "sort") {
        sortField = {
          dataField: sortField,
          order: sortOrder,
        };
        var sortArray =
          this.state.sortArray.length > 1
            ? [_.last(this.state.sortArray)]
            : this.state.sortArray;
        sortArray.splice(0, 0, sortField);
        this.setState({ sortArray }, () => {
          this.getPage();
        });
        return -1;
      }

      this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
        this.getPage();
      });
    }
  };

  getDisabledEditRows = () => {
    return _.map(this.props.fillFactors.content, (fillFactor) => {
      if (fillFactor.memberCount >= 5 || !this.state.isAuthorized) {
        return fillFactor.id;
      }
    });
  };

  render() {
    if (
      _.isEmpty(this.props.baselineDates) ||
      _.isEmpty(this.props.fillFactors) ||
      (!_.isEmpty(this.props.fillFactors.content) &&
        !this.props.fillFactors.content[0].hasOwnProperty("category"))
    ) {
      return <h2>LOADING...</h2>;
    }

    const baselineDates = _.map(this.props.baselineDates, (ffdate) => {
      return {
        value: getDateString(ffdate.baselineDate),
        displayValue: getDateString(ffdate.baselineDate),
      };
    });

    return (
      <Container className="main-container" fluid>
        <Row className="mt-3">
          <Col>
            <CategoryFillFactorSearchForm
              onSubmit={this.onSearchSubmit}
              baselineDates={baselineDates}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <RPSDataGrid
              keyField="id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.fillFactors.totalElements}
              data={this.props.fillFactors.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              cellEditFactory={cellEditFactory({
                mode: "click",
                blurToSave: true,
                nonEditableRows: this.getDisabledEditRows,
              })}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    baselineDates: Object.values(state.fillFactorsBaselineDates),
    fillFactors: state.fillFactors,
  };
};

export default connect(mapStateToProps, {
  getFillFactorBaselineDates,
  tryUpdateCategoryFillFactor,
  tryCategoryFillFactorsSearch,
  populateBreadCrumbs,
  populateSidebar,
  clearFillFactors,
})(CategoryFillFactor);
