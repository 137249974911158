import { getAuthRequest, getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import {
  GET_CLIENTS,
  SAVE_CLIENT,
  SAVE_CONTRACT,
  GET_CLIENT,
  EDIT_CLIENT,
  EDIT_CONTRACT,
  GET_CONTRACT,
  CLEAR_SELECTED_CONTRACT,
  DELETE_CONTRACT,
  DELETE_CLIENT,
  GET_LINES_OF_BUSINESS,
  CLEAR_CLIENTS,
  GET_CATALOG_DELIVERIES,
  GET_CLIENT_CONTRACT_PRODUCT_AUDIT_LOG,
  CLEAR_CLIENT_CONTRACT_PRODUCT_AUDIT_LOG,
} from "../types";
import history from "../../history";
import { handleError, formatPageableParameters } from "../../utils";
import _ from "lodash";
import axios from "axios";

export const getClients = (reportType) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    var endpoint = "/api/clients";
    if (reportType) {
      endpoint = `${endpoint}?reportType=${reportType}`;
    }

    const clients = await getAuthRequest(authToken).get(endpoint);
    dispatch({
      type: GET_CLIENTS,
      payload: clients.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getClient = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const client = await getAuthRequest(authToken).get("/api/clients/" + id);

    dispatch({
      type: GET_CLIENT,
      payload: client.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getCatalogDeliveries =
  (clientId, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `/api/catalog-deliveries?clientId=${clientId}${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null
      )}`;

      const deliveries = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_CATALOG_DELIVERIES,
        payload: deliveries.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getLinesOfBusiness = () => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const lines = await getAuthRequest(authToken).get("/api/codes/?type=LOB");

    dispatch({
      type: GET_LINES_OF_BUSINESS,
      payload: lines.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryAddNotes = (formValues) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    const newNoteData = {
      clientId: formValues.clientId,
      notes: _.filter(formValues.notes, (note) => {
        return note.new;
      }),
    };

    const noteEdits = {
      clientId: formValues.clientId,
      notes: _.filter(formValues.notes, (note) => {
        return note.edited;
      }),
    };

    if (newNoteData.notes.length > 0) {
      await getClinicalBackend(authToken).post(
        "/api/client-notes/",
        JSON.stringify(newNoteData)
      );
    }

    if (noteEdits.notes.length > 0) {
      await getClinicalBackend(authToken).put(
        "/api/client-notes/",
        JSON.stringify(noteEdits)
      );
    }

    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryDeleteNote = (noteId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    await getClinicalBackend(authToken).delete("/api/client-notes/" + noteId);
    history.go(0);
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryAddClient = (formValues) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const data = {
      ...formValues,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
    };

    const response = await getClinicalBackend(authToken).post(
      "/api/clients/",
      JSON.stringify(data)
    );

    dispatch({
      type: SAVE_CLIENT,
      payload: response.data,
    });
    history.push("/clients");
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryEditClient = (formValues) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const data = {
      ...formValues,
      grant_type: process.env.REACT_APP_GRANT_TYPE,
    };

    const response = await getClinicalBackend(authToken).put(
      "/api/clients/" + formValues.id,
      JSON.stringify(data)
    );

    dispatch({
      type: EDIT_CLIENT,
      payload: response.data,
    });
    history.push("/clients");
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryDeleteClient = (clientId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    await getClinicalBackend(authToken).delete("/api/clients/" + clientId);
    dispatch({ type: DELETE_CLIENT, payload: clientId });
    history.push("/clients");
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getContract = (id) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const contract = await getAuthRequest(authToken).get(
      "/api/clients/contract/" + id
    );

    dispatch({
      type: GET_CONTRACT,
      payload: contract.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryAddContract =
  (formValues, clientId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const productNotifications = formValues.newNotifications;
      const sanitizedContractVals = _.omit(formValues, "newNotifications");

      const data = {
        ...sanitizedContractVals,
        grant_type: process.env.REACT_APP_GRANT_TYPE,
      };
      const response = await getClinicalBackend(authToken).post(
        "/api/clients/contract/" + clientId,
        JSON.stringify(data)
      );
      response.data.clientId = clientId;

      await axios.all(
        _.map(productNotifications, (notification) => {
          notification.clientContractId = response.data.id;
          getClinicalBackend(authToken).post(
            "/api/products/notifications",
            JSON.stringify(notification)
          );
        })
      );

      dispatch({
        type: SAVE_CONTRACT,
        payload: response.data,
      });
      history.push("/clients/view/" + clientId);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryEditContract =
  (formValues, clientId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const data = {
        ...formValues,
        grant_type: process.env.REACT_APP_GRANT_TYPE,
      };

      const response = await getClinicalBackend(authToken).put(
        "/api/clients/contract/" + formValues.id,
        JSON.stringify(data)
      );
      response.data.clientId = clientId;

      dispatch({
        type: EDIT_CONTRACT,
        payload: response.data,
      });

      // After editing the contract we need to clear the selected contract
      dispatch({ type: CLEAR_SELECTED_CONTRACT });
      history.push("/clients/view/" + clientId);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryDeleteContract =
  (contractId, clientId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      await getClinicalBackend(authToken).delete(
        "/api/clients/contract/" + contractId
      );

      const payload = { contractId: contractId, clientId: clientId };
      dispatch({ type: DELETE_CONTRACT, payload: payload });
      history.push("/clients/view/" + clientId);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearClients = () => (dispatch) => {
  dispatch({ type: CLEAR_CLIENTS });
};

export const getClientContractProductAuditLog =
  (searchTerms, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `/api/client-contract-product-audits?${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null
      )}`;

      const auditLogs = await getClinicalBackend(authToken).post(
        endPoint,
        JSON.stringify(searchTerms)
      );

      dispatch({
        type: GET_CLIENT_CONTRACT_PRODUCT_AUDIT_LOG,
        payload: auditLogs.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearClientContractProductsAuditLogs = () => (dispatch) => {
  dispatch({ type: CLEAR_CLIENT_CONTRACT_PRODUCT_AUDIT_LOG });
};

export const getClientsBy = (params) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    var endpoint = "/api/clients";
    if (params.type) {
      endpoint = `${endpoint}?type=${params.type}`;
    }
    if (params.reportType) {
      endpoint = `${endpoint}?reportType=${params.reportType}`;
    }

    const clients = await getAuthRequest(authToken).get(endpoint);
    dispatch({
      type: GET_CLIENTS,
      payload: clients.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};
