import React from "react";
import PropTypes from "prop-types";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from "reactstrap";
import _ from "lodash";
import "./Notes.css";
import NoteHeader from "./NoteHeader";

const NoteList = props => {
  const notes = _.orderBy(props.notes, ["createdDate"], ["desc"]);
  return (
    <div className="data-grid-scroll-container note-container">
      <h3>{props.listTitle ? props.listTitle : "Notes"}</h3>
      <ListGroup>
        {_.map(notes, note => {
          return (
            <ListGroupItem key={note.id}>
              <ListGroupItemHeading>
                <NoteHeader note={note} currentUser={props.currentUser} />
              </ListGroupItemHeading>
              <ListGroupItemText>{note.body}</ListGroupItemText>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </div>
  );
};

NoteList.propTypes = {
  notes: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  listTitle: PropTypes.string
};

export default NoteList;
