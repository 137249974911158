import React, { Component } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import {
  ChipAwareTextInput,
  getSessionFiltersOrDefault,
  MultiSelectCheckbox,
  removeSessionFilterValue,
  SESSION_KEYS,
  setSessionFilter,
} from "../../../utils";
import _ from "lodash";

class DPOSearchForm extends Component {
  FILTER_KEYS = {
    DRUG_NAMES: "drugNames",
    PAIRING_CODES: "pairingCodes",
    GPIS: "gpis",
    DACONS: "dacons",
    DES: "des",
    INCLUDE_OPTIONS: "includeOptions",
  };

  constructor(props) {
    super(props);

    const localStorageFilters = getSessionFiltersOrDefault(
      SESSION_KEYS.DECISION_GRID_VIEW,
    );

    const localDrugNames = localStorageFilters[this.FILTER_KEYS.DRUG_NAMES];
    const localPairingCodes =
      localStorageFilters[this.FILTER_KEYS.PAIRING_CODES];
    const localGPIs = localStorageFilters[this.FILTER_KEYS.GPIS];
    const localDACONS = localStorageFilters[this.FILTER_KEYS.DACONS];
    const localDES = localStorageFilters[this.FILTER_KEYS.DES];
    const localIncludeOptions =
      localStorageFilters[this.FILTER_KEYS.INCLUDE_OPTIONS];

    this.state = {
      isOpen: true,
      searchFormDirty: true,
      searchTerms: {
        drugNames: localDrugNames ? localDrugNames : [],
        pairingCodes: localPairingCodes ? localPairingCodes : [],
        gpis: localGPIs ? localGPIs : [],
        dacons: localDACONS ? localDACONS : [],
        des: localDES ? localDES : [],
        includeOptions: localIncludeOptions ? localIncludeOptions : [],
      },
      includeOptions: this.getIncludeOptions(),
    };
  }

  getIncludeOptions = () => {
    const initialOptions = getSessionFiltersOrDefault(
      SESSION_KEYS.DECISION_GRID_VIEW,
    );
    const availableOptions = [
      { id: 1, name: "Yes - GPI", value: "Y" },
      { id: 2, name: "Yes - NDC", value: "YNDC" },
      { id: 3, name: "No", value: "N" },
    ];
    const includeOptions = _.map(availableOptions, (option) => {
      let checked = false;
      if (!_.isEmpty(initialOptions.includeOptions)) {
        checked = _.includes(initialOptions.includeOptions, option.value);
      }
      return {
        id: option.id,
        name: option.name,
        value: option.value,
        displayName: option.name,
        checked,
      };
    });
    return includeOptions;
  };

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      const includeOptions = _.filter(
        _.map(this.state.includeOptions, (val) => {
          if (val.checked) {
            return val.value;
          }
        }),
        (val) => {
          return val !== undefined;
        },
      );
      this.props.onSubmit({ ...this.state.searchTerms, includeOptions });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleCheckboxSessionFilters = (item, checked) => {
    if (checked) {
      setSessionFilter(
        SESSION_KEYS.DECISION_GRID_VIEW,
        this.FILTER_KEYS.INCLUDE_OPTIONS,
        item.value,
      );
    } else {
      removeSessionFilterValue(
        SESSION_KEYS.DECISION_GRID_VIEW,
        this.FILTER_KEYS.INCLUDE_OPTIONS,
        item.value,
      );
    }
  };

  onCheckboxClick = (e) => {
    const element = e.currentTarget;
    const items = this.state.includeOptions;
    items[parseInt(element.id) - 1].checked = element.checked;

    _.forEach(items, (item) => {
      this.handleCheckboxSessionFilters(item, item.checked);
    });

    this.setState({ includeOptions: items, searchFormDirty: true });
  };

  onCheckSelectAll = (e) => {
    const items = this.state.includeOptions;
    _.forEach(items, (item) => {
      const checked = e.currentTarget.checked;
      item.checked = checked;
      this.handleCheckboxSessionFilters(item, checked);
    });
    this.setState({ includeOptions: items, searchFormDirty: true });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  render = () => {
    const gpiLabel = this.props.hasNDCDPOs ? "GPI or Dashed NDC" : "GPI";

    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-4">
          <Row className="d-flex justify-content-between">
            <ChipAwareTextInput
              id="drugNames"
              name="drugNames"
              placeholder="Drug Name"
              updateCallback={this.onSearchTermUpdate}
              sticky
              sessionKey={SESSION_KEYS.DECISION_GRID_VIEW}
              filterKey={this.FILTER_KEYS.DRUG_NAMES}
              initialValues={this.state.searchTerms.drugNames}
            />
            <ChipAwareTextInput
              id="pairingCodes"
              name="pairingCodes"
              placeholder="PDC"
              updateCallback={this.onSearchTermUpdate}
              minLength={1}
              sticky
              sessionKey={SESSION_KEYS.DECISION_GRID_VIEW}
              filterKey={this.FILTER_KEYS.PAIRING_CODES}
              initialValues={this.state.searchTerms.pairingCodes}
            />
            <ChipAwareTextInput
              id="gpis"
              name="gpis"
              placeholder={gpiLabel}
              updateCallback={this.onSearchTermUpdate}
              sticky
              sessionKey={SESSION_KEYS.DECISION_GRID_VIEW}
              filterKey={this.FILTER_KEYS.GPIS}
              initialValues={this.state.searchTerms.gpis}
            />
            <ChipAwareTextInput
              id="dacons"
              name="dacons"
              placeholder="Standard DACon"
              updateCallback={this.onSearchTermUpdate}
              minLength={0}
              sticky
              sessionKey={SESSION_KEYS.DECISION_GRID_VIEW}
              filterKey={this.FILTER_KEYS.DACONS}
              initialValues={this.state.searchTerms.dacons}
            />
            <ChipAwareTextInput
              id="des"
              name="des"
              placeholder="Dose Equivalency"
              updateCallback={this.onSearchTermUpdate}
              minLength={0}
              sticky
              sessionKey={SESSION_KEYS.DECISION_GRID_VIEW}
              filterKey={this.FILTER_KEYS.DES}
              initialValues={this.state.searchTerms.des}
            />
            <Col>
              <MultiSelectCheckbox
                header="Include?"
                selectAllOnChange={this.onCheckSelectAll}
                items={this.state.includeOptions}
                itemOnChange={this.onCheckboxClick}
                showSelectedCount
              />
            </Col>
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default DPOSearchForm;
