import { loginRequest } from "../../api/clinicalBackend";

import {
  DISPLAY_ERROR,
  HEALTH_CHECK_PASSED,
  HEALTH_CHECK_FAILED
} from "../types";

export const tryHealthCheck = () => async dispatch => {
  const displayErrorDispatch = {
    type: DISPLAY_ERROR,
    payload: {
      status: 500,
      message: "Internal Server Error",
      detailedMessage:
        "Cannot communicate with server. Please refresh the page to try again"
    }
  };

  try {
    const response = await loginRequest.get("/actuator/health");
    if (response.data.status !== "UP") {
      dispatch({ type: HEALTH_CHECK_FAILED });
      dispatch(displayErrorDispatch);
    } else {
      dispatch({ type: HEALTH_CHECK_PASSED });
    }
  } catch (err) {
    dispatch({ type: HEALTH_CHECK_FAILED });
    dispatch(displayErrorDispatch);
  }
};
