import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import {
  getLinesOfBusiness,
  tryAddClient,
  toggleSidebarVisibility
} from "../../../actions";
import { populateBreadCrumbs } from "../../../actions";
import "./Client.css";
import ClientForm from "./ClientForm";

class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLoaded: false
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false }
      ],
      finalCrumb: "Add Client"
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getLinesOfBusiness();
  }
  componentDidUpdate() {
    if (!this.state.navLoaded) {
      this.setState({ navLoaded: true });
      this.setupBreadCrumbs();
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
  }

  onSubmit = formValues => {
    this.props.tryAddClient(formValues);
  };

  render() {
    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h1>Add Client</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <ClientForm
              linesOfBusiness={this.props.linesOfBusiness}
              onSubmit={this.onSubmit}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    linesOfBusiness: Object.values(state.linesOfBusiness)
  };
};

export default connect(
  mapStateToProps,
  {
    getLinesOfBusiness,
    tryAddClient,
    toggleSidebarVisibility,
    populateBreadCrumbs
  }
)(AddClient);
