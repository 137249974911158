import { DISPLAY_ERROR, CLEAR_ERROR } from "../actions/types";

const errorReducer = (state = {}, action) => {
  switch (action.type) {
    case DISPLAY_ERROR:
      return { ...state, ...action.payload };
    case CLEAR_ERROR:
      return {};
    default:
      return state;
  }
};

export default errorReducer;
