import { CLEAR_ALL_FILTERS, UPDATE_MIN_MAX_FILTER } from "../types";

export const updatePlanSavingsMinMax = minMax => dispatch => {
  dispatch({
    type: UPDATE_MIN_MAX_FILTER,
    payload: { min: minMax[0], max: minMax[1] }
  });
};

export const clearAllFilters = () => dispatch => {
  dispatch({ type: CLEAR_ALL_FILTERS });
};
