import React, { Component } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import _ from "lodash";
import {
  ChipAwareTextInput,
  SESSION_KEYS,
  getSessionFiltersOrDefault,
  MultiSelectCheckbox,
  removeSessionFilterValue,
  setSessionFilter,
} from "../../../utils";

class DecisionGridSearchForm extends Component {
  FILTER_KEYS = {
    STATUSES: "statuses",
    NAMES: "names",
  };

  constructor(props) {
    super(props);

    const localStorageFilters = getSessionFiltersOrDefault(
      SESSION_KEYS.DECISION_GRID_LIST
    );

    const localNames = localStorageFilters[this.FILTER_KEYS.NAMES];
    const localStatuses = localStorageFilters[this.FILTER_KEYS.STATUSES];

    this.state = {
      isOpen: true,
      searchFormDirty: true,
      searchTerms: {
        names: localNames ? localNames : [],
        statuses: localStatuses ? localStatuses : [],
      },
      statuses: this.getStatuses(),
    };
  }

  getStatuses = () => {
    const initialStatuses = getSessionFiltersOrDefault(
      SESSION_KEYS.DECISION_GRID_LIST
    );

    const statuses = _.map(this.props.statuses, (status) => {
      let checked = false;
      if (_.isEmpty(initialStatuses.statuses)) {
        if (status.name === "Draft" || status.name === "Active") {
          checked = true;
          this.handleCheckboxSessionFilters(status, checked);
        }
      } else {
        checked = _.includes(initialStatuses.statuses, status.id);
      }
      return {
        id: status.id,
        name: status.name,
        value: status.id,
        displayName: status.name,
        checked,
      };
    });
    return statuses;
  };

  componentDidUpdate = () => {
    if (this.state.searchFormDirty) {
      const statuses = _.filter(
        _.map(this.state.statuses, (val) => {
          if (val.checked) {
            return val.value;
          }
        }),
        (val) => {
          return val !== undefined;
        }
      );
      this.props.onSubmit({ ...this.state.searchTerms, statuses });
      this.setState({ searchFormDirty: false });
    }
  };

  toggleForm = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleCheckboxSessionFilters = (item, checked) => {
    if (checked) {
      setSessionFilter(
        SESSION_KEYS.DECISION_GRID_LIST,
        this.FILTER_KEYS.STATUSES,
        parseInt(item.id)
      );
    } else {
      removeSessionFilterValue(
        SESSION_KEYS.DECISION_GRID_LIST,
        this.FILTER_KEYS.STATUSES,
        parseInt(item.id)
      );
    }
  };

  onCheckboxClick = (e) => {
    const element = e.currentTarget;
    const items = this.state.statuses;
    items[parseInt(element.id) - 1].checked = element.checked;

    _.forEach(items, (item) => {
      this.handleCheckboxSessionFilters(item, item.checked);
    });

    this.setState({ statuses: items, searchFormDirty: true });
  };

  onCheckSelectAll = (e) => {
    const items = this.state.statuses;
    _.forEach(items, (item) => {
      const checked = e.currentTarget.checked;
      item.checked = checked;
      this.handleCheckboxSessionFilters(item, checked);
    });
    this.setState({ statuses: items, searchFormDirty: true });
  };

  onSearchTermUpdate = (inputName, terms) => {
    this.setState({
      searchTerms: { ...this.state.searchTerms, [inputName]: terms },
      searchFormDirty: true,
    });
  };

  render = () => {
    return (
      <div>
        <Collapse isOpen={this.state.isOpen} className="mb-5">
          <Row>
            <ChipAwareTextInput
              id="name"
              name="names"
              placeholder="P&T Decision Grid Desc"
              updateCallback={this.onSearchTermUpdate}
              sticky
              sessionKey={SESSION_KEYS.DECISION_GRID_LIST}
              filterKey={this.FILTER_KEYS.NAMES}
              initialValues={this.state.searchTerms.names}
            />
            <Col>
              <MultiSelectCheckbox
                header="Status"
                selectAllOnChange={this.onCheckSelectAll}
                items={this.state.statuses}
                itemOnChange={this.onCheckboxClick}
                showSelectedCount
              />
            </Col>
          </Row>
        </Collapse>
        <div className="toggle-container">
          <hr className="claim-form-hr" />
          <Button onClick={this.toggleForm}>
            {this.state.isOpen ? "Hide Filters" : "Show Filters"}
          </Button>
          <hr className="claim-form-hr" />
        </div>
      </div>
    );
  };
}

export default DecisionGridSearchForm;
