import moment from "moment";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_PICKER_FORMAT = "MM/dd/yyyy";
export const DATE_CONVERT_FORMAT = "MM/DD/YYYY";
export const DATE_PRESENTATION_FORMAT = "M/d/yyyy";

export const getUnrestrictedDateConfig = () => {
  return {
    minEffectiveDate: moment().toDate(),
    minTermDate: moment().add(1, "d").toDate(),
    maxEffectiveDate: moment().year(2999).toDate(),
    maxTermDate: moment().add(1, "d").year(2999).toDate(),
  };
};

export const getMaxDate = () => {
  return moment().year(2999).toDate();
};

export const geteffTermDateConfig = (dateAwareObjects, fromEdit) => {
  // Calculate the latest effective date for each object
  const edAsMoments = dateAwareObjects.map((obj) => {
    return moment(obj.effectiveDate, DATE_FORMAT);
  });

  // Calculate the latest term date for each object
  const tdAsMoments = dateAwareObjects.map((obj) => {
    return moment(obj.termDate, DATE_FORMAT);
  });

  // Set an arbitrary max date for the date picker

  const minDate = moment().toDate();
  const maxDate = moment().year(2999).toDate();

  let minEffectiveDate = minDate;
  let minTermDate = minDate;
  let maxEffectiveDate = maxDate;
  let maxTermDate = maxDate;

  if (edAsMoments.length > 0 && tdAsMoments <= 0) {
    // Add a day to the lattest effective date since effective dates can't overlap
    minEffectiveDate = moment.max(edAsMoments).add(1, "d").toDate();
    minTermDate = minEffectiveDate;
  } else if (edAsMoments.length > 0 && tdAsMoments.length > 0) {
    // termDate can be null. If that's the case we have to use the max effectiveDate to determine minDate
    let containsNullTerm = tdAsMoments.reduce((acc, curr) => {
      return !curr.isValid();
    }, false);

    if (containsNullTerm) {
      minEffectiveDate = moment.max(edAsMoments).add(1, "d").toDate();
      minTermDate = minEffectiveDate;
    } else {
      //If the objects are coming from an edit form, we want to be able to choose between the current effective and term dates
      if (fromEdit) {
        minEffectiveDate = moment.max(edAsMoments).add(1, "d").toDate();
        maxEffectiveDate = moment.max(tdAsMoments).subtract(1, "d").toDate();
      } else {
        minEffectiveDate = moment.max(tdAsMoments).add(1, "d").toDate();
      }
      minTermDate = minEffectiveDate;
    }
  }

  return {
    minEffectiveDate: minEffectiveDate,
    minTermDate: minTermDate,
    maxEffectiveDate: maxEffectiveDate,
    maxTermDate: maxTermDate,
  };
};

// react-date-picker is always setting dates 1 behind what is actually picked. This method fixes that.
export const getRenderDate = (date) => {
  return Date.parse(moment(date).add(1, "d").format(DATE_FORMAT)) || null;
};

export const getDateFromDescription = (description) => {
  switch (description) {
    case "previousMonth":
      return moment().subtract(1, "month");
    case "last6Months":
      return moment().subtract(6, "months");
    case "last1Year":
      return moment().subtract(1, "years");
    case "last2Years":
      return moment().subtract(2, "years");
    case "last5Years":
      return moment().subtract(5, "years");
    default:
      return moment();
  }
};
