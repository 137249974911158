import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import {
  GET_MPDS,
  GET_MPDS_CORE_SORTED,
  SAVE_MPDS,
  GET_MPD,
  DELETE_MPD,
  CALCULATE_MPD,
  CLEAR_CALCULATED_MPD,
  EDIT_MPD,
  EXCLUDE_MPD_CATEGORIES,
  EXCLUDE_MPD_GPIS,
  MPDS_LOADED,
  MPDS_UNLOADED,
  GET_MPD_TARGET_COSTS,
  SET_INITIAL_MIN_MAX_FILTER,
  GET_MPD_ACTIVITY_LOG,
  CLEAR_MPDS,
} from "../types";
import { handleError } from "../../utils/error/errorHandlers";
import history from "../../history";
import moment from "moment";
import { formatPageableParameters } from "../../utils/string/stringUtils";
import _ from "lodash";
import {
  DATE_CONVERT_FORMAT,
  downloadExcelFile,
  EXCEL_DOWNLOAD_OPTIONS,
} from "../../utils";

export const getMPDs =
  (clientId, projection = "core") =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const mpds = await getClinicalBackend(authToken).get(
        "/api/client-ref-rpts/?clientId=" +
          clientId +
          "&projection=" +
          projection
      );

      dispatch({
        type: GET_MPDS,
        payload: mpds.data,
      });
      dispatch({ type: MPDS_LOADED });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getMPDsPageable =
  (clientId, projection = "core", sorts, pageNumber = 0, size = 20) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      let endPoint =
        "/api/client-ref-rpts/?clientId=" +
        clientId +
        "&projection=" +
        projection;

      endPoint += formatPageableParameters(pageNumber, size, sorts);

      const mpds = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_MPDS_CORE_SORTED,
        payload: mpds.data,
      });
      dispatch({ type: MPDS_LOADED });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getMPDActivity =
  (clientReferenceReportId, activityTrackTypes, pageNumber, size, sorts) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      let endPoint = "/api/ref-price-activities/?";

      if (clientReferenceReportId && clientReferenceReportId.length > 0) {
        endPoint += "&clientReferenceReportId=" + clientReferenceReportId;
      }

      if (activityTrackTypes && activityTrackTypes.length > 0) {
        endPoint += "&activityTrackTypes=" + activityTrackTypes;
      }

      endPoint += formatPageableParameters(pageNumber, size, sorts);

      const activityLogs = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_MPD_ACTIVITY_LOG,
        payload: activityLogs.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const unloadMPDs = () => (dispatch) => {
  dispatch({ type: MPDS_UNLOADED });
};

export const getMPD = (mpdId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const mpd = await getClinicalBackend(authToken).get(
      "/api/client-ref-rpts/" + mpdId
    );

    dispatch({
      type: GET_MPD,
      payload: mpd.data,
    });
    dispatch({ type: MPDS_LOADED });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryExportMPDReport =
  (mpdId, includeNdcs) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const report = await getClinicalBackend(authToken).get(
        "/api/mpd-reports/?mpdId=" + mpdId + "&includeNdcs=" + includeNdcs,
        EXCEL_DOWNLOAD_OPTIONS
      );

      downloadExcelFile(report);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryExportInternalMPDReport =
  (reportSelections) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const report = await getClinicalBackend(authToken).post(
        "/api/mpd-reports",
        JSON.stringify(reportSelections),
        EXCEL_DOWNLOAD_OPTIONS
      );

      downloadExcelFile(report);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryAddMPD = (mpd) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const response = await getClinicalBackend(authToken).post(
      "/api/client-ref-rpts",
      JSON.stringify(mpd)
    );
    dispatch({
      type: SAVE_MPDS,
      payload: response.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryEditMPD = (mpd) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    const response = await getClinicalBackend(authToken).put(
      "/api/client-ref-rpts/" + mpd.id,
      JSON.stringify(mpd)
    );

    dispatch({
      type: EDIT_MPD,
      payload: response.data,
    });
    history.goBack();
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryDeleteMPD = (mpdId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    await getClinicalBackend(authToken).delete("/api/client-ref-rpts/" + mpdId);
    dispatch({ type: DELETE_MPD, payload: mpdId });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const excludeMPDCategories =
  (mpdId, excludedMPDCategories) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      const response = await getClinicalBackend(authToken).post(
        "/api/mpds/" + mpdId + "/category-exclusions",
        JSON.stringify(excludedMPDCategories)
      );

      dispatch({
        type: EXCLUDE_MPD_CATEGORIES,
        payload: response.data,
      });
      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const excludeMPDGpis =
  (mpdId, excludedMPDGpis) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      const response = await getClinicalBackend(authToken).post(
        "/api/mpds/" + mpdId + "/gpi-exclusions",
        JSON.stringify(excludedMPDGpis)
      );

      dispatch({
        type: EXCLUDE_MPD_GPIS,
        payload: response.data,
      });
      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getMPDTargetCosts =
  (
    mpdId,
    categories,
    nameSearches,
    exclusions,
    planSavingsMinMax,
    errorReasons,
    pageNumber,
    size,
    sorts
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endPoint = "/api/target-costs/all?reportId=" + mpdId;

      if (categories && categories.length > 0) {
        endPoint += "&categories=" + categories.join();
      }

      if (nameSearches && nameSearches.length > 0) {
        endPoint += "&likeDrugNamePatterns=" + nameSearches.join();
      }

      if (exclusions && exclusions.length > 0) {
        endPoint += "&excludeReasons=" + exclusions.join();
      }

      if (errorReasons && errorReasons.length > 0) {
        //Check to see if only-errors is included, if so, strip it off and send appropriate url param
        const onlyErrors = _.remove(errorReasons, (reason) => {
          return reason === "only-errors";
        });

        if (!_.isEmpty(onlyErrors)) {
          endPoint += "&onlyErrors=false";
        } else {
          endPoint += "&onlyErrors=true";
        }

        endPoint += "&errorReasons=" + errorReasons.join();
      }

      // planSavingsMinMax should ALWAYS have 2 values if it is to be included in the query string
      if (planSavingsMinMax.length === 2) {
        endPoint += "&planSavingsMin=" + planSavingsMinMax[0];
        endPoint += "&planSavingsMax=" + planSavingsMinMax[1];
      }

      if (pageNumber && pageNumber > 0) {
        endPoint += "&page=" + pageNumber;
      }
      if (size && size > 0) {
        endPoint += "&size=" + size;
      }
      if (sorts && sorts.length > 0) {
        sorts.forEach((sort) => {
          endPoint += "&sort=" + sort.dataField + "," + sort.order;
        });
      }

      const mpdTargetCosts = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_MPD_TARGET_COSTS,
        payload: mpdTargetCosts.data,
      });

      dispatch({
        type: SET_INITIAL_MIN_MAX_FILTER,
        // Using whole numbers. Subtract 1 from min to cover lowest and add 1 to max to include top planSavings value
        payload: {
          min: parseInt(mpdTargetCosts.data.planSavingsMin) - 1,
          max: parseInt(mpdTargetCosts.data.planSavingsMax) + 1,
        },
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const calculateMpd =
  (mpdId, targetGpi, dacon, requestDate) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      const data = {
        mpdId: mpdId,
        targetGpi: targetGpi,
        dacon: dacon,
        requestDate: requestDate
          ? requestDate
          : moment().format(DATE_CONVERT_FORMAT),
      };

      const response = await getClinicalBackend(authToken).post(
        "/api/mpd-calculations",
        JSON.stringify(data)
      );

      dispatch({ type: CALCULATE_MPD, payload: response.data });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearCalculatedMpd = () => (dispatch) => {
  dispatch({ type: CLEAR_CALCULATED_MPD });
};

export const clearMpds = () => (dispatch) => {
  dispatch({ type: CLEAR_MPDS });
  dispatch({ type: MPDS_UNLOADED });
};
