import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { handleError, formatPageableParameters } from "../../utils";
import {
  TRY_ADDITIONAL_TARGET_DRUG_SEARCH,
  TRY_PROVIDER_TARGET_SEARCH,
  CLEAR_ADDITIONAL_TARGET_DRUGS,
  GET_LATEST_CATALOG,
  ADD_ADDITIONAL_TARGET_SAVE_ERRORS,
  GET_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG,
  CLEAR_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG,
} from "../types";
import history from "../../history";
import _ from "lodash";

export const tryAdditionalTargetDrugSearch =
  (clientId, searchTerms, pageNumber, size, sort) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/addtl-targets/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null
      )}`;

      const targets = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify({ ...searchTerms, clientId })
      );

      dispatch({
        type: TRY_ADDITIONAL_TARGET_DRUG_SEARCH,
        payload: targets.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getAllAdditionalTargetDrugs =
  (clientId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/addtl-targets/?clientId=${clientId}&fullList=1`;

      const targets = await getClinicalBackend(authToken).get(endpoint);

      const latestCatalog = await getClinicalBackend(authToken).get(
        "/api/catalogs/latest"
      );

      dispatch({ type: GET_LATEST_CATALOG, payload: latestCatalog.data });
      dispatch({
        type: TRY_ADDITIONAL_TARGET_DRUG_SEARCH,
        payload: targets.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryProviderTargetSearch =
  (searchTerms, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/target-gpis/search?sort=${sort.dataField},${sort.order}&projection=additionalTargetGpiDto&fullList=true`;
      const targets = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms)
      );

      dispatch({
        type: TRY_PROVIDER_TARGET_SEARCH,
        payload: targets.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const deleteAdditionalTargetDrug =
  (id) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/addtl-targets/${id}`;

      await getClinicalBackend(authToken).delete(endpoint);

      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryUpdateAdditionalTargetDrug =
  (additionalTarget) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/addtl-targets/${additionalTarget.id}`;

      await getClinicalBackend(authToken).put(
        endpoint,
        JSON.stringify(additionalTarget)
      );

      history.go(0);
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const tryAddAdditionalTargetDrugs =
  (additionalTargets) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = "/api/addtl-targets";
      const errors = [];
      const calls = _.map(additionalTargets, async (target) => {
        await getClinicalBackend(authToken)
          .post(endpoint, JSON.stringify(target))
          .catch(() => {
            errors.push(target);
          });
      });

      await Promise.allSettled(calls);

      if (errors.length > 0) {
        dispatch({ type: ADD_ADDITIONAL_TARGET_SAVE_ERRORS, payload: errors });
      } else {
        history.goBack();
      }
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearAdditionalTargetDrugs = () => (dispatch) => {
  dispatch({ type: CLEAR_ADDITIONAL_TARGET_DRUGS });
};

export const getAdditionalTargetDrugsAuditLog =
  (searchTerms, pageNumber, size, sorts) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endPoint = `/api/addtl-targets-audits?${formatPageableParameters(
        pageNumber,
        size,
        sorts ? [sorts] : null
      )}`;

      const auditLogs = await getClinicalBackend(authToken).post(
        endPoint,
        JSON.stringify(searchTerms)
      );

      dispatch({
        type: GET_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG,
        payload: auditLogs.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearAdditionalTargetDrugsAuditLogs = () => (dispatch) => {
  dispatch({ type: CLEAR_ADDITIONAL_TARGET_DRUGS_AUDIT_LOG });
};
