import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";

import states from "./states";
import "./Client.css";
import history from "../../../history";
import {
  required,
  maxLength50,
  maxLength15,
  maxLength45,
  zipcode,
  validSelect,
  alphanWithSpace,
  alphanNoSpace,
  renderInput,
  renderSelectInput,
  CLIENT_TYPES
} from "./../../../utils";

class ClientForm extends Component {
  onSubmit = formValues => {
    this.props.onSubmit(formValues);
  };

  render() {
    const { invalid, submitting, pristine } = this.props;
    return (
      <Form
        className="add-client-form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        autoComplete="off"
      >
        <FormGroup>
          <Field
            id="name"
            name="name"
            type="text"
            placeholder="Name"
            required
            validate={[required, maxLength50, alphanWithSpace]}
            component={renderInput}
          />
        </FormGroup>
        <FormGroup>
          <Field
            id="slug"
            name="slug"
            type="text"
            placeholder="Client's Nickname/Acronym"
            required
            validate={[required, maxLength15, alphanNoSpace]}
            component={renderInput}
          />
        </FormGroup>
        <FormGroup>
          <Field
            id="type"
            name="type"
            type="select"
            label="Client Type"
            required
            items={CLIENT_TYPES}
            valueProp="value"
            keyProp="key"
            displayProp="label"
            validate={[required, validSelect]}
            component={renderSelectInput}
          />
        </FormGroup>
        <FormGroup>
          <Field
            id="lineOfBusiness"
            name="lineOfBusiness"
            type="select"
            label="Line Of Business"
            required
            items={this.props.linesOfBusiness}
            valueProp="id"
            keyProp="id"
            displayProp="description"
            validate={[required, validSelect]}
            component={renderSelectInput}
          />
        </FormGroup>
        <FormGroup>
          <Field
            id="address1"
            name="address1"
            type="text"
            placeholder="Address 1"
            required
            validate={[required, maxLength45]}
            component={renderInput}
          />
        </FormGroup>
        <FormGroup>
          <Field
            id="address2"
            name="address2"
            type="text"
            placeholder="Address 2"
            validate={[maxLength45]}
            component={renderInput}
          />
        </FormGroup>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Field
                id="city"
                name="city"
                type="text"
                placeholder="City"
                required
                validate={[required, maxLength50]}
                component={renderInput}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <Field
              id="state"
              name="state"
              type="select"
              label="State"
              required
              items={states}
              valueProp="val"
              keyProp="val"
              displayProp="label"
              validate={[required, validSelect]}
              component={renderSelectInput}
            />
          </Col>
          <Col md={2}>
            <Field
              id="zipCode"
              name="zipCode"
              type="text"
              placeholder="Zip Code"
              required
              validate={[required, zipcode]}
              component={renderInput}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8} />
          <Col md={2}>
            <Button
              color="danger"
              block
              onClick={e => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col md={2}>
            <Button
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default reduxForm({ form: "clientForm" })(ClientForm);
