import {
  TRY_CLAIM_SEARCH,
  CLEAR_CLAIMS,
  SHOW_CLAIM_SEARCH_EXCEPTION,
} from "../actions/types";

const claimsReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_CLAIM_SEARCH_EXCEPTION:
    case TRY_CLAIM_SEARCH:
      return action.payload;
    case CLEAR_CLAIMS:
      return {};
    default:
      return state;
  }
};

export default claimsReducer;
