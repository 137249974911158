import { MPDS_LOADED, MPDS_UNLOADED } from "../actions/types";

const mpdsLoadedReducer = (state = false, action) => {
  switch (action.type) {
    case MPDS_LOADED:
      return true;
    case MPDS_UNLOADED:
      return false;
    default:
      return state;
  }
};

export default mpdsLoadedReducer;
