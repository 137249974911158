import React from "react";

export default function EnvironmentBadge() {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  let badgeContent = "";
  let badgeClass = "font-weight-bold";
  switch (environment) {
    case "dev":
      badgeContent = "DEVELOPMENT";
      badgeClass += " text-danger";
      break;
    case "qa":
      badgeContent = "QA";
      badgeClass += " text-warning";
      break;
    case "local":
      badgeContent = "LOCAL";
      badgeClass += " text-info";
      break;
    default:
      badgeContent = "";
      badgeClass = "";
  }

  return <h1 className={badgeClass}>{badgeContent}</h1>;
}
