import {
  TRY_CATEGORY_FILL_FACTOR_UPDATE,
  TRY_GPI12_FILL_FACTOR_UPDATE,
} from "../actions/types";

const fillFactorsUpdateReducer = (state = [], action) => {
  switch (action.type) {
    case TRY_CATEGORY_FILL_FACTOR_UPDATE:
    case TRY_GPI12_FILL_FACTOR_UPDATE:
      return action.payload;
    default:
      return state;
  }
};

export default fillFactorsUpdateReducer;
