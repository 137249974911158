import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { GET_REPORT_CLAIMS_DATE_RANGE } from "../types";
import { handleError } from "../../utils/error/errorHandlers";

export const getReportClaimsDateRange =
  (clientId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;

      const claimCorrelations = await getClinicalBackend(authToken).get(
        `/api/rp-perf-reports/date-range?clientId=${clientId}`
      );

      dispatch({
        type: GET_REPORT_CLAIMS_DATE_RANGE,
        payload: claimCorrelations.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
