import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import {
  GET_MPD_TIE_IN,
  GET_MPD_TIE_INS,
  SAVE_MPD_TIE_IN,
  EDIT_MPD_TIE_IN,
  DELETE_MPD_TIE_IN,
  CLEAR_MPD_TIE_INS
} from "../types";
import { handleError } from "../../utils/error/errorHandlers";

export const getMPDTieIns = clientId => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    const tieIns = await getClinicalBackend(authToken).get(
      "/api/client-tie-ins?clientId=" + clientId
    );

    dispatch({
      type: GET_MPD_TIE_INS,
      payload: tieIns.data
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getMPDTieIn = id => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const tieIn = await getClinicalBackend(authToken).get(
      "/api/client-tie-ins/" + id
    );

    dispatch({
      type: GET_MPD_TIE_IN,
      payload: tieIn.data
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryAddMPDTieIn = formValues => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const data = {
      ...formValues,
      grant_type: process.env.REACT_APP_GRANT_TYPE
    };

    const response = await getClinicalBackend(authToken).post(
      "/api/client-tie-ins/",
      JSON.stringify(data)
    );

    dispatch({
      type: SAVE_MPD_TIE_IN,
      payload: response.data
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryEditMPDTieIn = formValues => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const data = {
      ...formValues,
      grant_type: process.env.REACT_APP_GRANT_TYPE
    };
    const response = await getClinicalBackend(authToken).put(
      "/api/client-tie-ins/" + formValues.id,
      JSON.stringify(data)
    );

    dispatch({
      type: EDIT_MPD_TIE_IN,
      payload: response.data
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const tryDeleteMPDTieIn = id => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    await getClinicalBackend(authToken).delete("/api/client-tie-ins/" + id);
    dispatch({ type: DELETE_MPD_TIE_IN, payload: id });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearMPDTieIns = () => dispatch => {
  dispatch({ type: CLEAR_MPD_TIE_INS });
};
