import { SIGN_OUT, DISPLAY_ERROR } from "../../actions/types";
import { showAuthError, tryRefreshToken } from "../../actions/auth/authAction";
import _ from "lodash";

export const handleError = async (error, dispatch) => {
  const message = error.data.error_description;
  switch (error.status) {
    case 401:
      if (message.indexOf("Access token expired") !== -1) {
        dispatch(tryRefreshToken(true));
      }
      if (message.indexOf("Invalid refresh token (expired)") !== -1) {
        dispatch({ type: SIGN_OUT, payload: true });
      }
      if (message.indexOf("Result must not be null!") !== -1) {
        // We get this one when you provide a username that doesn't exist in the system
        dispatch(showAuthError("Invalid User Name or Password."));
      }
      break;
    case 400:
      if (error.data.error === "invalid_grant") {
        if (message.indexOf("User account is locked") !== -1) {
          dispatch(showAuthError("Account is locked."));
        } else {
          dispatch(showAuthError("Invalid User Name or Password."));
        }
      } else {
        dispatch({ type: DISPLAY_ERROR, payload: getErrorPayload(error) });
      }
      break;
    case 403:
    default:
      dispatch({ type: DISPLAY_ERROR, payload: getErrorPayload(error) });
  }
};

const getErrorPayload = error => {
  if (_.hasIn(error, "data.error")) {
    const msg = messages.hasOwnProperty(error.data.error)
      ? messages[error.data.error]
      : error.data.error;
    let detailedMsg = error.data.detailedMessage
      ? error.data.detailedMessage
      : error.data.error_description;
    if (!detailedMsg) {
      detailedMsg = error.data.message;
    }
    return {
      status: error.status,
      message: msg,
      detailedMessage: detailedMsg
    };
  } else {
    const msg = messages.hasOwnProperty(error.data.message)
      ? messages[error.data.message]
      : error.data.message;
    return {
      status: error.status,
      message: msg,
      detailedMessage: error.data.detailedMessage
    };
  }
};

// Translate the message coming from the server into something a little more presentable. Hopefully the detailed
// descriptions coming through are good enough to show the user.
const messages = {
  locked_catalog: "Catalog is locked",
  data_integrity_violation: "Data Integrity Issue",
  resource_not_found: "Resource Not Found",
  access_denied: "Access Denied",
  bad_request: "Bad Request"
};
