import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import {
  GET_MPD_SUMMARIES,
  CLEAR_MPD_SUMMARIES,
  UPDATE_MPD_SUMMARIES,
} from "../types";
import { handleError } from "../../utils/error/errorHandlers";

export const getMpdSummaries = (mpdId) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const summaries = await getClinicalBackend(authToken).get(
      "/api/mpd-summaries/" + mpdId
    );

    dispatch({
      type: GET_MPD_SUMMARIES,
      payload: summaries.data,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const updateMpdSummaries = (summaries) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_MPD_SUMMARIES,
      payload: summaries,
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearMpdSummaries = () => (dispatch) => {
  dispatch({ type: CLEAR_MPD_SUMMARIES });
};
