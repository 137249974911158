import _ from "lodash";
import { gpiNameFormatter } from "../index";
import { getDrugName } from "../string/stringUtils";

export const sortHandler = (sorts, newSort) => {
  const nonUniq = _.intersectionBy(sorts, newSort, "dataField");
  let returnVal = newSort;
  if (nonUniq.length > 0) {
    if (nonUniq[0].order === "desc") {
      nonUniq[0].order = "asc";
    } else {
      nonUniq[0].order = "desc";
    }
    returnVal = nonUniq;
  }
  return returnVal;
};

export const drugNameSort = (a, b, order, dataField, c, d) => {
  const nameA = gpiNameFormatter(c, c);
  const nameB = gpiNameFormatter(d, d);
  if (order === "asc") {
    return nameA.localeCompare(nameB);
  }
  return nameB.localeCompare(nameA);
};

export const getInitiallySortedAhfsGpis = ahfsGpis => {
  const named = _.map(ahfsGpis, gpi => {
    gpi.fullName = getDrugName(gpi);
    return gpi;
  });

  // Sort and remove anything that isn't prescribable (activeRxNdcsCode indicates prescribability)
  const sorted = _.orderBy(
    named,
    ["gpi", "ahfsMultiSource", "fullName"],
    ["asc", "asc", "asc"]
  ).filter(gpi => gpi.activeRxNdcsCode === "Y");

  return sorted;
};

export const userNameSortHandler = (a, b, order) => {
  if (order === "asc") {
    return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
  }
  return b.lastName.toLowerCase().localeCompare(a.lastName.toLowerCase());
};
