import React, { useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import { FaTrashAlt } from "react-icons/fa";
import { isValidEmail } from "../../../../utils";
import ConfirmationModal from "../../../Modal/ConfirmationModal";

const CatalogProductNotificationForm = props => {
  const [values, setValues] = useState(props.initialValues);
  const [inputVal, setInputVal] = useState("");
  const [errorVal, setErrorVal] = useState(null);
  const [emailToAdd, setEmailToAdd] = useState(null);
  const [isRollinConfirmOpen, setIsRollinConfirmOpen] = useState(false);
  const [isRollinDeleteConfirmOpen, setIsRollinDeleteConfirmOpen] = useState(
    false
  );
  const [emailToRemove, setEmailToRemove] = useState({});

  const toggleRollinConfirmModal = () => {
    setIsRollinConfirmOpen(!isRollinConfirmOpen);
  };

  const toggleRollinDeleteConfirmModal = () => {
    setIsRollinDeleteConfirmOpen(!isRollinDeleteConfirmOpen);
  };

  const onAdd = () => {
    if (props.name === "rollin" && values.length === 0) {
      setEmailToAdd(inputVal);
      toggleRollinConfirmModal();
    } else {
      addEmail(inputVal);
    }
  };

  const addEmail = email => {
    const newVals = values;
    if (!_.find(newVals, { email: inputVal })) {
      newVals.push({
        id: 0,
        clientContractId: 0,
        type: props.name.toUpperCase(),
        email
      });
      setValues(newVals);

      resetInput();
      props.addCallback(props.name, email, newVals);
    } else {
      setErrorVal(
        `${inputVal} already exists. Please enter a new email address.`
      );
    }
  };

  const onRollinConfirm = () => {
    addEmail(emailToAdd);
  };

  const resetInput = () => {
    setInputVal("");
    setEmailToAdd(null);
  };

  const onRemove = val => {
    setEmailToRemove(val);
    const canRemove = props.preRemoveCallback();
    if (canRemove) {
      if (props.name === "rollin" && values.length === 1) {
        toggleRollinDeleteConfirmModal();
      } else {
        removeEmail(val);
      }
    }
  };

  const removeEmail = val => {
    const removeVal = val ? val : emailToRemove;
    const newVals = _.filter(values, value => {
      return value.email !== removeVal.email;
    });
    setValues(newVals);
    props.removeCallback(props.name, removeVal, newVals);
  };

  const onRollinDeleteConfirm = () => {
    removeEmail();
  };

  const onEnterKey = e => {
    if (e.key === "Enter" && !_.isEmpty(inputVal.trim())) {
      onAdd();
    }
  };

  const onTextInputChange = e => {
    if (errorVal) {
      setErrorVal(null);
    }
    setInputVal(e.target.value);
  };

  const getNoValuesProvidedMessage = () => {
    if (props.name === "catalog") {
      return (
        <span className="form-error">
          At least one Email address is required.
        </span>
      );
    }
    return (
      <span className="blue-txt font-weight-bold">
        No Roll-in Report will be delivered.
      </span>
    );
  };

  return (
    <>
      <InputGroup>
        <Input
          type="text"
          id={props.name}
          name={props.name}
          value={inputVal}
          onChange={onTextInputChange}
          onKeyPress={onEnterKey}
        />

        <Button
          onClick={onAdd}
          disabled={
            _.isEmpty(_.trim(inputVal)) || !inputVal || isValidEmail(inputVal)
          }
        >
          Add
        </Button>
      </InputGroup>
      <ListGroup flush>
        {values.length <= 0 && getNoValuesProvidedMessage()}
        {_.map(values, value => (
          <ListGroupItem className="align-text-bottom" key={value.email}>
            <Button
              className="actionIconBtnDanger mr-4"
              color="link"
              onClick={() => {
                onRemove(value);
              }}
            >
              <FaTrashAlt />
            </Button>
            <span className="align-bottom">{value.email}</span>
          </ListGroupItem>
        ))}
      </ListGroup>
      {errorVal && <h6 className="form-error">{errorVal}</h6>}
      <ConfirmationModal
        isOpen={isRollinConfirmOpen}
        toggle={toggleRollinConfirmModal}
        header="Adding Client Roll-ins Reports"
        content="Adding Roll-ins Report recipient will provide the report to the recipient on a weekly basis. Please verify you are adding this service."
        action={onRollinConfirm}
        cancelAction={resetInput}
      />
      <ConfirmationModal
        isOpen={isRollinDeleteConfirmOpen}
        toggle={toggleRollinDeleteConfirmModal}
        header="Deleted Last Email Recipient"
        content="Deleting the last email recipient of a roll-ins Report will stop all future Client Roll-ins reports from being delivered. Please confirm you wish to stop delivery of the Roll-ins reort for this client."
        action={onRollinDeleteConfirm}
        cancelAction={resetInput}
        derp={emailToRemove}
      />
    </>
  );
};

export default CatalogProductNotificationForm;

CatalogProductNotificationForm.propTypes = {
  addCallback: PropTypes.func.isRequired,
  preRemoveCallback: PropTypes.func.isRequired,
  removeCallback: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  initialValues: PropTypes.array.isRequired
};
