import { CLEAR_REPORTS, GET_CAGCENTRIC_COUNTS } from "../actions/types";
import _ from "lodash";

const cagcentricCountsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CAGCENTRIC_COUNTS:
      const noResults =
        _.isEmpty(action.payload) && _.isEmpty(action.payload.content);
      return noResults
        ? {}
        : {
            ...state,
            [action.payload.content[0].clientReport.id]: action.payload,
          };
    case CLEAR_REPORTS:
      return {};
    default:
      return state;
  }
};

export default cagcentricCountsReducer;
