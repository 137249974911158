import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col } from "reactstrap";
import { FaDownload, FaLock, FaTrashAlt } from "react-icons/fa";
import {
  clearClients,
  clearReports,
  getClients,
  searchReports,
  deletePerformanceReport,
  populateBreadCrumbs,
  populateSidebar,
  tryExportPerformaceReport,
} from "../../../actions";
import _ from "lodash";
import { dateFormatter, userNameFormatter } from "../../../utils";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { REPORT_TYPES, SIDEBAR_ITEMS } from "../Constants";
import ReportSearchForm from "./ReportSearchForm";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { Role } from "../../../utils/auth/roles";

class ReportList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      searchTerms: { users: [], clientId: null },
      isOpen: false,
      isAuthorized: _.some(this.props.roles, (role) => {
        return role.name === Role.CLIENT_ADMIN.name;
      }),
    };
    this.setupBreadCrumbs();
  }
  componentDidMount = () => {
    this.setupSidebar();

    this.props.getClients(REPORT_TYPES.RP_PERFORMANCE);
    this.getPage();
  };

  componentWillUnmount = () => {
    this.props.clearReports();
    this.props.clearClients();
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/reporting",
          name: "Reporting",
          active: false,
        },
      ],
      finalCrumb: "Performance Reporting",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  getPage = () => {
    this.props.searchReports(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  userNameFormatter = (content, _) => {
    if (!content) {
      return "System Generated";
    }
    return userNameFormatter(content);
  };

  deleteReport = (reportId, reportName) => {
    this.toggleModal(reportId, reportName);
  };

  toggleModal = (reportId, reportName) => {
    const header = "Delete " + reportName;
    const content = "Are you sure you want to delete " + reportName + "?";

    this.setState({
      isOpen: !this.state.isOpen,
      modalHeader: header,
      modalContent: content,
      modalAction: this.props.deletePerformanceReport,
      reportId: reportId,
    });
  };

  actionFormatter = (content, row) => {
    const shouldDisableDownload = !(
      row.clientReport.downloadable === "Y" &&
      row.clientReport.reportStatus === "Completed"
    );
    const deletable = this.state.isAuthorized;
    return (
      <>
        {deletable && (
          <Button
            className="linkBtn tableBtn actionIconBtnDanger"
            color="link"
            onClick={() =>
              this.deleteReport(row.clientReport.id, row.clientReport.name)
            }
          >
            <FaTrashAlt />
          </Button>
        )}
        <Button
          className="linkBtn tableBtn"
          color="link"
          onClick={() => {
            this.props.tryExportPerformaceReport(row.clientReport.id, true);
          }}
          disabled={shouldDisableDownload}
        >
          {shouldDisableDownload ? <FaLock /> : <FaDownload />}
        </Button>
      </>
    );
  };

  COLUMNS = [
    {
      dataField: "actions",
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: { width: "3%" },
    },
    {
      dataField: "clientReport.client.name",
      sort: true,
      text: "Client",
    },
    {
      dataField: "clientReport.name",
      sort: true,
      text: "Report Name",
    },
    {
      dataField: "clientReport.periodStart",
      sort: true,
      text: "Period of Analysis - From",
      formatter: dateFormatter,
    },
    {
      dataField: "clientReport.periodEnd",
      sort: true,
      text: "Period of Analysis - End",
      formatter: dateFormatter,
    },
    {
      dataField: "clientReport.generatedDate",
      sort: true,
      text: "Date Requested",
      formatter: dateFormatter,
    },
    {
      dataField: "clientReport.generatedBy",
      sort: true,
      text: "Generated By",
      formatter: this.userNameFormatter,
    },
  ];

  render() {
    if (_.isEmpty(this.props.clients) || _.isEmpty(this.props.reports)) {
      return <h1>LOADING...</h1>;
    }
    const currentUserName = `${this.props.currentUser.firstName} ${this.props.currentUser.lastName}`;

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <ReportSearchForm
              onSubmit={this.onSearchSubmit}
              clients={this.props.clients}
              currentUser={currentUserName}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <Button
              className="mb-3"
              color="primary"
              tag={Link}
              to="/reporting/performance/request"
            >
              Request Performance Report
            </Button>
            <RPSDataGrid
              keyField="clientReport.id"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.reports.totalElements}
              data={this.props.reports.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalHeader}
          content={this.state.modalContent}
          action={this.state.modalAction}
          objId={this.state.reportId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    clients: Object.values(state.clients),
    reports: state.reports,
    roles: state.auth.currentUser.roles,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  clearReports,
  getClients,
  searchReports,
  deletePerformanceReport,
  populateBreadCrumbs,
  populateSidebar,
  tryExportPerformaceReport,
})(ReportList);
