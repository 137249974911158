import _ from "lodash";
import { CALCULATE_MPD, CLEAR_CALCULATED_MPD } from "../actions/types";

const mpdCalculationReducer = (state = {}, action) => {
  switch (action.type) {
    case CALCULATE_MPD:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case CLEAR_CALCULATED_MPD:
      return {};
    default:
      return state;
  }
};

export default mpdCalculationReducer;
