import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Container, Row, Button } from "reactstrap";
import {
  FaPencilAlt,
  FaTrashAlt,
  FaLock,
  FaBomb,
  FaCalculator,
  FaDownload,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  clearClients,
  getClient,
  getMPDsPageable,
  tryDeleteMPD,
  clearSidebarActions,
  populateBreadCrumbs,
  populateSidebar,
  tryExportMPDReport,
  getMpdSummaries,
  clearMpdSummaries,
  tryExportInternalMPDReport,
  unloadMPDs,
  clearMpds,
} from "./../../../actions";
import {
  dateFormatter,
  getNPEBreadcrumbs,
  npeBrandingName,
  Role,
} from "./../../../utils";
import AlertModal from "../../Modal/AlertModal";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import MPDExportModal from "../../Modal/MPDExportModal";
import _ from "lodash";
import "./MPD.css";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import { getClientSidebarItems } from "../../SideBar/SidebarItems";

class MPDs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isExportOpen: false,
      isDeleteOpen: false,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      exportModalLoaded: false,
      includeNdcs: false,
      cModalContent: [],
      isAuthorized: _.some(this.props.roles, {
        name: Role.CLINICIAN.name,
      }),
      isDeleteAuthorized: _.some(this.props.roles, (role) => {
        return (
          role.name === Role.CLINICAL_APPROVER.name ||
          role.name === Role.CLIENT_ADMIN.name ||
          role.name === Role.CLINICIAN.name
        );
      }),
    };
  }

  componentDidUpdate() {
    if (this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadcrumbs();
        this.setupSidebar();
      }
    }
    if (!_.isEmpty(this.props.mpdSummaries) && !this.state.isExportOpen) {
      if (!this.state.exportModalLoaded) {
        this.setState({ exportModalLoaded: true });
        this.toggleExportModal();
      }
    }
  }

  componentDidMount() {
    this.props.getClient(this.state.clientId);
    this.props.getMPDsPageable(this.state.clientId, "coreplus");
    this.props.clearMpdSummaries();
  }

  componentWillUnmount() {
    this.props.clearSidebarActions();
    this.props.clearMpdSummaries();
    this.props.clearMpds();
    this.props.unloadMPDs();
    this.props.clearClients();
  }

  setupBreadcrumbs() {
    const crumbParts = getNPEBreadcrumbs(this.props.selectedClient).slice(
      0,
      -1
    );
    const breadcrumbs = {
      crumbs: crumbParts,
      finalCrumb: `${npeBrandingName}s`,
    };
    this.props.populateBreadCrumbs(breadcrumbs);
  }

  setupSidebar() {
    this.props.populateSidebar(
      getClientSidebarItems(this.props.selectedClient, this.state.isAuthorized)
    );
  }

  deleteMpd = (id, name) => {
    this.toggleDeleteModal(id, name);
  };

  toggleDeleteModal = (mpdId, mpdName) => {
    this.setState({
      isDeleteOpen: !this.state.isDeleteOpen,
      dModalHeader: `Delete ${npeBrandingName}`,
      dModalContent: "Are you sure you want to delete " + mpdName + "?",
      dModalAction: this.props.tryDeleteMPD,
      selectedMPDId: mpdId,
    });
  };

  showLongRunningAlert = (forInternal, isArtas) => {
    const modalTitle = isArtas
      ? "Export AR-TAS Formatted Data File Submitted"
      : "Export VBR Formatted Data File Submitted";

    if (!forInternal) {
      this.props.tryExportMPDReport(
        this.state.cModalTargetId,
        this.state.includeNdcs
      );
      this.props.clearMpdSummaries();
    }

    this.toggleModal(
      modalTitle,
      "This can take a couple minutes to complete.  Look for the file in your browser's download folder."
    );
  };

  toggleModal = (title, content) => {
    this.setState({
      isOpen: !this.state.isOpen,
      modalTitle: title,
      modalContent: content,
    });
  };

  toggleExportModal = () => {
    const modalStats = this.getMPDStats(this.props.mpdSummaries);

    const statsOutput = [
      modalStats.categoriesExcludedCount +
        " Categories were excluded from the report",
      modalStats.drugPairsExcludedCount +
        " Drug pairs were excluded from the report",
      modalStats.drugPairsErrorCount +
        " Drug pairs had errors and were excluded from the report",
    ];

    if (!this.state.isExportOpen) {
      this.setState({
        isExportOpen: !this.state.isExportOpen,
        cModalContent: statsOutput,
      });
    } else {
      // If the modal is closing, reset the include ndc checkbox
      this.setState({
        isExportOpen: !this.state.isExportOpen,
        cModalContent: [],
        includeNdcs: false,
      });
      this.props.clearMpdSummaries();
    }
  };

  fillMPDData = (mpd) => {
    //clear out previous mpdSummaries so it doesn't give us incorrect counts
    this.props.clearMpdSummaries();

    this.props.getMpdSummaries(mpd.id);
    this.setState({
      cModalTargetId: mpd.id,
      cModalAction: this.showLongRunningAlert,
      exportModalLoaded: false,
      selectedMPD: mpd,
    });
  };

  getMPDStats = (mpdSummary) => {
    const stats = {};
    stats.categoriesExcludedCount = mpdSummary
      .filter((ms) => ms.categoryExcludedCode !== null)
      .reduce((acc, curr) => acc + 1, 0);

    stats.drugPairsExcludedCount = mpdSummary
      .filter((ms) => ms.gpiExcludedCount > 0)
      .reduce((acc, curr) => acc + curr.gpiExcludedCount, 0);

    stats.drugPairsErrorCount = mpdSummary
      .filter((ms) => ms.tgtErrSummaryCount > 0)
      .reduce((acc, curr) => acc + curr.tgtErrSummaryCount, 0);

    return stats;
  };

  isMPDImplemented = (mpd) => {
    return mpd.implementationDate != null;
  };

  actionFormatter = (content, row) => {
    const editTarget =
      "/clients/" + this.state.clientId + "/mpds/" + row.id + "/edit";

    const calculateTarget =
      "/clients/" + row.clientId + "/mpds/" + row.id + "/tester";

    const shouldDisable = this.isMPDImplemented(row);

    if (row.lockedFlag === "Y") {
      return (
        <div className="lock-btn">
          <FaLock />
        </div>
      );
    } else if (row.lockedFlag === "F") {
      return (
        <div className="lock-btn">
          {this.state.isDeleteAuthorized && (
            <Button
              className="linkBtn tableBtn actionIconBtnDanger"
              color="link"
              onClick={() => this.deleteMpd(row.id, row.name)}
            >
              <FaTrashAlt />
            </Button>
          )}
          <Button
            className="linkBtn tableBtn actionIconBtnDanger"
            color="link"
            onClick={() => this.toggleModal("Calculation Failure", row.message)}
          >
            <FaBomb />
          </Button>
        </div>
      );
    }
    return (
      <div>
        {this.state.isDeleteAuthorized && (
          <Button
            className="linkBtn tableBtn actionIconBtnDanger"
            color="link"
            disabled={shouldDisable}
            onClick={() => this.deleteMpd(row.id, row.name)}
          >
            <FaTrashAlt />
          </Button>
        )}
        {this.state.isAuthorized && (
          <Button
            className="linkBtn tableBtn actionIconLinkPrimary"
            color="link"
            tag={Link}
            to={editTarget}
          >
            <FaPencilAlt />
          </Button>
        )}
        <Button
          className="linkBtn tableBtn actionIconLinkPrimary"
          color="link"
          onClick={() => this.fillMPDData(row)}
        >
          <FaDownload />
        </Button>

        <Button
          className="linkBtn tableBtn actionIconLinkPrimary"
          color="link"
          tag={Link}
          to={calculateTarget}
        >
          <FaCalculator />
        </Button>
      </div>
    );
  };

  catFormatter = (content, row) => {
    return (
      row.catalogDesc + "(" + dateFormatter(row.catalogPublishedDate) + ")"
    );
  };

  linkFormatter = (content, row) => {
    const target = "/clients/" + row.clientId + "/mpds/" + row.id;

    return (
      <Button color="link" tag={Link} to={target}>
        {content}
      </Button>
    );
  };

  rebateFormatter = (content, row) => {
    if (row.hasRebates && !row.useRebates) {
      return "Rebate Values are Available";
    } else if (row.hasRebates && row.useRebates) {
      return "HCA/LCA is Net of Rebates";
    }
    return "";
  };

  COLUMNS = [
    {
      dataField: "actions",
      sort: false,
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "name",
      sort: true,
      text: npeBrandingName,
      formatter: this.linkFormatter,
    },
    {
      dataField: "benefitName",
      sort: true,
      text: "Benefit Name",
    },
    {
      dataField: "catalogDate",
      sort: true,
      text: "Catalog Effective Date",
      formatter: dateFormatter,
    },
    {
      dataField: "hasRebates",
      sort: true,
      text: "Rebates",
      formatter: this.rebateFormatter,
    },
    {
      dataField: "implementationDate",
      sort: true,
      text: "Implemented Dt",
      formatter: dateFormatter,
    },
    {
      dataField: "implementationEndDate",
      sort: true,
      text: "Implementation End Dt",
      formatter: dateFormatter,
    },
    {
      dataField: "lastEditDate",
      sort: true,
      text: "Last Edited Dt",
      formatter: dateFormatter,
    },
    {
      dataField: "generateDate",
      sort: true,
      text: "Last Exported Dt",
      formatter: dateFormatter,
    },
  ];

  isLoaded = () => {
    if (!this.props.selectedClient || !this.props.mpdsLoaded) return false;
    return true;
  };

  onInternalSubmit = (formValues) => {
    this.showLongRunningAlert(true, false);
    this.toggleExportModal();
    const reportSelections = {
      mpdId: this.state.selectedMPD.id,
      ...formValues,
    };

    this.props.tryExportInternalMPDReport(reportSelections);
    this.props.clearMpdSummaries();
  };

  artasDownload = (formValues) => {
    this.showLongRunningAlert(true, true);
    this.toggleExportModal();
    this.props.tryExportInternalMPDReport({
      mpdId: this.state.selectedMPD.id,
      isArtas: true,
      includeNDCs: Boolean(formValues.includeRefNDCs),
    });
    this.props.clearMpdSummaries();
  };

  render() {
    if (!this.isLoaded()) {
      return "Loading...";
    }

    return (
      <Container className="main-container" fluid>
        <Row className="mt-2">
          <Col>
            {this.state.isAuthorized &&
              this.props.selectedClient.slug !== "QCCLIENT" && (
                <Button
                  className="mb-3"
                  color="primary"
                  tag={Link}
                  to={`/clients/${this.state.clientId}/add-mpd`}
                >
                  Request {npeBrandingName}
                </Button>
              )}
            <RPSDataGrid
              keyField="id"
              data={this.props.mpds}
              columns={this.COLUMNS}
            />
          </Col>
        </Row>
        <AlertModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalTitle}
          content={this.state.modalContent}
        />
        <MPDExportModal
          isOpen={this.state.isExportOpen}
          toggle={this.toggleExportModal}
          mpd={this.state.selectedMPD}
          content={this.state.cModalContent}
          action={this.state.cModalAction}
          checkBoxLabel={"Include Reference NDC"}
          checkBoxEnabled={true}
          checkBoxAction={(value) => {
            this.setState({ includeNdcs: value });
          }}
          checkBoxDefault={this.state.includeNdcs}
          className="mpdModal"
          onInternalSubmit={this.onInternalSubmit}
          artasAction={this.artasDownload}
        />
        <ConfirmationModal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedMPDId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.auth.currentUser.roles,
    selectedClient: state.clients[ownProps.match.params.clientId],
    mpds: Object.values(state.mpds),
    mpdSummaries: state.mpdSummaries,
    mpdsLoaded: state.mpdsLoaded,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getMPDsPageable,
  tryDeleteMPD,
  clearSidebarActions,
  populateBreadCrumbs,
  populateSidebar,
  tryExportMPDReport,
  getMpdSummaries,
  clearMpdSummaries,
  tryExportInternalMPDReport,
  unloadMPDs,
  clearMpds,
})(MPDs);
