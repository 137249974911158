import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import {
  getRollinsReportNotifications,
  clearRollinsReportNotication,
  populateBreadCrumbs,
  toggleSidebarVisibility
} from "../../../actions";
import { localDateTimeFormatter } from "../../../utils";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";

class RollinsReportNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      navLoaded: false,
      reportId: this.props.match.params.reportId
    };
  }

  setupBreadCrumbs = () => {
    const finalCrumbText = _.first(
      _.map(this.props.rollinsReportNotifications.content, "fileName")
    );
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/reporting", name: "Reporting", active: false },
        {
          to: "/reporting/roll-ins-uploads/",
          name: "Upload Weekly Clinical Catalog Roll ins",
          active: false
        }
      ],
      finalCrumb: finalCrumbText
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.getPage();
  }

  componentDidUpdate() {
    if (!_.isEmpty(this.props.rollinsReportNotifications)) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearRollinsReportNotication();
  }

  getPage(page, sizePerPage, sorts) {
    return this.props.getRollinsReportNotifications(
      this.state.reportId,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  }

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  COLUMNS = [
    {
      dataField: "clientName",
      text: "Client Name",
      sort: true
    },
    {
      dataField: "emailedDate",
      text: "Emailed Date",
      formatter: localDateTimeFormatter
    },
    {
      dataField: "recipientsString",
      text: "Recipients"
    }
  ];

  render() {
    if (_.isEmpty(this.props.rollinsReportNotifications)) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>
              Client Facing Clinical Catalog Roll-ins Report Delivery History
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RPSDataGrid
              keyField="clientName"
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.rollinsReportNotifications.totalElements}
              data={this.props.rollinsReportNotifications.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
              remote
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    rollinsReportNotifications: state.rollinsReportNotifications
  };
};

export default connect(
  mapStateToProps,
  {
    clearRollinsReportNotication,
    getRollinsReportNotifications,
    populateBreadCrumbs,
    toggleSidebarVisibility
  }
)(RollinsReportNotifications);
