import React, { Component } from "react";
import { connect } from "react-redux";
import { SIDEBAR_ITEMS } from "../Constants";
import {
  populateBreadCrumbs,
  populateSidebar,
  getUploadedRollinsReport,
  uploadRollinsReport,
  deleteRollinsReport,
  getWeekOfYearList,
  clearWeekOfYearList,
} from "../../../actions";
import _ from "lodash";
import { Container, Row, Col } from "reactstrap";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import {
  DATE_FORMAT,
  localDateTimeFormatter,
  required,
  validSelect,
  renderSelectInput,
  userNameFormatter,
  FileInput,
  Role,
} from "../../../utils";
import { FaUpload, FaTrashAlt, FaDownload } from "react-icons/fa";
import { Button } from "reactstrap";
import FormModal from "../../Modal/FormModal";
import { FormGroup } from "reactstrap";
import { Field } from "redux-form";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import "./UploadedRollinsReports.css";
import { Link } from "react-router-dom";
import moment from "moment";

class UploadedRollinsReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      sortField: null,
      isAddModalOpen: false,
      isDeleteOpen: false,
      isAuthorized: _.some(this.props.roles, (role) => {
        return (
          role.name === Role.CLINICAL_APPROVER.name ||
          role.name === Role.CLIENT_ADMIN.name
        );
      }),
    };
  }

  componentDidMount = () => {
    this.setupBreadCrumbs();
    this.setupSidebar();
    this.getPage();
    this.props.getWeekOfYearList(moment().format(DATE_FORMAT), 5);
  };

  componentWillUnmount() {
    this.props.clearWeekOfYearList();
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/reporting",
          name: "Reporting",
          active: false,
        },
      ],
      finalCrumb: "Upload Weekly Clinical Catalog Roll ins Report",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  setupSidebar = () => {
    this.props.populateSidebar(SIDEBAR_ITEMS);
  };

  getFormattedWeekNumberArray = (prependedText) => {
    return _.map(this.props.weekOfYearList, (week) => {
      return {
        id: prependedText + week.week + " " + week.year,
        description: prependedText + week.week + " " + week.year,
      };
    });
  };

  getAvailableWeekNumbers = () => {
    let startingList = this.getFormattedWeekNumberArray("Week");
    const lockedReportingWeeks = _.map(
      _.filter(this.props.uploadedRollinsReports.content, {
        locked: "Y",
      }),
      (report) => {
        return { id: report.reportingWeek };
      }
    );
    return _.pullAllBy(startingList, lockedReportingWeeks, "id");
  };

  toggleModal = () => {
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen });
  };

  onAddSubmit = (formValues) => {
    const formData = new FormData();

    formData.append("file", formValues.file);
    formData.append("reportingWeek", formValues.reportingWeek);
    formData.append("fileName", formValues.file.name);
    formData.append("fileType", formValues.file.type);

    this.props.uploadRollinsReport(formData);
    this.getPage();
  };

  deleteRollinsReport = (row) => {
    this.props.deleteRollinsReport(row.reportName);
    this.getPage();
  };

  toggleDeleteModal = (target) => {
    if (target && target.hasOwnProperty("id")) {
      const name = target.reportName;
      this.setState({
        isDeleteOpen: !this.state.isDeleteOpen,
        dModalHeader: `Delete ${name}`,
        dModalContent: `Are you sure you want to delete ${name}?`,
        dModalAction: this.props.deleteRollinsReport,
        selectedTargetId: target.id,
      });
    } else {
      this.setState({ isDeleteOpen: !this.state.isDeleteOpen });
    }
  };

  iconHeaderFormatter = (column, colIndex) => {
    return (
      <div>
        Action
        {this.state.isAuthorized && (
          <Button
            className="tableBtn actionIconLinkPrimary"
            color="link"
            onClick={this.toggleModal}
          >
            <FaUpload />
          </Button>
        )}
      </div>
    );
  };

  linkFormatter = (content, row) => {
    const target = "/reporting/roll-ins-uploads/" + row.id;

    let markup = <span>{content}</span>;

    if (row.locked === "Y") {
      markup = (
        <Button color="link" tag={Link} to={target}>
          {content}
        </Button>
      );
    }

    return markup;
  };

  actionFormatter = (content, row) => {
    return (
      <div>
        {this.state.isAuthorized && row.locked !== "Y" && (
          <Button
            className="linkBtn tableBtn actionIconBtnDanger"
            color="link"
            onClick={() => this.toggleDeleteModal(row)}
            size="sm"
          >
            <FaTrashAlt />
          </Button>
        )}
        <Button
          className="linkBtn tableBtn actionIconLinkPrimary"
          color="link"
          tag={Link}
          to={{ pathname: row.downloadUrl }}
          target="_blank"
          size="sm"
        >
          <FaDownload />
        </Button>
      </div>
    );
  };

  COLUMNS = [
    {
      dataField: "actions",
      text: "",
      formatter: this.actionFormatter,
      headerFormatter: this.iconHeaderFormatter,
      isDummyField: true,
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "reportName",
      sort: true,
      text: "Report Name",
      formatter: this.linkFormatter,
    },
    {
      dataField: "reportingWeek",
      sort: true,
      text: "Roll-in Reporting Week Year",
    },
    {
      dataField: "uploadedDate",
      sort: true,
      text: "Date Uploaded",
      formatter: localDateTimeFormatter,
    },
    {
      dataField: "uploader",
      sort: true,
      text: "User",
      formatter: userNameFormatter,
    },
  ];

  getPage = () => {
    this.props.getUploadedRollinsReport(
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.setState({ currPage: page, sizePerPage }, () => {
      this.getPage();
    });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  render() {
    if (
      _.isEmpty(this.props.uploadedRollinsReports) ||
      _.isEmpty(this.props.weekOfYearList)
    ) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>
              Upload Weekly Client Facing Clinical Catalog Roll-ins Report
            </h3>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="reportName"
              remote
              paginated
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.uploadedRollinsReports.totalElements}
              data={this.props.uploadedRollinsReports.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <FormModal
          isOpen={this.state.isAddModalOpen}
          toggle={this.toggleModal}
          header="Attach Client Facing Clinical Catalog Roll-ins Report"
          onSubmit={this.onAddSubmit}
          size="lg"
          submitButtonText="Attach File"
          touchOnChange={true}
        >
          <FormGroup row>
            <Field
              id="file"
              name="file"
              label="Select File to Upload: "
              required
              validate={[required]}
              component={FileInput}
              acceptedFileType="application/pdf"
              className="inputfile"
            />
          </FormGroup>

          <Container>
            <FormGroup row>
              <Field
                id="reportingWeek"
                name="reportingWeek"
                type="select"
                label="Roll-ins Reporting Week:"
                required
                items={this.getAvailableWeekNumbers()}
                valueProp="id"
                keyProp="id"
                displayProp="description"
                validate={[required, validSelect]}
                component={renderSelectInput}
              />
            </FormGroup>
          </Container>
        </FormModal>
        <ConfirmationModal
          isOpen={this.state.isDeleteOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.dModalHeader}
          content={this.state.dModalContent}
          action={this.state.dModalAction}
          objId={this.state.selectedTargetId}
          size="lg"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    uploadedRollinsReports: state.uploadedRollinsReports,
    weekOfYearList: state.weekOfYearList,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  populateSidebar,
  getUploadedRollinsReport,
  uploadRollinsReport,
  deleteRollinsReport,
  getWeekOfYearList,
  clearWeekOfYearList,
})(UploadedRollinsReports);
