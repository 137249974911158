import { ActionType, SidebarIcon } from "../SideBar/SideBar";

const REPORT_TYPES = {
  RP_PERFORMANCE: "RP_PERFORMANCE",
  MEMBERSHIP: "MEMBERSHIP",
  PROSPECTIVE: "PROSPECTIVE",
};

const SIDEBAR_ITEMS = [
  {
    type: ActionType.LINK_LIST,
    bundle: {
      header: "Clinical Reports",
      key: "clinical-reports",
      children: [
        {
          path: "/reporting/roll-ins",
          name: "Medispan Roll-ins",
          icon: SidebarIcon.ROLL_INS,
        },
        {
          path: "/reporting/category-fill-factors",
          name: "Drug Category Fill Factors",
          icon: SidebarIcon.DRUG_CAT_FILL_FACTOR,
        },
        {
          path: "/reporting/gpi12-fill-factors",
          name: "GPI Fill Factors",
          icon: SidebarIcon.GPI_FILL_FACTOR,
        },
      ],
    },
  },
  {
    type: ActionType.LINK_LIST,
    bundle: {
      header: "Client Reports",
      key: "client-reports",
      children: [
        {
          path: "/reporting/performance",
          name: "Performance Reporting",
          icon: SidebarIcon.PERFORMANCE,
        },
        {
          path: "/reporting/prospective",
          name: "Prospective Savings/Impact Report",
          icon: SidebarIcon.SAVINGS,
        },
        {
          path: "/reporting/membership",
          name: "Billing Membership",
          icon: SidebarIcon.BILLING_MEMBERSHIP,
        },
        {
          path: "/reporting/utilization",
          name: "Utilization Spend & Inflation",
          icon: SidebarIcon.UTILIZATION,
        },
      ],
    },
  },
  {
    type: ActionType.LINK_LIST,
    bundle: {
      header: "Operations",
      key: "operations-reports",
      children: [
        {
          path: "/reporting/roll-ins-uploads",
          name: "Client Roll-ins Reports",
          icon: SidebarIcon.CLIENT_ROLL_IN,
        },
        {
          path: "/reporting/internal-clinical-catalog",
          name: "Internal Clinical Catalog CSV",
          icon: SidebarIcon.INTERNAL_CLINICAL_CATALOG,
        },
        {
          path: "/reporting/glossary-terms",
          name: "Reporting Glossary of Terms",
          icon: SidebarIcon.GLOSSARY,
        },
      ],
    },
  },
];

export { REPORT_TYPES, SIDEBAR_ITEMS };
