import React from "react";
import PropTypes from "prop-types";

const ExpandRowDetail = props => {
  return (
    <>
      <span className="expand-row-label">{`${props.label}:`}</span>
      &nbsp;
      <span className="expand-row-text">{props.detail}</span>
    </>
  );
};

ExpandRowDetail.defaultProps = {
  detail: ""
};

ExpandRowDetail.propTypes = {
  label: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired
};

export const getDetailString = val => {
  return val !== null ? val.toString() : "";
};

export default ExpandRowDetail;
