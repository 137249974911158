import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  userNameFormatter,
  isConfigFileLocked,
  localDateTimeFormatter,
  required,
  Role,
  FileInput,
  validFilename,
} from "./../../../utils";
import {
  clearClients,
  getClient,
  getBenefits,
  clearBenefits,
  getConfigFilesByClient,
  tryDeleteConfigFile,
  uploadConfigFile,
  toggleSidebarVisibility,
  populateBreadCrumbs,
  generateFormularyContent,
  clearConfigFiles,
} from "./../../../actions";
import { ConfigFile } from "./fileTypes";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { FaTrashAlt } from "react-icons/fa";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import FormModal from "../../Modal/FormModal";
import { FormGroup } from "reactstrap";
import { Field } from "redux-form";
import ActionButton from "../../DataGrid/ActionButton";
import AlertModal from "../../Modal/AlertModal";

class ConfigDash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      navLoaded: false,
      clientId: this.props.match.params.clientId,
      isAddModalOpen: false,
      configFileType: {},
      countOfFormularyFiles: 0,
      isAuthorized: _.some(this.props.roles, {
        name: Role.CLIENT_ADMIN.name,
      }),
      isAlertModalOpen: false,
    };
  }

  componentDidMount = () => {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getConfigFilesByClient(this.state.clientId);
    this.props.getBenefits(this.state.clientId);
  };

  componentDidUpdate = () => {
    if (
      this.props.selectedClient &&
      this.props.configFiles &&
      this.props.benefitsLoaded
    ) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  };

  componentWillUnmount = () => {
    this.props.toggleSidebarVisibility();
    this.props.clearBenefits();
    this.props.clearClients();
    this.props.clearConfigFiles();
  };

  deleteFile(fileId, fileName) {
    this.setState({ countOfFormularyFiles: 0 });
    this.toggleDeleteModal(fileId, fileName);
  }

  generateFormularyFile = (clientId) => {
    this.setState({ countOfFormularyFiles: _.size(this.props.configFiles) });
    this.props.generateFormularyContent(clientId);
  };

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false,
        },
      ],
      finalCrumb: "Configuration Files",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  toggleFileInputModal = (configFiletype) => {
    let fullNameConfigFileName = "";
    switch (configFiletype) {
      case ConfigFile.AWPD:
        fullNameConfigFileName = "Average Wholesale Price Discount";
        break;
      case ConfigFile.MAC:
        fullNameConfigFileName = "Maximum Allowable Cost";
        break;
      case ConfigFile.FORMULARY:
        fullNameConfigFileName = "Formulary ";
        break;
      case ConfigFile.BC:
        fullNameConfigFileName = "Beneficiary Cost";
        break;
      case ConfigFile.GP:
        fullNameConfigFileName = "Group Plan";
        break;
      default:
        fullNameConfigFileName = "";
    }

    this.setState({
      configFileType: {
        type: configFiletype,
        fullName: fullNameConfigFileName,
      },
    });
    this.setState({ isAddModalOpen: !this.state.isAddModalOpen });
  };

  toggleDeleteModal = (fileId, fileName) => {
    const header = "Delete " + fileName;
    const content = "Are you sure you want to delete " + fileName + "?";

    this.setState({
      isOpen: !this.state.isOpen,
      modalHeader: header,
      modalContent: content,
      modalAction: this.props.tryDeleteConfigFile,
      fileId: fileId,
    });
  };

  iconHeaderFormatter = (column, colIndex) => {
    return (
      <div>
        Action
        {this.state.isAuthorized && (
          <ActionButton
            onClick={() => this.toggleFileInputModal(column.text)}
            actionType="upload"
            actionColor="blue"
            tooltip={`Upload ${this.props.selectedClient.name} ${column.text} file`}
            centered
            size="large"
          />
        )}
        <>
          {this.state.isAuthorized && column.text === "FF" && (
            <ActionButton
              onClick={() =>
                this.generateFormularyFile(this.props.selectedClient.id)
              }
              actionType="create"
              actionColor="blue"
              tooltip={`Create Open Formulary for ${this.props.selectedClient.name}`}
              centered
              size="large"
            />
          )}
        </>
      </div>
    );
  };

  actionFormatter = (content, row) => {
    const shouldDisable = isConfigFileLocked(this.props.benefits, row.id);
    return (
      <div>
        {this.state.isAuthorized && (
          <Button
            className="linkBtn tableBtn actionIconBtnDanger"
            disabled={shouldDisable}
            color="link"
            onClick={() => this.deleteFile(row.id, row.fileName)}
          >
            <FaTrashAlt />
          </Button>
        )}
      </div>
    );
  };

  linkFormatter = (content, row) => {
    let target = "/client-config/";
    switch (row.type.toUpperCase()) {
      case ConfigFile.AWPD:
        target += "awpd/";
        break;
      case ConfigFile.MAC:
        target += "mac/";
        break;
      case ConfigFile.FORMULARY:
        target += "formulary/";
        break;
      case ConfigFile.BC:
        target += "beneficiary/";
        break;
      case ConfigFile.GP:
        target += "gp/";
        break;
      default:
        target = "#";
    }

    let markup = <span>{content}</span>;
    if (target !== "#") {
      target += row.clientId + "/" + row.id;
      markup = (
        <Button color="link" tag={Link} to={target}>
          {content}
        </Button>
      );
    }

    return markup;
  };

  rowsImportedCountFormatter = (content, row) => {
    return row.status === "LOADING" ? "LOADING" : row.totalRecordCount;
  };

  activeCountFormatter = (content, row) => {
    return row.countsByStatus.ACTIVE;
  };

  inactiveCountFormatter = (content, row) => {
    return row.countsByStatus.INACTIVE;
  };

  hasRebatesFormatter = (content, row) => {
    const rebated = row.countsByStatus.rebatePERCENT;
    return rebated !== undefined && rebated > 0 ? "Y" : "";
  };

  toggleAlertModal = (title, content) => {
    this.setState({
      isAlertModalOpen: !this.state.isAlertModalOpen,
      modalTitle: title,
      modalContent: content,
    });
  };

  COLUMNS = [
    {
      dataField: "fileName",
      sort: true,
      text: "File Name",
      formatter: this.linkFormatter,
    },
    {
      dataField: "totalRecordCount",
      text: "Rows Imported",
      formatter: this.rowsImportedCountFormatter,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "activeCount",
      text: "Active Count",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: this.activeCountFormatter,
    },
    {
      dataField: "inactiveCount",
      text: "Inactive Count",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: this.inactiveCountFormatter,
    },
  ];

  FF_COLUMNS = [
    ...this.COLUMNS,
    {
      dataField: "hasRebates",
      text: "Contains Rebate Data",
      align: "center",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: this.hasRebatesFormatter,
    },
  ];

  UPDATE_COLUMNS = [
    {
      dataField: "importedByUser",
      text: "Uploaded by User",
      formatter: userNameFormatter,
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField: "updatedDate",
      text: "Uploaded Date",
      headerStyle: () => {
        return { width: "10%" };
      },
      formatter: localDateTimeFormatter,
    },
  ];

  renderFileTable(type) {
    const files = _.filter(this.props.configFiles, {
      type: type,
      clientId: this.props.selectedClient.id,
    });

    const ACTION_COLUMN = [
      {
        dataField: "actions",
        text: type,
        formatter: this.actionFormatter,
        headerFormatter: this.iconHeaderFormatter,
        headerStyle: {
          width: "8%",
        },
      },
    ];

    return (
      <RPSDataGrid
        keyField="id"
        id={type}
        data={files}
        columns={
          type === "FF"
            ? [...ACTION_COLUMN, ...this.FF_COLUMNS, ...this.UPDATE_COLUMNS]
            : [...ACTION_COLUMN, ...this.COLUMNS, ...this.UPDATE_COLUMNS]
        }
      />
    );
  }

  onAddSubmit = (formValues) => {
    const formData = new FormData();

    const fileNameSlug = formValues.file.name.split("_")[0];
    const sameFilenameCount = _.filter(this.props.configFiles, {
      fileName: formValues.file.name,
    });

    if (fileNameSlug !== this.props.selectedClient.slug) {
      this.toggleAlertModal(
        `ERROR: Invalid file name format`,
        `File name must start with ${this.props.selectedClient.slug}_${this.state.configFileType.type}_ .  Valid characters are A-Z a-z 0-9 . - _`
      );
    } else if (!_.isEmpty(sameFilenameCount)) {
      this.toggleAlertModal(
        `ERROR: Duplicate filename for configuration type`,
        `File name must be a unique name for the configuration file type.`
      );
    } else {
      formData.append("file", formValues.file);
      formData.append("fileName", formValues.file.name);
      formData.append("fileType", formValues.file.type);
      formData.append("clientId", this.state.clientId);

      this.props.uploadConfigFile(formData);
    }
  };

  render() {
    if (
      !this.props.selectedClient ||
      !this.props.configFiles ||
      !this.props.benefitsLoaded
    ) {
      return <div>Loading...</div>;
    }
    const selectedClient = this.props.selectedClient;

    return (
      <Container className="main-container" fluid>
        <Row className="mb-4">
          <Col>
            <h3>{selectedClient.name} Configuration Files</h3>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h5>Maximum Allowable Cost Files</h5>
            {this.renderFileTable(ConfigFile.MAC)}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h5>Formulary Files</h5>
            {this.renderFileTable(ConfigFile.FORMULARY)}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <h5>Average Wholesale Price Discount Files</h5>
            {this.renderFileTable(ConfigFile.AWPD)}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Beneficiary Cost Files</h5>
            {this.renderFileTable(ConfigFile.BC)}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Group Plan Files</h5>
            {this.renderFileTable(ConfigFile.GP)}
          </Col>
        </Row>
        <FormModal
          isOpen={this.state.isAddModalOpen}
          toggle={this.toggleFileInputModal}
          header={`Upload a client's ${this.state.configFileType.fullName} file to the client's configuration`}
          onSubmit={this.onAddSubmit}
          size="lg"
          submitButtonText="Attach File"
          touchOnChange={true}
        >
          <FormGroup row>
            <Field
              id="file"
              name="file"
              label="Select a CSV file to upload.  Large files can take a while to fully load.  Files will load in the background so you can continue to work in RPS."
              required
              validate={[required, validFilename]}
              component={FileInput}
              acceptedFileType="text/csv"
              className="inputfile"
            />
          </FormGroup>
        </FormModal>

        <ConfirmationModal
          isOpen={this.state.isOpen}
          toggle={this.toggleDeleteModal}
          header={this.state.modalHeader}
          content={this.state.modalContent}
          action={this.state.modalAction}
          objId={this.state.fileId}
        />
        <AlertModal
          isOpen={this.state.isAlertModalOpen}
          toggle={this.toggleAlertModal}
          header={this.state.modalTitle}
          content={this.state.modalContent}
          headerClassname="form-error"
        />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    roles: state.auth.currentUser.roles,
    selectedClient: state.clients[ownProps.match.params.clientId],
    configFiles: state.clientConfigFiles,
    benefits: Object.values(state.benefits),
    benefitsLoaded: state.benefitsLoaded,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClient,
  getBenefits,
  clearBenefits,
  getConfigFilesByClient,
  tryDeleteConfigFile,
  uploadConfigFile,
  populateBreadCrumbs,
  toggleSidebarVisibility,
  generateFormularyContent,
  clearConfigFiles,
})(ConfigDash);
