import React from "react";

const DetailBlock = props => {
  const stack = props.stack;
  return (
    <div>
      <h3>{stack.stack}</h3>
      <ol className="list-unstyled fontSizeLarge ms-3">
        <li>
          <span className="halfBold">Version: </span>
          {stack.version}
        </li>
        <li>
          <span className="halfBold">Branch: </span>
          {stack.branch}
        </li>
        <li>
          <span className="halfBold">Last Commit Message: </span>
          {stack.lastCommitMsg}
        </li>
        <li>
          <span className="halfBold">Last Commit Author: </span>
          {stack.lastCommitAuth}
        </li>
      </ol>
      <hr />
    </div>
  );
};

export default DetailBlock;
