import React from "react";
import { Label, Input, InputGroup, InputGroupText } from "reactstrap";
import DatePicker from "react-datepicker";
import { DATE_PICKER_FORMAT, DATE_CONVERT_FORMAT } from "./dates";

export const renderInlineInput = ({
  input,
  id,
  placeholder,
  required,
  type,
  disabled,
  meta: { touched, error },
}) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      <Label for={id}>
        <div className="d-flex">
          {placeholder} {required && <span className="required-field">*</span>}
        </div>
      </Label>
      <div className="d-flex gap-2 align-items-center">
        <Input
          type={type}
          id={id}
          required={required}
          disabled={disabled}
          {...input}
        />
        {touched && error && <span className="form-error">{error}</span>}
      </div>
    </div>
  );
};

export const renderInput = ({
  input,
  id,
  placeholder,
  required,
  type,
  disabled,
  tooltip,
  meta: { touched, error },
}) => {
  return (
    <div className="field">
      <Label for={id} title={tooltip}>
        {placeholder} {required && <span className="required-field">*</span>}
      </Label>
      <Input
        type={type}
        id={id}
        required={required}
        disabled={disabled}
        {...input}
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};

export const renderInputGroup = ({
  input,
  id,
  hint,
  placeholder,
  symbolText,
  required,
  type,
  disabled,
  tooltip,
  meta: { touched, error },
}) => {
  return (
    <div className="field">
      <Label for={id} title={tooltip}>
        {placeholder} {required && <span className="required-field">*</span>}
      </Label>
      <InputGroup>
        <InputGroupText>{symbolText}</InputGroupText>
        <Input
          type={type}
          id={id}
          required={required}
          disabled={disabled}
          placeholder={hint}
          {...input}
        />
      </InputGroup>

      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};

export const renderCheckbox = ({
  input,
  id,
  defaultChecked,
  placeholder,
  disabled,
  tooltip,
  meta: { touched, error },
}) => {
  return (
    <div className="field">
      <Input
        type="checkbox"
        id={id}
        name={id}
        disabled={disabled}
        defaultChecked={defaultChecked}
        {...input}
      />
      <Label for={id} title={tooltip} check>
        {placeholder}
      </Label>
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};

export const renderDatePicker = ({
  input,
  id,
  placeholder,
  required,
  minDate,
  maxDate,
  isClearable,
  shouldDisable,
  showYearDropdown,
  meta: { touched, error },
}) => {
  return (
    <div className="field">
      <Label for={id}>
        {placeholder} {required && <span className="required-field">*</span>}
      </Label>{" "}
      <br />
      <DatePicker
        {...input}
        dateFormat={DATE_PICKER_FORMAT}
        placeholderText={DATE_CONVERT_FORMAT}
        selected={Date.parse(input.value) || null}
        onChange={input.onChange}
        autoComplete="off"
        strictParsing
        minDate={minDate}
        maxDate={maxDate}
        className="form-control"
        showYearDropdown={showYearDropdown}
        disabled={shouldDisable}
        isClearable={isClearable}
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};

export const renderTextArea = ({
  input,
  label,
  inputobj,
  elementid,
  disabled,
  numRows,
  meta: { touched, error },
}) => {
  return (
    <div className="field">
      <Input
        {...input}
        rows={numRows}
        data-elementid={elementid}
        onChange={input.onChange}
        type="textarea"
        placeholder={label}
        value={inputobj.body}
        disabled={disabled}
      />
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};

export const renderSelectInput = ({
  input,
  id,
  label,
  required,
  items,
  valueProp,
  displayProp,
  keyProp,
  disabled,
  tooltip,
  meta: { touched, error },
}) => {
  return (
    <div className="field">
      <Label for={id} title={tooltip}>
        {label} {required && <span className="required-field">*</span>}
      </Label>
      <Input
        type="select"
        id={id}
        required={required}
        {...input}
        disabled={disabled}
      >
        <option value="">-Select-</option>
        {items.map((item) => {
          return (
            <option key={item[keyProp]} value={item[valueProp]}>
              {item[displayProp]}
            </option>
          );
        })}
      </Input>
      {touched && error && <span className="form-error">{error}</span>}
    </div>
  );
};
