import _ from "lodash";
import { GET_NDC_PRICES, CLEAR_NDC_PRICES } from "../actions/types";

const ndcPricesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NDC_PRICES:
      if (action.payload.length === 0) {
        const emptyPayload = [
          {
            ndc: null,
            drugName: "No NDC Prices Found",
            priceEffectiveDate: null,
          },
        ];
        return { ...state, ..._.mapKeys(emptyPayload) };
      }
      return { ...state, ..._.mapKeys(action.payload, "ndc") };
    case CLEAR_NDC_PRICES:
      return {};
    default:
      return state;
  }
};

export default ndcPricesReducer;
