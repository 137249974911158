import { GET_TARGET_GPIS, GET_FILTERED_TARGET_GPIS } from "../actions/types";

const targetGpiReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TARGET_GPIS:
      return { ...state, ...action.payload };
    case GET_FILTERED_TARGET_GPIS:
      return action.payload;
    default:
      return state;
  }
};

export default targetGpiReducer;
