import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import {
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getClient,
  clearClients,
  tryProviderTargetSearch,
  getAllAdditionalTargetDrugs,
  clearAdditionalTargetDrugs,
  tryAddAdditionalTargetDrugs,
} from "../../../../actions";
import RPSDataGrid from "../../../DataGrid/RPSDataGrid";
import cellEditFactory from "react-bootstrap-table2-editor";
import ProviderAdministeredDrugsSearchForm from "./ProviderAdministeredDrugsSearchForm";
import _ from "lodash";
import {
  dateFormatter,
  DATE_FORMAT,
  DATE_PICKER_FORMAT,
  getRenderDate,
  optionalNAValueFormatter,
} from "../../../../utils";
import DatePicker from "react-datepicker";
import moment from "moment";
import AssignedAltsList from "./AssignedAltsList";
import history from "../../../../history";
import AlertModalv2 from "../../../Modal/AlertModalv2";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import ModalTargetsList from "./ModalTargetsList";

class AddProviderAdministeredDrugs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientId: props.match.params.clientId,
      contractId: props.location.state.contractId,
      navLoaded: false,
      searchTerms: {
        names: [],
        gpis: [],
        ndcCodes: [],
        adminType: "PROVIDER",
      },
      selectedTargets: [],
      blurFix: false,
      errorMessageShown: false,
      isErrorModalOpen: false,
      isNonOverlappingDatesOpen: false,
      nonOverlappingTargets: [],
      targetsToAdd: [],
      sortField: { dataField: "drugName.commonName", order: "asc" },
    };
  }

  componentDidMount = () => {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getAllAdditionalTargetDrugs(this.state.clientId);
  };

  componentWillUnmount = () => {
    this.props.clearClients();
    this.props.toggleSidebarVisibility();
    this.props.clearAdditionalTargetDrugs();
  };

  componentDidUpdate = () => {
    if (this.props.selectedClient && !this.state.navLoaded) {
      this.setState({ navLoaded: true });
      this.setupBreadCrumbs();
    }

    if (
      this.props.targetSaveErrors.length > 0 &&
      !this.state.errorMessageShown
    ) {
      this.setState({ errorMessageShown: true }, () => {
        this.toggleErrorModal();
      });
    }
  };

  setupBreadCrumbs = () => {
    const client = this.props.selectedClient;
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        { to: `/clients/view/${client.id}`, name: client.name, active: false },
        {
          to: `/products/provider-drugs/${this.state.contractId}/${client.id}`,
          name: `${client.name}'s Provider Administered Drug List`,
          active: false,
        },
      ],
      finalCrumb: "Add Targeted GPI/MSC",
    };
    this.props.populateBreadCrumbs(breadCrumbs);
  };

  toggleErrorModal = () => {
    this.setState({ isErrorModalOpen: !this.state.isErrorModalOpen });
  };

  toggleNonOverlappingTargetsAlert = () => {
    this.setState({
      isNonOverlappingDatesOpen: !this.state.isNonOverlappingDatesOpen,
    });
  };

  getProviderTargets = () => {
    this.props.tryProviderTargetSearch(
      this.state.searchTerms,
      this.state.sortField
    );
  };

  alternativesFormatter = (alts) => {
    return <AssignedAltsList alts={alts} />;
  };

  datePickerRenderer = (
    editorProps,
    value,
    row,
    column,
    rowIndex,
    columnIndex
  ) => {
    const dataField = column.dataField;
    const targets = this.props.targets;

    const duplicateGpiMsc = _.find(targets, (target) => {
      return target.gpi === row.gpi && target.msc === row.msc;
    });

    let minDate = moment().startOf("day").toDate();
    if (duplicateGpiMsc) {
      let duplicateDate = moment(duplicateGpiMsc.effectiveDate)
        .startOf("day")
        .toDate();

      if (duplicateGpiMsc.termDate) {
        duplicateDate = moment(duplicateGpiMsc.termDate)
          .startOf("day")
          .toDate();
        minDate = moment(duplicateDate).add(1, "days").toDate();
      } else {
        if (moment(duplicateDate).isSame(minDate)) {
          minDate = moment().add(1, "days").toDate();
        } else if (moment(duplicateDate).isAfter(minDate)) {
          minDate = moment(duplicateDate).add(1, "days").toDate();
        }
      }
    }

    return (
      <DatePicker
        id="targetDate"
        name="targetDate"
        dateFormat={DATE_PICKER_FORMAT}
        placeholderText={DATE_PICKER_FORMAT}
        selected={getRenderDate(value) || null}
        onChange={(newVal) => {
          const formattedDate = moment(newVal).format(DATE_FORMAT);
          row[dataField] = formattedDate;

          if (
            dataField === "effectiveDate" &&
            moment(newVal) > moment(row.termDate)
          ) {
            row.termDate = null;
          }
          // Calling this setState forces a component re-draw, fixing the issue of the date picker not closing on date select
          this.setState({ blurFix: !this.state.blurFix });
        }}
        autoComplete="off"
        strictParsing
        className="form-control"
        showYearDropdown
        minDate={minDate}
      />
    );
  };

  COLUMNS = [
    {
      dataField: "name",
      sort: true,
      text: "Targeted Drug Name",
      headerStyle: {
        width: "20%",
      },
      editable: false,
    },
    {
      dataField: "gpi",
      sort: true,
      text: "GPI",
      headerStyle: {
        width: "6%",
      },
      editable: false,
    },
    {
      dataField: "multiSource",
      sort: false,
      text: "MSC",
      editable: false,
      headerStyle: {
        width: "4%",
      },
    },
    {
      dataField: "adminType",
      sort: false,
      text: "Administered By",
      editable: false,
      headerStyle: {
        width: "6%",
      },
      formatter: optionalNAValueFormatter,
    },
    {
      dataField: "courseDayCount",
      sort: false,
      text: "Days per Course",
      editable: false,
      headerStyle: {
        width: "7%",
      },
      formatter: optionalNAValueFormatter,
    },
    {
      dataField: "effectiveDate",
      sort: false,
      text: "Effective Date",
      editable: true,
      formatter: dateFormatter,
      editorRenderer: this.datePickerRenderer,
      headerStyle: {
        width: "7%",
      },
    },
    {
      dataField: "alternatives",
      sort: false,
      text: "Assigned Alternative(s)",
      editable: false,
      formatter: this.alternativesFormatter,
    },
  ];

  onSearchSubmit = (searchTerms) => {
    this.setState({ searchTerms }, () => {
      this.getProviderTargets();
    });
  };

  getCellEditFactory = () => {
    return cellEditFactory({ mode: "click", blurToSave: false });
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      if (sortField === "name") {
        sortField = "drugName.commonName";
      }

      sortField = {
        dataField: sortField,
        order: sortOrder,
      };

      this.setState({ sortField }, () => {
        this.getProviderTargets();
      });
      return -1;
    }

    //this.getProviderTargets();
  };

  getSelectRow = () => {
    return {
      mode: "checkbox",
      clickToSelect: false,
      headerColumnStyle: { width: "2%" },
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll,
    };
  };

  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        selectedTargets: [...this.state.selectedTargets, row],
      });
    } else {
      this.setState({
        selectedTargets: _.filter(this.state.selectedTargets, (target) => {
          return target.key !== row.key;
        }),
      });
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    if (isSelect) {
      this.setState({ selectedTargets: rows });
    } else {
      this.setState({ selectedTargets: [] });
    }
  };

  handleAddTargets = () => {
    const targetsToAdd = _.map(this.state.selectedTargets, (target) => {
      target.clientId = parseInt(this.state.clientId);
      delete target.alternatives;
      return target;
    });

    const curentTargets = this.props.targets;

    const intersection = _.intersectionWith(
      targetsToAdd,
      curentTargets,

      (a, b) => {
        const hasTermDate = a.termDate;
        return a.gpi === b.gpi && a.msc === b.msc && hasTermDate;
      }
    );

    if (intersection && intersection.length > 0) {
      this.setState(
        { nonOverlappingTargets: intersection, targetsToAdd },
        () => {
          this.toggleNonOverlappingTargetsAlert();
        }
      );
    } else {
      this.props.tryAddAdditionalTargetDrugs(targetsToAdd);
    }
  };

  onConfirmContinue = () => {
    this.props.tryAddAdditionalTargetDrugs(this.state.targetsToAdd);
    this.setState({
      nonOverlappingTargets: [],
      targetsToAdd: [],
      showNonOverlappingTargetsAlert: false,
    });
  };

  getTransformedSearchResults = () => {
    const searchResults = this.props.searchResults;
    const targets = this.props.targets;

    // Get a list of the duplicate GPI/MSC combinations
    const duplicates = _.intersectionWith(
      targets,
      searchResults,
      (target, searchResult) => {
        return (
          target.gpi === searchResult.gpi && target.msc === searchResult.msc
        );
      }
    );

    _.forEach(duplicates, (duplicate) => {
      const result = _.find(searchResults, (searchResult) => {
        return (
          searchResult.gpi === duplicate.gpi &&
          searchResult.msc === duplicate.msc
        );
      });

      let minDate = moment().startOf("day").toDate();

      // handles if the duplicate has a term date
      if (duplicate.termDate) {
        const dupeTermDate = moment(duplicate.termDate);
        if (dupeTermDate.isSame(minDate) || dupeTermDate.isAfter(minDate)) {
          minDate = dupeTermDate.add(1, "days").toDate();
        }
      } else {
        const dupeEffectiveDate = moment(duplicate.effectiveDate);
        if (dupeEffectiveDate.isSame(minDate)) {
          minDate = dupeEffectiveDate.add(1, "days").toDate();
        }
      }

      result.effectiveDate = moment(minDate).format(DATE_FORMAT);
    });

    return searchResults;
  };

  render() {
    if (!this.props.selectedClient) {
      return <h1>LOADING...</h1>;
    }
    const transformedSearchResults = _.isEmpty(this.props.searchResults)
      ? []
      : this.getTransformedSearchResults();
    // const transformedSearchResults = [];
    const selectedClient = this.props.selectedClient;

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>
              Add Targeted GPI/MSC to {selectedClient.name}'s Provider
              Administered Drug List
            </h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <ProviderAdministeredDrugsSearchForm
              onSubmit={this.onSearchSubmit}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex flex-row-reverse">
              <span>
                The Alternatives listed on the Provider Administered drug list
                are from the&nbsp;
                <Link to={`/targetCategories`}>current clinical catalog</Link>
              </span>
            </div>
            <RPSDataGrid
              keyField="key"
              remote
              data={transformedSearchResults}
              columns={this.COLUMNS}
              onTableChange={this.handleTableChange}
              cellEditFactory={this.getCellEditFactory()}
              selectRow={this.getSelectRow()}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-row-reverse">
            <Button
              color="primary"
              disabled={_.isEmpty(this.state.selectedTargets)}
              onClick={this.handleAddTargets}
            >
              Save
            </Button>
            <Button
              className="me-2"
              color="danger"
              onClick={(e) => {
                e.preventDefault();
                history.push(
                  `/products/provider-drugs/${this.state.contractId}/${selectedClient.id}`
                );
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
        <AlertModalv2
          isOpen={this.state.isErrorModalOpen}
          toggle={this.toggleErrorModal}
          header="Error"
          alertBtnText="Cancel"
          closeCallback={() => {
            history.goBack();
          }}
          isError
        >
          <>
            <p>
              The following Targeted GPI/MSC(s) is/are already included in&nbsp;
              {selectedClient.name}'s Provider Administered Drug List.
            </p>
            <ModalTargetsList items={this.props.targetSaveErrors} />
            <p>
              Adding this/these medication(s) would result in overlapping
              effective &amp; termination dates.
            </p>
          </>
        </AlertModalv2>
        <ConfirmationModal
          isOpen={this.state.isNonOverlappingDatesOpen}
          toggle={this.toggleNonOverlappingTargetsAlert}
          header="Warning"
          confirmText="Continue"
          action={this.onConfirmContinue}
        >
          <>
            <p>
              The following Targeted GPI/MSC(s) is/are already included in&nbsp;
              {selectedClient.name}'s Provider Administered Drug List with a
              non-overlapping effective &amp; termination date.
            </p>
            <ModalTargetsList items={this.state.nonOverlappingTargets} />
            <p>Do you wish to continue?</p>
          </>
        </ConfirmationModal>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    searchResults: state.additionalTargetDrugs.providerTargetSearchResults,
    targets: state.additionalTargetDrugs.targets,
    targetSaveErrors: state.additionalTargetDrugs.erroredTargetsOnSave,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getClient,
  clearClients,
  tryProviderTargetSearch,
  getAllAdditionalTargetDrugs,
  clearAdditionalTargetDrugs,
  tryAddAdditionalTargetDrugs,
})(AddProviderAdministeredDrugs);
