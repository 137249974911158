import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import {
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getCategoryReferenceDrugsAuditLog,
  clearCategoryReferenceDrugsAuditLog,
} from "../../../actions";
import { localDateTimeFormatter } from "../../../utils";
import _ from "lodash";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import CategoryReferenceDrugAuditLogSearchForm from "./CategoryReferenceDrugAuditLogSearchForm";

class CategoryReferenceDrugAuditLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currPage: 1,
      sizePerPage: 15,
      navLoaded: false,
      totalSizePerPage: 15,
      breadcrumbsLoaded: false,
      searchTerms: {
        searchDate: "",
        userNames: [],
        drugCategoryNames: [],
        changeTypes: [],
        auditDescriptions: [],
        ahfsClassNames: [],
        gpis: [],
      },
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        {
          to: "/category-refs",
          name: "Category Reference GPIs",
          active: false,
        },
      ],
      finalCrumb: `Category Reference Drug Audit Log`,
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.getPage();
    this.props.toggleSidebarVisibility();
  }

  componentDidUpdate() {
    if (!this.state.breadcrumbsLoaded && !_.isEmpty(this.props.auditLogs)) {
      this.setupBreadCrumbs();
      this.setState({ breadcrumbsLoaded: true });
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearCategoryReferenceDrugsAuditLog();
  }

  getPage = () => {
    this.props.getCategoryReferenceDrugsAuditLog(
      this.state.searchTerms,
      this.state.currPage - 1,
      this.state.sizePerPage,
      this.state.sortField
    );
  };

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (type === "sort") {
      sortField = {
        dataField: sortField,
        order: sortOrder,
      };
      this.setState({ sortField }, () => {
        this.getPage();
      });
      return -1;
    }

    this.setState({ currPage: page, sizePerPage: sizePerPage }, () => {
      this.getPage();
    });
  };

  onSizePerPageChange = (sizePerPage, page) => {
    this.getPage(page, sizePerPage, this.props.sorts);
  };

  onSearchSubmit = (searchTerms) => {
    this.setState({ currPage: 1, searchTerms }, () => {
      this.getPage();
    });
  };

  COLUMNS = [
    {
      dataField: "updatedDate",
      text: "Change Date/Time",
      sort: true,
      formatter: localDateTimeFormatter,
    },
    {
      dataField: "userName",
      text: "User",
      sort: true,
    },
    {
      dataField: "ahfsDescription",
      text: "AHFS Classification",
      sort: true,
    },
    {
      dataField: "drugCategoryName",
      text: "Drug Category",
      sort: true,
    },
    {
      dataField: "gpi",
      text: "GPI",
      sort: true,
    },
    {
      dataField: "changeDescription",
      text: "Change Type",
      sort: true,
    },
    {
      dataField: "auditDescription",
      text: "Audit Description",
      sort: true,
    },
  ];

  render() {
    if (_.isEmpty(this.props.auditLogs)) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <Col>
            <h3>Category Reference Audit Log</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <CategoryReferenceDrugAuditLogSearchForm
              onSubmit={this.onSearchSubmit}
              searchTerms={this.state.searchTerms}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <RPSDataGrid
              keyField="id"
              paginated
              remote
              sizePerPage={this.state.sizePerPage}
              page={this.state.currPage}
              totalSize={this.props.auditLogs.totalElements}
              data={this.props.auditLogs.content}
              columns={this.COLUMNS}
              onSizePerPageChange={this.onSizePerPageChange}
              onTableChange={this.handleTableChange}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auditLogs: state.categoryReferenceDrugAuditLogs.auditLogs,
  };
};

export default connect(mapStateToProps, {
  populateBreadCrumbs,
  toggleSidebarVisibility,
  getCategoryReferenceDrugsAuditLog,
  clearCategoryReferenceDrugsAuditLog,
})(CategoryReferenceDrugAuditLog);
