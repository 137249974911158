import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { getFileNameNoExt } from "../../../utils";
import {
  clearClients,
  getClient,
  getAWDPFileContent,
  getConfigFile,
  clearFileContent,
  populateBreadCrumbs,
  toggleSidebarVisibility
} from "./../../../actions";
import { ConfigFile } from "./fileTypes";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";

class AWPDView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navLoaded: false,
      clientId: this.props.match.params.clientId
    };
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [
        { to: "/dashboard", name: "Home", active: false },
        { to: "/clients", name: "Client List", active: false },
        {
          to: "/clients/view/" + this.state.clientId,
          name: this.props.selectedClient.name,
          active: false
        },
        {
          to: "/client-config/dashboard/" + this.state.clientId,
          name: "Configuration Files",
          active: false
        }
      ],
      finalCrumb: this.props.selectedFile.fileName
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  componentDidMount() {
    this.props.toggleSidebarVisibility();
    this.props.getClient(this.state.clientId);
    this.props.getConfigFile(this.props.match.params.correlationId);
    this.props.getAWDPFileContent(this.props.match.params.correlationId);
  }

  componentDidUpdate() {
    if (this.props.selectedClient && this.props.selectedFile) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearFileContent([ConfigFile.AWPD]);
    this.props.clearClients();
  }

  drugTypeFormatter = (content, row) => {
    let type = "";
    switch (row.drugType.toUpperCase()) {
      case "B":
        type = "Brand";
        break;
      case "G":
        type = "Generic";
        break;
      case "E":
        type = "(end of file)";
        break;
      default:
        type = "";
    }
    return type;
  };

  pharmTypeFormatter = (content, row) => {
    let type = "";
    if (row.pharmacyAwpType) {
      switch (row.pharmacyAwpType.toUpperCase()) {
        case "MAIL_90":
          type = "Mail 90";
          break;
        case "RETAIL_30":
          type = "Retail 30";
          break;
        case "RETAIL_90":
          type = "Retail 90";
          break;
        default:
          type = "";
      }
    }
    return type;
  };

  getFileContents = () => {
    let contents = this.props.fileContents;
    //Add the end of file row
    contents.push({
      id: 0,
      clientId: 0,
      correlationId: 0,
      drugType: "E",
      pharmacyAwpType: null,
      awpDiscountRetail: null,
      awpDiscountMail: null
    });
    return contents;
  };

  COLUMNS = [
    {
      dataField: "drugType",
      sort: false,
      text: "Drug Type",
      formatter: this.drugTypeFormatter
    },
    {
      dataField: "pharmacyAwpType",
      sort: false,
      text: "Retail/Mail",
      formatter: this.pharmTypeFormatter
    },
    {
      dataField: "awpDiscountRetail",
      sort: false,
      text: "AWP Discount % 30days"
    },
    {
      dataField: "awpDiscountMail",
      sort: false,
      text: "AWP Discount % 90days"
    },
    {
      dataField: "awpDiscount90DayRetail",
      sort: false,
      text: "AWP Discount % 90days Retail"
    }
  ];

  render() {
    if (
      !this.props.selectedClient ||
      !this.props.fileContents ||
      !this.props.selectedFile
    ) {
      return <div>Loading...</div>;
    }

    const fileName = getFileNameNoExt(this.props.selectedFile.fileName);

    return (
      <Container className="main-container" fluid>
        <Row className="mb-4">
          <Col>
            <h3>{fileName}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <RPSDataGrid
              keyField="id"
              data={this.getFileContents()}
              columns={this.COLUMNS}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedClient: state.clients[ownProps.match.params.clientId],
    configFiles: state.clientConfigFiles,
    fileContents: Object.values(state.awdpFileContent),
    selectedFile: state.clientConfigFiles[ownProps.match.params.correlationId]
  };
};

export default connect(
  mapStateToProps,
  {
    clearClients,
    getClient,
    getAWDPFileContent,
    getConfigFile,
    clearFileContent,
    populateBreadCrumbs,
    toggleSidebarVisibility
  }
)(AWPDView);
