import {
  SET_INITIAL_MIN_MAX_FILTER,
  UPDATE_MIN_MAX_FILTER,
  CLEAR_ALL_FILTERS,
} from "../actions/types";

const getPristineState = () => {
  const pristine = {
    pristine: true,
    planSavings: {
      initial: { min: null, max: null },
      updated: { min: null, max: null },
    },
  };
  return pristine;
};

const drugPairFiltersReducer = (state = getPristineState(), action) => {
  switch (action.type) {
    case SET_INITIAL_MIN_MAX_FILTER:
      const initialPlanSavings = state.planSavings;
      if (
        action.payload != null &&
        action.payload.min !== 0 &&
        action.payload.max !== 0
      ) {
        initialPlanSavings.initial = action.payload;
      } else {
        initialPlanSavings.initial = { min: null, max: null };
      }

      return { ...state, planSavings: initialPlanSavings };
    case UPDATE_MIN_MAX_FILTER:
      const updated = state.planSavings;
      updated.updated = action.payload;
      return { ...state, planSavings: updated, pristine: false };
    case CLEAR_ALL_FILTERS:
      return getPristineState();
    default:
      return state;
  }
};

export default drugPairFiltersReducer;
