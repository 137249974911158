import { CLEAR_PROSPECTIVE_REPORTS, GET_PROSPECTIVE_REPORTS } from "../actions/types";

const prospectiveSavingsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROSPECTIVE_REPORTS:
      return action.payload;
    case CLEAR_PROSPECTIVE_REPORTS:
      return {};
    default:
      return state;
  }
};

export default prospectiveSavingsReducer;
