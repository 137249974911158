import React, { useState, useEffect } from "react";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Chip } from "./Chip";
import _ from "lodash";
import {
  removeSessionFilterValue,
  setSessionFilter,
} from "../session/sessionFilterManager";

export const ChipAwareTextInput = (props) => {
  const { name, updateCallback } = props;

  const [terms, setTerms] = useState(
    props.initialValues ? props.initialValues : [],
  );
  const [inputVal, setInputVal] = useState("");

  const onAdd = () => {
    const newTerms = terms;
    newTerms.push(inputVal);
    setTerms(newTerms);

    if (props.sticky && props.sessionKey && props.filterKey) {
      setSessionFilter(props.sessionKey, props.filterKey, inputVal);
    }

    setInputVal("");
    updateCallback(name, terms);
  };

  const onRemove = (val) => {
    const newTerms = _.filter(terms, (term) => {
      return term !== val;
    });

    if (props.sticky && props.sessionKey && props.filterKey) {
      removeSessionFilterValue(props.sessionKey, props.filterKey, val);
    }

    setTerms(newTerms);
  };

  const onEnterKey = (e) => {
    if (
      e.key === "Enter" &&
      !_.isEmpty(inputVal.trim()) &&
      _.trim(inputVal).length >= props.minLength &&
      _.trim(inputVal).length <= props.maxLength
    ) {
      onAdd();
    }
  };

  useEffect(() => {
    updateCallback(name, terms);
  }, [terms, name, updateCallback]);

  return (
    <Col md={2}>
      <Row>
        <Col md={10}>
          <FormGroup className="d-flex gap-2">
            <div className="field">
              <Label
                for={props.id}
              >{`${props.placeholder} (${terms.length})`}</Label>
              <Input
                type="text"
                id={props.id}
                name={name}
                value={inputVal}
                onChange={(e) => setInputVal(e.target.value)}
                onKeyPress={onEnterKey}
              />
            </div>
            <Button
              className="inline-text-input-button"
              onClick={onAdd}
              disabled={
                _.isEmpty(_.trim(inputVal)) ||
                !inputVal ||
                _.trim(inputVal).length < props.minLength ||
                _.trim(inputVal).length > props.maxLength
              }
            >
              Add
            </Button>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {_.map(terms, (term) => (
            <Chip
              key={term + _.random(1, 999)}
              value={term}
              removeCallback={onRemove}
            />
          ))}
        </Col>
      </Row>
    </Col>
  );
};

ChipAwareTextInput.defaultProps = {
  minLength: 3,
  maxLength: 50,
  sticky: false,
};

ChipAwareTextInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  updateCallback: PropTypes.func.isRequired,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  initialValues: PropTypes.array,
  sticky: PropTypes.bool,
  sessionKey: PropTypes.string,
  filterKey: PropTypes.string,
};
