import _ from "lodash";
import { GET_LINES_OF_BUSINESS } from "../actions/types";

const linesOfBusinessReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_LINES_OF_BUSINESS:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    default:
      return state;
  }
};

export default linesOfBusinessReducer;
