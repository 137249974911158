import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { trySignOut, clearError } from "../../actions";
import "./Header.css";
import logo from "./ar-logo.png";
import AlertModal from "../Modal/AlertModal";
import _ from "lodash";
import MenuSeparator from "./MenuSeparator";
import EnvironmentBadge from "./EnvironmentBadge";

class Header extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  onSignOutClick = (e) => {
    e.preventDefault();
    this.props.trySignOut();
  };

  renderUserNav() {
    if (this.props.auth.currentUser) {
      return (
        <Nav id="user-nav" className="ms-auto" navbar>
          <NavItem>
            <Link to="/dashboard">Home</Link>
            <MenuSeparator />
          </NavItem>
          <NavItem>
            <Link to="/user-profile">User Profile</Link>
            <MenuSeparator />
          </NavItem>
          <NavItem>
            <Link to="#" onClick={this.onSignOutClick}>
              Sign-Out
            </Link>
          </NavItem>
        </Nav>
      );
    }
  }

  renderAppNav() {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const navClass = `${environment}-app-nav me-auto`;
    if (this.props.auth.currentUser) {
      return (
        <Nav className={navClass} navbar>
          <NavItem>
            <Link to="/grids">Clinical Mgmt</Link>
            <MenuSeparator />
          </NavItem>
          <NavItem>
            <Link to="/clients">Client Data Mgmt</Link>
            <MenuSeparator />
          </NavItem>
          <NavItem>
            <Link to="/reporting">Reporting</Link>
          </NavItem>
        </Nav>
      );
    }
  }

  render() {
    return (
      <header className="header">
        <Navbar color="dark" dark expand="md">
          <NavbarBrand id="header-logo" tag={Link} to="/dashboard">
            <img src={logo} alt="Active Radar Logo" />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            {process.env.REACT_APP_ENVIRONMENT !== "prod" && (
              <EnvironmentBadge />
            )}
            {this.renderAppNav()}
            {this.renderUserNav()}
          </Collapse>
        </Navbar>
        <AlertModal
          isOpen={!_.isEmpty(this.props.error)}
          toggle={this.props.clearError}
          header={this.props.error.message}
          content={this.props.error.detailedMessage}
        />
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, error: state.error };
};

export default connect(mapStateToProps, { trySignOut, clearError })(Header);
