import React, { Component } from "react";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";

class StandardBoard extends Component {
  render() {
    return (
      <React.Fragment>
        <h6>{this.props.title}</h6>
        <RPSDataGrid
          wrapperClasses="quarterPageGrid"
          keyField="id"
          data={this.props.data}
          columns={this.props.columns}
        />
      </React.Fragment>
    );
  }
}

export default StandardBoard;
