import React, { Component } from "react";
import { Row, Col, Form, FormGroup, Input, Button } from "reactstrap";

class DataGridSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.searchTerm
    };
  }

  updateSearchTerm(e) {
    this.setState({
      searchTerm: e.target.value
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.submitFunc(this.state.searchTerm);
  };

  handleClear = () => {
    this.props.clearFunc();
    this.setState({ searchTerm: "" });
  };

  render() {
    return (
      <Row>
        <Col sm="5">
          <Form onSubmit={this.handleSubmit} autoComplete="off">
            <FormGroup>
              <Input
                type="text"
                id="searchInput"
                placeholder={this.props.placeholder}
                value={this.state.searchTerm}
                onChange={e => this.updateSearchTerm(e)}
              />
            </FormGroup>
          </Form>
        </Col>
        <Col className="ps-0">
          <Button
            className="me-1"
            color="primary"
            type="button"
            onClick={this.handleSubmit}
          >
            Search
          </Button>
          <Button color="secondary" type="button" onClick={this.handleClear}>
            Clear
          </Button>
        </Col>
      </Row>
    );
  }
}

export default DataGridSearch;
