import {
  GET_MPD_CATEGORY_EXCLUSIONS,
  CLEAR_MPD_CATEGORY_EXCLUSIONS,
} from "../types.js";
import { getClinicalBackend } from "../../api/clinicalBackend.js";
import { tryRefreshToken } from "../auth/authAction.js";
import { handleError } from "../../utils/error/errorHandlers.js";

export const getMPDCategoryExclusions =
  (clientReferenceReportId) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const categoryExclusions = await getClinicalBackend(authToken).get(
        `/api/mpds/${clientReferenceReportId}/category-exclusions`
      );

      dispatch({
        type: GET_MPD_CATEGORY_EXCLUSIONS,
        payload: categoryExclusions.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearMPDCategoryExclusions = () => (dispatch) => {
  dispatch({ type: CLEAR_MPD_CATEGORY_EXCLUSIONS });
};
