import React from "react";
import PropTypes from "prop-types";
import { Col, Input } from "reactstrap";

export const ClientDropDown = props => {
  return (
    <Col md={props.colSize}>
      <label>
        {props.label}&nbsp;
        {props.required && <span className="required-field">*</span>}
      </label>
      <Input
        type="select"
        id={props.id}
        onChange={props.onChange}
        required={props.required}
      >
        {props.showAllOption && <option value="all">All Clients</option>}
        {!props.showAllOption && <option value="">--Select--</option>}
        {props.clients.map(client => {
          return (
            <option key={client.id} value={client.id}>
              {client.name}
            </option>
          );
        })}
      </Input>
    </Col>
  );
};

ClientDropDown.defaultProps = {
  colSize: 2,
  required: false,
  showAllOption: true
};

ClientDropDown.propTypes = {
  colSize: PropTypes.number,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
  required: PropTypes.bool,
  showAllOption: PropTypes.bool
};
