import _ from "lodash";

export const SESSION_KEYS = {
  DECISION_GRID_LIST: "dg-list",
  DECISION_GRID_VIEW: "dg-view",
  CAT_REF_LIST: "cat-ref-list",
};

export const getSessionFiltersOrDefault = (sessionKey, defaultValue = {}) => {
  const sessionFilters = localStorage.getItem(sessionKey);
  if (!sessionFilters) {
    return defaultValue;
  }
  return JSON.parse(sessionFilters);
};

export const setSessionFilter = (sessionKey, filterKey, value) => {
  let sessionFilters = getSessionFiltersOrDefault(sessionKey);
  if (!sessionFilters) {
    // first time creating filters for this session
    sessionFilters = {
      [filterKey]: [value],
    };
  } else if (!sessionFilters[filterKey]) {
    // first time setting this filter for this session
    sessionFilters[filterKey] = [value];
  } else {
    // already have filters for this session, just add this one
    if (!_.includes(sessionFilters[filterKey], value)) {
      sessionFilters[filterKey].push(value);
    }
  }
  localStorage.setItem(sessionKey, JSON.stringify(sessionFilters));
};

export const removeSessionFilterValue = (sessionKey, filterKey, value) => {
  const sessionFilters = getSessionFiltersOrDefault(sessionKey);
  if (sessionFilters[filterKey]) {
    sessionFilters[filterKey] = _.filter(sessionFilters[filterKey], (val) => {
      return val !== value;
    });
    localStorage.setItem(sessionKey, JSON.stringify(sessionFilters));
  }
};
