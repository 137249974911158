import { GET_NDC_PRICES, CLEAR_NDC_PRICES } from "../types.js";
import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { handleError } from "../../utils/error/errorHandlers.js";

export const getNDCPricesAction =
  (
    targetGpi,
    targetMultiSource,
    targetDacon,
    altGpi,
    altMultiSource,
    altDacon
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endPoint = `/api/ndc-prices?targetGpi=${targetGpi}&targetMultiSource=${targetMultiSource}`;

      if (targetDacon) {
        endPoint += `&targetDacon=${targetDacon}`;
      }

      if (altGpi && altMultiSource && altDacon) {
        endPoint += `&altGpi=${altGpi}&altMultiSource=${altMultiSource}&altDacon=${altDacon}`;
      }

      const response = await getClinicalBackend(authToken).get(endPoint);

      dispatch({
        type: GET_NDC_PRICES,
        payload: response.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const clearNdcPrices = () => (dispatch) => {
  dispatch({ type: CLEAR_NDC_PRICES });
};
