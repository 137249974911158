import React, { Component } from "react";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import {
  DATE_PICKER_FORMAT,
  getUnrestrictedDateConfig,
  maxDecimalValidator,
  minValue,
  validMembershipCount,
  validSubscriberCount,
  renderDatePicker,
  renderInput,
  renderInputGroup,
  renderSelectInput,
  required,
  validSelect,
} from "../../../utils";
import { DateTime } from "luxon";
import _ from "lodash";
import history from "../../../history";

class RequestForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedClient: null,
      shouldBeDisabled: true,
      shouldDatesBeDisabled: true,
      subCountRequired: false,
      error: { reason: null, message: null },
      warning: { reason: null, message: null },
    };
  }

  onSubmit = (formValues) => {
    // convert values to correct format
    formValues.clientId = parseInt(formValues.clientId);
    formValues.membershipCount = parseInt(
      formValues.membershipCount.replace(/,/g, ""),
    );
    formValues.subscriberCount = formValues.subscriberCount
      ? parseInt(formValues.subscriberCount.replace(/,/g, ""))
      : null;
    formValues.totalPaid = parseFloat(formValues.totalPaid.replace(/,/g, ""));

    this.props.onSubmit(formValues);
  };

  onPeriodFromChange = (newVal) => {
    this.setState({
      minTo: DateTime.fromJSDate(newVal).plus({ days: 1 }).toJSDate(),
    });
  };

  onPeriodToChange = (newVal) => {
    this.setState({
      maxFrom: DateTime.fromJSDate(newVal).minus({ days: 1 }).toJSDate(),
    });
  };

  onClientSelectChange = (e, newVal) => {
    // Clear out any values that may have been input prior to client change
    this.props.reset();
    // Determine if selected client has VBR, today's date is greater than VBR effectiveDate
    const selectedClient = _.find(this.props.clients, {
      id: parseInt(newVal),
    });
    this.props.handleClientChange(selectedClient.id);

    const today = DateTime.now();
    // filter to only VBRs
    const vbrs = _.filter(selectedClient.contracts, (contract) => {
      if (contract.type === "VALUE_BASED" && !contract.termDate) {
        return contract;
      } else if (
        contract.type === "VALUE_BASED" &&
        DateTime.fromISO(contract.termDate).diff(today) > 1
      ) {
        return contract;
      }
    });

    // We don't know how we are going to handle multiple VBRS yet, so for now just take the most recent one
    const vbr = _.maxBy(vbrs, "id");
    const error = { reason: null, message: null };
    const initialMaxDate = getUnrestrictedDateConfig.maxEffectiveDate;
    let shouldBeDisabled = true;
    let minFrom = null;
    let minTo = null;
    let subCountRequired = false;

    if (!vbr) {
      error.reason = "NO_VBR";
      error.message =
        "Client does not have a valid Value Based Reimbursement Product";
    } else if (
      vbr &&
      DateTime.fromISO(vbr.effectiveDate).diff(today, "months") >= 0
    ) {
      error.reason = "INVALID_VBR_DATE";
      error.message =
        "Client VBR Effective Date must be prior to the current month.";
    } else if (vbr && !error.reason) {
      minFrom = DateTime.fromISO(vbr.effectiveDate)
        .plus({ days: 1 })
        .toJSDate();
      minTo = DateTime.fromISO(minFrom).plus({ days: 1 }).toJSDate();
      shouldBeDisabled = false;
      subCountRequired = vbr.billingType === "EMPLOYEE";
    }

    this.setState({
      minFrom,
      maxFrom: initialMaxDate,
      minTo,
      maxTo: initialMaxDate,
      selectedClient,
      shouldBeDisabled,
      subCountRequired,
      error,
    });
  };

  onMpdChange = (e, newVal) => {
    const mpdId = parseInt(newVal);
    const mpd = _.find(this.props.mpds, { id: mpdId });
    const today = DateTime.now();
    const implementationDate = mpd.implementationDate
      ? DateTime.fromISO(mpd.implementationDate)
      : today;
    const implementationEndDate = mpd.implementationEndDate
      ? DateTime.fromISO(mpd.implementationEndDate)
      : today;
    const claimsStart = this.props.claimsDateRange.minDate
      ? DateTime.fromISO(this.props.claimsDateRange.minDate)
      : null;
    const claimsEnd = this.props.claimsDateRange.maxDate
      ? DateTime.fromISO(this.props.claimsDateRange.maxDate)
      : null;

    const error = { reason: null, message: null };
    const warning = { reason: null, message: null };

    if (!claimsStart || !claimsEnd) {
      error.reason = "NO_EVALED_CLAIMS";
      error.message = "No claims have been evaluated for this client.";
    } else if (
      claimsStart > implementationEndDate ||
      claimsEnd < implementationDate
    ) {
      error.reason = "NO_CLAIMS_MPD_INTERSECTION";
      error.message = `Evaluated claims are outside of MPD implementation dates. Claims found from ${claimsStart.toFormat(
        DATE_PICKER_FORMAT,
      )} - ${claimsEnd.toFormat(DATE_PICKER_FORMAT)}`;
    }

    if (error.reason) {
      this.setState({
        shouldDatesBeDisabled: true,
        error,
      });
    } else {
      if (claimsStart > implementationDate || claimsEnd < implementationDate) {
        warning.reason = "CLAIM_DATE_RESTRICTION";
        warning.message = `Evaluated claims are limited to a subset of MPD dates. Claims found from ${claimsStart.toFormat(
          DATE_PICKER_FORMAT,
        )} - ${claimsEnd.toFormat(DATE_PICKER_FORMAT)}`;
      }
      const minFrom = DateTime.max(implementationDate, claimsStart).toJSDate();
      const minTo = DateTime.min(implementationEndDate, claimsEnd).toJSDate();
      this.setState({
        minFrom,
        maxFrom: minTo,
        minTo,
        maxTo: minTo,
        shouldDatesBeDisabled: false,
        error,
        warning,
      });
    }
  };

  render() {
    const { invalid, submitting, pristine } = this.props;

    const subCountValidators = this.state.subCountRequired
      ? [required, ...validSubscriberCount]
      : validSubscriberCount;

    return (
      <Form
        autoComplete="off"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <Row md={3}>
          <FormGroup>
            <Field
              id="client"
              label="Client"
              name="clientId"
              items={this.props.clients}
              valueProp="id"
              keyProp="id"
              displayProp="name"
              onChange={this.onClientSelectChange}
              required
              validate={[required, validSelect]}
              component={renderSelectInput}
            />
          </FormGroup>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Field
                id="mpd"
                name="mpd"
                label="Low Cost Therapeutic Alternatives File"
                tooltip="Only LCTAs with an implementation end date are listed."
                items={this.props.mpds}
                valueProp="id"
                keyProp="id"
                displayProp="displayName"
                disabled={this.state.shouldBeDisabled}
                onChange={this.onMpdChange}
                required
                validate={[required, validSelect]}
                component={renderSelectInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <FormGroup>
              <Field
                id="membershipCount"
                name="membershipCount"
                type="text"
                placeholder="Membership Count"
                tooltip="Reflects the client’s membership/person codes count, for PMPM calculations"
                required
                validate={[required, ...validMembershipCount]}
                component={renderInput}
                disabled={this.state.shouldBeDisabled}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Field
                id="subscriberCount"
                name="subscriberCount"
                type="text"
                placeholder="Subscriber Count"
                tooltip="Reflects the client’s subscriber count, for PEPM calculations"
                required={this.state.subCountRequired}
                validate={subCountValidators}
                component={renderInput}
                disabled={this.state.shouldBeDisabled}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Field
                id="totalPaid"
                name="totalPaid"
                type="text"
                placeholder="Total Fees Paid"
                tooltip="Total Fees paid by the client during the Period of Analysis"
                symbolText="$"
                required
                validate={[
                  required,
                  maxDecimalValidator(99999.99),
                  minValue(0.01),
                ]}
                component={renderInputGroup}
                disabled={this.state.shouldBeDisabled}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <FormGroup>
              <Field
                id="periodFrom"
                name="periodFrom"
                placeholder="Period of Analysis - From"
                required
                onChange={this.onPeriodFromChange}
                minDate={this.state.minFrom}
                maxDate={this.state.maxFrom}
                showYearDropdown
                component={renderDatePicker}
                validate={[required]}
                shouldDisable={this.state.shouldDatesBeDisabled}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Field
                id="periodTo"
                name="periodTo"
                placeholder="Period of Analysis - To"
                required
                onChange={this.onPeriodToChange}
                minDate={this.state.minTo}
                maxDate={this.state.maxTo}
                showYearDropdown
                component={renderDatePicker}
                validate={[required]}
                shouldDisable={this.state.shouldDatesBeDisabled}
              />
            </FormGroup>
          </Col>
        </Row>
        {this.state.error.reason && (
          <Row className="mb-3">
            <Col>
              <span className="form-error">{this.state.error.message}</span>
            </Col>
          </Row>
        )}
        {this.state.warning.reason && (
          <Row className="mb-3">
            <Col>
              <span className="form-warning">{this.state.warning.message}</span>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={1}>
            <Button
              color="danger"
              block
              onClick={(e) => {
                e.preventDefault();
                history.push("/reporting/performance");
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col md={1}>
            <Button
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

RequestForm = reduxForm({ form: "requestForm" })(RequestForm);

// We need access to the selected client's id
const selector = formValueSelector("requestForm");

RequestForm = connect((state) => {
  const selectedClientValue = selector(state, "client");
  return { selectedClientValue };
})(RequestForm);

export default RequestForm;
