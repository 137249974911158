import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row } from "reactstrap";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import {
  clearClients,
  getClients,
  tryDeleteClient,
  populateBreadCrumbs,
  toggleSidebarVisibility,
} from "./../../../actions";
import { Link } from "react-router-dom";
import { dateFormatter, clientTypeFormatter } from "./../../../utils";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import "./Client.css";
import RPSDataGrid from "../../DataGrid/RPSDataGrid";
import _ from "lodash";
import { Role } from "../../../utils/auth/roles";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      navLoaded: false,
      isAuthorized: _.some(this.props.roles, (role) => {
        return (
          role.name === Role.CLINICAL_APPROVER.name ||
          role.name === Role.CLIENT_ADMIN.name ||
          role.name === Role.ADMIN_USER.name
        );
      }),
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.props.getClients();
    this.props.toggleSidebarVisibility();
  }

  componentDidUpdate() {
    if (this.props.clients) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadCrumbs();
      }
    }
  }

  componentWillUnmount() {
    this.props.clearClients();
    this.props.toggleSidebarVisibility();
  }

  setupBreadCrumbs = () => {
    const breadCrumbs = {
      crumbs: [{ to: "/dashboard", name: "Home", active: false }],
      finalCrumb: "Client List",
    };

    this.props.populateBreadCrumbs(breadCrumbs);
  };

  deleteClient(clientId, clientName) {
    this.toggleModal(clientId, clientName);
  }

  toggleModal = (clientId, clientName) => {
    const header = "Delete " + clientName;
    const content = "Are you sure you want to delete " + clientName + "?";

    this.setState({
      isOpen: !this.state.isOpen,
      modalHeader: header,
      modalContent: content,
      modalAction: this.props.tryDeleteClient,
      clientId: clientId,
    });
  };

  actionFormatter = (content, row) => {
    const editTarget = "/clients/edit/" + row.id;
    return (
      <div>
        {this.state.isAuthorized && (
          <div>
            <Button
              className="linkBtn tableBtn actionIconBtnDanger"
              color="link"
              onClick={() => this.deleteClient(row.id, row.name)}
            >
              <FaTrashAlt />
            </Button>
            <Button
              className="linkBtn tableBtn actionIconLinkPrimary"
              color="link"
              tag={Link}
              to={editTarget}
            >
              <FaPencilAlt />
            </Button>
          </div>
        )}
      </div>
    );
  };

  linkFormatter = (content, row) => {
    const target = "/clients/view/" + row.id;
    return (
      <Button color="link" tag={Link} to={target}>
        {content}
      </Button>
    );
  };

  COLUMNS = [
    {
      dataField: "actions",
      sort: false,
      text: "Action",
      formatter: this.actionFormatter,
      isDummyField: true,
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "name",
      sort: true,
      text: "Name",
      formatter: this.linkFormatter,
    },
    {
      dataField: "type",
      sort: true,
      text: "Type",
      formatter: clientTypeFormatter,
    },
    {
      dataField: "latestContract.effectiveDate",
      sort: true,
      text: "Effective Date",
      formatter: dateFormatter,
    },
    {
      dataField: "latestContract.termDate",
      sort: true,
      text: "Term Date",
      formatter: dateFormatter,
    },
    {
      dataField: "updatedDate",
      sort: true,
      text: "Last Updated",
      formatter: dateFormatter,
    },
  ];

  getClients = () => {
    const clients = this.props.clients;
    const noTermDates = _.sortBy(
      _.filter(clients, (client) => {
        return (
          client.latestContract === null ||
          client.latestContract === undefined ||
          client.latestContract.termDate === null
        );
      }),
      (client) => {
        return client.name.trim().toLowerCase();
      }
    );
    const termDates = _.sortBy(
      _.filter(clients, (client) => {
        return client.latestContract && client.latestContract.termDate;
      }),
      (client) => {
        return client.name.trim().toLowerCase();
      }
    );
    return _.concat(noTermDates, termDates);
  };

  render() {
    if (!this.props.clients) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row className="mt-2">
          <Col>
            {this.state.isAuthorized && (
              <Button
                className="mb-3"
                color="primary"
                tag={Link}
                to={"/clients/add"}
              >
                Add Client
              </Button>
            )}
            <RPSDataGrid
              keyField="id"
              data={this.getClients()}
              columns={this.COLUMNS}
            />
          </Col>
        </Row>
        <ConfirmationModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          header={this.state.modalHeader}
          content={this.state.modalContent}
          action={this.state.modalAction}
          objId={this.state.clientId}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    clients: Object.values(state.clients),
  };
};

export default connect(mapStateToProps, {
  clearClients,
  getClients,
  tryDeleteClient,
  toggleSidebarVisibility,
  populateBreadCrumbs,
})(Clients);
