import {
  POPULATE_SIDEBAR,
  TOGGLE_SIDEBAR_COLLAPSE,
  TOGGLE_SIDEBAR_VIS
} from "../types";

export const toggleSidebarVisibility = () => dispatch => {
  dispatch({ type: TOGGLE_SIDEBAR_VIS });
};

export const populateSidebar = items => dispatch => {
  dispatch({ type: POPULATE_SIDEBAR, payload: items });
};

export const clearSidebarActions = () => dispatch => {
  dispatch({ type: POPULATE_SIDEBAR, payload: [] });
};

export const toggleSidebarCollapse = () => (dispatch, getState) => {
  const { sidebar } = getState();
  dispatch({ type: TOGGLE_SIDEBAR_COLLAPSE, payload: !sidebar.open });
};
