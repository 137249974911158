import {
  GET_TARGET_CATEGORY_SUMMARIES,
  GET_FILTERED_TARGET_CATEGORY_SUMMARIES,
} from "../types.js";
import { getClinicalBackend } from "../../api/clinicalBackend.js";
import { tryRefreshToken } from "../auth/authAction.js";
import { formatPageableParameters, handleError } from "../../utils/index.js";

export const getTargetCategorySummaries =
  (authToken, catalogDate) => async (dispatch) => {
    try {
      dispatch(tryRefreshToken());
      const response = await getClinicalBackend(authToken).get(
        "/api/target-categories-summaries?catalogDate=" + catalogDate,
      );
      var targetCategories = {};
      targetCategories[catalogDate] = response.data;
      dispatch({
        type: GET_TARGET_CATEGORY_SUMMARIES,
        payload: targetCategories,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getFilteredTargetCategorySummaries =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/target-categories-summaries/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null,
      )}`;
      const response = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms),
      );

      dispatch({
        type: GET_FILTERED_TARGET_CATEGORY_SUMMARIES,
        payload: response.data,
      });
      return response;
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
