import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearClients,
  clearRollIns,
  tryRefreshToken,
  toggleSidebarVisibility,
  getClaimCorrelations,
  getEligibilityCorrelations,
  getConfigFiles,
  getRollIns,
  getClients,
  getMPDActivity,
  clearBreadCrumbs,
} from "../../actions";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import {
  dateFormatter,
  localDateTimeFormatter,
  DATE_FORMAT,
  getDateString,
  userNameFormatter,
  npeBrandingName,
} from "../../utils";
import "./Dashboard.css";
import _ from "lodash";
import StandardBoard from "./widgets/StandardBoard";
import moment from "moment";
import { Role } from "../../utils/auth/roles";
import SummaryChart from "./widgets/SummaryChart";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsToDisplay: 150,
      clients: [],
      isAuthorized: _.some(this.props.roles, {
        name: Role.CLINICIAN.name,
      }),
    };
  }

  componentDidMount() {
    const mpdEventsToShow = "CREATED_BY,EFF_DT,LOOKBACK,TERM_DT";

    this.props.clearBreadCrumbs();
    this.props.getClaimCorrelations(0, this.state.rowsToDisplay);
    this.props.getEligibilityCorrelations(0, this.state.rowsToDisplay);

    this.props.getRollIns(
      0,
      this.state.rowsToDisplay,
      [{ dataField: "changeDate", order: "desc" }],
      moment().subtract(30, "days").format(DATE_FORMAT),
      moment().add(14, "days").format(DATE_FORMAT),
      true
    );

    //default page is 0 and default size is from user's data
    this.props.getConfigFiles(
      [{ dataField: "importedDate", order: "desc" }],
      0,
      this.state.rowsToDisplay
    );

    this.props.getMPDActivity(
      undefined,
      mpdEventsToShow,
      0,
      this.state.rowsToDisplay
    );

    this.props.clearClients();
    this.props.getClients();
    this.props.tryRefreshToken();
    this.props.toggleSidebarVisibility();
  }

  componentDidUpdate = () => {
    if (!_.isEmpty(this.props.clients) && _.isEmpty(this.state.clients)) {
      this.setState({ clients: this.props.clients });
    }
  };

  componentWillUnmount() {
    this.props.toggleSidebarVisibility();
    this.props.clearClients();
    this.props.clearRollIns();
  }

  linkFormatter = (content, row, _, extra) => {
    let target = "#";
    switch (extra) {
      case "mpd":
        const crr = row.clientReferenceReport;
        target = `/clients/${crr.clientId}/mpds/${crr.id}`;
        if (this.state.isAuthorized) {
          target = target + "/edit";
        }
        break;
      case "configFile":
        target = `/client-config/dashboard/${row.clientId}`;
        break;
      case "rollins":
        target = "/reporting/roll-ins";
        break;
      case "eligibility":
        target = `/clients/${row.clientId}/eligibilitydata`;
        break;
      case "claims":
        target = `/clients/${row.clientId}/claimfiles`;
        break;
      case "clientName":
        target = `/clients/${row.clientReferenceReport.client.id}/mpds`;
        break;
      default:
        break;
    }
    return (
      <Button className="dashboardButton" color="link" tag={Link} to={target}>
        {content}
      </Button>
    );
  };

  getClaimCorrelationData = () => {
    return _.forEach(
      this.props.claimCorrelations.content,
      (claimCorrelation) => {
        claimCorrelation.clientName = _.find(this.props.clients, {
          id: claimCorrelation.clientId,
        }).name;
      }
    );
  };

  getEligibilityCorrelationData = () => {
    return _.forEach(
      this.props.eligibilityCorrelations.content,
      (eligibilityCorrelation) => {
        eligibilityCorrelation.clientName = _.find(this.props.clients, {
          id: eligibilityCorrelation.clientId,
        }).name;
      }
    );
  };

  getConfigurationData = () => {
    return _.forEach(this.props.clientConfigFiles.content, (configFile) => {
      configFile.clientName = _.find(this.props.clients, {
        id: configFile.clientId,
      }).name;
    });
  };

  getMPDData = () => {
    return _.forEach(this.props.mpdActivity.content, (mpdActivity) => {
      switch (mpdActivity.activityTrackType.longValue) {
        case "Eff DT":
        case "Term DT":
          mpdActivity.activityLong =
            mpdActivity.activityTrackType.longValue +
            "(" +
            getDateString(mpdActivity.dateSetTo) +
            ")";
          break;
        case "Lookback":
          mpdActivity.activityLong =
            mpdActivity.activityTrackType.longValue +
            "(" +
            mpdActivity.lookbackDays +
            ")";
          break;
        default:
          mpdActivity.activityLong = mpdActivity.activityTrackType.longValue;
          break;
      }
    });
  };

  ROLLINS_COLUMNS = [
    {
      dataField: "changeDate",
      text: "Date",
      sort: false,
      formatter: dateFormatter,
    },
    {
      dataField: "type",
      text: "Change Type",
      sort: false,
      formatter: this.linkFormatter,
      formatExtraData: "rollins",
    },
    {
      dataField: "message",
      text: "Change Message",
      sort: false,
    },
    {
      dataField: "gpi",
      text: "GPI-14 Code",
      sort: false,
    },
    {
      dataField: "drugName",
      text: "Drug Name",
      sort: false,
    },
  ];

  CLAIMS_COLUMNS = [
    {
      dataField: "updatedDate",
      text: "Process Date",
      sort: false,
      formatter: localDateTimeFormatter,
    },
    {
      dataField: "clientName",
      text: "Client",
      sort: false,
      formatter: this.linkFormatter,
      formatExtraData: "claims",
    },
    {
      dataField: "description",
      text: "File Name",
      sort: false,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
    },
  ];

  ELIGIBILITY_COLUMNS = [
    {
      dataField: "updatedDate",
      text: "Process Date",
      sort: false,
      formatter: localDateTimeFormatter,
    },
    {
      dataField: "clientName",
      text: "Client",
      sort: false,
      formatter: this.linkFormatter,
      formatExtraData: "eligibility",
    },
    {
      dataField: "description",
      text: "File Name",
      sort: false,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
    },
  ];

  CONFIGURATION_COLUMNS = [
    {
      dataField: "importedDate",
      text: "Imported Date",
      sort: false,
      formatter: localDateTimeFormatter,
    },
    {
      dataField: "clientName",
      text: "Client",
      sort: false,
      formatter: this.linkFormatter,
      formatExtraData: "configFile",
    },
    {
      dataField: "type",
      text: "File Type",
      sort: false,
    },
    {
      dataField: "fileName",
      text: "File Name",
      sort: false,
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
    },
  ];

  MPD_COLUMNS = [
    {
      dataField: "updatedDate",
      text: "Date",
      sort: false,
      formatter: localDateTimeFormatter,
    },
    {
      dataField: "clientReferenceReport.client.name",
      text: "Client",
      sort: false,
      formatter: this.linkFormatter,
      formatExtraData: "clientName",
    },
    {
      dataField: "clientReferenceReport.name",
      text: npeBrandingName,
      sort: false,
      formatter: this.linkFormatter,
      formatExtraData: "mpd",
    },
    {
      dataField: "activityLong",
      text: "Activity",
      sort: false,
    },
    {
      dataField: "user",
      text: "User",
      sort: false,
      formatter: userNameFormatter,
    },
  ];

  render() {
    if (
      _.isEmpty(this.props.claimCorrelations) ||
      _.isEmpty(this.props.clientConfigFiles) ||
      _.isEmpty(this.props.eligibilityCorrelations) ||
      _.isEmpty(this.props.rollIns) ||
      _.isEmpty(this.props.mpdActivity) ||
      _.isEmpty(this.state.clients)
    ) {
      return <div>Loading...</div>;
    }

    return (
      <Container className="main-container" fluid>
        <Row>
          <h1>Dashboard</h1>
        </Row>
        <Row>
          <SummaryChart />
        </Row>
        <Row>
          <Col className="h-100 ps-0 w-50 mw-50 mt-3">
            <StandardBoard
              data={this.props.rollIns.content}
              columns={this.ROLLINS_COLUMNS}
              title={"MediSpan Roll-ins Board"}
            />
          </Col>
          <Col className="h-100 ps-0 w-50 mw-50 mt-3">
            <StandardBoard
              data={this.getMPDData()}
              columns={this.MPD_COLUMNS}
              title={`${npeBrandingName} Activity`}
            />
          </Col>
        </Row>
        <Row>
          <Col className="h-100 ps-0 w-50 mw-50 mt-3">
            <StandardBoard
              data={this.getClaimCorrelationData()}
              columns={this.CLAIMS_COLUMNS}
              title={"Claims File Board"}
            />
          </Col>
          <Col className="h-100 ps-0 w-50 mw-50 mt-3">
            <StandardBoard
              data={this.getConfigurationData()}
              columns={this.CONFIGURATION_COLUMNS}
              title={"Configuration Board"}
            />
          </Col>
        </Row>
        <Row>
          <Col className="h-100 ps-0 w-50 mw-50 mt-3">
            <StandardBoard
              data={this.getEligibilityCorrelationData()}
              columns={this.ELIGIBILITY_COLUMNS}
              title={"Eligibility File Board"}
            />
          </Col>
          <Col className="h-100 ps-0 w-50 mw-50 mt-3" />
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    roles: state.auth.currentUser.roles,
    claimCorrelations: state.claimCorrelations,
    eligibilityCorrelations: state.eligibilityCorrelations,
    clientConfigFiles: state.clientConfigFilesPageable,
    rollIns: state.rollIns,
    clients: state.clients,
    mpdActivity: state.mpdActivity,
  };
};

export default connect(mapStateToProps, {
  clearClients,
  clearRollIns,
  tryRefreshToken,
  toggleSidebarVisibility,
  getClaimCorrelations,
  getEligibilityCorrelations,
  getConfigFiles,
  getRollIns,
  getClients,
  getMPDActivity,
  clearBreadCrumbs,
})(Dashboard);
