export const npeBrandingName = "Lower Cost Therapeutic Alternatives File";

export const getNPEBreadcrumbs = (selectedClient) => {
  return [
    { to: "/dashboard", name: "Home", active: false },
    { to: "/clients", name: "Client List", active: false },
    {
      to: "/clients/view/" + selectedClient.id,
      name: selectedClient.name,
      active: false,
    },
    {
      to: "/clients/" + selectedClient.id + "/mpds",
      name: `${npeBrandingName}s`,
      active: false,
    },
  ];
};
