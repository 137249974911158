import _ from "lodash";
import {
  GET_CONFIG_FILES,
  GET_CONFIG_FILE,
  DELETE_CONFIG_FILE,
  GENERATE_FORMULARY_CONTENT,
  UPLOAD_CONFIG_FILE,
  CLEAR_CONFIG_FILES,
} from "../actions/types";

const clientConfigFilesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CONFIG_FILES:
      return { ...state, ..._.mapKeys(action.payload, "id") };
    case GET_CONFIG_FILE:
    case GENERATE_FORMULARY_CONTENT:
    case UPLOAD_CONFIG_FILE:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_CONFIG_FILE:
      return _.omit(state, action.payload);
    case CLEAR_CONFIG_FILES:
      return {};
    default:
      return state;
  }
};

export default clientConfigFilesReducer;
