import { GET_ALTERNATE_GPI_COUNTS } from "../types.js";
import { getClinicalBackend } from "../../api/clinicalBackend.js";
import { tryRefreshToken } from "../auth/authAction.js";
import { handleError } from "../../utils/error/errorHandlers.js";

export const getAlternateGpiCountsAction =
  (fromDate, frequency, toDate) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      let endpoint = `/api/alternate-gpis/counts?fromDate=${fromDate}`;
      if (toDate) {
        endpoint += `&toDate=${toDate}`;
      }
      if (frequency) {
        endpoint += `&frequency=${frequency}`;
      }
      const response = await getClinicalBackend(authToken).get(endpoint);

      dispatch({
        type: GET_ALTERNATE_GPI_COUNTS,
        payload: response.data,
      });
      return response;
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
