import { GET_CATALOG_DELIVERIES } from "../actions/types";

const catalogDeliveriesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CATALOG_DELIVERIES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default catalogDeliveriesReducer;
