import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "reactstrap";
import TesterForm from "./TesterForm";
import {
  clearClients,
  toggleSidebarVisibility,
  getMPD,
  calculateMpd,
  clearCalculatedMpd,
  getClient,
  populateBreadCrumbs,
  unloadMPDs,
} from "./../../../../actions";
import {
  dateFormatter,
  getDrugName,
  getNPEBreadcrumbs,
  npeBrandingName,
} from "../../../../utils";
import RPSDataGrid from "../../../DataGrid/RPSDataGrid";

class CalcTester extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ahfsToTest: "",
      targetGpiToTest: "",
      navLoaded: false,
    };
  }
  componentDidMount() {
    this.props.getMPD(this.props.match.params.mpdId);
    this.props.getClient(this.props.match.params.clientId);
    this.props.toggleSidebarVisibility();
  }
  componentDidUpdate() {
    if (this.props.selectedMpd && this.props.selectedClient) {
      if (!this.state.navLoaded) {
        this.setState({ navLoaded: true });
        this.setupBreadcrumbs();
      }
    }
  }
  componentWillUnmount() {
    this.props.clearCalculatedMpd();
    this.props.toggleSidebarVisibility();
    this.props.unloadMPDs();
    this.props.clearClients();
  }

  setupBreadcrumbs() {
    const breadcrumbs = {
      crumbs: getNPEBreadcrumbs(this.props.selectedClient),
      finalCrumb: this.props.selectedMpd.name,
    };
    this.props.populateBreadCrumbs(breadcrumbs);
  }

  targetDrugNameFormatter = (content, row) => {
    return getDrugName(row.target);
  };

  altDrugNameFormatter = (content, row) => {
    return getDrugName(row.alt);
  };

  infoFormatter = (content, row) => {
    return (
      <ul>
        {row.info.map((item, index) => {
          return <li key={index}>{item.description}</li>;
        })}
      </ul>
    );
  };

  COLUMNS = [
    {
      dataField: "lca",
      sort: false,
      text: "LCA*",
    },
    {
      dataField: "mpdName",
      sort: false,
      text: npeBrandingName,
    },
    {
      dataField: "requestDate",
      sort: true,
      text: "Request Date",
      formatter: dateFormatter,
    },
    {
      dataField: "info",
      sort: false,
      text: "Info",
      formatter: this.infoFormatter,
    },
    {
      dataField: "design",
      sort: false,
      text: "Design Id",
    },
    {
      dataField: "targetName",
      sort: false,
      text: "Target Drug",
      formatter: this.targetDrugNameFormatter,
      dummyField: true,
    },
    {
      dataField: "target.gpi.gpi",
      sort: true,
      text: "Target GPI",
    },
    {
      dataField: "tmony",
      sort: false,
      text: "Target MONY",
    },
    {
      dataField: "target.dacon",
      sort: false,
      text: "Target DaCon",
    },
    {
      dataField: "targetTierLevel",
      sort: false,
      text: "Target Tier",
    },
    {
      dataField: "targetTotalCost30Retail",
      sort: true,
      text: "Target Total Cost 30 Retail",
    },
    {
      dataField: "targetTotalCost90Mail",
      sort: true,
      text: "Target Total Cost 90 Mail",
    },
    {
      dataField: "targetPlanPay30Retail",
      sort: true,
      text: "Target Plan Pay 30 Retail",
    },
    {
      dataField: "targetPlanPay90Mail",
      sort: true,
      text: "Target Plan Pay 90 Mail",
    },
    {
      dataField: "targetCopay30Retail",
      sort: true,
      text: "Target Copay 30 Retail",
    },
    {
      dataField: "targetCopay90Mail",
      sort: true,
      text: "Target Copay 90 Mail",
    },
    {
      dataField: "targetBuyUpCopay30Retail",
      sort: true,
      text: "Target Buy-Up Copay 30 Retail",
    },
    {
      dataField: "targetBuyUpCopay90Mail",
      sort: true,
      text: "Target Buy-Up Copay 90 Mail",
    },
    {
      dataField: "altName",
      sort: false,
      text: "LCA Drug",
      formatter: this.altDrugNameFormatter,
      dummyField: true,
    },
    {
      dataField: "alt.gpi.gpi",
      sort: true,
      text: "LCA GPI",
    },
    {
      dataField: "alt.multiSource",
      sort: false,
      text: "LCA MONY",
    },
    {
      dataField: "alt.dacon",
      sort: false,
      text: "LCA DaCon",
    },
    {
      dataField: "lcaTierLevel",
      sort: false,
      text: "LCA Tier",
    },
    {
      dataField: "lcaTotalCost30Retail",
      sort: true,
      text: "LCA Total Cost 30 Retail",
    },
    {
      dataField: "lcaTotalCost90Mail",
      sort: true,
      text: "LCA Total Cost 90 Mail",
    },
    {
      dataField: "lcaPlanPay30Retail",
      sort: true,
      text: "LCA Plan Pay 30 Retail",
    },
    {
      dataField: "lcaPlanPay90Mail",
      sort: true,
      text: "LCA Plan Pay 90 Mail",
    },
    {
      dataField: "lcaCopay30Retail",
      sort: true,
      text: "LCA Copay 30 Retail",
    },
    {
      dataField: "lcaCopay90Mail",
      sort: true,
      text: "LCA Copay 90 Mail",
    },
  ];

  onSubmit = (formValues) => {
    this.props.clearCalculatedMpd();
    this.props.calculateMpd(
      this.props.selectedMpd.id,
      formValues.gpi,
      formValues.dacon
    );
  };

  render() {
    return (
      <Container className="main-container" fluid>
        <Row className="mt-2">
          <Col>
            <h1>Test Net Pricing Engine Calculations</h1>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <TesterForm onSubmit={this.onSubmit} />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <RPSDataGrid
              keyField="id"
              data={this.props.mpdCalculations}
              columns={this.COLUMNS}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm={3}>
            <Button color="primary" block disabled>
              Export Grid to CSV File
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    selectedMpd: state.mpds[ownProps.match.params.mpdId],
    selectedClient: state.clients[ownProps.match.params.clientId],
    mpdCalculations: Object.values(state.mpdCalculations),
  };
};

export default connect(mapStateToProps, {
  clearClients,
  toggleSidebarVisibility,
  getMPD,
  calculateMpd,
  clearCalculatedMpd,
  getClient,
  populateBreadCrumbs,
  unloadMPDs,
})(CalcTester);
