import { CLEAR_ROLL_INS, GET_ROLL_INS } from "../actions/types";
import _ from "lodash";

const rollinsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ROLL_INS:
      action.payload.content = _.uniqBy(action.payload.content, "id");
      return action.payload;
    case CLEAR_ROLL_INS:
      return {};
    default:
      return state;
  }
};

export default rollinsReducer;
