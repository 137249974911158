import { GET_TARGET_GPIS, GET_FILTERED_TARGET_GPIS } from "../types.js";
import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { formatPageableParameters, handleError } from "../../utils";

export const getTargetGpisAction =
  (authToken, categoryId) => async (dispatch) => {
    try {
      dispatch(tryRefreshToken());
      const response = await getClinicalBackend(authToken).get(
        "/api/target-gpis?categoryId=" + categoryId
      );
      var gpis = {};
      gpis[categoryId] = response.data;
      dispatch({
        type: GET_TARGET_GPIS,
        payload: gpis,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };

export const getFilteredTargetGpisAction =
  (searchTerms, pageNumber, size, sort) => async (dispatch, getState) => {
    try {
      dispatch(tryRefreshToken());
      const authToken = getState().auth.authToken;
      const endpoint = `/api/target-gpis/search?${formatPageableParameters(
        pageNumber,
        size,
        sort ? [sort] : null
      )}`;
      const response = await getClinicalBackend(authToken).post(
        endpoint,
        JSON.stringify(searchTerms)
      );

      dispatch({
        type: GET_FILTERED_TARGET_GPIS,
        payload: response.data,
      });
    } catch (err) {
      handleError(err.response, dispatch);
    }
  };
