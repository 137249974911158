import React from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import _ from "lodash";
import PropTypes from "prop-types";

const AlertModal = (props) => {
  return ReactDOM.createPortal(
    <div>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        size={props.formatted ? "xl" : ""}
      >
        <ModalHeader className={props.headerClassname}>
          {props.header}
        </ModalHeader>
        {/*
            We should think about swapping the props.formatted bit into a modal that takes
            actual react children. This is specific to EditMPDForm and doesn't belong here.
          */}
        <ModalBody>
          {props.formatted ? (
            <ListGroup>
              {_.map(props.content, (stat) => {
                return <ListGroupItem key={stat}>{stat}</ListGroupItem>;
              })}
            </ListGroup>
          ) : (
            props.content
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (props.action) {
                props.action();
              }
              props.toggle();
            }}
          >
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>,
    document.querySelector("#modal")
  );
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  header: PropTypes.string,
  headerClassname: PropTypes.string,
  content: PropTypes.string,
  formatted: PropTypes.bool,
  action: PropTypes.func,
};

AlertModal.defaultProps = {
  formatted: false,
};

export default AlertModal;
