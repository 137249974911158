import _ from "lodash";
import { GET_MPD_GPI_EXCLUSION_REASONS } from "../actions/types";

const systemCodesGpiReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MPD_GPI_EXCLUSION_REASONS:
      return { ..._.mapKeys(action.payload, "id") };
    default:
      return state;
  }
};

export default systemCodesGpiReducer;
