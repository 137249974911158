import React, { Component } from "react";

import ReactDOM from "react-dom";
import { reduxForm } from "redux-form";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import PropTypes from "prop-types";

class FormModal extends Component {
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
    this.toggle();
  };

  onCancel = () => {
    if (this.props.cancelCallback) {
      this.props.cancelCallback();
    }
    this.toggle();
  };

  toggle = () => {
    this.props.reset();
    this.props.toggle();
  };

  render = () => {
    const { invalid, submitting, pristine } = this.props;

    const disabled = this.props.usePristine
      ? invalid || submitting || pristine
      : invalid || submitting;

    return ReactDOM.createPortal(
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.toggle}
          className={this.props.className}
          size={this.props.size}
        >
          <ModalHeader toggle={this.toggle}>{this.props.header}</ModalHeader>
          <Form
            autoComplete="off"
            onSubmit={this.props.handleSubmit(this.onSubmit)}
          >
            <ModalBody>{this.props.children}</ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={this.onCancel}>
                Cancel
              </Button>
              <Button
                className="mt-1"
                color="primary"
                type="submit"
                disabled={disabled}
              >
                {this.props.submitButtonText
                  ? this.props.submitButtonText
                  : "Save"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </>,
      document.querySelector("#modal")
    );
  };
}

FormModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  className: PropTypes.string,
  usePristine: PropTypes.bool,
  cancelCallback: PropTypes.func,
  size: PropTypes.string,
};

FormModal.defaultProps = {
  usePristine: true,
};

export default reduxForm({ form: "formModal", enableReinitialize: true })(
  FormModal
);
