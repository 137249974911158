import { GET_ALTERNATE_GPI_COUNTS } from "../actions/types";

const defaultState = { counts: [] };

const additionalTargetDrugAuditLogReducer = (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALTERNATE_GPI_COUNTS:
      return { counts: action.payload };
    default:
      return state;
  }
};

export default additionalTargetDrugAuditLogReducer;
