import React, { Component } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { reset, Field, reduxForm } from "redux-form";
import history from "../../history";
import {
  required,
  password,
  verifyPasswordsDoNotMatch,
  verifyPasswordsMatch
} from "./../../utils/forms/validators";

class PasswordForm extends Component {
  renderInput({
    input,
    id,
    placeholder,
    required,
    type,
    meta: { touched, error }
  }) {
    return (
      <div className="field">
        <Label for={id}>
          {placeholder} {required && <span className="required-field">*</span>}
        </Label>
        <Input type={type} id={id} required={required} {...input} />
        {touched && (error && <span className="form-error">{error}</span>)}
      </div>
    );
  }

  onSubmit = formValues => {
    this.props.onSubmit(formValues);
  };

  render() {
    const { invalid, submitting, pristine } = this.props;

    var passwordRules = String.raw`Passwords must have:
    At least 10 characters in length
    At least one capitalized and one lower case letter
    At least one number
    At least one special character (#$%^&, etc.)`;

    return (
      <Form
        className="change-password-form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
        autoComplete="off"
      >
        <Row>
          <Col sm={{ size: 4, offset: 1 }}>
            <FormGroup row>
              <Field
                id="currentPassword"
                name="currentPassword"
                type="password"
                placeholder="Current Password"
                required
                validate={[required]}
                component={this.renderInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 4, offset: 1 }}>
            <FormGroup row>
              <Field
                id="newPassword"
                name="newPassword"
                type="password"
                placeholder="New Password"
                required
                validate={[required, password, verifyPasswordsDoNotMatch]}
                component={this.renderInput}
              />
            </FormGroup>
          </Col>
          <div sm={{ size: 6, offset: 0 }}>
            <div className="relative">
              <pre className="font-weight-bold">{passwordRules}</pre>
            </div>
          </div>
        </Row>
        <Row>
          <Col sm={{ size: 4, offset: 1 }}>
            <FormGroup row>
              <Field
                id="verifiedNewPassword"
                name="verifiedNewPassword"
                type="password"
                placeholder="Verify New Password"
                required
                validate={[required, password, verifyPasswordsMatch]}
                component={this.renderInput}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col sm={{ size: 1, offset: 1 }}>
            <Button
              color="danger"
              block
              onClick={e => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col sm={{ size: 2 }}>
            <Button
              color="primary"
              type="submit"
              block
              disabled={invalid || submitting || pristine}
            >
              Change Password
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

// Clear the password field when form is submitted
const postSubmit = (result, dispatch) => {
  dispatch(reset("passwordForm", "currentPassword", ""));
  dispatch(reset("passwordForm", "newPassword", ""));
  dispatch(reset("passwordForm", "verifiedNewPassword", ""));
};

export default reduxForm({ form: "passwordForm", onSubmitSuccess: postSubmit })(
  PasswordForm
);
