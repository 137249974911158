import { DateTime } from "luxon";

export const isVBRCurrentlyImplemented = (vbr) => {
  const mpdImplementationDate = DateTime.fromISO(vbr.implementationDate);

  return (
    mpdImplementationDate.isValid &&
    mpdImplementationDate < DateTime.now().startOf("day")
  );
};

export const FILTER_KEYS = {
  DRUG_CATEGORY: "drugCategory",
};
