import _ from "lodash";
import {
  GET_PRODUCT_NOTIFICATIONS,
  ADD_PRODUCT_NOTIFICATION,
  REMOVE_PRODUCT_NOTIFICATION,
  CLEAR_PRODUCT_NOTIFICATIONS,
} from "../actions/types";

const initialState = {
  notifications: {},
  loading: true,
};

const productNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_NOTIFICATIONS:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          ..._.mapKeys(action.payload, "id"),
        },
        loading: false,
      };
    case ADD_PRODUCT_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.id]: action.payload,
        },
      };
    case REMOVE_PRODUCT_NOTIFICATION:
      return {
        ...state,
        notification: _.omit(state.notifications, action.payload),
      };
    case CLEAR_PRODUCT_NOTIFICATIONS:
      return initialState;
    default:
      return state;
  }
};

export default productNotificationsReducer;
