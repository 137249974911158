import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { GET_ELIGIBILITY_CORRELATIONS } from "../types";
import { handleError } from "../../utils/error/errorHandlers";
import { formatPageableParameters } from "../../utils/string/stringUtils";

export const getEligibilityCorrelations = (pageNumber, size, sorts) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    let endPoint = "/api/eligibility-correlations/?";

    endPoint += formatPageableParameters(pageNumber, size, sorts);

    const eligibilityCorrelations = await getClinicalBackend(authToken).get(
      endPoint
    );

    dispatch({
      type: GET_ELIGIBILITY_CORRELATIONS,
      payload: eligibilityCorrelations.data
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const getEligibilityCorrelationsByClient = (
  clientId,
  pageNumber,
  size,
  sorts
) => async (dispatch, getState) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;

    let endPoint = "/api/eligibility-correlations/?clientId=" + clientId;

    endPoint += formatPageableParameters(pageNumber, size, sorts);

    const eligibilityCorrelations = await getClinicalBackend(authToken).get(
      endPoint
    );

    dispatch({
      type: GET_ELIGIBILITY_CORRELATIONS,
      payload: eligibilityCorrelations.data
    });
  } catch (err) {
    handleError(err.response, dispatch);
  }
};
