import { getClinicalBackend } from "../../api/clinicalBackend";
import { tryRefreshToken } from "../auth/authAction";
import { CHANGE_PASSWORD, CLEAR_CHANGE_PASSWORD } from "../types";
import history from "../../history";
import { handleError } from "../../utils/error/errorHandlers";

export const tryChangePassword = (userId, formValues) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(tryRefreshToken());
    const authToken = getState().auth.authToken;
    const data = {
      ...formValues,
      grant_type: process.env.REACT_APP_GRANT_TYPE
    };

    const response = await getClinicalBackend(authToken).patch(
      "/api/users/" + userId,
      JSON.stringify(data)
    );

    dispatch({
      type: CHANGE_PASSWORD,
      payload: response.data
    });
    history.push("/user-profile");
  } catch (err) {
    handleError(err.response, dispatch);
  }
};

export const clearChangePassword = () => dispatch => {
  dispatch({ type: CLEAR_CHANGE_PASSWORD });
};
