import { GET_MPD_ACTIVITY_LOG } from "../actions/types";

const mpdActivityLogReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MPD_ACTIVITY_LOG:
      return action.payload;
    default:
      return state;
  }
};

export default mpdActivityLogReducer;
